// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

// Components
import ActiveLink from 'components/activeLink';

const SecondaryNavigationItem = ({
    Icon,
    href,
    additionalHrefs,
    label,
    variant,
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const isDark = variant === 'dark';
    const isLight = variant === 'light';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ActiveLink
            href={href}
            additionalHrefs={additionalHrefs}
            active="!text-blue-300 !bg-opacity-100">
            <a
                className={cc([
                    'flex items-center w-full h-32 px-16 py-8 space-x-8 text-blue-100 bg-opacity-0 whitespace-nowrap t-caption rounded-4 hover:bg-opacity-100 transition-default',
                    {
                        'bg-blue-20': isDark,
                        'bg-blue-10': isLight,
                    },
                ])}>
                {Icon && <Icon className="shrink-0 w-16 h-16" />}
                <span className="relative top-2 whitespace-nowrap">
                    {label}
                </span>
            </a>
        </ActiveLink>
    );
};

SecondaryNavigationItem.propTypes = {
    variant: t.oneOf(['dark', 'light']),
};

SecondaryNavigationItem.defaultProps = {
    variant: 'dark',
};

export default SecondaryNavigationItem;
