// React
import React from 'react';

// Utilities
import {
    useLabels,
    useContext,
    useInitiativeAndReportData,
} from 'utilities/hooks';

// Packages

// Data

// Components
import SectionContainer from 'components/_report/templates/report_5_0_pdf/sectionContainer';
import Collection from 'components/_report/templates/report_5_0_pdf/collection';
import { ReportUpdate } from 'components/_report/templates/report_5_0_pdf/_cards';

// Card Contents
import ActivityDevelopmentCard from 'components/_cardContents/activityDevelopment';
import ActivityEngagementCard from 'components/_cardContents/activityEngagement';
import ActivityEOTeachingCard from 'components/_cardContents/activityEOTeaching';
import ActivityInterventionCard from 'components/_cardContents/activityIntervention';
import ActivityPhysicalCard from 'components/_cardContents/activityPhysical';
import ActivityServiceCard from 'components/_cardContents/activityService';
import ActivityTeachingCard from 'components/_cardContents/activityTeaching';
import ActivityTeamEducationCard from 'components/_cardContents/activityTeamEducation';
import ActivityUpgradeCard from 'components/_cardContents/activityUpgrade';

// Legacy card contents
import ActivityDisseminationCard from 'components/_cardContents/activityDissemination';
import ActivityEvaluationCard from 'components/_cardContents/activityEvaluation';

const SectionActivitiesComponent = ({
    utilities,
    items: navigationItems,
    CONSTANTS,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();
    const { label, getValueLabel } = useLabels();
    const { legacyMode } = useInitiativeAndReportData();

    // ///////////////////
    // DATA
    // ///////////////////

    const reflectionIds = utilities.reportDetails
        .getTypeActivityOverviewFromReportId(REPORT_ID)
        .map(reflection => reflection.Initiative_Activity__c);

    // Get items with reflection
    const items = legacyMode
        ? utilities.activities.getAll()
        : utilities.activities
              .getAll()
              .filter(item => reflectionIds.includes(item.Id))
              .sort(
                  (a, b) =>
                      new Date(b.LastModifiedDate) -
                      new Date(a.LastModifiedDate)
              );

    const navigationItem = navigationItems[0] ?? {};

    const contentCards = {
        [CONSTANTS.ACTIVITIES.DEVELOPMENT](props) {
            return <ActivityDevelopmentCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.ENGAGEMENT](props) {
            return <ActivityEngagementCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.EO_TEACHING](props) {
            return <ActivityEOTeachingCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.INTERVENTION](props) {
            return <ActivityInterventionCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.PHYSICAL](props) {
            return <ActivityPhysicalCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.SERVICE](props) {
            return <ActivityServiceCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.TEACHING](props) {
            return <ActivityTeachingCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.TEAM_EDUCATION](props) {
            return <ActivityTeamEducationCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.UPGRADE](props) {
            return <ActivityUpgradeCard {...props} />;
        },
        // LEGACY
        [CONSTANTS.ACTIVITIES.DISSEMINATION](props) {
            return <ActivityDisseminationCard {...props} />;
        },
        [CONSTANTS.ACTIVITIES.EVALUATION](props) {
            return <ActivityEvaluationCard {...props} />;
        },
    };

    const postTitles = {
        [CONSTANTS.ACTIVITIES.DEVELOPMENT](item) {
            return getValueLabel(
                'Initiative_Activity__c.Material_Type__c',
                item.Material_Type__c
            );
        },
        [CONSTANTS.ACTIVITIES.ENGAGEMENT](item) {
            return getValueLabel(
                'Initiative_Activity__c.Engagement_Method__c',
                item.Engagement_Method__c
            );
        },
        [CONSTANTS.ACTIVITIES.EO_TEACHING](item) {
            return getValueLabel(
                'Initiative_Activity__c.EO_Teaching_Type__c',
                item.EO_Teaching_Type__c
            );
        },
        [CONSTANTS.ACTIVITIES.INTERVENTION](item) {
            return null;
        },
        [CONSTANTS.ACTIVITIES.PHYSICAL](item) {
            return getValueLabel(
                'Initiative_Activity__c.Implementation_Stage__c',
                item.Implementation_Stage__c
            );
        },
        [CONSTANTS.ACTIVITIES.SERVICE](item) {
            return getValueLabel(
                'Initiative_Activity__c.Delivery_Method__c',
                item.Delivery_Method__c
            );
        },
        [CONSTANTS.ACTIVITIES.TEACHING](item) {
            return getValueLabel(
                'Initiative_Activity__c.Teaching_Type__c',
                item.Teaching_Type__c
            );
        },
        [CONSTANTS.ACTIVITIES.TEAM_EDUCATION](item) {
            return getValueLabel(
                'Initiative_Activity__c.Education_Type__c',
                item.Education_Type__c
            );
        },
        [CONSTANTS.ACTIVITIES.UPGRADE](item) {
            return null;
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col py-48 space-y-24">
            <SectionContainer
                {...{
                    item: navigationItem,
                }}>
                <Collection
                    {...{
                        collection: {
                            items,
                            emptyLabel: label(
                                'EmptyStateWizardPageActivitiesBasic'
                            ),
                        },
                        card: {
                            preTitle(item) {
                                return getValueLabel(
                                    'Initiative_Activity__c.Activity_Type__c',
                                    item.Activity_Type__c
                                );
                            },
                            title(item) {
                                return item?.Things_To_Do__c;
                            },
                            postTitle(item) {
                                return (
                                    postTitles[item?.Activity_Type__c]?.(
                                        item
                                    ) || null
                                );
                            },
                            components(item) {
                                return {
                                    cardContent: contentCards[
                                        item?.Activity_Type__c
                                    ]
                                        ? contentCards[item?.Activity_Type__c]({
                                              item,
                                              theme: 'blue',
                                              utilities,
                                              CONSTANTS,
                                          })
                                        : null,
                                    reportUpdate(additinalProps) {
                                        return (
                                            <ReportUpdateActivity
                                                {...{
                                                    additinalProps,
                                                    item,
                                                    CONSTANTS,
                                                    utilities,
                                                }}
                                            />
                                        );
                                    },
                                };
                            },
                        },
                    }}
                />
            </SectionContainer>
        </div>
    );
};

const ReportUpdateActivity = ({
    additinalProps,
    item,
    CONSTANTS,
    utilities,
    showStatus = false,
}) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ReportUpdate
            {...{
                ...additinalProps,
                utilities,
                CONSTANTS,
                ...(showStatus
                    ? {
                          status: {
                              item,
                              relationKey: 'Initiative_Activity__c',
                              type: CONSTANTS.REPORT_DETAILS.ACTIVITY_OVERVIEW,
                          },
                      }
                    : {}),
                metrics: {
                    item,
                },
                reflection: {
                    item,
                    relationKey: 'Initiative_Activity__c',
                    type: CONSTANTS.REPORT_DETAILS.ACTIVITY_OVERVIEW,
                },
            }}
        />
    );
};

SectionActivitiesComponent.propTypes = {};

SectionActivitiesComponent.defaultProps = {};

export default SectionActivitiesComponent;
