// React
import React, { useState, useLayoutEffect, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useWindowResize } from 'use-events';

// Utilities
import { useLayoutStore } from 'utilities/store';

// Components

const FullWidthWrapper = ({ children, paddingX }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        mainNavigationWide,
        secondaryNavigationActive,
        secondaryNavigationAnimationOnly,
    } = useLayoutStore();

    // ///////////////////
    // REFS
    // ///////////////////

    const Wrapper = useRef();

    // ///////////////////
    // STATE
    // ///////////////////

    const [wrapperHeight, setWrapperHeight] = useState(0);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    if (typeof window !== 'undefined') {
        useLayoutEffect(() => {
            setWrapperHeight(Wrapper.current.offsetHeight);
        }, [useWindowResize()]);
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div
                ref={Wrapper}
                className={cc([
                    'absolute left-0 right-0 z-content',
                    {
                        'page-px': paddingX,
                        'lg:left-nav-narrow':
                            !mainNavigationWide && !secondaryNavigationActive,
                        'lg:left-nav-wide':
                            (mainNavigationWide &&
                                !secondaryNavigationActive) ||
                            (mainNavigationWide &&
                                secondaryNavigationActive &&
                                secondaryNavigationAnimationOnly),
                        'lg:left-nav-combined':
                            !mainNavigationWide &&
                            secondaryNavigationActive &&
                            !secondaryNavigationAnimationOnly,
                    },
                ])}>
                {children}
            </div>
            {/* Fakie for height and pageflow */}
            <div
                style={{ height: `${wrapperHeight}px` }}
                className="pointer-events-none z-below"></div>
        </>
    );
};

FullWidthWrapper.propTypes = {
    paddingX: t.bool,
};

FullWidthWrapper.defaultProps = {
    paddingX: true,
};

export default FullWidthWrapper;
