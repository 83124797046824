// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsInnovationComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsInnovationDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsInnovationDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Innovation_Type__c, URL__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Innovation_Type__c, URL__c].some(x => x) && (
                <CardContentPairBox>
                    {Innovation_Type__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Innovation_Type__c'
                                ),
                                value: getValueLabel(
                                    'Initiative_Result__c.Innovation_Type__c',
                                    Innovation_Type__c
                                ),
                            }}
                        />
                    )}
                    {URL__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}
        </>
    );
};

ResultsInnovationComponent.propTypes = {
    item: t.object,
};

ResultsInnovationComponent.defaultProps = {};

export { ResultsInnovationDetails };
export default ResultsInnovationComponent;
