// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsDisseminationComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsDisseminationDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsDisseminationDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Publication_Title__c,
        Year__c,
        Publisher__c,
        URL__c,
        Author__c,
        DOI__c,
    } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[
                Publication_Title__c,
                Year__c,
                Publisher__c,
                URL__c,
                Author__c,
                DOI__c,
            ].some(x => x) && (
                <CardContentPairBox>
                    {Publication_Title__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Publication_Title__c'
                                ),
                                value: Publication_Title__c,
                            }}
                        />
                    )}

                    {Year__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Dissemination'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {Publisher__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Publisher__c'
                                ),
                                value: Publisher__c,
                            }}
                        />
                    )}

                    {URL__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}

                    {Author__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Author__c'
                                ),
                                value: Author__c,
                            }}
                        />
                    )}

                    {DOI__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.DOI__c'
                                ),
                                value: DOI__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}
        </>
    );
};

ResultsDisseminationComponent.propTypes = {};

ResultsDisseminationComponent.defaultProps = {};

export { ResultsDisseminationDetails };
export default ResultsDisseminationComponent;
