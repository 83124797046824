const sdgsColors = [
    '#E32840',
    '#DCA545',
    '#4F9E3E',
    '#C31D32',
    '#FC3D2E',
    '#33BEE0',
    '#FBC230',
    '#A01C44',
    '#FB6A33',
    '#DB1C68',
    '#FB9D37',
    '#BE8A38',
    '#417D47',
    '#1D98D7',
    '#5ABE38',
    '#0D699B',
    '#1C4969',
];

export default sdgsColors;
