// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Data
import { reportTypeTheme } from 'utilities/data/reportThemes';

const ReportTypeComponent = ({ type, variant, size }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const isHorizontal = variant === 'horizontal';
    const isVertical = variant === 'vertical';
    const isDefault = size === 'default';
    const isSmall = size === 'small';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex-col items-center justify-center shrink-0 hidden space-y-12 sm:flex p-8 text-center',
                {
                    'w-128 min-h-[128px] rounded-8': isVertical && isDefault,
                    'h-128 min-w-[128px] rounded-8': isHorizontal && isDefault,
                    'w-48 min-h-[48px] rounded-4': isVertical && isSmall,
                    'h-48 min-w-[48px] rounded-4': isHorizontal && isSmall,
                },
                reportTypeTheme[type]?.[0] ?? 'bg-blue-10',
                reportTypeTheme[type]?.[1] ?? 'text-blue-80',
            ])}>
            {isDefault && (reportTypeTheme[type]?.[3] ?? null)}
            {isSmall && (reportTypeTheme[type]?.[4] ?? null)}
            {isDefault && (
                <span className="t-sh4">
                    {getValueLabel('Initiative_Report__c.Report_Type__c', type)}
                </span>
            )}
        </div>
    );
};

ReportTypeComponent.propTypes = {
    type: t.string,
    variant: t.oneOf(['horizontal', 'vertical']),
    size: t.oneOf(['default', 'small']),
};

ReportTypeComponent.defaultProps = {
    variant: 'vertical',
    size: 'default',
};

export default ReportTypeComponent;
