// React
import React from 'react';

// Utilities
import { asId } from 'utilities';

import Image from "next/legacy/image";

// Components
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';

const SectionHeaderComponent = ({ id, section }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <FullWidthWrapper paddingX={false}>
            <div
                className="page-px flex h-[328px] w-full bg-coral-20 flex-col justify-center text-blue-100 items-center"
                id={asId(id)}>
                <div className="max-w-[900px] w-full flex h-[200px] space-x-96 xl:space-x-192 justify-between">
                    <div className="flex flex-col justify-center">
                        <h2 className="t-h2">{section.title}</h2>
                        <p
                            className="t-body"
                            dangerouslySetInnerHTML={{
                                __html: section.byline,
                            }}
                        />
                    </div>
                    <div className="hidden relative md:flex w-[200px] h-full shrink-0">
                        {section.image && (
                            <Image
                                layout="fill"
                                objectFit="contain"
                                src={section.image}
                            />
                        )}
                    </div>
                </div>
            </div>
        </FullWidthWrapper>
    );
};

SectionHeaderComponent.propTypes = {};

SectionHeaderComponent.defaultProps = {};

export default SectionHeaderComponent;
