function initiativeGetters(getter, constants) {
    return {
        initiative: {
            // Returns object
            get() {
                return getter().initiative;
            },
        },
        $UserRecordAccess: {
            // Returns bool
            hasReadAccess() {
                return (
                    getter().initiative?.$UserRecordAccess?.HasReadAccess ||
                    false
                );
            },
        },
        activities: {
            // Returns object
            get(id) {
                return getter().initiative._activities[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._activities);
            },
            // Returns array
            getTypeIntervention() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.INTERVENTION ===
                        item.Activity_Type__c
                );
            },
            // Returns array
            getTypeDissemination() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.DISSEMINATION ===
                        item.Activity_Type__c
                );
            },
            // Returns array
            getTypeEngagement() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.ENGAGEMENT ===
                        item.Activity_Type__c
                );
            },
            // Returns array
            getTypePhysical() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.PHYSICAL === item.Activity_Type__c
                );
            },
            // Returns array
            getTypeTeamEducation() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.TEAM_EDUCATION ===
                        item.Activity_Type__c
                );
            },
            // Returns array
            getTypeEvaluation() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.EVALUATION ===
                        item.Activity_Type__c
                );
            },
            // Returns array
            getTypeTeaching() {
                return Object.values(getter().initiative._activities).filter(
                    item =>
                        constants.ACTIVITIES.TEACHING === item.Activity_Type__c
                );
            },
        },
        activityEmployees: {
            // Returns array
            getFromActivityId(activityId) {
                return Object.values(
                    getter().initiative._activityEmployees
                ).filter(item => item.Initiative_Activity__c === activityId);
            },
            // Returns array
            getFromEmployeeId(employeeId) {
                return Object.values(
                    getter().initiative._activityEmployees
                ).filter(
                    item => item.Initiative_Employee_Funded__c === employeeId
                );
            },
        },
        activityGoals: {
            // Returns array
            getFromActivityId(activityId) {
                return Object.values(getter().initiative._activityGoals).filter(
                    item => item.Initiative_Activity__c === activityId
                );
            },
        },
        activitySuccessMetrics: {
            // Returns object
            get(id) {
                return getter().initiative._activitySuccessMetrics[id] || {};
            },
            // Returns array
            getFromActivityId(activityId) {
                return Object.values(
                    getter().initiative._activitySuccessMetrics
                ).filter(item => item.Initiative_Activity__c === activityId);
            },
            // Returns array
            getTypePredefinedFromActivityId(activityId) {
                return this.getFromActivityId(activityId).filter(
                    item =>
                        item.Type__c ===
                        constants.ACTIVITY_SUCCESS_METRICS.INDICATOR_PREDEFINED
                );
            },
            // Returns array
            getTypeCustomFromActivityId(activityId) {
                return this.getFromActivityId(activityId).filter(
                    item =>
                        item.Type__c ===
                        constants.ACTIVITY_SUCCESS_METRICS.INDICATOR_CUSTOM
                );
            },
        },
        collaborators: {
            // Returns object
            get(id) {
                return getter().initiative._collaborators[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._collaborators);
            },
            // Return array
            getAllRelevant() {
                return Object.values(getter().initiative._collaborators).filter(
                    item =>
                        item.Type__c !==
                            constants.COLLABORATORS.FINANCIAL_RECIPIENTS &&
                        item.Type__c !==
                            constants[1.1].COLLABORATORS.MAIN_COLLABORATOR
                );
            },
            // Returns array
            getTypeAdditional() {
                return Object.values(getter().initiative._collaborators).filter(
                    item =>
                        item.Type__c ===
                        constants.COLLABORATORS.ADDITIONAL_COLLABORATORS
                );
            },
        },
        contents: {
            // Returns object
            get(id) {
                return getter().initiative._contents[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._contents);
            },
            // Returns array
            getFromMilestoneId(milestoneId) {
                return Object.values(getter().initiative._contents).filter(
                    item => item.Initiative_Milestone__c === milestoneId
                );
            },
        },
        employeesFunded: {
            // Returns object
            get(id) {
                return getter().initiative._employeesFunded[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._employeesFunded);
            },
        },
        employeeFundedFundings: {
            // Returns object
            get(id) {
                return getter().initiative._employeeFundedFundings?.[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(
                    getter().initiative._employeeFundedFundings ?? {}
                );
            },
            // Returns array
            getFromEmployeesFundedId(fundedId) {
                return Object.values(
                    getter().initiative._employeeFundedFundings ?? {}
                ).filter(
                    item => item.Initiative_Employee_Funded__c === fundedId
                );
            },
        },
        funders: {
            // Returns object
            get(id) {
                return getter().initiative._funders[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._funders);
            },
            // Returns object
            getFromAccountId(accountId) {
                return (
                    Object.values(getter().initiative._funders).find(
                        funder => funder.Account__c === accountId
                    ) || {}
                );
            },
            // Returns array
            getTypeLead() {
                return Object.values(getter().initiative._funders).filter(
                    item => constants.FUNDERS.LEAD_FUNDER === item.Type__c
                );
            },
            // Returns array
            getTypeAdditional() {
                return Object.values(getter().initiative._funders).filter(
                    item => constants.FUNDERS.ADDITIONAL_FUNDER === item.Type__c
                );
            },
            // Returns array
            getFromFundingId(fundingId) {
                return Object.values(getter().initiative._funders).filter(
                    item => item.Initiative_Funding__c === fundingId
                );
            },
            // Returns array
            getTypeLeadFromFundingId(fundingId) {
                return Object.values(getter().initiative._funders).filter(
                    item =>
                        item.Initiative_Funding__c === fundingId &&
                        constants.FUNDERS.LEAD_FUNDER === item.Type__c
                );
            },
            // Returns array
            getTypeAdditionalFromFundingId(fundingId) {
                return Object.values(getter().initiative._funders).filter(
                    item =>
                        item.Initiative_Funding__c === fundingId &&
                        constants.FUNDERS.ADDITIONAL_FUNDER === item.Type__c
                );
            },
        },
        funderTags: {
            // Returns bool
            isReady() {
                return !!getter().initiative._funderTags;
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._funderTags) || [];
            },
            // Returns array
            getTypeFromCollectionId(type, id) {
                return (
                    Object.values(getter().initiative._funderTags).filter(
                        item =>
                            item.Type__c === type && item.Collection__c === id
                    ) || []
                );
            },
        },
        fundings: {
            // Returns object
            get(id) {
                return getter().initiative._fundings[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._fundings);
            },
        },
        fundingRecipients: {
            // Returns array
            getAll() {
                return Object.values(getter().initiative._fundingRecipients);
            },
            // Returns array
            getTypeMainApplicant() {
                return Object.values(
                    getter().initiative._fundingRecipients
                ).filter(
                    item =>
                        item.Relationship__c ===
                        constants.FUNDING_RECIPIENTS.MAIN_APPLICANTS
                );
            },
            // Returns array
            getTypeCoApplicant() {
                return Object.values(
                    getter().initiative._fundingRecipients
                ).filter(
                    item =>
                        item.Relationship__c ===
                        constants.FUNDING_RECIPIENTS.CO_APPLICANTS
                );
            },
            // Returns object
            getTypeMainApplicantFromFundingId(fundingId) {
                return (
                    Object.values(getter().initiative._fundingRecipients)
                        .filter(
                            item => item.Initiative_Funding__c === fundingId
                        )
                        .find(
                            item =>
                                item.Relationship__c ===
                                constants.FUNDING_RECIPIENTS.MAIN_APPLICANTS
                        ) || {}
                );
            },
            // Returns array
            getTypeCoApplicantsFromFundingId(fundingId) {
                return (
                    Object.values(getter().initiative._fundingRecipients)
                        .filter(
                            item => item.Initiative_Funding__c === fundingId
                        )
                        .filter(
                            item =>
                                item.Relationship__c ===
                                constants.FUNDING_RECIPIENTS.CO_APPLICANTS
                        ) || []
                );
            },
            // Returns array
            getFromFundingId(fundingId) {
                return (
                    Object.values(
                        getter().initiative._fundingRecipients
                    ).filter(
                        item => item.Initiative_Funding__c === fundingId
                    ) || []
                );
            },
        },
        goals: {
            // Returns object
            get(id) {
                return getter().initiative._goals[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._goals);
            },
            // Returns array
            getTypePredefined() {
                return (
                    Object.values(getter().initiative._goals).filter(
                        item => item.Type__c === constants.GOALS.GOAL_PREDEFINED
                    ) || []
                );
            },
            // Returns array
            getTypeCustom() {
                return (
                    Object.values(getter().initiative._goals).filter(item =>
                        constants.GOALS.GOAL_CUSTOM.includes(item.Type__c)
                    ) || []
                );
            },
            // Returns array
            getWithFunderObjective() {
                return (
                    Object.values(getter().initiative._goals).filter(
                        item => item.Funder_Objective__c
                    ) || []
                );
            },
        },
        kpis: {
            get(id) {
                // Returns object
                return getter().initiative._kpis[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._kpis);
            },
        },
        locations: {
            get(id) {
                // Returns object
                return getter().initiative._locations[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._locations);
            },
        },
        milestones: {
            // Returns object
            get(id) {
                return getter().initiative._milestones[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._milestones);
            },
            // Returns object
            getPrevious(milestoneId) {
                const milestone = this.get(milestoneId);
                const sortedMilestones = Object.values(
                    getter().initiative._milestones
                ).sort(
                    (a, b) =>
                        new Date(a?.Deadline__c) - new Date(b?.Deadline__c)
                );
                const currentMilestoneIndex = sortedMilestones.findIndex(
                    x => x.Deadline__c === milestone?.Deadline__c
                );
                return sortedMilestones[currentMilestoneIndex - 1] ?? {};
            },
            // Returns object
            getNext(milestoneId) {
                const milestone = this.get(milestoneId);
                const sortedMilestones = Object.values(
                    getter().initiative._milestones
                ).sort(
                    (a, b) =>
                        new Date(a?.Deadline__c) - new Date(b?.Deadline__c)
                );
                const currentMilestoneIndex = sortedMilestones.findIndex(
                    x => x.Deadline__c === milestone?.Deadline__c
                );
                return sortedMilestones[currentMilestoneIndex + 1] ?? {};
            },
        },
        milestoneActivities: {
            // Returns array
            getFromMilestoneId(milestoneId) {
                return Object.values(
                    getter().initiative._milestoneActivities
                ).filter(item => item.Initiative_Milestone__c === milestoneId);
            },
        },
        milestoneKpis: {
            get(id) {
                // Returns object
                return getter().initiative._milestoneKpis[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._milestoneKpis);
            },
            // Returns array
            getFromMilestoneId(milestoneId) {
                return Object.values(getter().initiative._milestoneKpis).filter(
                    item => item.Initiative_Milestone__c === milestoneId
                );
            },
        },
        regulations: {
            // Returns object
            get(id) {
                return getter().initiative._regulations[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._regulations);
            },
            // Returns array
            getFromActivityId(activityId) {
                return Object.values(getter().initiative._regulations).filter(
                    item => item.Initiative_Activity__c === activityId
                );
            },
        },
        reportDetailEntries: {},
        reportDetailGoals: {},
        reportDetails: {
            // Returns object
            get(id) {
                return getter().initiative._reportDetails[id] || {};
            },
            // Returns object
            getFromActivityId(activityId) {
                return (
                    Object.values(getter().initiative._reportDetails).find(
                        item => item.Initiative_Activity__c === activityId
                    ) || {}
                );
            },
            // Returns object
            getFromActivityIdAndReportId(activityId, reportId) {
                return (
                    Object.values(getter().initiative._reportDetails).find(
                        item =>
                            item.Initiative_Activity__c === activityId &&
                            item.Initiative_Report__c === reportId
                    ) || {}
                );
            },
            // Returns object
            getFromResultId(resultId) {
                return (
                    Object.values(getter().initiative._reportDetails).find(
                        item => item.Initiative_Result__c === resultId
                    ) || {}
                );
            },
            // Returns object
            getFromResultIdAndReportId(resultId, reportId) {
                return (
                    Object.values(getter().initiative._reportDetails).find(
                        item =>
                            item.Initiative_Result__c === resultId &&
                            item.Initiative_Report__c === reportId
                    ) || {}
                );
            },
            // Returns array
            getFromReportId(reportId) {
                return Object.values(getter().initiative._reportDetails).filter(
                    item => item.Initiative_Report__c === reportId
                );
            },
            // Returns object
            getTypeMilestoneFromMilestoneId(milestoneId) {
                return (
                    Object.values(getter().initiative._reportDetails).find(
                        item =>
                            item.Type__c ===
                                constants.REPORT_DETAILS.MILESTONE &&
                            item.Initiative_Milestone__c === milestoneId
                    ) || {}
                );
            },
            // Returns array
            getTypeInfluenceOnPolicy() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.INFLUENCE_ON_POLICY
                );
            },
            // Returns array
            getTypeEvaluation() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item => item.Type__c === constants.REPORT_DETAILS.EVALUATION
                );
            },
            // Returns array
            getTypeActivityOverview() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.ACTIVITY_OVERVIEW
                );
            },
            // Returns array
            getTypeFunderOverview() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.FUNDER_OVERVIEW
                );
            },
            // Returns array
            getTypeCollaboratorOverview() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.COLLABORATOR_OVERVIEW
                );
            },
            // Returns array
            getTypeEmployeesFundedOverview() {
                return Object.values(getter().initiative._reportDetails).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.EMPLOYEES_FUNDED_OVERVIEW
                );
            },
            // Returns array
            getTypeInfluenceOnPolicyFromReportId(reportId) {
                return this.getFromReportId(reportId).filter(
                    item =>
                        item.Type__c ===
                        constants.REPORT_DETAILS.INFLUENCE_ON_POLICY
                );
            },
            // Returns array
            getTypeEvaluationFromReportId(reportId) {
                return this.getFromReportId(reportId).filter(
                    item => item.Type__c === constants.REPORT_DETAILS.EVALUATION
                );
            },
            // Returns object
            getTypeEmployeesFundedFromReportId(reportId) {
                return (
                    this.getFromReportId(reportId).find(
                        item =>
                            item.Type__c ===
                            constants.REPORT_DETAILS.EMPLOYEES_FUNDED_OVERVIEW
                    ) || {}
                );
            },
            // Returns array
            getTypeActivityOverviewFromReportId(reportId) {
                return (
                    this.getFromReportId(reportId).filter(
                        item =>
                            item.Type__c ===
                            constants.REPORT_DETAILS.ACTIVITY_OVERVIEW
                    ) || []
                );
            },
            // Returns array
            getTypeResultsFromReportId(reportId) {
                return (
                    this.getFromReportId(reportId).filter(
                        item =>
                            item.Type__c === constants.REPORT_DETAILS.RESULTS
                    ) || []
                );
            },
        },
        reports: {
            // Returns object
            get(id) {
                return getter().initiative._reports[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._reports);
            },
            // Returns array
            getFromFundingId(fundingId) {
                return Object.values(getter().initiative._reports).filter(
                    item => item.Funding_Report__c === fundingId
                );
            },
            // Returns object
            getFromMilestoneId(milestoneId) {
                return (
                    Object.values(getter().initiative._reports).find(
                        item => item.Initiative_Milestone__c === milestoneId
                    ) || {}
                );
            },
        },
        results: {
            // Returns object
            get(id) {
                return getter().initiative._results[id] || {};
            },
            // Returns array
            getAll() {
                return Object.values(getter().initiative._results);
            },
            // Returns array
            getTypeCustomOutput() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.CUSTOM_OUTPUT === item.Type__c
                );
            },
            // Returns array
            getTypeCustomOutcome() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.CUSTOM_OUTCOME === item.Type__c
                );
            },
            // Returns array
            getTypeResearch() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.RESEARCH === item.Type__c
                );
            },
            // Returns array
            getTypeKnowledge() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.KNOWLEDGE === item.Type__c
                );
            },
            // Returns array
            getTypeInfluenceOnPolicy() {
                return Object.values(getter().initiative._results).filter(
                    item =>
                        constants.RESULTS.INFLUENCE_ON_POLICY === item.Type__c
                );
            },
            // Returns array
            getTypeInnovation() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.INNOVATION === item.Type__c
                );
            },
            // Returns array
            getTypeAward() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.AWARD === item.Type__c
                );
            },
            // Returns array
            getTypeInventionDisclosure() {
                return Object.values(getter().initiative._results).filter(
                    item =>
                        constants.RESULTS.INVENTION_DISCLOSURE === item.Type__c
                );
            },
            // Returns array
            getTypeIntellectualProperty() {
                return Object.values(getter().initiative._results).filter(
                    item =>
                        constants.RESULTS.INTELLECTUAL_PROPERTY === item.Type__c
                );
            },
            // Returns array
            getTypeCompany() {
                return Object.values(getter().initiative._results).filter(
                    item => constants.RESULTS.COMPANY === item.Type__c
                );
            },
            // Returns array
            getTypeProductsAndInventions() {
                return Object.values(getter().initiative._results).filter(
                    item =>
                        constants.RESULTS.PRODUCTS_AND_INVENTIONS ===
                        item.Type__c
                );
            },
        },
        resultActivities: {
            // Returns array
            getFromResultId(resultId) {
                return Object.values(
                    getter().initiative._resultActivities
                ).filter(item => item.Initiative_Result__c === resultId);
            },
        },
        resultGoals: {
            // Returns array
            getFromResultId(resultId) {
                return Object.values(getter().initiative._resultGoals).filter(
                    item => item.Initiative_Result__c === resultId
                );
            },
        },
        tags: {
            // Returns object
            get(id) {
                return getter().initiative._tags[id] || {};
            },
            // Returns arrat
            getAll() {
                return Object.values(getter().initiative._tags);
            },
            // Returns array
            getFromFunderId(funderId) {
                return Object.values(getter().initiative._tags).filter(
                    item => item.Tag__r?.Account__c === funderId
                );
            },
            // Returns array
            getTypeFromFunderId(type, funderId) {
                return Object.values(getter().initiative._tags).filter(
                    item =>
                        item.Tag__r?.Account__c === funderId &&
                        item.Tag_Type__c === type
                );
            },
            // Returns array
            getFromRelationKeyId(relationKey, id) {
                return Object.values(getter().initiative._tags).filter(
                    item => item[relationKey] === id
                );
            },
            // Returns array
            getFromRelationKeyIdAndFunderId(relationKey, id, funderId) {
                return Object.values(getter().initiative._tags)
                    .filter(item => item[relationKey] === id)
                    .filter(item => item.Tag__r?.Account__c === funderId);
            },
            getTypeGoal() {
                return Object.values(getter().initiative._tags).filter(
                    item => item.Tag_Type__c === constants.TAGS.GOAL
                );
            },
        },
        teamMembers: {
            // Returns array
            getAll() {
                return Object.values(getter().initiative._teamMembers);
            },
        },
        updateContents: {
            // Returns object
            getFromUpdateId(initiativeUpdateId) {
                return (
                    Object.values(getter().initiative._updateContents).find(
                        item => item.Initiative_Update__c === initiativeUpdateId
                    ) || {}
                );
            },
        },
        updates: {
            // Returns object
            get(id) {
                return getter().initiative._updates[id] || {};
            },
            // Returns array
            getTypeLogbookUpdate() {
                return Object.values(getter().initiative._updates).filter(
                    item =>
                        item.Type__c === constants.REPORT_DETAILS.LOGBOOK_UPDATE
                );
            },
        },
        // Legacy reports
        1.1: {
            collaborators: {
                // Returns array
                getTypeMain() {
                    return Object.values(
                        getter().initiative._collaborators
                    ).filter(
                        item =>
                            item.Type__c ===
                            constants[1.1].COLLABORATORS.MAIN_COLLABORATOR
                    );
                },
                // Returns array
                getTypeApplicantsCreate() {
                    return Object.values(
                        getter().initiative._collaborators
                    ).filter(item =>
                        constants[1.1].COLLABORATORS.APPLICANTS_CREATE.includes(
                            item.Type__c
                        )
                    );
                },
            },
            goals: {
                // Returns object
                getTypePredefined() {
                    return (
                        Object.values(getter().initiative._goals).find(
                            item =>
                                item.Type__c === constants.GOALS.GOAL_PREDEFINED
                        ) || {}
                    );
                },
            },
        },
    };
}

export default initiativeGetters;
