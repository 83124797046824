import { useEffect } from 'react';

import {
    useWizardNavigationStore,
    useInitiativeDataStore,
} from 'utilities/store';
import { useContext } from 'utilities/hooks';

// Method: Default Form error/validation handler
function error(error) {
    console.warn('Form invalid', error);
    // return error;
    throw error;
}

// Hook for handling which current submithandler that are set in Wizard Navigation Store
// The Submithandler is run when the "continue" button is clicked
// The submit will be context specific
const useWizardSubmit = (submitOptions = {}, dependencies = []) => {
    const { setCurrentSubmitHandler } = useWizardNavigationStore();
    const { initiative } = useInitiativeDataStore();
    const { CONTEXT } = useContext();

    useEffect(() => {
        setTimeout(() => {
            if (submitOptions[CONTEXT]) {
                const [form, submit] = submitOptions[CONTEXT]();

                setCurrentSubmitHandler(
                    form ? form.handleSubmit(submit, error) : null
                );
            }
            if (submitOptions.noContext) {
                const [form, submit] = submitOptions.noContext();
                setCurrentSubmitHandler(
                    form ? form.handleSubmit(submit, error) : null
                );
            }
        }, 100);
    }, [initiative, CONTEXT, ...dependencies]);

    return null;
};

export default useWizardSubmit;
