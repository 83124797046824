// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

// Components

const ChartWrapper = ({
    heading,
    size,
    chart,
    legend,
    attention,
    maxHeight,
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const is1 = size === 1;
    const is2 = size === 2;
    const is3 = size === 3;
    const is4 = size === 4;
    const isFit = size === 'fit';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex flex-col h-full p-32 space-y-32 bg-white rounded-8',
                {
                    'col-span-12 md:col-span-6 2xl:col-span-4': is1,
                    'col-span-12 md:col-span-6': is2,
                    'col-span-12 md:col-span-6 2xl:col-span-8': is3,
                    'col-span-12': is4,
                    'w-full': isFit,
                },
            ])}>
            <div className="flex justify-between">
                <h3 className="text-blue-100 t-sh4 !font-bold">{heading}</h3>
            </div>
            <div
                className={cc([
                    'grid grid-cols-12 gap-16',
                    {
                        ' max-h-[900px] overflow-y-scroll': maxHeight,
                    },
                ])}>
                <div
                    className={cc([
                        {
                            'col-span-12':
                                is1 || is2 || is4 || (!legend && is3),
                            'col-span-12 2xl:col-span-8':
                                (legend && is3) || isFit,
                        },
                    ])}>
                    {chart}
                </div>
                {legend && (
                    <div
                        className={cc([
                            {
                                'col-span-12': is1 || is2 || is4,
                                'col-span-12 2xl:col-span-4': is3 || isFit,
                            },
                        ])}>
                        {legend}
                    </div>
                )}
            </div>
            {attention && <div className="col-span-12">{attention}</div>}
        </div>
    );
};

ChartWrapper.propTypes = {
    heading: t.string.isRequired,
    size: t.oneOf([1, 2, 3, 4, 'fit']).isRequired,
    chart: t.element.isRequired,
};

ChartWrapper.defaultProps = {
    maxHeight: false,
};

export default ChartWrapper;
