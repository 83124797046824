// React
import React from 'react';

// Components

const HeaderImageComponent = ({ initiative }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return initiative?.Hero_Image_URL__c ? (
        <div className="h-[260px] w-full">
            <img
                className="w-full z-below max-h-[260px] object-cover"
                src={initiative?.Hero_Image_URL__c}
            />
        </div>
    ) : null;
};

HeaderImageComponent.propTypes = {};

HeaderImageComponent.defaultProps = {};

export default HeaderImageComponent;
