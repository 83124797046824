// Packages
import { useRouter } from 'next/router';
import _get from 'lodash.get';
import _uniqBy from 'lodash.uniqby';

// Utilities
import { useDynamicLabelsDataStore } from 'utilities/store';

// Data
import allLabelStuff from '_labels/labels';

const {
    labels,
    texts,
    objects,
    pickLists,
    controlledPickLists,
    staticData,
    wizardSections,
    onboardings,
} = allLabelStuff;

const useLabels = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { funderTagLabels } = useDynamicLabelsDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////
    const { locale } = useRouter();

    // ///////////////////
    // DATA
    // ///////////////////

    // TODO FunderId logic
    const funderId = false;

    // ///////////////////
    // METHODS
    // ///////////////////

    function missing(path, label = '') {
        return process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION' ? (
            <span className="px-6 pt-4 pb-2 font-bold text-white bg-error rounded-4 text-14">
                {path} {label}
            </span>
        ) : null;
    }

    function label(path, hideError) {
        let label;
        // 1. Funder based
        if (funderId) {
            label = _get(labels, `${path}.${funderId}.${locale}`);
            if (typeof label === 'string') {
                // Send empty if "hidden on purpose"
                return label === 'hidden on purpose' ? '' : label;
            }
        }

        // 2. Default
        label = _get(labels, `${path}.${locale}`);
        if (typeof label === 'string') {
            // Send empty if "hidden on purpose"
            return label === 'hidden on purpose' ? '' : label;
        }

        // 3. Missing
        label = hideError ? '' : missing(path, `(label [${locale}])`);
        // Send empty if "hidden on purpose"
        return label === 'hidden on purpose' ? '' : label;
    }

    function text(path, hideError) {
        let text;
        // 1. Funder based
        if (funderId) {
            text = _get(texts, `${path}.${funderId}.${locale}`);
            if (typeof text === 'string') {
                return text === 'hidden on purpose' ? '' : text;
            }
        }

        // 2. Default
        text = _get(texts, `${path}.${locale}`);
        if (typeof text === 'string') {
            return text === 'hidden on purpose' ? '' : text;
        }

        // 3. Missing
        text = hideError ? '' : missing(path, `(text [${locale}])`);
        return text === 'hidden on purpose' ? '' : text;
    }

    const object = {
        label(path) {
            let label;
            // 1. Funder based
            if (funderId) {
                label = _get(objects, `${path}.${funderId}.${locale}.label`);
                if (typeof label === 'string') {
                    return label;
                }
            }
            // 2. Default
            label = _get(objects, `${path}.${locale}.label`);
            if (typeof label === 'string') {
                return label;
            }
            // 3. Missing
            label = missing(path, `(object label [${locale}])`);
            return label;
        },
        subLabel(path) {
            let label;
            // 1. Funder based
            if (funderId) {
                label = _get(objects, `${path}.${funderId}.${locale}.helpText`);
                if (typeof label === 'string') {
                    return label;
                }
            }
            // 2. Default
            label = _get(objects, `${path}.${locale}.helpText`);
            if (typeof label === 'string') {
                return label;
            }
            // 3. Missing
            label = missing(path, `(object help text [${locale}])`);
            return label;
        },
        config(path) {
            let label;
            let subLabel;
            let errorLabel = null;
            let required = false;
            // 1. Funder based
            if (funderId) {
                label = _get(objects, `${path}.${funderId}.${locale}.label`);
                subLabel = _get(
                    objects,
                    `${path}.${funderId}.${locale}.helpText`
                );
                errorLabel = _get(
                    objects,
                    `${path}.${funderId}.${locale}.error`
                );
                required = _get(
                    objects,
                    `${path}.${funderId}.${locale}.required`
                );
            }

            // 2. Default
            label = _get(
                objects,
                `${path}.${locale}.label`,
                missing(path, `(object label [${locale}])`)
            );
            subLabel = _get(
                objects,
                `${path}.${locale}.helpText`,
                missing(path, `(object help text [${locale}])`)
            );
            errorLabel = _get(objects, `${path}.${locale}.error`);
            required = _get(objects, `${path}.${locale}.required`);

            return {
                label,
                subLabel,
                errorLabel,
                required,
            };
        },
    };

    function dataSet(path) {
        return _get(staticData, `${path}.${locale}`, []);
    }

    function pickList(path) {
        let foundationPickList = {};
        let pickList;

        // 1. Funder based
        if (funderId) {
            foundationPickList = _get(
                pickLists,
                `${path}.${funderId}.${locale}`
            );
        }

        // 2. Default
        pickList = _get(pickLists, `${path}.${locale}`);
        if (typeof pickList === 'object') {
            return Object.values({ ...pickList, ...foundationPickList });
        }

        // 3. Missing
        return [
            {
                label: `${path}, (pickList [${locale}])`,
                value: 'missing',
            },
        ];
    }

    function controlledPickList(path, controllerValue) {
        let foundationPickList = [];
        let pickList;
        // 1. Funder based
        if (funderId) {
            foundationPickList = _get(
                controlledPickLists,
                `${path}.${funderId}.${locale}.${controllerValue}`,
                []
            );
        }
        // 2. Default
        pickList = _get(
            controlledPickLists,
            `${path}.${locale}.${controllerValue}`
        );
        if (Array.isArray(pickList)) {
            return _uniqBy([...foundationPickList, ...pickList], 'value');
        }
        // 3. Missing
        return [
            {
                label: `${path}.${controllerValue}, (controlled pickList [${locale}])`,
                value: 'missing',
            },
        ];
    }

    function getValueLabel(path, value, controlled = false) {
        let valuesArray;

        // Controlled picklist (build values array of all controlled values)
        if (controlled) {
            let foundationPickList = [];
            let pickList;
            // 1. Funder based
            if (funderId) {
                foundationPickList = Object.values(
                    _get(
                        controlledPickLists,
                        `${path}.${funderId}.${locale}`,
                        {}
                    )
                ).reduce((acc, values) => [...acc, ...values], []);
            }

            // 2. Default
            pickList = Object.values(
                _get(controlledPickLists, `${path}.${locale}`, {})
            ).reduce((acc, values) => [...acc, ...values], []);

            // Combine
            valuesArray = _uniqBy(
                [...foundationPickList, ...pickList],
                'value'
            );
        } else {
            // PickList value
            valuesArray = pickList(path);
        }

        // Return value
        return valuesArray.find(v => v.value === value)?.label ?? '';
    }

    function labelTodo(label, path) {
        return <span title={path}>{label}</span>;
    }

    function wizardSection(sectionId) {
        return {
            ..._get(wizardSections, `${sectionId}.${locale}`, {}),
            image: _get(
                wizardSections,
                `${sectionId}.image.responsiveImage.src`,
                null
            ),
        };
    }

    function onboarding(page, access) {
        return _get(onboardings, `${page}.${access}.${locale}`, []);
    }

    function tagLabel(tag) {
        return _get(
            funderTagLabels,
            `${tag?.Name}.${locale}`,
            tag?.Tag_Name__c
        );
    }

    function log() {
        console.log({
            labels,
            texts,
            objects,
            pickLists,
            controlledPickLists,
            staticData,
            onboardings,
        });
    }

    // ///////////////////
    // RETURN
    // ///////////////////

    return {
        label,
        onboarding,
        text,
        object,
        pickList,
        controlledPickList,
        getValueLabel,
        dataSet,
        labelTodo,
        log,
        wizardSection,
        tagLabel,
    };
};

export default useLabels;
