// React
import React, { useState, useMemo } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

// Components
import ToolTip from 'components/tooltip';

const Legend = ({ legend, data, tooltip }) => {
    // ///////////////////
    // STATE
    // ///////////////////

    const [activeSection, setActiveSection] = useState(null);

    const sortedLegendData = useMemo(
        () =>
            [...data].sort((a, b) =>
                legend.key(a).localeCompare(legend.key(b))
            ),
        []
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col p-24 border-2 border-blue-20 rounded-8">
            <div className="mb-16 t-caption-bold">{legend.title}</div>
            <div
                className={cc([
                    'flex flex-wrap',
                    {
                        'flex-col': legend.direction !== 'horizontal',
                        'flex-row gap-x-20': legend.direction === 'horizontal',
                    },
                ])}>
                {sortedLegendData.map((item, index) => (
                    <div
                        key={legend.key(item)}
                        className={cc([
                            'py-4',
                            {
                                'cursor-pointer': tooltip,
                            },
                        ])}
                        onMouseEnter={() => {
                            setActiveSection(item);
                        }}
                        onMouseLeave={() => setActiveSection(null)}>
                        {legend.label(item)}
                    </div>
                ))}
            </div>
            {tooltip && <ToolTip {...{ item: activeSection, tooltip }} />}
        </div>
    );
};

Legend.propTypes = {
    legend: t.shape({
        title: t.string.isRequired,
        label: t.func.isRequired,
        key: t.func.isRequired,
    }),
    data: t.array.isRequired,
    tooltip: t.object,
};

Legend.defaultProps = {};

export default Legend;
