import WizardFlow from './wizardFlow';
import { CONTEXTS } from '../contexts';

// ///////////////////
// WIZARD FLOWS
// ///////////////////

const flow = new WizardFlow({
    context: CONTEXTS.CREATE,
    pages: [
        'funderTagging',
        'funding',
        'initiativeSetup',
        'initiativeTags',
        'nameAndCategory',
        'shareWithContacts',
        'strategicThemes',
    ],
});

// ///////////////////
// CREATE STRUCTURES
// ///////////////////

const {
    nameAndCategory,
    initiativeSetup,
    funding,
    funderTagging,
    initiativeTags,
    shareWithContacts,
    strategicThemes,
} = flow.pages;

const createStructures = {
    Default: [
        flow.metaPages.start(),
        flow.focusedSection([
            initiativeSetup,
            nameAndCategory,
            funding,
            funderTagging,
            initiativeTags,
            strategicThemes,
            shareWithContacts,
        ]),
        flow.metaPages.complete(),
        flow.metaPages.done,
    ],
};

// Export for permissions
const p = flow.pages;
export { p };

// Default export
export default createStructures;
