// React
import React, { useState } from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

const BaseCardComponent = ({ preTitle, title, postTitle, components }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [hasUpdate, setHasUpdate] = useState(false);
    const [updates, setUpdates] = useState({});

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <table className="w-full mb-32">
            {/* Content wrapper */}
            <tr>
                <td className="pb-32">
                    <div className="flex flex-col">
                        {/* Card preTitle */}
                        <div className="mb-4 t-h6 text-blue-60">{preTitle}</div>

                        {/* Title wrapper */}
                        <div className="mb-4 text-blue-100 break-keep t-h4">
                            {title}
                        </div>

                        {/* Card postTitle */}
                        <div className="t-body text-blue-60 !-mt-2">
                            {postTitle}
                        </div>
                    </div>
                </td>
            </tr>

            {/* Card provide report update wrapper */}
            {components?.reportUpdate && (
                <tr>
                    <td className="pb-32">
                        {components?.reportUpdate({
                            setHasUpdate,
                            hasUpdate,
                            setUpdates,
                            updates,
                        })}
                    </td>
                </tr>
            )}

            {/* Card content */}
            {components?.cardContent && (
                <tr>
                    <td className="pb-16">
                        <>
                            <h4 className="t-h5">
                                {label('PdfReportObjectDetails')}
                            </h4>
                            {components?.cardContent}
                        </>
                    </td>
                </tr>
            )}

            {/* Card related items wrapper */}
            {components?.relatedItems && (
                <tr>
                    <td className="pb-32">{components?.relatedItems}</td>
                </tr>
            )}

            {/* Card child items wrapper */}
            {components?.childCollection && (
                <tr>
                    <td className="pb-32">{components?.childCollection}</td>
                </tr>
            )}
        </table>
    );
};

BaseCardComponent.propTypes = {
    preTitle: t.string,
    title: t.string.isRequired,
    postTitle: t.string,
    components: t.shape({
        cardContent: t.element,
        relatedItems: t.element,
        childCollection: t.element,
    }),
};

BaseCardComponent.defaultProps = {
    preTitle: '',
    title: '',
    postTitle: '',
    components: {
        cardContent: null,
        relatedItems: null,
        childCollection: null,
    },
};

export default BaseCardComponent;
