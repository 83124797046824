const wizardPages = [
    {
        id: 'activities',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'acknowledgementPolicy',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'backgroundInformation',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'collaborators',
        permissions: {
            add: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            update: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            delete: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'employeesFunded',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'endOfGrantReflections',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'evaluations',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'facilities',
        permissions: {
            add: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            update: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            delete: [
                'funder.admin',
                'funder.collaborator',
                'grantee.admin',
                'grantee.collaborator',
                'super',
            ],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'funding',
        permissions: {
            add: ['funder.admin', 'grantee.admin', 'super'],
            update: ['funder.admin', 'grantee.admin', 'super'],
            delete: ['funder.admin', 'grantee.admin', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'funderTagging',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'goals',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'initiativeSetup',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'initiativeTags',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: ['funder.admin', 'super'],
        },
    },
    {
        id: 'nameAndCategory',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'openToUsers',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'overview',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'regulations',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'reportSummary',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'results',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator', 'super'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'risks',
        permissions: {
            add: ['grantee.admin', 'grantee.collaborator', 'super'],
            update: ['grantee.admin', 'grantee.collaborator', 'super'],
            delete: ['grantee.admin', 'grantee.collaborator'],
            view: [
                'funder.admin',
                'funder.collaborator',
                'funder.viewer',
                'grantee.admin',
                'grantee.collaborator',
                'grantee.viewer',
                'super',
            ],
        },
    },
    {
        id: 'shareWithContacts',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: ['funder.admin', 'grantee.admin', 'super'],
        },
    },
    {
        id: 'strategicThemes',
        permissions: {
            add: ['funder.admin', 'super'],
            update: ['funder.admin', 'super'],
            delete: ['funder.admin', 'super'],
            view: ['funder.admin', 'super'],
        },
    },
];

export default wizardPages;
