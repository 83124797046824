// Packages
import _camelCase from 'lodash.camelcase';
import _kebabCase from 'lodash.kebabcase';
import { pascalCase } from 'pascal-case';

// Data
import wizardPages from './wizardPages';
import { CONTEXTS, ACTIONS } from '../contexts';

class WizardFlow {
    constructor({ context = '', action = '', pages }) {
        this.context = context;
        this.contextName = context ? pascalCase(context) : '';
        this.contextUrl = `${context.toLowerCase()}-`;
        this.action = action;
        this.actionName = action ? pascalCase(action) : '';
        this.actionUrl = action ? `-${action.toLowerCase()}` : '';
        this.pages = this.generatePages(pages);
        this.metaPages = this.generateMetaPages();
    }

    _generateUrl(baseUrl) {
        switch (this.context) {
            case CONTEXTS.CREATE:
            case CONTEXTS.INITIATIVE:
                return ({ initiativeId }) =>
                    `/${this.context}/${initiativeId}/${baseUrl}`;
            case CONTEXTS.REPORT:
                return ({ initiativeId, reportId }) =>
                    `/${this.context}/${initiativeId}/${baseUrl}/${reportId}`;
            default:
                break;
        }
    }

    _generateDoneUrl() {
        switch (this.context) {
            case CONTEXTS.CREATE:
            case CONTEXTS.INITIATIVE:
                return ({ initiativeId }) => `/${initiativeId}/overview`;
            case CONTEXTS.REPORT:
                return ({ initiativeId, reportId }) =>
                    `/${initiativeId}/reports/${reportId}`;
            default:
                break;
        }
    }

    _generatePage(id, permissions) {
        const baseUrl = _kebabCase(id);
        const name = pascalCase(id);
        return {
            title: `${this.contextName}${this.actionName}WizardMenu${name}`,
            labels: {
                form: {
                    title: `${this.contextName}${this.actionName}Wizard${name}Heading`,
                    preamble: `${this.contextName}${this.actionName}Wizard${name}SubHeading`,
                },
                help: {
                    why: `${this.contextName}${this.actionName}Wizard${name}HelpWhy`,
                    what: `${this.contextName}${this.actionName}Wizard${name}HelpWhat`,
                    guide: `${this.contextName}${this.actionName}Wizard${name}HelpGuide`,
                },
            },
            baseUrl: `${baseUrl}`,
            url: this._generateUrl(`${baseUrl}`),
            permissions,
        };
    }

    generateMetaPages() {
        const _self = this;
        return {
            start() {
                return {
                    hideInNavigation: true,
                    metaItem: true,
                    labels: {
                        form: {
                            title: `${_self.contextName}${_self.actionName}WizardWelcomeHeading`,
                            preamble: `${_self.contextName}${_self.actionName}WizardWelcomeSubHeading`,
                        },
                    },
                    baseUrl: `${_self.contextUrl}start${_self.actionUrl}`,
                    url: _self._generateUrl(
                        `${_self.contextUrl}start${_self.actionUrl}`
                    ),
                };
            },
            sections() {
                return {
                    hideInNavigation: true,
                    metaItem: true,
                    labels: {
                        form: {
                            title: `${_self.contextName}${_self.actionName}WizardSectionsHeading`,
                            preamble: `${_self.contextName}${_self.actionName}WizardSectionsSubHeading`,
                        },
                    },
                    baseUrl: 'sections',
                    url: _self._generateUrl(`sections${_self.actionUrl}`),
                };
            },
            complete() {
                return {
                    hideInNavigation: true,
                    metaItem: true,
                    labels: {
                        form: {
                            title: `${_self.contextName}${_self.actionName}WizardCompleteHeading`,
                            preamble: `${_self.contextName}${_self.actionName}WizardCompleteSubHeading`,
                        },
                    },
                    baseUrl: `${_self.contextUrl}complete${_self.actionUrl}`,
                    url: _self._generateUrl(
                        `${_self.contextUrl}complete${_self.actionUrl}`
                    ),
                };
            },
            done: {
                hideInNavigation: true,
                metaItem: true,
                url: _self._generateDoneUrl(),
            },
        };
    }

    generatePages(pages = []) {
        return wizardPages.reduce((acc, page) => {
            if (pages.includes(page.id)) {
                acc = {
                    ...acc,
                    [_camelCase(page.id)]: this._generatePage(
                        page.id,
                        page.permissions
                    ),
                };
            }
            return acc;
        }, {});
    }

    section(section, items, options) {
        const id = `${this.contextName}${section}`;
        const sectionUrl = section?.toLowerCase();

        const defaultOptions = {
            hideSectionTitle: false,
            hideSectionStart: false,
            hideSectionComplete: false,
            hideSectionInDataLayout: false,
            hideSectionInReportView: false,
        };

        const combinedOptions = {
            ...defaultOptions,
            ...options,
        };

        const sectionStart = {
            id,
            hideInNavigation: true,
            metaItem: true,
            sectionStart: true,
            baseUrl: `section-start-${sectionUrl}`,
            url: this._generateUrl(`section-start-${sectionUrl}`),
        };

        const sectionComplete = {
            id,
            hideInNavigation: true,
            metaItem: true,
            sectionComplete: true,
            labels: {
                form: {
                    title: 'SectionCompleteHeading',
                    preamble: 'SectionCompleteSubHeading',
                },
            },
            baseUrl: `section-complete-${sectionUrl}`,
            url: this._generateUrl(`section-complete-${sectionUrl}`),
        };

        return {
            id,
            section: true,
            section,
            dataUrl: sectionUrl,
            items: [
                ...(!combinedOptions.hideSectionStart ? [sectionStart] : []),
                ...items,
                ...(!combinedOptions.hideSectionComplete
                    ? [sectionComplete]
                    : []),
            ],
            hideSectionTitle: combinedOptions.hideSectionTitle,
            hideSectionInDataLayout: combinedOptions.hideSectionInDataLayout,
            hideSectionInReportView: combinedOptions.hideSectionInReportView,
        };
    }

    focusedSection(items) {
        return {
            id: 'section',
            section: true,
            items,
            hideSectionTitle: true,
        };
    }
}

export default WizardFlow;
