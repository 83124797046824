// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components

// Icons
import { FiAlertCircle } from 'react-icons/fi';

const Attention = ({ data, attention }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex gap-16 p-16 bg-amber-10">
            <div className="text-amber-200">
                <FiAlertCircle className="stroe-current" />
            </div>
            <div className="text-blue-100 t-footnote">{attention(data)}</div>
        </div>
    );
};

Attention.propTypes = {
    data: t.oneOfType([t.array, t.object]).isRequired,
    attention: t.func.isRequired,
};

Attention.defaultProps = {};

export default Attention;
