// React
import React from 'react';

// Packages

// Components

// Icons
import FGLogo from 'assets/images/fg-logo-mark.svg';

const PreloaderComponent = ({ progress }) => {
    return (
        <div className="flex flex-col items-center space-y-32 pt-76 pb-96 rounded-8 animate-fade-in">
            <FGLogo className="text-blue-300 fill-current animate-bounce" />
            {progress && (
                <div className="mt-24 text-blue-300 t-caption-bold">
                    {Math.round(progress)} %
                </div>
            )}
        </div>
    );
};

PreloaderComponent.propTypes = {};

PreloaderComponent.defaultProps = {};

export default PreloaderComponent;
