// React
import React from 'react';

// Packages

// Utilities
import { useInitiativeDataStore } from 'utilities/store';
import { useFormat, useLabels, useContext } from 'utilities/hooks';

// Components

const AsideMetadataComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { CONTEXT, CONTEXTS, REPORT_ID } = useContext();
    const { label } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const initiative = utilities.initiative.get();

    const content = {
        create: {
            default: (
                <>
                    <p className="t-footnote text-teal-60">
                        {label('CreateInitiativeWizardAsideTitle')}
                    </p>
                    {initiative.Name && (
                        <h2 className="mt-16 text-teal-100 t-h5">
                            {initiative.Name === '___' ? ' ' : initiative.Name}
                        </h2>
                    )}
                    {initiative.Lead_Grantee__r?.Name && (
                        <h3 className="mt-16 text-teal-100 t-sh6">
                            {initiative.Lead_Grantee__r?.Name}
                        </h3>
                    )}
                </>
            ),
        },
        initiative: {
            default: (
                <>
                    <p className="t-footnote text-teal-60">
                        {label('InitiativeWizardAsideTitle')}
                    </p>
                    <h2 className="mt-16 text-teal-100 t-h5">
                        {initiative.Name}
                    </h2>
                    {initiative.Lead_Grantee__r?.Name && (
                        <h3 className="mt-16 text-teal-100 t-sh6">
                            {initiative.Lead_Grantee__r?.Name}
                        </h3>
                    )}
                </>
            ),
        },
        report: {
            default: (
                <>
                    <p className="t-footnote text-teal-60">{initiative.Name}</p>
                    <h2 className="mt-16 text-teal-100 t-h5">
                        {`${
                            initiative._reports[REPORT_ID]?.Report_Type__c
                        } ${label('TitleReport')} ${format.year(
                            initiative._reports[REPORT_ID]?.Due_Date__c
                        )}`}
                    </h2>
                    <h3 className="mt-16 text-teal-100 t-sh6">
                        {
                            initiative._reports[REPORT_ID]?.Funder_Report__r
                                ?.Account__r?.Name
                        }
                    </h3>
                </>
            ),
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {initiative.Id && (
                <header>
                    {/* Initiative mode */}
                    {CONTEXT === CONTEXTS.INITIATIVE &&
                        content.initiative.default}

                    {/* Report mode */}
                    {CONTEXT === CONTEXTS.REPORT && content.report.default}
                </header>
            )}
        </>
    );
};

AsideMetadataComponent.propTypes = {};

AsideMetadataComponent.defaultProps = {};

export default AsideMetadataComponent;
