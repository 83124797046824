import WizardFlow from './wizardFlow';
import { CONTEXTS } from '../contexts';
import CONSTANTS from '../constants';

// ///////////////////
// WIZARD FLOWS
// ///////////////////

const flow = new WizardFlow({
    context: CONTEXTS.INITIATIVE,
    pages: [
        'activities',
        'backgroundInformation',
        'collaborators',
        'employeesFunded',
        'facilities',
        'funding',
        'goals',
        'overview',
        'regulations',
        'results',
    ],
});

// ///////////////////
// METHODS
// ///////////////////

function initiativeSet(initiativeSections) {
    return [
        start(),
        sections(),
        ...initiativeSections,
        flow.section('Activities', [activities]),
        flow.section('Results', [results], {
            hideSectionComplete: true,
        }),
        complete(),
        done,
    ];
}

// ///////////////////
// INITIATIVE STRUCTURES
// ///////////////////

const {
    activities,
    backgroundInformation,
    collaborators,
    employeesFunded,
    facilities,
    funding,
    goals,
    overview,
    regulations,
    results,
} = flow.pages;

const { start, complete, done, sections } = flow.metaPages;

const initiativeStructures = {
    [CONSTANTS.INITIATIVE.SOCIAL]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.HUMANITARIAN]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.PEP]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.EDUCATION]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.ENVIRONMENT]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.CULTURE]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.IMPACT_INVESTMENT]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.SCIENCE]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.BII]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [
            funding,
            collaborators,
            employeesFunded,
            facilities,
        ]),
    ]),
    [CONSTANTS.INITIATIVE.INNOVATION]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.GLOBAL_HEALTH]: initiativeSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE]: initiativeSet([
        flow.section('Overview', [overview, regulations]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.BASIC]: initiativeSet([
        flow.section('Overview', [overview, goals]),
        flow.section('Inputs', [funding, collaborators]),
    ]),
    [CONSTANTS.INITIATIVE.INTERMEDIATE]: initiativeSet([
        flow.section('Overview', [backgroundInformation, overview, goals]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
};

// Export for permissions
const p = flow.pages;
export { p };

// Default export
export default initiativeStructures;
