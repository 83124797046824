import { useAuthStore, authStore } from './auth';
import { useDynamicLabelsDataStore } from './dynamicLabels';
import { useInitiativeDataStore } from './initiativeData';
import { useInitiativeNavigationStore } from './initiativeNavigation';
import { useLayoutStore } from './layout';
import { useReportNavigationStore } from './reportNavigation';
import { useWizardNavigationStore } from './wizardNavigation';
import { useDashboardStore } from './dashboard';
import { usePortfolioStore } from './portfolio';
import { useDefaultTagsStore } from './defaultTags';

export {
    // Vanilla
    authStore,
    useAuthStore,
    // Hooks
    useDynamicLabelsDataStore,
    useInitiativeDataStore,
    useInitiativeNavigationStore,
    useLayoutStore,
    useReportNavigationStore,
    useWizardNavigationStore,
    useDashboardStore,
    usePortfolioStore,
    useDefaultTagsStore,
};
