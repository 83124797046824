// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsInventionDisclosure = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsInventionDisclosureDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsInventionDisclosureDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Year__c, Ownership__c, Commercialisation__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Year__c, Ownership__c, Commercialisation__c].some(x => x) && (
                <CardContentPairBox>
                    {Year__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Invention_Disclosure'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {Ownership__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Ownership__c'
                                ),
                                value: format.booleanToWords(Ownership__c),
                            }}
                        />
                    )}

                    {Commercialisation__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Commercialisation__c'
                                ),
                                value: format.booleanToWords(
                                    Commercialisation__c
                                ),
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}
        </>
    );
};

ResultsInventionDisclosure.propTypes = {
    item: t.object,
};

ResultsInventionDisclosure.defaultProps = {};

export { ResultsInventionDisclosureDetails };
export default ResultsInventionDisclosure;
