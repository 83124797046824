// React
import React, { useEffect, useState } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import { animate } from 'framer-motion';

// Utilities
import { useFormat } from 'utilities/hooks';

// Components

const KeyNumberComponent = ({ size, data }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const is1 = size === 1;
    const is2 = size === 2;
    const is3 = size === 3;
    const is4 = size === 4;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="grid grid-cols-12 col-span-12 gap-24 p-24 bg-amber-10 rounded-8">
            {data.map((item, index) => (
                <div
                    key={index}
                    className={cc([
                        'flex flex-col px-16 py-12 bg-white rounded-8',
                        {
                            'col-span-12': is1,
                            'col-span-12 md:col-span-6': is2,
                            'col-span-12 md:col-span-4': is3,
                            'col-span-12 md:col-span-6 2xl:col-span-3': is4,
                        },
                    ])}>
                    <div className="flex items-baseline mt-56">
                        <span className="text-blue-100 t-h2">
                            <Number maxValue={item.number} />
                        </span>
                        {item.unit && (
                            <span className="ml-8 t-h4 text-blue-40">
                                {item.unit}
                            </span>
                        )}
                    </div>
                    <div className="text-blue-60 t-sh7">{item.label}</div>
                </div>
            ))}
        </div>
    );
};

const Number = ({ maxValue }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { number, numberNoDecimals } = useFormat();

    // ///////////////////
    // STATE
    // ///////////////////

    const [counter, setCounter] = useState(0);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        const controls = animate(0, maxValue, {
            duration: 0.7,
            onUpdate(value) {
                setCounter(numberNoDecimals(value));
            },
            onComplete() {
                setCounter(number(maxValue));
            },
        });
        return () => controls.stop();
    }, [maxValue]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return counter;
};

KeyNumberComponent.propTypes = {
    data: t.arrayOf(
        t.shape({
            number: t.oneOfType([t.string, t.number]).isRequired,
            unit: t.string,
            label: t.string.isRequired,
        })
    ),
};

KeyNumberComponent.defaultProps = {};

export default KeyNumberComponent;
