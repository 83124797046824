// React
import React, { useState, useMemo } from 'react';

// Packages
import t from 'prop-types';
import { motion } from 'framer-motion';

// Utilities
import { useFormat } from 'utilities/hooks';

// Components
import ChartWrapper from '../chartWrapper';
import ToolTip from 'components/tooltip';
import Attention from '../attention';

const BarChartComponent = ({
    data,
    dataLabel,
    tooltip,
    attention,
    ...rest
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    // Get max value (with buffer)
    const maxValue = useMemo(() => Math.max(...data.map(x => x.value)) * 1.1, [
        data,
    ]);

    // Create chart data
    const chartData = useMemo(
        () =>
            data.map(x => ({
                ...x,
                value: x.value ?? 0,
                percentage: x.value ? (x.value / maxValue) * 100 : 0,
            })),
        [maxValue]
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ChartWrapper
            {...{
                chart: (
                    <Chart
                        {...{
                            data: chartData,
                            dataLabel,
                            tooltip,
                        }}
                    />
                ),
                attention: attention ? (
                    <Attention {...{ data: chartData, attention }} />
                ) : null,
                ...rest,
            }}
        />
    );
};

BarChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(t.shape({ name: t.string, value: t.number })).isRequired,
    dataLabel: t.func.isRequired,
    tooltip: t.object,
};

BarChartComponent.defaultProps = {};

const Chart = ({ data, dataLabel, tooltip }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();

    // ///////////////////
    // STATE
    // ///////////////////

    const [activeSection, setActiveSection] = useState(null);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <ToolTip {...{ item: activeSection, tooltip }} />
            <table>
                <tbody>
                    {data.map((x, index) => (
                        <tr
                            key={index}
                            className="cursor-pointer"
                            onMouseEnter={() => setActiveSection(x)}
                            onMouseLeave={() => {
                                setActiveSection(null);
                            }}>
                            <td className="t-footnote text-blue-60">
                                {dataLabel(x, index)}
                            </td>
                            <td className="w-full">
                                <div className="relative grow h-6 mx-16 rounded-full bg-blue-10">
                                    <motion.span
                                        className="absolute top-0 left-0 inline-block w-10 h-6 rounded-full bg-blue-60 transition-slow"
                                        initial={{ width: 0 }}
                                        animate={{ width: `${x.percentage}%` }}
                                        transition={{
                                            duration: 0.2,
                                        }}
                                    />
                                </div>
                            </td>
                            <td className="t-footnote text-blue-80">
                                {format.number(x.value)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default BarChartComponent;
