// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

import Tags from 'components/tags';

const ChildCollectionComponent = ({ title, item, collection }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Top row wrapper */}
            <h6 className="flex items-center justify-between t-h5">
                {/* Title */}
                <span>
                    {title} ({collection?.items(item).length})
                </span>
            </h6>
            {/* Collection items */}
            <div className="flex flex-col mt-16 space-y-12">
                {collection?.items(item).map(childItem => (
                    // Item wrapper
                    <div
                        key={childItem.Id}
                        className="flex flex-col p-16 space-y-12 border-2 border-blue-20 rounded-8">
                        {/* Metric */}
                        <div className="flex flex-col justify-center col-span-5 shrink">
                            {/* Pre title */}
                            {collection?.preTitle &&
                                collection?.preTitle(childItem) && (
                                    <span className="t-sh6 text-blue-60">
                                        {collection?.preTitle(childItem)}
                                    </span>
                                )}
                            {/* Title */}
                            <h5 className="text-blue-100 truncate t-h5">
                                {collection?.title(childItem)}
                            </h5>
                            {/* Post title */}
                            {collection?.postTitle &&
                                collection?.postTitle(childItem) && (
                                    <span className="t-footnote text-blue-60">
                                        {collection?.postTitle(childItem)}
                                    </span>
                                )}
                            {/* Tagging */}
                            {collection?.taggingItems && (
                                <Tags
                                    {...{
                                        tags: collection?.taggingItems(
                                            childItem
                                        ),
                                        theme: 'blue',
                                        className: 'mt-12',
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

ChildCollectionComponent.propTypes = {
    title: t.string.isRequired,
    item: t.object.isRequired,
    collection: t.shape({
        preTitle: t.func,
        title: t.func.isRequired,
        postTitle: t.func,
        items: t.func.isRequired,
    }),
};

ChildCollectionComponent.defaultProps = {
    title: '',
    collection: {
        title: '',
        preTitle: null,
        postTitle: null,
        items: [],
    },
};

export default ChildCollectionComponent;
