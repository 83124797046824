import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import _cloneDeep from 'lodash.clonedeep';
import { CONSTANTS } from 'utilities/configuration';

import { initiativeStructures } from 'utilities/configuration';

// Auth shenaningans
import { grantPermissionBase } from 'utilities/hooks/usePermissions';

const initiativeNavigationStore = createVanilla((set, get) => ({
    // Rebuilds items
    buildItems({ initiativeType, permissionType = 'view', user = {} }) {
        if (user) {
            // Extract user data
            const {
                userAccountType,
                userInitiativeTeamRole,
                userAccountId,
            } = user;

            // Dictionary for remapping legacy initiative types
            const initativeStructureDictionary = {
                [CONSTANTS.INITIATIVE.SOCIAL]: CONSTANTS.INITIATIVE.SOCIAL,
                [CONSTANTS.INITIATIVE.HUMANITARIAN]:
                    CONSTANTS.INITIATIVE.HUMANITARIAN,
                [CONSTANTS.INITIATIVE.PEP]: CONSTANTS.INITIATIVE.PEP,
                [CONSTANTS.INITIATIVE.EDUCATION]:
                    CONSTANTS.INITIATIVE.EDUCATION,
                [CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF]:
                    CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF,
                [CONSTANTS.INITIATIVE.ENVIRONMENT]:
                    CONSTANTS.INITIATIVE.ENVIRONMENT,
                [CONSTANTS.INITIATIVE.CULTURE]: CONSTANTS.INITIATIVE.CULTURE,
                [CONSTANTS.INITIATIVE.IMPACT_INVESTMENT]:
                    CONSTANTS.INITIATIVE.IMPACT_INVESTMENT,
                [CONSTANTS.INITIATIVE.SCIENCE]: CONSTANTS.INITIATIVE.SCIENCE,
                [CONSTANTS.INITIATIVE.BII]: CONSTANTS.INITIATIVE.BII,
                [CONSTANTS.INITIATIVE.INNOVATION]:
                    CONSTANTS.INITIATIVE.INNOVATION,
                [CONSTANTS.INITIATIVE.GLOBAL_HEALTH]:
                    CONSTANTS.INITIATIVE.GLOBAL_HEALTH,
                [CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE]:
                    CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE,
                [CONSTANTS.INITIATIVE.BASIC]: CONSTANTS.INITIATIVE.BASIC,
                [CONSTANTS.INITIATIVE.INTERMEDIATE]:
                    CONSTANTS.INITIATIVE.INTERMEDIATE,

                // Legacy
                Advanced: CONSTANTS.INITIATIVE.SOCIAL,
                Default: CONSTANTS.INITIATIVE.SOCIAL,
                Reporting: CONSTANTS.INITIATIVE.SOCIAL,
            };

            // Get real type from dictionary
            const initativeStructureType =
                initativeStructureDictionary[initiativeType];

            // Clone for immutability
            const clonedInitiativeStructures = _cloneDeep(initiativeStructures);

            // Get structure based on context before authentication check
            // Only sections
            let navigationStructure =
                clonedInitiativeStructures?.[initativeStructureType]?.filter(
                    section =>
                        section.section && !section.hideSectionInDataLayout
                ) ?? [];

            // Loop through navigation structure
            let authenticatedNavigationStructure = [];
            for (let section of navigationStructure) {
                // Check all the section items
                if (section?.items?.length > 0) {
                    // New array for items that are authenticated
                    let authenticatedItems = [];

                    // Filter out metaItems
                    section.items = section.items.filter(x => !x.metaItem);

                    for (const item of section.items) {
                        // Push item to new array if permission is granted
                        if (
                            grantPermissionBase(
                                item?.permissions[permissionType],
                                userAccountType,
                                userInitiativeTeamRole,
                                userAccountId
                            )
                        ) {
                            authenticatedItems.push(item);
                        }
                    }

                    // If there are any authenticated items except metaItems, show them
                    if (authenticatedItems.length > 0) {
                        // Overwrite section items with new items
                        section.items = authenticatedItems;
                    } else {
                        section.items = [];
                    }
                    // Add to authenticated navigation struture
                    authenticatedNavigationStructure = [
                        ...authenticatedNavigationStructure,
                        section,
                    ];
                }
            }

            // Update state
            set(state => {
                state.items = authenticatedNavigationStructure;
            });
        }
    },

    getSection(section) {
        return get().items.find(item => item.section === section);
    },

    hasSection(section) {
        // flatten items.items into a single array and search for string section in item.baseUrl
        return get()
            .items.reduce((acc, item) => {
                return [...acc, ...item.items];
            }, [])
            .some(item => item.baseUrl === section);
    },

    items: [],

    reset() {
        set(() => ({
            items: [],
        }));
    },
}));

const useInitiativeNavigationStore = create(initiativeNavigationStore);

export { useInitiativeNavigationStore, initiativeNavigationStore };
