const world = {
    type: 'Topology',
    arcs: [
        [
            [17078, 11146],
            [72, -46],
            [54, 16],
            [15, 55],
            [55, 18],
            [40, 38],
            [14, 98],
            [59, 23],
            [12, 44],
            [33, -33],
            [21, -4],
        ],
        [
            [17453, 11355],
            [40, -1],
            [53, -25],
        ],
        [
            [17546, 11329],
            [22, -15],
            [52, 39],
            [23, -24],
            [23, 56],
            [43, -2],
            [11, 18],
            [7, 49],
            [30, 43],
            [38, -29],
            [-8, -37],
            [22, -6],
            [-7, -102],
            [28, -40],
            [25, 26],
            [31, 12],
            [45, 54],
            [49, -9],
            [73, 0],
        ],
        [
            [18053, 11362],
            [12, -35],
        ],
        [
            [18065, 11327],
            [-41, -13],
            [-36, -23],
            [-81, -14],
            [-77, -26],
            [-41, -53],
            [17, -52],
            [8, -61],
            [-35, -51],
            [2, -47],
            [-19, -43],
            [-67, 3],
            [28, -81],
            [-45, -31],
            [-30, -73],
            [4, -73],
            [-28, -35],
            [-26, 12],
            [-54, -16],
            [-8, -35],
            [-53, 0],
            [-39, -69],
            [-3, -103],
            [-92, -51],
            [-49, 11],
            [-15, -27],
            [-42, 15],
            [-70, -18],
            [-119, 62],
        ],
        [
            [17054, 10435],
            [64, 111],
            [-5, 78],
            [-54, 22],
            [-6, 77],
            [-23, 97],
            [30, 67],
            [-31, 18],
            [20, 89],
            [29, 152],
        ],
        [
            [14437, 5457],
            [7, -38],
            [-8, -59],
            [13, -57],
            [-11, -45],
            [6, -43],
            [-147, 1],
            [-4, -388],
            [48, -100],
            [46, -77],
        ],
        [
            [14387, 4651],
            [-130, -49],
            [-171, 17],
            [-49, 59],
            [-287, -6],
            [-10, -8],
            [-42, 56],
            [-46, 3],
            [-43, -21],
            [-34, -23],
        ],
        [
            [13575, 4679],
            [-7, 77],
            [10, 107],
            [25, 112],
            [3, 52],
            [23, 110],
            [17, 50],
            [41, 81],
            [23, 54],
            [7, 90],
            [-4, 69],
            [-21, 43],
            [-19, 75],
            [-17, 73],
            [4, 26],
            [22, 48],
            [-22, 118],
            [-15, 82],
            [-35, 77],
            [7, 24],
        ],
        [
            [13617, 6047],
            [29, 16],
            [21, -2],
            [24, 14],
            [209, -1],
            [17, -91],
            [21, -73],
            [16, -39],
            [27, -65],
            [47, 11],
            [23, 17],
            [40, -17],
            [10, 30],
            [18, 71],
            [44, 5],
            [4, 21],
            [36, 0],
            [-6, -43],
            [85, 1],
            [1, -77],
            [15, -48],
            [-11, -73],
            [5, -75],
            [24, -45],
            [-4, -146],
            [18, 11],
            [31, -3],
            [44, 18],
            [32, -7],
        ],
        [
            [13607, 6084],
            [-19, 93],
        ],
        [
            [13588, 6177],
            [28, 52],
            [22, 21],
            [26, -42],
        ],
        [
            [13664, 6208],
            [-25, -25],
            [-12, -32],
            [-2, -53],
            [-18, -14],
        ],
        [
            [14233, 11781],
            [-2, -33],
            [-23, -18],
            [-4, -39],
            [-33, -59],
        ],
        [
            [14171, 11632],
            [-12, 8],
            [-2, 27],
            [-39, 41],
            [-6, 59],
            [6, 83],
            [10, 38],
            [-12, 19],
        ],
        [
            [14116, 11907],
            [-5, 38],
            [31, 60],
            [4, -23],
            [19, 11],
        ],
        [
            [14165, 11993],
            [15, -32],
            [17, -13],
            [5, -44],
        ],
        [
            [14202, 11904],
            [-9, -41],
            [10, -53],
            [30, -29],
        ],
        [
            [16396, 9753],
            [12, 6],
            [3, -34],
            [55, 20],
            [59, -4],
            [43, -3],
            [49, 83],
            [52, 78],
            [45, 75],
        ],
        [
            [16714, 9974],
            [14, -41],
        ],
        [
            [16728, 9933],
            [9, -97],
        ],
        [
            [16737, 9836],
            [-36, 0],
            [-6, -80],
            [13, -17],
            [-32, -24],
            [0, -50],
            [-21, -50],
            [-2, -49],
        ],
        [
            [16653, 9566],
            [-14, -26],
            [-213, 61],
            [-28, 124],
            [-2, 28],
        ],
        [
            [8003, 87],
            [-42, 3],
            [-76, 0],
            [-1, 273],
        ],
        [
            [7884, 363],
            [28, -57],
            [35, -91],
            [92, -74],
            [100, -30],
            [-32, -61],
            [-68, -6],
            [-36, 43],
        ],
        [
            [8306, 4074],
            [130, -198],
            [58, -19],
            [87, -91],
            [73, -47],
            [10, -54],
            [-70, -186],
            [72, -33],
            [79, -19],
            [55, 20],
            [65, 93],
            [11, 108],
        ],
        [
            [8876, 3648],
            [35, 24],
            [36, -71],
            [-1, -98],
            [-60, -67],
            [-48, -49],
            [-80, -119],
            [-94, -166],
        ],
        [
            [8664, 3102],
            [-17, -98],
            [-19, -126],
            [0, -121],
            [-15, -27],
            [-5, -80],
        ],
        [
            [8608, 2650],
            [-5, -63],
            [90, -105],
            [-10, -85],
            [44, -53],
            [-3, -59],
            [-68, -156],
            [-106, -66],
            [-142, -26],
            [-78, 13],
            [15, -73],
            [-14, -91],
            [13, -63],
            [-43, -42],
            [-71, -17],
            [-69, 44],
            [-27, -32],
            [10, -121],
            [48, -36],
            [39, 38],
            [20, -63],
            [-65, -39],
            [-57, -76],
            [-10, -122],
            [-17, -65],
            [-67, -1],
            [-55, -63],
            [-21, -91],
            [70, -89],
            [68, -25],
            [-25, -110],
            [-83, -69],
            [-46, -143],
            [-65, -48],
            [-29, -57],
            [23, -127],
            [47, -71],
            [-30, 6],
        ],
        [
            [7889, 404],
            [-66, 19],
            [-170, 17],
            [-30, 71],
            [2, 91],
            [-48, -7],
            [-25, 44],
            [-6, 130],
            [55, 53],
            [22, 77],
            [-8, 62],
            [38, 105],
            [25, 161],
            [-7, 73],
            [31, 23],
            [-8, 45],
            [-33, 25],
            [24, 51],
            [-32, 46],
            [-17, 141],
            [29, 25],
            [-12, 149],
            [16, 125],
            [19, 109],
            [43, 44],
            [-22, 120],
            [0, 112],
            [53, 79],
            [-2, 103],
            [41, 118],
            [0, 113],
            [-19, 23],
            [-32, 210],
            [43, 126],
            [-6, 118],
            [25, 111],
            [46, 115],
            [51, 75],
            [-22, 49],
            [15, 39],
            [-2, 203],
            [77, 61],
            [24, 126],
            [-8, 31],
        ],
        [
            [7993, 4015],
            [59, 111],
            [93, -31],
            [41, -88],
            [28, 98],
            [80, -5],
            [12, -26],
        ],
        [
            [15829, 11811],
            [99, 19],
        ],
        [
            [15928, 11830],
            [15, -32],
            [27, -21],
            [-15, -31],
            [38, -42],
            [-20, -39],
            [30, -33],
            [32, -20],
            [2, -84],
        ],
        [
            [16037, 11528],
            [-26, -4],
        ],
        [
            [16011, 11524],
            [-29, 70],
            [0, 19],
            [-31, 0],
            [-21, 33],
            [-15, -4],
        ],
        [
            [15915, 11642],
            [-28, 36],
            [-52, 30],
            [6, 60],
            [-12, 43],
        ],
        [
            [17625, 853],
            [46, -38],
            [67, -15],
            [2, -24],
            [-20, -55],
            [-109, -8],
            [-1, 65],
            [10, 50],
            [5, 25],
        ],
        [
            [23038, 1809],
            [69, -42],
            [38, 17],
            [56, 23],
            [41, -8],
            [5, -144],
            [-23, -42],
            [-7, -99],
            [-25, 33],
            [-49, -85],
            [-15, 7],
            [-44, 4],
            [-43, 105],
            [-10, 80],
            [-41, 106],
            [2, 56],
            [46, -11],
        ],
        [
            [22908, 5111],
            [26, -96],
            [45, 46],
            [24, -51],
            [34, -48],
            [-8, -55],
            [15, -104],
            [11, -61],
            [18, -15],
            [19, -105],
            [-6, -63],
            [22, -82],
            [77, -64],
            [50, -59],
            [47, -53],
            [-9, -29],
            [40, -76],
            [28, -133],
            [28, 27],
            [29, -53],
            [17, 19],
            [13, -129],
            [50, -75],
            [33, -48],
            [55, -98],
            [20, -98],
            [2, -70],
            [-5, -76],
            [34, -103],
            [-4, -109],
            [-12, -56],
            [-20, -109],
            [2, -70],
            [-14, -88],
            [-31, -110],
            [-53, -60],
            [-26, -96],
            [-24, -60],
            [-21, -105],
            [-27, -60],
            [-18, -92],
            [-9, -84],
            [4, -39],
            [-41, -42],
            [-80, -5],
            [-64, -50],
            [-33, -48],
            [-42, -52],
            [-59, 54],
            [-44, 21],
            [11, 65],
            [-38, -23],
            [-63, -89],
            [-61, 33],
            [-40, 19],
            [-41, 9],
            [-67, 36],
            [-46, 75],
            [-13, 92],
            [-17, 62],
            [-35, 50],
            [-68, 15],
            [23, 59],
            [-17, 90],
            [-34, -84],
            [-64, -22],
            [38, 67],
            [10, 70],
            [28, 60],
            [-6, 91],
            [-58, -105],
            [-44, -41],
            [-27, -97],
            [-56, 50],
            [3, 64],
            [-45, 90],
            [-36, 45],
            [12, 28],
            [-90, 74],
            [-50, 4],
            [-68, 59],
            [-127, -12],
            [-92, -43],
            [-81, -41],
            [-66, 8],
            [-76, -62],
            [-61, -28],
            [-14, -64],
            [-26, -51],
            [-60, -3],
            [-45, -10],
            [-62, 22],
            [-51, -13],
            [-48, -6],
            [-42, -65],
            [-21, 6],
            [-36, -35],
            [-34, -39],
            [-51, 5],
            [-48, 0],
            [-75, 78],
            [-38, 23],
            [1, 70],
            [35, 17],
            [12, 28],
            [-2, 44],
            [9, 85],
            [-8, 72],
            [-38, 124],
            [-11, 69],
            [3, 69],
            [-29, 81],
            [-1, 35],
            [-32, 49],
            [-9, 95],
            [-40, 98],
            [-10, 52],
            [31, -53],
            [-24, 113],
            [35, -36],
            [21, -47],
            [-1, 63],
            [-35, 96],
            [-7, 38],
            [-16, 36],
            [8, 72],
            [14, 30],
            [10, 61],
            [-8, 71],
            [29, 88],
            [6, -93],
            [30, 84],
            [57, 41],
            [35, 52],
            [54, 45],
            [32, 9],
            [20, -15],
            [56, 45],
            [43, 14],
            [11, 27],
            [18, 11],
            [40, -3],
            [73, 35],
            [39, 55],
            [18, 65],
            [42, 62],
            [3, 49],
            [2, 67],
            [49, 103],
            [30, -105],
            [30, 25],
            [-25, 57],
            [22, 59],
            [32, -26],
            [8, 92],
            [39, 60],
            [17, 49],
            [36, 21],
            [1, 34],
            [31, -14],
            [1, 30],
            [31, 18],
            [34, 16],
            [52, -56],
            [40, -72],
            [44, 0],
            [45, -12],
            [-15, 67],
            [34, 98],
            [32, 31],
            [-11, 31],
            [31, 70],
            [43, 43],
            [36, -14],
            [60, 23],
            [-2, 62],
            [-52, 40],
            [38, 18],
            [47, -31],
            [38, -49],
            [59, -32],
            [20, 13],
            [44, -38],
            [41, 35],
            [27, -10],
            [17, 23],
            [32, -60],
            [-19, -66],
            [-27, -50],
            [-24, -4],
            [8, -48],
            [-21, -61],
            [-25, -60],
            [5, -35],
            [57, -68],
            [54, -39],
            [37, -41],
            [51, -73],
            [20, 1],
            [37, -32],
            [11, -38],
            [68, -41],
            [46, 41],
            [14, 66],
            [14, 54],
            [9, 67],
            [21, 97],
            [-10, 60],
            [5, 35],
            [-8, 70],
            [9, 92],
            [14, 24],
            [-11, 42],
            [17, 64],
            [14, 67],
            [1, 35],
            [27, 46],
            [20, -60],
            [5, -76],
            [17, -15],
            [3, -51],
            [26, -63],
            [6, -69],
            [-3, -44],
        ],
        [
            [13946, 12670],
            [-5, -50],
            [-40, 0],
            [13, -27],
            [-23, -79],
        ],
        [
            [13891, 12514],
            [-14, -20],
            [-62, -3],
            [-35, -28],
            [-58, 9],
        ],
        [
            [13722, 12472],
            [-101, 32],
            [-16, 42],
            [-70, -21],
            [-9, -23],
            [-43, 17],
        ],
        [
            [13483, 12519],
            [-36, 3],
            [-32, 23],
            [11, 29],
            [-3, 22],
        ],
        [
            [13423, 12596],
            [22, 7],
            [35, -34],
            [11, 32],
            [62, -5],
            [51, 23],
            [34, -4],
            [22, -26],
            [7, 21],
            [-10, 80],
            [25, 15],
            [25, 57],
        ],
        [
            [13707, 12762],
            [52, -40],
            [40, 50],
            [25, 9],
            [55, -37],
            [33, 6],
            [33, -23],
        ],
        [
            [13945, 12727],
            [-6, -15],
            [7, -42],
        ],
        [
            [16011, 11524],
            [-49, 16],
            [-35, 56],
            [-12, 46],
        ],
        [
            [16142, 11849],
            [42, 49],
        ],
        [
            [16184, 11898],
            [37, -64],
            [36, -87],
            [33, -6],
            [21, -33],
            [-58, -9],
            [-11, -95],
            [-13, -43],
            [-26, -28],
            [2, -61],
        ],
        [
            [16205, 11472],
            [-17, -6],
            [-45, 64],
            [25, 61],
            [-21, 35],
            [-27, -9],
            [-83, -89],
        ],
        [
            [15928, 11830],
            [17, 20],
            [53, -35],
            [38, -7],
            [10, 14],
            [-35, 66],
            [19, 17],
        ],
        [
            [16030, 11905],
            [19, -4],
            [49, -74],
            [32, -9],
            [12, 31],
        ],
        [
            [14821, 6242],
            [-5, 148],
            [-17, 55],
        ],
        [
            [14799, 6445],
            [43, -9],
            [21, 69],
            [38, -8],
        ],
        [
            [14901, 6497],
            [4, -48],
            [15, -27],
            [1, -40],
            [-17, -26],
            [-28, -64],
            [-26, -44],
            [-29, -6],
        ],
        [
            [13031, 13053],
            [65, 26],
            [45, -54],
            [39, -28],
        ],
        [
            [13180, 12997],
            [-8, -82],
        ],
        [
            [13172, 12915],
            [-18, -5],
            [-8, -69],
        ],
        [
            [13146, 12841],
            [-62, 55],
            [-36, -9],
            [-50, 58],
            [-33, 49],
            [-33, 2],
            [-10, 43],
        ],
        [
            [12922, 13039],
            [57, 24],
        ],
        [
            [12979, 13063],
            [52, -10],
        ],
        [
            [12934, 7557],
            [-58, -14],
        ],
        [
            [12876, 7543],
            [-18, 83],
            [4, 281],
            [-15, 25],
            [-2, 60],
            [-25, 42],
            [-22, 36],
            [9, 65],
        ],
        [
            [12807, 8135],
            [25, 14],
            [14, 53],
            [35, 12],
            [15, 36],
        ],
        [
            [12896, 8250],
            [24, 36],
            [26, 0],
            [54, -70],
        ],
        [
            [13000, 8216],
            [-3, -40],
            [16, -73],
            [-14, -50],
            [7, -32],
            [-34, -76],
            [-22, -37],
            [-13, -77],
            [2, -78],
            [-5, -196],
        ],
        [
            [12544, 7970],
            [-49, 31],
            [-33, -5],
            [-25, -30],
            [-32, 25],
            [-12, 41],
            [-32, 26],
        ],
        [
            [12361, 8058],
            [-5, 72],
            [20, 51],
            [-2, 42],
            [56, 101],
            [11, 84],
            [19, 30],
            [35, -17],
            [29, 25],
            [10, 31],
            [55, 55],
            [14, 38],
            [66, 50],
            [39, 18],
            [18, -24],
            [44, 1],
        ],
        [
            [12770, 8615],
            [-6, -59],
            [10, -55],
            [40, -80],
            [2, -60],
            [82, -27],
            [-2, -84],
        ],
        [
            [12807, 8135],
            [-62, 3],
        ],
        [
            [12745, 8138],
            [-32, 10],
            [-23, -20],
            [-31, 9],
            [-123, -6],
            [-2, -70],
            [10, -91],
        ],
        [
            [19305, 9484],
            [-2, -88],
            [-24, 19],
            [4, -98],
        ],
        [
            [19283, 9317],
            [-20, 63],
            [-4, 62],
            [-14, 60],
            [-28, 71],
            [-66, 4],
            [7, -50],
            [-22, -68],
            [-31, 24],
            [-10, -22],
            [-20, 13],
            [-27, 11],
        ],
        [
            [19048, 9485],
            [-11, 102],
            [-25, 91],
            [12, 73],
            [-44, 34],
            [16, 44],
            [44, 46],
            [-51, 64],
            [25, 83],
            [56, -53],
            [34, -6],
            [7, -85],
            [67, -16],
            [65, 2],
            [41, -21],
            [-33, -104],
            [-31, -7],
            [-22, -70],
            [39, -63],
            [11, 78],
            [19, 1],
            [38, -194],
        ],
        [
            [14348, 12195],
            [20, -50],
            [28, 8],
            [54, -19],
            [104, -6],
            [36, 31],
            [83, 28],
            [52, -44],
            [40, -13],
        ],
        [
            [14765, 12130],
            [-35, -50],
            [-26, -88],
            [23, -70],
        ],
        [
            [14727, 11922],
            [-61, 17],
            [-73, -38],
        ],
        [
            [14593, 11901],
            [0, -61],
            [-65, -12],
            [-50, 43],
            [-57, -33],
            [-52, 3],
        ],
        [
            [14369, 11841],
            [-5, 81],
            [-36, 39],
        ],
        [
            [14328, 11961],
            [12, 17],
            [-8, 14],
            [12, 39],
            [27, 39],
            [-34, 53],
            [-7, 44],
            [18, 28],
        ],
        [
            [7254, 9694],
            [-16, -6],
            [-18, 70],
            [-27, 36],
            [16, 77],
            [21, -5],
            [24, -102],
            [0, -70],
        ],
        [
            [7235, 10038],
            [-78, -20],
            [-4, 46],
            [33, 10],
            [47, -3],
            [2, -33],
        ],
        [
            [7292, 10039],
            [-12, -86],
            [-13, 15],
            [1, 64],
            [-31, 49],
            [0, 14],
            [55, -56],
        ],
        [
            [14090, 12271],
            [26, 0],
            [-18, -54],
            [34, -46],
            [-10, -58],
            [-17, -5],
        ],
        [
            [14105, 12108],
            [-13, -11],
            [-23, -28],
            [-11, -68],
        ],
        [
            [14058, 12001],
            [-63, 47],
            [-26, 51],
            [-27, 27],
            [-33, 45],
            [-15, 38],
            [-35, 57],
            [15, 50],
            [25, -28],
            [16, 25],
            [33, 3],
            [61, -20],
            [49, 2],
            [32, -27],
        ],
        [
            [14407, 13376],
            [68, 0],
            [77, 45],
            [17, 70],
            [58, 39],
            [-7, 55],
        ],
        [
            [14620, 13585],
            [43, 20],
            [76, 47],
        ],
        [
            [14739, 13652],
            [74, -30],
            [10, -31],
            [37, 15],
            [70, -29],
            [7, -57],
            [-16, -33],
            [45, -81],
            [29, -22],
            [-4, -22],
            [47, -21],
            [21, -33],
            [-28, -26],
            [-57, 4],
            [-14, -12],
            [17, -40],
            [17, -79],
        ],
        [
            [14994, 13155],
            [-60, -7],
            [-22, -27],
            [-5, -61],
            [-28, 12],
            [-64, -6],
            [-19, 28],
            [-26, -21],
            [-27, 18],
            [-55, 2],
            [-79, 29],
            [-72, 10],
            [-55, -3],
            [-39, -33],
            [-33, -5],
        ],
        [
            [14410, 13091],
            [-2, 55],
            [-22, 57],
            [43, 25],
            [0, 48],
            [-19, 47],
            [-3, 53],
        ],
        [
            [6433, 8967],
            [-1, 18],
            [9, 6],
            [12, -15],
            [26, 74],
            [13, 2],
        ],
        [
            [6492, 9052],
            [1, -18],
            [13, -1],
            [-1, -33],
            [-12, -53],
            [7, -19],
            [-8, -44],
            [5, -12],
            [-9, -61],
            [-14, -32],
            [-12, -4],
            [-14, -42],
        ],
        [
            [6448, 8733],
            [-22, 0],
            [6, 137],
            [1, 97],
        ],
        [
            [8160, 10739],
            [1, 1],
            [0, 1],
            [0, 1],
        ],
        [
            [8161, 10742],
            [1, 1],
        ],
        [
            [8162, 10743],
            [0, 1],
            [1, 0],
        ],
        [
            [8163, 10744],
            [0, -2],
            [1, -1],
            [1, 1],
            [1, 1],
            [0, 1],
        ],
        [
            [8166, 10744],
            [-1, 1],
        ],
        [
            [8165, 10745],
            [0, -1],
        ],
        [
            [8165, 10744],
            [-1, 1],
        ],
        [
            [8164, 10745],
            [0, -1],
            [-1, 0],
        ],
        [
            [8163, 10744],
            [0, 1],
            [0, 1],
            [1, 0],
            [1, 1],
            [1, 1],
            [0, -2],
            [1, 0],
            [0, -1],
            [-1, -1],
        ],
        [
            [8166, 10744],
            [1, -1],
        ],
        [
            [8167, 10743],
            [-2, -2],
            [-1, -1],
            [-1, -1],
            [-1, 0],
            [-1, -2],
            [-1, -1],
            [-1, -1],
            [-1, -1],
            [-1, -1],
            [-1, -1],
            [-1, -1],
        ],
        [
            [8155, 10731],
            [-1, 0],
        ],
        [
            [8154, 10731],
            [-1, 0],
        ],
        [
            [8153, 10731],
            [-1, 0],
            [-1, 1],
            [-1, 3],
            [1, 1],
            [0, 2],
        ],
        [
            [8151, 10738],
            [1, 0],
        ],
        [
            [8152, 10738],
            [1, 0],
            [1, 3],
            [0, -1],
            [-1, -1],
            [-1, -2],
            [1, 0],
            [-1, -1],
            [-1, -1],
            [0, -1],
            [1, 0],
            [0, -2],
        ],
        [
            [8152, 10732],
            [2, 0],
        ],
        [
            [8154, 10732],
            [0, 1],
            [1, 0],
            [1, 1],
            [1, 1],
            [1, 0],
            [-1, 1],
            [-1, 1],
            [-1, 1],
            [1, 0],
            [1, -1],
            [0, 1],
            [1, 0],
            [1, 0],
            [1, 1],
        ],
        [
            [7993, 4015],
            [-51, -17],
            [-28, 169],
            [-38, 137],
            [22, 118],
            [-37, 52],
            [-10, 88],
            [-34, 82],
        ],
        [
            [7817, 4644],
            [44, 133],
            [-30, 102],
            [16, 41],
            [-13, 46],
            [28, 61],
            [2, 103],
            [3, 86],
            [15, 42],
            [-61, 196],
        ],
        [
            [7821, 5454],
            [53, -10],
            [36, 2],
            [16, 37],
            [62, 51],
            [37, 45],
            [93, 21],
            [-7, -92],
            [8, -47],
            [-5, -82],
            [77, -109],
            [78, -20],
            [28, -46],
            [48, -24],
            [29, -36],
            [45, 2],
            [41, -37],
            [3, -70],
            [14, -35],
            [1, -54],
            [-21, -2],
            [28, -142],
            [136, -5],
            [-11, -70],
            [8, -48],
            [39, -35],
            [16, -76],
            [-12, -96],
            [-20, -53],
            [7, -70],
            [-22, -26],
        ],
        [
            [8626, 4327],
            [-1, 38],
            [-66, 63],
            [-66, 2],
            [-124, -37],
            [-34, -107],
            [-2, -65],
            [-27, -147],
        ],
        [
            [8876, 3648],
            [14, 71],
            [10, 72],
            [0, 68],
            [-26, 22],
            [-26, -20],
            [-27, 5],
            [-8, 47],
            [-7, 112],
            [-13, 36],
            [-47, 33],
            [-29, -24],
            [-75, 24],
            [5, 166],
            [-21, 67],
        ],
        [
            [7821, 5454],
            [-40, -21],
            [-32, 14],
            [4, 186],
            [-57, -72],
            [-62, 3],
            [-27, 65],
            [-47, 7],
            [15, 53],
            [-40, 74],
            [-29, 110],
            [18, 23],
            [0, 51],
            [43, 35],
            [-7, 66],
            [18, 42],
            [5, 58],
            [82, 83],
            [58, 23],
            [9, 19],
            [63, -6],
        ],
        [
            [7795, 6267],
            [32, 335],
            [2, 52],
            [-11, 71],
            [-32, 44],
            [0, 89],
            [40, 20],
            [15, -13],
            [2, 47],
            [-42, 13],
            [0, 77],
            [138, -3],
            [23, 42],
            [20, -39],
            [14, -72],
            [13, 16],
        ],
        [
            [8009, 6946],
            [39, -66],
            [55, 8],
            [14, 37],
            [53, 30],
            [29, 20],
            [8, 51],
            [50, 35],
            [-4, 25],
            [-59, 11],
            [-10, 77],
            [3, 82],
            [-32, 32],
            [14, 11],
            [52, -16],
            [56, -30],
            [20, 29],
            [51, 19],
            [79, 45],
            [26, 47],
            [-9, 34],
        ],
        [
            [8444, 7427],
            [37, 5],
            [16, -28],
            [-9, -53],
            [25, -19],
            [16, -56],
            [-20, -43],
            [-11, -105],
            [18, -61],
            [5, -57],
            [44, -57],
            [35, -6],
            [7, 24],
            [23, 5],
            [32, 22],
            [23, 32],
            [39, -10],
            [18, 4],
        ],
        [
            [8742, 7024],
            [37, -10],
            [6, 25],
            [-11, 24],
            [7, 36],
            [28, -11],
            [34, 12],
            [40, -26],
        ],
        [
            [8883, 7074],
            [31, -25],
            [22, 33],
            [16, -5],
            [10, -34],
            [34, 9],
            [27, 46],
            [22, 89],
            [42, 113],
        ],
        [
            [9087, 7300],
            [24, 6],
            [18, -68],
            [39, -214],
            [38, -20],
            [2, -85],
            [-53, -100],
            [22, -37],
            [124, -19],
            [3, -123],
            [54, 81],
            [89, -44],
            [118, -75],
            [34, -71],
            [-11, -68],
            [82, 38],
            [137, -64],
            [106, 4],
            [105, -101],
            [91, -137],
            [54, -35],
            [61, -5],
            [25, -38],
            [24, -156],
            [12, -74],
            [-29, -202],
            [-35, -79],
            [-100, -171],
            [-45, -137],
            [-52, -107],
            [-18, -2],
            [-20, -90],
            [5, -229],
            [-20, -187],
            [-7, -82],
            [-23, -48],
            [-12, -162],
            [-72, -160],
            [-12, -126],
            [-58, -54],
            [-16, -73],
            [-76, 1],
            [-112, -47],
            [-50, -54],
            [-79, -36],
            [-84, -98],
            [-60, -120],
            [-10, -92],
            [12, -67],
            [-14, -123],
            [-16, -60],
            [-48, -68],
            [-79, -214],
            [-62, -97],
            [-49, -57],
            [-32, -116],
            [-47, -71],
        ],
        [
            [8965, 2667],
            [-20, 70],
            [32, 58],
            [-41, 83],
            [-56, 67],
            [-73, 79],
            [-26, -4],
            [-70, 95],
            [-47, -13],
        ],
        [
            [20829, 7345],
            [28, 45],
            [61, 67],
        ],
        [
            [20918, 7457],
            [-3, -60],
            [-5, -78],
            [-33, 4],
            [-15, -41],
            [-33, 63],
        ],
        [
            [19237, 10184],
            [27, -39],
            [-4, -75],
            [-57, -3],
            [-60, 8],
            [-45, -20],
            [-64, 47],
            [-2, 24],
        ],
        [
            [19032, 10126],
            [47, 91],
            [38, 31],
            [51, -28],
            [38, -3],
            [31, -33],
        ],
        [
            [14827, 4093],
            [-99, -89],
            [-64, -91],
            [-23, -81],
            [-22, -47],
            [-38, -10],
            [-13, -58],
            [-7, -38],
            [-45, -28],
            [-58, 6],
            [-34, 34],
            [-30, 15],
            [-34, -29],
            [-18, -58],
            [-33, -36],
            [-36, -56],
            [-50, -12],
            [-15, 44],
            [6, 74],
            [-42, 115],
            [-19, 19],
        ],
        [
            [14153, 3767],
            [0, 357],
            [70, 4],
            [2, 435],
            [52, 4],
            [109, 42],
            [27, -50],
            [46, 48],
            [21, 0],
            [40, 27],
        ],
        [
            [14520, 4634],
            [13, -9],
        ],
        [
            [14533, 4625],
            [27, -97],
            [14, -22],
            [23, -70],
            [80, -135],
            [30, -13],
            [1, -43],
            [20, -77],
            [54, -19],
            [45, -56],
        ],
        [
            [13825, 7698],
            [59, 9],
            [13, 32],
            [12, -3],
            [18, -27],
            [89, 46],
            [30, 49],
            [37, 42],
            [-7, 43],
            [20, 11],
            [69, -7],
            [66, 56],
            [51, 134],
            [36, 49],
            [45, 21],
        ],
        [
            [14363, 8153],
            [8, -52],
            [40, -77],
            [1, -50],
            [-12, -50],
            [5, -38],
            [24, -35],
        ],
        [
            [14429, 7851],
            [54, -53],
        ],
        [
            [14483, 7798],
            [39, -51],
            [1, -39],
            [48, -64],
            [29, -52],
            [18, -73],
            [53, -49],
            [11, -39],
        ],
        [
            [14682, 7431],
            [-23, -13],
            [-45, 3],
            [-54, 13],
            [-26, -11],
            [-11, -29],
            [-23, -4],
            [-28, 26],
            [-79, -61],
            [-32, 12],
            [-10, -9],
            [-21, -74],
            [-53, 24],
            [-51, 12],
            [-45, 45],
            [-58, 41],
            [-38, -39],
            [-28, -62],
            [-6, -85],
        ],
        [
            [14051, 7220],
            [-46, 6],
            [-48, 22],
            [-42, -66],
            [-38, -113],
        ],
        [
            [13877, 7069],
            [-7, 35],
            [-3, 56],
            [-33, 39],
            [-26, 64],
            [-6, 43],
            [-33, 64],
            [5, 36],
            [-7, 52],
            [6, 95],
            [17, 22],
            [35, 123],
        ],
        [
            [8237, 12477],
            [50, -16],
            [66, 3],
            [-35, -50],
            [-26, -7],
            [-89, 51],
            [-18, 41],
            [27, 38],
            [25, -60],
        ],
        [
            [8368, 12789],
            [-35, -2],
            [-91, 38],
            [-66, 58],
            [25, 10],
            [92, -30],
            [73, -51],
            [2, -23],
        ],
        [
            [3999, 12717],
            [-35, -17],
            [-117, 55],
            [-21, 43],
            [-63, 43],
            [-13, 35],
            [-72, 23],
            [-28, 66],
            [7, 28],
            [74, -27],
            [43, -18],
            [66, -13],
            [24, -43],
            [35, -58],
            [71, -50],
            [29, -67],
        ],
        [
            [8769, 12983],
            [-46, -108],
            [46, 43],
            [47, -28],
            [-25, -42],
            [63, -33],
            [33, 29],
            [71, -37],
            [-22, -89],
            [49, 21],
            [10, -65],
            [22, -77],
            [-30, -107],
            [-32, -4],
            [-47, 23],
            [15, 99],
            [-19, 17],
            [-83, -107],
            [-42, 4],
            [50, 58],
            [-68, 29],
            [-75, -7],
            [-138, 4],
            [-11, 37],
            [44, 42],
            [-30, 34],
            [59, 73],
            [73, 195],
            [44, 69],
            [61, 42],
            [33, -5],
            [-14, -33],
            [-38, -77],
        ],
        [
            [3348, 13392],
            [68, 10],
            [-21, -139],
            [61, -98],
            [-28, 0],
            [-43, 56],
            [-26, 57],
            [-36, 37],
            [-13, 54],
            [4, 39],
            [34, -16],
        ],
        [
            [7132, 14384],
            [-28, -64],
            [-31, 10],
            [-19, 37],
            [4, 8],
            [27, 37],
            [29, -3],
            [18, -25],
        ],
        [
            [6946, 14451],
            [-84, -67],
            [-50, 3],
            [-15, 33],
            [53, 56],
            [97, -1],
            [-1, -24],
        ],
        [
            [6715, 14811],
            [13, -54],
            [35, 19],
            [42, -32],
            [77, -42],
            [82, -38],
            [6, -57],
            [52, 9],
            [51, -41],
            [-63, -39],
            [-111, 30],
            [-39, 55],
            [-71, -65],
            [-100, -63],
            [-24, 71],
            [-96, -12],
            [61, 61],
            [10, 96],
            [24, 112],
            [51, -10],
        ],
        [
            [7372, 14993],
            [-79, -6],
            [-18, 60],
            [30, 68],
            [65, 17],
            [56, -34],
            [1, -52],
            [-8, -17],
            [-47, -36],
        ],
        [
            [5972, 15233],
            [-44, -43],
            [-96, 37],
            [-57, -13],
            [-96, 54],
            [62, 38],
            [49, 53],
            [75, -35],
            [43, -22],
            [21, -23],
            [43, -46],
        ],
        [
            [7991, 12304],
            [-47, 70],
            [0, 166],
            [-31, 35],
            [-48, -20],
            [-23, 32],
            [-54, -92],
            [-22, -95],
            [-24, -55],
            [-30, -20],
            [-23, -6],
            [-7, -30],
            [-131, 0],
            [-108, -1],
            [-32, -23],
            [-75, -87],
            [-9, -10],
            [-22, -47],
            [-64, 0],
            [-70, -1],
            [-32, -19],
            [11, -24],
            [7, -38],
            [-2, -12],
            [-92, -61],
            [-73, -19],
            [-83, -65],
            [-18, 0],
            [-24, 19],
            [-8, 18],
            [2, 12],
            [15, 43],
            [34, 67],
            [21, 73],
            [-15, 106],
            [-15, 110],
            [-74, 58],
            [9, 22],
            [-10, 15],
            [-20, 0],
            [-14, 19],
            [-4, 29],
            [-13, -13],
            [-20, 4],
            [5, 12],
            [-16, 12],
            [-7, 32],
            [-55, 39],
            [-57, 40],
            [-70, 49],
            [-66, 44],
            [-64, -35],
            [-23, -1],
            [-87, 32],
            [-58, -16],
            [-67, 38],
            [-73, 19],
            [-49, 7],
            [-22, 21],
            [-13, 67],
            [-24, -1],
            [0, -46],
            [-147, 0],
            [-242, 0],
            [-241, 0],
            [-212, 0],
            [-213, 0],
            [-208, 0],
            [-216, 0],
            [-70, 0],
            [-210, 0],
            [-201, 0],
        ],
        [
            [4047, 12777],
            [-9, 0],
            [-138, 119],
            [-51, 54],
            [-127, 50],
            [-40, 108],
            [11, 76],
            [-91, 52],
            [-13, 98],
            [-85, 88],
            [-2, 64],
        ],
        [
            [3502, 13486],
            [40, 58],
            [-2, 77],
            [-121, 78],
            [-73, 139],
            [-44, 88],
            [-64, 55],
            [-48, 50],
            [-37, 63],
            [-72, -39],
            [-69, -69],
            [-63, 81],
            [-49, 53],
            [-69, 34],
            [-70, 4],
            [0, 695],
            [1, 453],
        ],
        [
            [2762, 15306],
            [132, -29],
            [112, -59],
            [74, -11],
            [62, 51],
            [86, 38],
            [104, -15],
            [107, 54],
            [116, 30],
            [48, -51],
            [53, 29],
            [16, 57],
            [49, -13],
            [119, -109],
            [95, 83],
            [9, -93],
            [87, 20],
            [27, 36],
            [86, -7],
            [108, -51],
            [166, -45],
            [97, -21],
            [70, 8],
            [95, -63],
            [-99, -60],
            [128, -26],
            [190, 14],
            [61, 22],
            [75, -73],
            [78, 61],
            [-73, 52],
            [46, 43],
            [86, 5],
            [57, 12],
            [56, -29],
            [72, -67],
            [79, 10],
            [125, -55],
            [110, 19],
            [103, -3],
            [-8, 76],
            [63, 22],
            [110, -42],
            [-1, -115],
            [45, 97],
            [57, -3],
            [32, 123],
            [-76, 75],
            [-82, 49],
            [5, 136],
            [84, 88],
            [94, -19],
            [72, -54],
            [95, -138],
            [-62, -60],
            [131, -25],
            [0, -125],
            [94, 96],
            [85, -78],
            [-21, -91],
            [69, -83],
            [74, 88],
            [52, 106],
            [3, 134],
            [100, -9],
            [105, -18],
            [95, -61],
            [5, -60],
            [-53, -66],
            [50, -65],
            [-9, -59],
            [-139, -85],
            [-98, -19],
            [-73, 36],
            [-21, -62],
            [-69, -102],
            [-20, -54],
            [-83, -82],
            [-101, -8],
            [-56, -52],
            [-5, -79],
            [-81, -16],
            [-87, -98],
            [-77, -138],
            [-28, -96],
            [-4, -143],
            [105, -20],
            [32, -114],
            [33, -92],
            [98, 24],
            [132, -54],
            [71, -46],
            [51, -58],
            [88, -33],
            [76, -51],
            [116, -7],
            [77, -12],
            [-12, -107],
            [22, -122],
            [52, -137],
            [105, -116],
            [55, 40],
            [38, 126],
            [-37, 192],
            [-50, 65],
            [114, 57],
            [79, 86],
            [40, 84],
            [-6, 83],
            [-48, 103],
            [-86, 92],
            [83, 128],
            [-30, 111],
            [-24, 191],
            [49, 28],
            [121, -33],
            [73, -12],
            [59, 32],
            [66, -42],
            [88, -71],
            [21, -47],
            [126, -10],
            [-2, -102],
            [23, -155],
            [65, -19],
            [51, -71],
            [103, 67],
            [68, 136],
            [47, 56],
            [55, -109],
            [91, -156],
            [79, -146],
            [-29, -77],
            [95, -69],
            [64, -69],
            [113, -32],
            [45, -40],
            [28, -103],
            [55, -16],
            [28, -46],
            [5, -138],
            [-51, -45],
            [-50, -43],
            [-117, -43],
            [-89, -100],
            [-120, -20],
            [-151, 26],
            [-106, 1],
            [-74, -9],
            [-59, -88],
            [-91, -54],
            [-102, -161],
            [-81, -114],
            [59, 20],
            [114, 161],
            [149, 102],
            [106, 12],
            [63, -60],
            [-67, -82],
            [23, -132],
            [23, -92],
            [91, -60],
            [117, 17],
            [71, 137],
            [5, -88],
            [46, -44],
            [-88, -81],
            [-157, -72],
            [-69, -50],
            [-80, -87],
            [-53, 9],
            [-3, 103],
            [123, 100],
            [-113, -4],
            [-79, -15],
        ],
        [
            [4661, 15722],
            [-35, -57],
            [157, 37],
            [98, -61],
            [81, 62],
            [64, -40],
            [58, -120],
            [36, 51],
            [-50, 124],
            [62, 19],
            [71, -20],
            [78, -49],
            [45, -119],
            [22, -86],
            [119, -61],
            [128, -58],
            [-8, -53],
            [-116, -10],
            [45, -47],
            [-24, -44],
            [-128, 19],
            [-121, 33],
            [-83, -8],
            [-133, -41],
            [-180, -18],
            [-125, -13],
            [-39, 59],
            [-96, 33],
            [-63, -14],
            [-88, 96],
            [47, 13],
            [110, 21],
            [100, -5],
            [92, 21],
            [-137, 28],
            [-151, -9],
            [-101, 2],
            [-37, 46],
            [164, 49],
            [-109, -2],
            [-124, 32],
            [59, 91],
            [50, 49],
            [190, 75],
            [72, -25],
        ],
        [
            [5345, 15760],
            [-62, -82],
            [-110, 86],
            [24, 17],
            [94, 5],
            [54, -26],
        ],
        [
            [7339, 15720],
            [6, -34],
            [-75, 4],
            [-76, 2],
            [-77, -16],
            [-21, 7],
            [-78, 66],
            [3, 44],
            [34, 8],
            [162, -13],
            [122, -68],
        ],
        [
            [6616, 15727],
            [56, -76],
            [65, 99],
            [179, 50],
            [122, -127],
            [-11, -80],
            [140, 36],
            [67, 48],
            [157, -62],
            [98, -58],
            [9, -53],
            [132, 28],
            [73, -78],
            [171, -49],
            [61, -49],
            [68, -114],
            [-131, -56],
            [167, -81],
            [113, -27],
            [101, -111],
            [112, -9],
            [-22, -86],
            [-124, -141],
            [-87, 52],
            [-112, 118],
            [-92, -16],
            [-9, -70],
            [75, -71],
            [96, -56],
            [30, -32],
            [46, -122],
            [-25, -87],
            [-89, 33],
            [-178, 97],
            [100, -105],
            [74, -73],
            [12, -43],
            [-193, 49],
            [-151, 71],
            [-86, 59],
            [25, 34],
            [-106, 64],
            [-103, 59],
            [1, -36],
            [-204, -19],
            [-60, 42],
            [46, 89],
            [133, 3],
            [146, 15],
            [-24, 44],
            [25, 61],
            [92, 119],
            [-20, 54],
            [-27, 41],
            [-109, 59],
            [-144, 43],
            [46, 31],
            [-75, 75],
            [-62, 7],
            [-56, 42],
            [-38, -36],
            [-128, -16],
            [-258, 27],
            [-150, 36],
            [-115, 18],
            [-59, 43],
            [74, 57],
            [-100, 0],
            [-23, 123],
            [55, 109],
            [73, 51],
            [183, 32],
            [-52, -79],
        ],
        [
            [5639, 15811],
            [85, -25],
            [125, 15],
            [19, -35],
            [-67, -60],
            [108, -52],
            [-13, -110],
            [-116, -47],
            [-68, 10],
            [-49, 47],
            [-176, 94],
            [1, 39],
            [145, -15],
            [-78, 80],
            [84, 59],
        ],
        [
            [6146, 15680],
            [-76, -91],
            [-81, 4],
            [-44, 107],
            [1, 62],
            [37, 51],
            [70, 34],
            [148, -5],
            [135, -29],
            [-105, -110],
            [-85, -23],
        ],
        [
            [4216, 15511],
            [-187, -59],
            [-37, 53],
            [-164, 65],
            [30, 51],
            [49, 89],
            [62, 81],
            [-69, 75],
            [239, 19],
            [102, -25],
            [180, -7],
            [69, -36],
            [76, -51],
            [-89, -30],
            [-174, -87],
            [-87, -85],
            [0, -53],
        ],
        [
            [6116, 15949],
            [-38, -47],
            [-103, 9],
            [-86, 32],
            [38, 55],
            [102, 34],
            [62, -44],
            [25, -39],
        ],
        [
            [5770, 16162],
            [54, -56],
            [2, -63],
            [-32, -91],
            [-116, -13],
            [-76, 20],
            [1, 72],
            [-116, -10],
            [-5, 95],
            [77, -4],
            [107, 41],
            [98, -7],
            [6, 16],
        ],
        [
            [5083, 16099],
            [28, -43],
            [63, 20],
            [74, -5],
            [12, -61],
            [-43, -58],
            [-240, -18],
            [-179, -53],
            [-107, -3],
            [-9, 40],
            [147, 54],
            [-320, -15],
            [-99, 22],
            [97, 120],
            [66, 34],
            [199, -41],
            [126, -72],
            [124, -11],
            [-101, 118],
            [65, 44],
            [73, -14],
            [24, -58],
        ],
        [
            [6040, 16208],
            [79, -39],
            [139, 0],
            [61, -40],
            [-16, -45],
            [81, -28],
            [45, -29],
            [96, -5],
            [104, -11],
            [112, 27],
            [144, 10],
            [115, -8],
            [76, -47],
            [16, -51],
            [-44, -32],
            [-106, -26],
            [-91, 15],
            [-202, -19],
            [-146, -2],
            [-115, 15],
            [-187, 39],
            [-25, 67],
            [-8, 61],
            [-71, 53],
            [-147, 15],
            [-82, 38],
            [26, 50],
            [146, -8],
        ],
        [
            [4046, 16089],
            [120, 91],
            [145, 79],
            [109, -2],
            [97, 18],
            [-10, -94],
            [-54, -42],
            [-66, -6],
            [-132, -52],
            [-113, -19],
            [-96, 27],
        ],
        [
            [6100, 16260],
            [-32, -4],
            [-133, 8],
            [-19, 34],
            [143, -2],
            [50, -23],
            [-9, -13],
        ],
        [
            [4943, 16281],
            [-133, -35],
            [-104, 39],
            [57, 40],
            [103, 13],
            [100, -19],
            [-23, -38],
        ],
        [
            [4980, 16392],
            [-87, -24],
            [-117, 1],
            [1, 17],
            [73, 36],
            [38, -5],
            [92, -25],
        ],
        [
            [5959, 16326],
            [-105, -25],
            [-58, 28],
            [-30, 46],
            [-6, 50],
            [92, -5],
            [42, -8],
            [84, -42],
            [-19, -44],
        ],
        [
            [5660, 16358],
            [28, -50],
            [-116, 13],
            [-117, 40],
            [-158, 4],
            [69, 36],
            [-86, 30],
            [-5, 46],
            [139, -16],
            [192, -45],
            [54, -58],
        ],
        [
            [6583, 16521],
            [86, -39],
            [-98, -37],
            [-131, -91],
            [-125, -9],
            [-146, 16],
            [-77, 49],
            [2, 45],
            [56, 32],
            [-130, -1],
            [-78, 40],
            [-45, 56],
            [49, 55],
            [49, 37],
            [73, 8],
            [-31, 28],
            [165, 6],
            [89, -65],
            [120, -26],
            [116, -23],
            [56, -81],
        ],
        [
            [7894, 16942],
            [190, -9],
            [152, -16],
            [129, -33],
            [-3, -33],
            [-172, -53],
            [-172, -25],
            [-64, -27],
            [154, 1],
            [-167, -74],
            [-116, -35],
            [-120, -100],
            [-146, -20],
            [-46, -25],
            [-214, -13],
            [97, -15],
            [-49, -22],
            [59, -60],
            [-67, -42],
            [-109, -34],
            [-34, -49],
            [-99, -36],
            [10, -28],
            [121, 5],
            [2, -30],
            [-190, -73],
            [-185, 34],
            [-207, -19],
            [-106, 15],
            [-134, 6],
            [-9, 58],
            [131, 28],
            [-35, 89],
            [43, 8],
            [190, -53],
            [-97, 79],
            [-115, 23],
            [58, 47],
            [126, 29],
            [20, 43],
            [-100, 47],
            [-31, 64],
            [193, -5],
            [56, -14],
            [111, 45],
            [-160, 14],
            [-247, -8],
            [-125, 42],
            [-59, 49],
            [-83, 35],
            [-16, 42],
            [106, 23],
            [82, 4],
            [140, 20],
            [104, 46],
            [87, -6],
            [77, -35],
            [53, 66],
            [94, 20],
            [127, 13],
            [216, 5],
            [38, -13],
            [205, 21],
            [153, -8],
            [153, -8],
        ],
        [
            [13483, 12519],
            [-5, -50],
            [-32, -20],
            [-52, 15],
            [-15, -49],
            [-34, -4],
            [-13, 19],
            [-39, -41],
            [-34, -6],
            [-30, 26],
        ],
        [
            [13229, 12409],
            [-24, 54],
            [-34, -19],
            [1, 55],
            [52, 68],
            [-3, 31],
            [32, -11],
            [19, 21],
        ],
        [
            [13272, 12608],
            [61, -1],
            [14, 27],
            [76, -38],
        ],
        [
            [8003, 87],
            [-23, -49],
            [-61, -38],
            [-35, 4],
            [-42, 10],
            [-51, 36],
            [-74, 18],
            [-89, 68],
            [-72, 66],
            [-98, 136],
            [58, -25],
            [100, -81],
            [94, -44],
            [36, 56],
            [23, 83],
            [65, 50],
            [50, -14],
        ],
        [
            [7889, 404],
            [-63, 1],
            [-34, -30],
            [-63, -44],
            [-12, -114],
            [-30, -3],
            [-80, 40],
            [-81, 85],
        ],
        [
            [7526, 339],
            [-88, 69],
            [-23, 78],
            [21, 72],
            [-36, 81],
            [-9, 208],
            [30, 117],
            [75, 95],
            [-108, 36],
            [68, 107],
            [24, 203],
            [79, -42],
            [37, 253],
            [-48, 32],
            [-22, -153],
            [-45, 17],
            [23, 175],
            [24, 227],
            [32, 83],
            [-20, 119],
            [-6, 138],
            [30, 4],
            [43, 197],
            [50, 195],
            [30, 182],
            [-17, 184],
            [21, 100],
            [-8, 151],
            [41, 149],
            [13, 236],
            [22, 254],
            [22, 273],
            [-5, 200],
            [-15, 172],
        ],
        [
            [7761, 4551],
            [37, 31],
            [19, 62],
        ],
        [
            [20556, 9073],
            [-61, -58],
            [-58, 37],
            [-2, 105],
            [35, 55],
            [77, 35],
            [41, -2],
            [16, -48],
            [-31, -53],
            [-17, -71],
        ],
        [
            [21905, 12830],
            [84, -86],
            [29, -115],
            [108, 0],
            [61, 48],
            [116, 36],
            [-37, -110],
            [-26, -45],
            [-25, -133],
            [-47, -120],
            [-86, 22],
            [-61, -43],
            [18, -104],
            [-10, -145],
            [-36, -4],
            [0, -61],
        ],
        [
            [21993, 11970],
            [-45, 72],
            [-29, -69],
            [-109, -52],
            [11, -64],
            [-62, 4],
            [-32, 38],
            [-49, -86],
            [-78, -66],
            [-58, -79],
        ],
        [
            [21542, 11668],
            [-99, -35],
            [-52, -57],
            [-77, -33],
            [38, 56],
            [-15, 48],
            [56, 82],
            [-37, 64],
            [-62, -44],
            [-79, -84],
            [-44, -79],
            [-69, -6],
            [-37, -56],
            [38, -84],
            [58, -20],
            [2, -54],
            [56, -36],
            [79, 87],
            [63, -47],
            [46, -4],
            [11, -63],
            [-100, -34],
            [-33, -66],
            [-68, -62],
            [-37, -85],
            [76, -67],
            [28, -121],
            [43, -111],
            [48, -94],
            [-1, -91],
            [-45, -34],
            [17, -64],
            [42, -38],
            [-11, -100],
            [-18, -97],
            [-40, -11],
            [-51, -131],
            [-57, -161],
            [-66, -145],
            [-97, -114],
            [-99, -102],
            [-80, -14],
            [-43, -54],
            [-25, 39],
            [-40, -61],
            [-98, -61],
            [-75, -19],
            [-24, -128],
            [-40, -8],
            [-18, 89],
            [16, 47],
            [-95, 39],
            [-33, -20],
        ],
        [
            [20394, 9424],
            [-72, 32],
            [-33, 50],
            [11, 70],
            [-64, 22],
            [-34, 46],
            [-61, -65],
            [-69, -14],
            [-56, 1],
            [-38, -30],
        ],
        [
            [19978, 9536],
            [-37, -18],
            [11, -140],
            [-38, 3],
            [-7, 29],
        ],
        [
            [19907, 9410],
            [-2, 50],
            [-52, -35],
            [-31, 22],
            [-52, 46],
            [20, 102],
            [-44, 24],
            [-16, 112],
            [-75, -21],
            [8, 146],
            [67, 101],
            [3, 101],
            [-2, 94],
            [-31, 29],
            [-23, 72],
            [-42, -9],
        ],
        [
            [19635, 10244],
            [-76, 18],
            [24, 51],
            [-34, 77],
            [-50, -53],
            [-60, 31],
            [-82, -78],
            [-64, -91],
            [-56, -15],
        ],
        [
            [19032, 10126],
            [-6, 96],
            [-43, -25],
        ],
        [
            [18983, 10197],
            [-83, 12],
            [-80, 28],
            [-58, 53],
            [-54, 24],
            [-24, 59],
            [-39, 18],
            [-72, 79],
            [-57, 38],
            [-29, -30],
        ],
        [
            [18487, 10478],
            [-99, 86],
            [-70, 78],
            [-20, 134],
            [51, -17],
            [3, 63],
            [-29, 62],
            [8, 100],
            [-77, 143],
        ],
        [
            [18254, 11127],
            [-115, 49],
            [-21, 95],
            [-53, 56],
        ],
        [
            [18053, 11362],
            [-11, 70],
            [3, 47],
            [-44, 29],
            [-23, -13],
            [-18, 113],
        ],
        [
            [17960, 11608],
            [20, 28],
            [-9, 28],
            [67, 58],
            [49, 24],
            [75, -17],
            [27, 79],
            [90, 14],
            [26, 49],
            [111, 66],
            [10, 27],
        ],
        [
            [18426, 11964],
            [-5, 70],
            [48, 32],
            [-64, 212],
            [141, 48],
            [36, 28],
            [51, 218],
            [140, -40],
            [40, 55],
            [3, 123],
            [59, 12],
            [54, 81],
        ],
        [
            [18929, 12803],
            [28, 10],
        ],
        [
            [18957, 12813],
            [18, -85],
            [60, -65],
            [101, -45],
            [49, -99],
            [-27, -143],
            [25, -53],
            [84, -21],
            [95, -17],
            [86, -76],
            [44, -14],
            [32, -112],
            [42, -73],
            [78, 3],
            [145, -28],
            [95, 17],
            [70, -18],
            [105, -74],
            [85, 0],
            [32, -37],
            [81, 65],
            [115, 42],
            [106, 5],
            [83, 43],
            [51, 66],
            [50, 41],
            [-12, 40],
            [-22, 47],
            [37, 78],
            [40, -11],
            [72, -25],
            [71, 65],
            [108, 48],
            [52, 81],
            [50, 34],
            [103, 16],
            [56, -13],
            [7, 43],
            [-64, 86],
            [-57, 39],
            [-54, -45],
            [-70, 19],
            [-41, -15],
            [-18, 49],
            [50, 122],
            [35, 92],
        ],
        [
            [21005, 12885],
            [85, -46],
            [100, 78],
            [-1, 53],
            [63, 129],
            [40, 39],
            [-1, 68],
            [-39, 29],
            [59, 61],
            [88, 22],
            [94, 3],
            [106, -36],
            [62, -45],
            [44, -124],
            [27, -52],
            [23, -75],
            [27, -120],
            [123, -39],
        ],
        [
            [12542, 7402],
            [-32, -1],
            [-50, 23],
            [-45, -1],
            [-84, -21],
            [-50, -35],
            [-69, -45],
            [-14, 3],
        ],
        [
            [12198, 7325],
            [6, 100],
            [7, 16],
            [-3, 48],
            [-30, 52],
            [-22, 8],
            [-21, 33],
            [16, 54],
            [-7, 59],
            [3, 35],
        ],
        [
            [12147, 7730],
            [11, 1],
            [4, 53],
            [-5, 24],
            [7, 17],
            [26, 15],
            [-18, 97],
            [-16, 50],
            [6, 42],
            [14, 9],
        ],
        [
            [12176, 8038],
            [9, 11],
            [20, -18],
            [54, -1],
            [13, 35],
            [12, -2],
            [20, 14],
            [11, -52],
            [17, 15],
            [29, 18],
        ],
        [
            [12544, 7970],
            [19, -174],
            [-30, -103],
            [-19, -137],
            [31, -106],
            [-3, -48],
        ],
        [
            [13670, 7069],
            [-9, 7],
            [-42, -16],
            [-43, 16],
            [-34, -8],
        ],
        [
            [13542, 7068],
            [-115, 3],
        ],
        [
            [13427, 7071],
            [10, 96],
            [-27, 82],
            [-33, 20],
            [-14, 55],
            [-18, 17],
            [1, 34],
        ],
        [
            [13346, 7375],
            [18, 86],
            [34, 118],
            [20, 1],
            [42, 72],
            [27, 2],
            [40, -50],
            [49, 41],
            [6, 50],
            [16, 49],
            [11, 63],
            [38, 50],
            [15, 85],
            [15, 27],
            [10, 64],
            [18, 78],
            [59, 94],
            [4, 41],
            [8, 22],
            [-28, 48],
        ],
        [
            [13748, 8316],
            [2, 39],
            [20, 8],
        ],
        [
            [13770, 8363],
            [28, -79],
            [5, -81],
            [-3, -80],
            [39, -112],
            [-40, 1],
            [-20, -9],
            [-32, 13],
            [-15, -58],
            [41, -71],
            [31, -20],
            [10, -51],
            [23, -85],
            [-12, -33],
        ],
        [
            [13877, 7069],
            [-5, -66],
            [-56, 29],
            [-57, 32],
            [-89, 5],
        ],
        [
            [14951, 7061],
            [-23, -43],
            [-27, -32],
            [-27, -65],
            [-15, -56],
            [-4, -98],
            [-16, -46],
            [-1, -93],
        ],
        [
            [14838, 6628],
            [-20, -34],
            [-3, -73],
            [-10, -9],
            [-6, -67],
        ],
        [
            [14821, 6242],
            [13, -112],
            [-7, -64],
            [14, -71],
            [41, -68],
            [38, -154],
        ],
        [
            [14920, 5773],
            [-28, 12],
            [-95, -20],
            [-19, -15],
            [-20, -77],
            [16, -54],
            [-13, -145],
            [-9, -122],
            [19, -22],
            [50, -47],
            [20, 22],
            [5, -132],
            [-54, 1],
            [-29, 67],
            [-25, 52],
            [-54, 17],
            [-16, 64],
            [-44, -38],
            [-57, 17],
            [-23, 55],
            [-45, 11],
            [-34, -3],
            [-4, 38],
            [-24, 3],
        ],
        [
            [13617, 6047],
            [-10, 37],
        ],
        [
            [13664, 6208],
            [19, -12],
            [24, 46],
            [38, -1],
            [5, -34],
            [26, -22],
            [42, 76],
            [41, 60],
            [18, 39],
            [-2, 101],
            [30, 118],
            [33, 63],
            [47, 59],
            [8, 39],
            [2, 45],
            [11, 42],
            [-4, 69],
            [9, 109],
            [14, 76],
            [21, 65],
            [5, 74],
        ],
        [
            [14682, 7431],
            [43, -100],
            [31, -15],
            [19, 20],
            [33, -8],
            [40, 26],
            [16, -52],
            [63, -82],
        ],
        [
            [14927, 7220],
            [-4, -142],
            [28, -17],
        ],
        [
            [13588, 6177],
            [-58, 129],
        ],
        [
            [13530, 6306],
            [54, 67],
            [-27, 81],
            [24, 31],
            [48, 15],
            [6, 54],
            [38, -59],
            [62, -5],
            [21, 58],
            [9, 81],
            [-8, 96],
            [-32, 72],
            [29, 141],
            [-16, 25],
            [-53, -10],
            [-20, 63],
            [5, 53],
        ],
        [
            [7407, 6774],
            [-30, 28],
            [-35, 41],
            [-20, -20],
            [-60, 17],
            [-16, 53],
            [-14, -2],
            [-71, 70],
        ],
        [
            [7161, 6961],
            [-9, 38],
            [26, 9],
            [-3, 61],
            [17, 44],
            [35, 8],
            [29, 77],
            [27, 65],
            [-26, 28],
            [13, 71],
            [-16, 111],
            [15, 32],
            [-11, 104],
            [-28, 65],
        ],
        [
            [7230, 7674],
            [10, 59],
            [21, -8],
            [14, 36],
            [-17, 72],
            [9, 18],
        ],
        [
            [7267, 7851],
            [37, -4],
            [53, 85],
            [29, 13],
            [1, 41],
            [13, 103],
            [40, 57],
            [45, 2],
            [6, 26],
            [55, -11],
            [56, 62],
            [28, 27],
            [34, 59],
            [25, -8],
            [19, -32],
            [-14, -41],
        ],
        [
            [7694, 8230],
            [-45, -20],
            [-18, -61],
            [-28, -35],
            [-21, -46],
            [-8, -87],
            [-20, -71],
            [37, -8],
            [9, -56],
            [15, -27],
            [6, -50],
            [-8, -45],
            [2, -25],
            [18, -11],
            [17, -42],
            [91, 12],
            [40, -16],
            [50, -105],
            [29, 13],
            [51, -6],
            [40, 14],
            [25, -21],
            [-13, -67],
            [-15, -41],
            [-6, -87],
            [14, -81],
            [20, -37],
            [3, -27],
            [-36, -61],
            [26, -27],
            [19, -42],
            [21, -121],
        ],
        [
            [7795, 6267],
            [-35, 65],
            [-21, 2],
            [45, 125],
            [-53, 57],
            [-43, -10],
            [-25, 21],
            [-39, -32],
            [-53, 15],
            [-42, 128],
            [-33, 32],
            [-23, 57],
            [-47, 58],
            [-19, -11],
        ],
        [
            [6870, 7797],
            [-39, 27],
            [-14, 26],
            [8, 21],
            [-3, 27],
            [-19, 29],
            [-28, 24],
            [-25, 15],
            [-3, 36],
            [-19, 22],
            [5, -36],
            [-14, -29],
            [-17, 34],
            [-22, 12],
            [-10, 24],
            [0, 38],
            [10, 39],
            [-20, 17],
            [16, 24],
        ],
        [
            [6676, 8147],
            [11, 15],
            [46, -32],
            [16, 16],
            [22, -10],
            [12, -25],
            [21, -8],
            [17, 25],
        ],
        [
            [6821, 8128],
            [18, -67],
            [27, -49],
            [34, -52],
        ],
        [
            [6900, 7960],
            [-28, -11],
            [1, -49],
            [14, -18],
            [-10, -14],
            [2, -22],
            [-5, -25],
            [-4, -24],
        ],
        [
            [6919, 9624],
            [62, -9],
            [55, -1],
            [67, -41],
            [28, -45],
            [66, 14],
            [26, -29],
            [59, -76],
            [44, -55],
            [23, 2],
            [42, -25],
            [-5, -34],
            [53, -5],
            [53, -50],
            [-8, -29],
            [-47, -15],
            [-48, -6],
            [-49, 9],
            [-101, -11],
            [47, 67],
            [-29, 32],
            [-45, 8],
            [-24, 35],
            [-17, 70],
            [-40, -5],
            [-66, 32],
            [-22, 26],
            [-92, 20],
            [-25, 24],
            [27, 30],
            [-70, 6],
            [-51, -63],
            [-29, -2],
            [-10, -30],
            [-35, -14],
            [-30, 12],
            [37, 38],
            [15, 44],
            [32, 27],
            [36, 24],
            [54, 12],
            [17, 13],
        ],
        [
            [15062, 11084],
            [5, 0],
            [10, 30],
            [51, -2],
            [64, 36],
            [-48, -51],
            [6, -23],
        ],
        [
            [15150, 11074],
            [-8, 4],
            [-14, -9],
            [-10, 2],
            [-4, -4],
            [-1, 12],
            [-5, 7],
            [-14, 2],
            [-19, -11],
            [-13, 7],
        ],
        [
            [15150, 11074],
            [2, -10],
            [-73, -49],
            [-35, 15],
            [-16, 49],
            [34, 5],
        ],
        [
            [13707, 12762],
            [-40, 52],
            [-36, 29],
            [-8, 51],
            [-12, 38],
            [51, 26],
            [27, 30],
            [51, 24],
            [16, 23],
            [19, -14],
            [32, 13],
        ],
        [
            [13807, 13034],
            [33, -39],
            [53, -11],
            [-4, -33],
            [39, -26],
            [10, 32],
            [49, -14],
            [7, -38],
            [52, -8],
            [33, -60],
        ],
        [
            [14079, 12837],
            [-21, 0],
            [-11, -22],
            [-16, -5],
            [-5, -28],
            [-14, -6],
            [-2, -11],
            [-24, -13],
            [-31, 2],
            [-10, -27],
        ],
        [
            [13446, 13508],
            [2, -48],
            [71, -29],
            [0, -43],
            [72, 23],
            [40, 33],
            [80, -48],
            [33, -39],
        ],
        [
            [13744, 13357],
            [16, -61],
            [-19, -33],
            [25, -43],
            [17, -66],
            [-5, -42],
            [29, -78],
        ],
        [
            [13272, 12608],
            [9, 87],
            [36, 84],
            [-101, 22],
            [-34, 32],
        ],
        [
            [13182, 12833],
            [4, 53],
            [-14, 29],
        ],
        [
            [13180, 12997],
            [-12, 128],
            [43, 0],
            [18, 45],
            [17, 113],
            [-13, 41],
        ],
        [
            [13233, 13324],
            [13, 26],
            [60, 6],
            [13, -26],
            [48, 60],
            [-16, 45],
            [-4, 70],
        ],
        [
            [13347, 13505],
            [54, -16],
            [45, 19],
        ],
        [
            [15794, 8343],
            [17, -38],
            [-3, -51],
            [-40, -29],
            [30, -33],
        ],
        [
            [15798, 8192],
            [-26, -65],
        ],
        [
            [15772, 8127],
            [-16, 22],
            [-16, -9],
            [-39, 2],
            [-1, 37],
            [-6, 34],
            [24, 57],
            [25, 53],
        ],
        [
            [15743, 8323],
            [29, -10],
            [22, 30],
        ],
        [
            [13643, 13584],
            [-43, -99],
            [-74, 69],
            [-10, 51],
            [104, 40],
            [23, -61],
        ],
        [
            [13347, 13505],
            [-28, 68],
            [-3, 124],
            [12, 33],
            [21, 38],
            [62, 7],
            [25, 34],
            [57, 34],
            [-2, -63],
            [-21, -40],
            [8, -34],
            [39, -19],
            [-18, -46],
            [-21, 14],
            [-51, -88],
            [19, -59],
        ],
        [
            [7667, 9199],
            [9, 22],
            [55, 0],
            [41, -33],
            [19, 3],
            [13, -43],
            [39, 3],
            [-3, -37],
            [32, -4],
            [35, -45],
            [-26, -49],
            [-34, 26],
            [-33, -5],
            [-23, 6],
            [-13, -22],
            [-27, -8],
            [-10, 30],
            [-23, -18],
            [-29, -83],
            [-18, 19],
            [-4, 35],
        ],
        [
            [7667, 8996],
            [2, 33],
            [-18, 37],
            [17, 20],
            [5, 47],
            [-6, 66],
        ],
        [
            [13594, 9659],
            [-243, -233],
            [-205, -240],
            [-100, -55],
        ],
        [
            [13046, 9131],
            [-78, -12],
            [-1, 78],
            [-33, 21],
            [-44, 34],
            [-17, 57],
            [-238, 267],
            [-240, 267],
        ],
        [
            [12395, 9843],
            [-266, 295],
        ],
        [
            [12129, 10138],
            [2, 24],
            [0, 8],
        ],
        [
            [12131, 10170],
            [-1, 144],
            [114, 91],
            [71, 18],
            [58, 33],
            [27, 61],
            [83, 48],
            [3, 91],
            [41, 11],
            [32, 45],
            [93, 21],
            [13, 47],
            [-19, 26],
            [-24, 129],
            [-5, 74],
            [-26, 78],
        ],
        [
            [12591, 11087],
            [68, 67],
            [76, 21],
            [44, 51],
            [69, 37],
            [120, 22],
            [117, 10],
            [36, -18],
            [67, 48],
            [75, 0],
            [28, -28],
            [49, 8],
        ],
        [
            [13340, 11305],
            [-15, -63],
            [12, -117],
            [-17, -100],
            [-44, -68],
            [7, -93],
            [58, -72],
            [0, -30],
            [44, -49],
            [30, -219],
        ],
        [
            [13415, 10494],
            [23, -108],
            [4, -57],
            [-13, -100],
            [6, -55],
            [-9, -67],
            [6, -77],
            [-28, -51],
            [42, -89],
            [2, -52],
            [25, -69],
            [34, 23],
            [56, -57],
            [31, -76],
        ],
        [
            [7059, 6376],
            [37, 92],
            [-15, 53],
            [-27, -57],
            [-42, 54],
            [14, 34],
            [-12, 111],
            [25, 19],
            [13, 75],
            [27, 79],
            [-5, 50],
            [39, 26],
            [48, 49],
        ],
        [
            [7407, 6774],
            [10, -93],
            [-22, -80],
            [-77, -127],
            [-84, -48],
            [-44, -107],
            [-13, -82],
            [-40, -51],
            [-30, 62],
            [-29, 13],
            [-29, -10],
            [-2, 45],
            [20, 29],
            [-8, 51],
        ],
        [
            [15354, 9479],
            [-281, 0],
            [-275, 0],
            [-284, 0],
        ],
        [
            [14514, 9479],
            [0, 450],
            [0, 434],
            [-21, 99],
            [18, 75],
            [-11, 52],
            [26, 59],
        ],
        [
            [14526, 10648],
            [94, 2],
            [68, -33],
            [70, -36],
            [33, -19],
            [54, 39],
            [29, 35],
            [63, 10],
            [50, -15],
            [20, -61],
            [16, 40],
            [57, -29],
            [55, -7],
            [35, 31],
        ],
        [
            [15170, 10605],
            [47, -210],
        ],
        [
            [15217, 10395],
            [-20, -49],
            [-15, -92],
            [-20, -64],
            [-16, -21],
            [-24, 39],
            [-32, 55],
            [-50, 175],
            [-8, -11],
            [30, -129],
            [43, -123],
            [54, -190],
            [26, -67],
            [23, -68],
            [63, -136],
            [-15, -21],
            [3, -79],
            [82, -110],
            [13, -25],
        ],
        [
            [15743, 8323],
            [-24, 41],
            [-29, 71],
            [-32, 39],
            [-18, 42],
            [-62, 49],
            [-49, 2],
            [-17, 25],
            [-41, -29],
            [-43, 56],
            [-23, -91],
            [-82, 25],
        ],
        [
            [15323, 8553],
            [-8, 49],
            [31, 180],
            [7, 81],
            [22, 37],
            [52, 20],
            [36, 70],
        ],
        [
            [15463, 8990],
            [42, -141],
            [19, -112],
            [39, -59],
            [97, -116],
            [39, -70],
            [39, -70],
            [21, -42],
            [35, -37],
        ],
        [
            [12105, 11907],
            [3, 87],
            [-29, 54],
            [101, 87],
            [85, -22],
            [96, 1],
            [75, -21],
            [59, 7],
            [115, -4],
        ],
        [
            [12610, 12096],
            [28, -48],
            [129, -56],
            [26, 26],
            [80, -55],
            [82, 16],
        ],
        [
            [12955, 11979],
            [4, -71],
            [-67, -81],
            [-91, -25],
            [-6, -42],
            [-44, -68],
            [-26, -99],
            [26, -69],
            [-40, -56],
            [-15, -79],
            [-54, -24],
            [-50, -94],
            [-90, -1],
            [-67, 2],
            [-45, -43],
            [-27, -47],
            [-35, 10],
            [-26, 42],
            [-20, 70],
            [-65, 19],
        ],
        [
            [12217, 11323],
            [-6, 40],
            [26, 46],
            [9, 33],
            [-24, 36],
            [20, 81],
            [-28, 73],
            [30, 10],
            [2, 58],
            [12, 17],
            [0, 96],
            [33, 33],
            [-20, 61],
            [-40, 5],
            [-12, -16],
            [-42, 0],
            [-18, 60],
            [-29, -18],
            [-25, -31],
        ],
        [
            [14465, 13851],
            [9, 72],
            [-27, -16],
            [-45, 43],
            [-6, 70],
            [90, 35],
            [89, 18],
            [77, -20],
            [74, 3],
        ],
        [
            [14726, 14056],
            [10, -21],
            [-50, -71],
            [21, -113],
            [-31, -39],
        ],
        [
            [14676, 13812],
            [-58, 0],
            [-61, 45],
            [-31, 15],
            [-61, -21],
        ],
        [
            [15772, 8127],
            [-15, -43],
            [26, -68],
            [26, -59],
            [27, -43],
            [232, -146],
            [60, 1],
        ],
        [
            [16128, 7769],
            [-201, -366],
            [-92, -6],
            [-63, -85],
            [-45, -3],
            [-19, -38],
        ],
        [
            [15708, 7271],
            [-49, 0],
            [-28, 41],
            [-65, -51],
            [-21, -52],
            [-47, 10],
            [-16, 15],
            [-17, -3],
            [-22, 1],
            [-90, 103],
            [-49, 0],
            [-25, 40],
            [0, 69],
            [-36, 20],
        ],
        [
            [15243, 7464],
            [-41, 134],
            [-33, 28],
            [-12, 48],
            [-36, 60],
            [-44, 8],
            [24, 71],
            [38, 3],
            [11, 37],
        ],
        [
            [15150, 7853],
            [-1, 109],
        ],
        [
            [15149, 7962],
            [21, 129],
            [33, 34],
            [8, 50],
            [30, 93],
            [42, 60],
            [29, 121],
            [11, 104],
        ],
        [
            [14768, 15228],
            [-10, -87],
            [108, -81],
            [-65, -92],
            [82, -139],
            [-48, -104],
            [64, -91],
            [-29, -80],
            [105, -84],
            [-26, -62],
            [-66, -70],
            [-151, -156],
        ],
        [
            [14732, 14182],
            [-129, -10],
            [-125, -45],
            [-115, -25],
            [-41, 66],
            [-69, 40],
            [16, 121],
            [-34, 110],
            [33, 70],
            [65, 77],
            [162, 133],
            [47, 25],
            [-7, 52],
            [-99, 57],
        ],
        [
            [14436, 14853],
            [-24, 49],
            [-2, 187],
            [-110, 84],
            [-94, 60],
        ],
        [
            [14206, 15233],
            [42, 32],
            [78, -65],
            [93, 6],
            [76, -29],
            [68, 54],
            [35, 89],
            [110, 41],
            [90, -48],
            [-30, -85],
        ],
        [
            [25373, 4674],
            [24, -35],
            [-12, -64],
            [-44, -17],
            [-39, 15],
            [-7, 54],
            [28, 42],
            [32, -15],
            [18, 20],
        ],
        [
            [25443, 4740],
            [-45, -25],
            [-10, 45],
            [36, 25],
            [22, 7],
            [42, 38],
            [0, -60],
            [-45, -30],
        ],
        [
            [6, 4777],
            [-6, -7],
            [0, 60],
            [15, 5],
            [-9, -58],
        ],
        [
            [8411, 460],
            [85, 73],
            [60, -31],
            [43, 49],
            [57, -55],
            [-22, -42],
            [-95, -38],
            [-32, 44],
            [-61, -56],
            [-35, 56],
        ],
        [
            [13421, 11940],
            [-24, -94],
            [-32, 25],
            [-16, 82],
            [14, 45],
            [46, 47],
            [12, -105],
        ],
        [
            [13146, 12841],
            [16, -11],
            [20, 3],
        ],
        [
            [13229, 12409],
            [-3, -34],
            [20, -47],
            [-24, -37],
            [19, -94],
            [37, -15],
            [-8, -53],
        ],
        [
            [13270, 12129],
            [-63, -69],
            [-140, 33],
            [-104, -40],
            [-8, -74],
        ],
        [
            [12610, 12096],
            [36, 73],
            [14, 243],
            [-73, 128],
            [-53, 62],
            [-108, 47],
            [-7, 89],
            [92, 27],
            [119, -32],
            [-23, 138],
            [67, -52],
            [164, 96],
            [22, 99],
            [62, 25],
        ],
        [
            [13530, 6306],
            [-73, 124],
            [-47, 100],
            [-43, 126],
            [2, 41],
            [15, 39],
            [18, 89],
            [14, 90],
        ],
        [
            [13416, 6915],
            [24, 7],
            [103, -1],
            [-1, 147],
        ],
        [
            [12305, 13371],
            [-54, 25],
            [-43, -2],
            [15, 66],
            [-15, 66],
        ],
        [
            [12208, 13526],
            [59, 5],
            [76, -76],
            [-38, -84],
        ],
        [
            [12502, 13073],
            [-111, 20],
            [-20, 49],
            [74, 37],
            [-39, 67],
            [14, 80],
            [105, -11],
        ],
        [
            [12525, 13315],
            [11, 70],
            [-48, 75],
            [-1, 2],
            [-86, 22],
            [-17, 33],
            [26, 55],
            [-24, 33],
            [-38, -57],
            [-4, 117],
            [-36, 62],
            [26, 127],
            [55, 99],
            [57, -10],
            [85, 10],
            [-76, -132],
            [73, 17],
            [78, 0],
            [-19, -101],
            [-64, -109],
            [74, -7],
            [5, -13],
            [63, -145],
            [49, -19],
            [43, -139],
            [20, -48],
            [86, -23],
            [-9, -79],
            [-36, -36],
            [28, -63],
            [-63, -64],
            [-94, 2],
            [-121, -34],
            [-33, 24],
            [-47, -57],
            [-66, 14],
            [-50, -48],
            [-37, 26],
            [104, 127],
            [63, 27],
        ],
        [
            [15686, 11865],
            [11, 52],
            [-18, 83],
            [-41, 45],
            [-39, 15],
            [-26, 37],
        ],
        [
            [15573, 12097],
            [9, 15],
            [60, -21],
            [103, -20],
            [97, -59],
            [12, -23],
            [43, 19],
            [66, -25],
            [22, -50],
            [45, -28],
        ],
        [
            [15829, 11811],
            [-68, 60],
            [-75, -6],
        ],
        [
            [12819, 7517],
            [-111, -73],
            [-39, -41],
            [-64, -36],
            [-63, 35],
        ],
        [
            [12745, 8138],
            [-4, -38],
            [29, -64],
            [-1, -88],
            [7, -96],
            [18, -44],
            [-16, -111],
            [6, -60],
            [19, -78],
            [16, -42],
        ],
        [
            [12147, 7730],
            [-20, 4],
            [-15, -49],
            [-20, 0],
            [-14, 26],
            [5, 49],
            [-30, 75],
            [-18, -13],
            [-15, -3],
        ],
        [
            [12020, 7819],
            [-20, -7],
            [1, 45],
            [-11, 32],
            [2, 35],
            [-15, 51],
            [-20, 44],
            [-57, 0],
            [-16, -23],
            [-20, -3],
            [-12, -26],
            [-8, -34],
            [-38, -53],
        ],
        [
            [11806, 7880],
            [-31, 72],
            [-28, 47],
            [-17, 16],
            [-18, 24],
            [-8, 55],
            [-10, 27],
            [-21, 20],
        ],
        [
            [11673, 8141],
            [32, 59],
            [21, -2],
            [18, 20],
            [15, 0],
            [12, 16],
            [-7, 41],
            [8, 13],
            [2, 41],
        ],
        [
            [11774, 8329],
            [34, -1],
            [51, -30],
            [15, 3],
            [6, 13],
            [38, -9],
            [11, 6],
        ],
        [
            [11929, 8311],
            [4, -44],
            [11, 0],
            [19, 16],
            [11, -4],
            [20, -31],
            [30, -10],
            [20, 27],
            [23, 16],
            [17, 17],
            [14, -3],
            [16, -27],
            [8, -33],
            [29, -51],
            [-14, -32],
            [-3, -39],
            [15, 12],
            [9, -14],
            [-4, -38],
            [22, -35],
        ],
        [
            [11552, 8399],
            [9, 54],
        ],
        [
            [11561, 8453],
            [77, 3],
            [16, 29],
            [23, 2],
            [28, -30],
            [22, -1],
            [22, 21],
            [14, -35],
            [-29, -28],
            [-31, 2],
            [-31, 26],
            [-26, -28],
            [-13, -1],
            [-17, -17],
            [-64, 3],
        ],
        [
            [11673, 8141],
            [-38, 51],
            [-30, 8],
            [-16, 34],
            [1, 18],
            [-22, 26],
            [-5, 26],
        ],
        [
            [11563, 8304],
            [38, 20],
            [23, -4],
            [19, 14],
            [131, -5],
        ],
        [
            [13416, 6915],
            [-13, 18],
            [24, 138],
        ],
        [
            [14422, 11152],
            [39, -41],
            [55, 7],
            [53, -8],
            [-2, -21],
            [39, 14],
            [-9, -36],
            [-102, -10],
            [0, 20],
            [-86, 24],
            [13, 51],
        ],
        [
            [14589, 11778],
            [-43, 4],
            [-37, 11],
            [-86, -32],
            [49, -69],
            [-36, -19],
            [-40, -1],
            [-37, 63],
            [-13, -27],
            [15, -72],
            [36, -57],
            [-27, -27],
            [40, -56],
            [35, -36],
            [1, -69],
            [-66, 32],
            [21, -62],
            [-45, -13],
            [27, -107],
            [-47, -2],
            [-58, 53],
            [-27, 98],
            [-11, 81],
            [-28, 56],
            [-36, 70],
            [-5, 35],
        ],
        [
            [14233, 11781],
            [45, 11],
            [27, 26],
            [39, -2],
            [11, 21],
            [14, 4],
        ],
        [
            [14593, 11901],
            [35, -33],
            [-22, -76],
            [-17, -14],
        ],
        [
            [9433, 16884],
            [238, 73],
            [248, -6],
            [91, 45],
            [249, 12],
            [566, -15],
            [443, -97],
            [-130, -48],
            [-272, -5],
            [-381, -12],
            [36, -22],
            [250, 14],
            [214, -42],
            [138, 37],
            [59, -44],
            [-78, -70],
            [179, 45],
            [345, 47],
            [212, -23],
            [39, -52],
            [-288, -87],
            [-40, -28],
            [-227, -21],
            [165, -7],
            [-83, -89],
            [-57, -79],
            [2, -136],
            [85, -79],
            [-111, -6],
            [-115, -38],
            [129, -65],
            [17, -104],
            [-76, -11],
            [92, -105],
            [-156, -9],
            [81, -49],
            [-23, -43],
            [-99, -19],
            [-99, 0],
            [89, -84],
            [1, -54],
            [-141, 50],
            [-36, -32],
            [95, -31],
            [93, -74],
            [27, -99],
            [-126, -23],
            [-55, 47],
            [-88, 70],
            [25, -83],
            [-83, -64],
            [187, -5],
            [98, -7],
            [-190, -106],
            [-193, -97],
            [-207, -42],
            [-78, 0],
            [-73, -47],
            [-99, -129],
            [-151, -86],
            [-49, -5],
            [-95, -30],
            [-102, -28],
            [-61, -76],
            [-1, -85],
            [-35, -81],
            [-115, -97],
            [28, -95],
            [-31, -102],
            [-37, -119],
            [-100, -7],
            [-105, 99],
            [-141, 1],
            [-69, 66],
            [-47, 120],
            [-122, 152],
            [-36, 80],
            [-10, 109],
            [-98, 113],
            [25, 90],
            [-47, 43],
            [70, 142],
            [107, 47],
            [28, 51],
            [15, 95],
            [-81, -43],
            [-39, -19],
            [-64, -17],
            [-87, 40],
            [-5, 83],
            [28, 64],
            [66, 2],
            [145, -32],
            [-122, 78],
            [-64, 42],
            [-70, -17],
            [-60, 30],
            [80, 113],
            [-43, 45],
            [-57, 86],
            [-84, 129],
            [-90, 47],
            [0, 51],
            [-190, 72],
            [-151, 9],
            [-188, -5],
            [-173, -9],
            [-83, 39],
            [-122, 76],
            [185, 39],
            [143, 6],
            [-302, 32],
            [-160, 50],
            [9, 48],
            [268, 58],
            [260, 59],
            [27, 44],
            [-191, 44],
            [62, 49],
            [245, 85],
            [102, 13],
            [-29, 55],
            [168, 32],
            [218, 19],
            [217, 1],
            [78, -38],
            [188, 67],
            [168, -45],
            [100, -10],
            [147, -40],
            [-169, 66],
            [10, 53],
        ],
        [
            [6365, 8470],
            [-36, 21],
            [-45, 2],
            [-32, 24],
            [-37, 50],
        ],
        [
            [6215, 8567],
            [1, 36],
            [9, 28],
            [-10, 24],
            [33, 99],
            [91, 1],
            [2, 41],
            [-12, 8],
            [-8, 26],
            [-26, 28],
            [-26, 41],
            [32, 0],
            [0, 69],
            [66, 1],
            [66, -2],
        ],
        [
            [6448, 8733],
            [23, -22],
            [6, 18],
            [21, -16],
        ],
        [
            [6498, 8713],
            [-33, -46],
            [-33, -35],
            [-5, -24],
            [5, -24],
            [-14, -31],
        ],
        [
            [6418, 8553],
            [-17, -7],
            [4, -14],
            [-14, -14],
            [-24, -30],
            [-2, -18],
        ],
        [
            [8883, 7074],
            [18, 52],
            [7, 56],
            [12, 53],
        ],
        [
            [8920, 7235],
            [-28, 72],
            [-5, 83],
            [37, 105],
        ],
        [
            [8924, 7495],
            [24, -14],
            [52, -29],
            [75, -102],
            [12, -50],
        ],
        [
            [8513, 7814],
            [47, -45],
            [44, -80],
            [2, -62],
            [26, -3],
            [38, -60],
            [28, -42],
        ],
        [
            [8698, 7522],
            [-11, -111],
            [-43, -31],
            [4, -29],
            [-13, -63],
            [31, -89],
            [23, 0],
            [9, -69],
            [44, -106],
        ],
        [
            [8444, 7427],
            [-48, 93],
            [19, 34],
            [-1, 56],
            [43, 19],
            [18, 23],
            [-24, 45],
            [6, 45],
            [56, 72],
        ],
        [
            [6562, 8378],
            [-12, 38],
            [-22, 11],
        ],
        [
            [6528, 8427],
            [5, 49],
            [-9, 13],
            [-15, 9],
            [-31, -15],
            [-3, 16],
            [-21, 20],
            [-15, 24],
            [-21, 10],
        ],
        [
            [6498, 8713],
            [7, -5],
            [16, 22],
            [20, 2],
            [6, -10],
            [11, 6],
            [33, -11],
            [33, 3],
            [23, 13],
            [8, 14],
            [23, -6],
            [17, -9],
            [19, 3],
            [14, 11],
            [31, -17],
            [11, -3],
            [22, -23],
            [20, -27],
            [26, -18],
            [19, -35],
        ],
        [
            [6857, 8623],
            [-24, 3],
            [-10, -17],
            [-25, -16],
            [-18, 0],
            [-15, -15],
            [-15, 5],
            [-11, 19],
            [-7, -4],
            [-9, -29],
            [-7, 1],
            [-1, -25],
            [-25, -34],
            [-13, -14],
            [-7, -15],
            [-21, 25],
            [-16, -33],
            [-14, 1],
            [-17, -3],
            [1, -60],
            [-10, -1],
            [-9, -28],
            [-22, -5],
        ],
        [
            [14077, 12399],
            [18, -47],
            [22, -36],
            [-27, -45],
        ],
        [
            [14058, 12001],
            [-8, -21],
        ],
        [
            [14050, 11980],
            [-66, 45],
            [-41, 45],
            [-65, 36],
            [-60, 90],
            [14, 9],
            [-32, 51],
            [-1, 41],
            [-46, 19],
            [-21, -52],
            [-21, 40],
            [2, 44],
            [2, 2],
        ],
        [
            [13715, 12350],
            [49, -5],
            [13, 21],
            [24, -20],
            [28, -2],
            [0, 34],
            [24, 12],
            [7, 49],
            [57, 33],
        ],
        [
            [13917, 12472],
            [22, -15],
            [53, -52],
            [59, -24],
            [26, 18],
        ],
        [
            [7667, 8996],
            [-47, 21],
            [-33, -9],
            [-43, 9],
            [-34, -23],
            [-38, 38],
            [7, 40],
            [65, -17],
            [53, -10],
            [26, 27],
            [-32, 53],
            [0, 46],
            [-45, 19],
            [16, 35],
            [44, -6],
            [61, -20],
        ],
        [
            [13946, 12670],
            [36, -31],
            [26, -14],
            [60, 15],
            [6, 25],
            [28, 3],
            [34, 19],
            [8, -8],
            [33, 16],
            [17, 28],
            [23, 8],
            [75, -37],
            [15, 12],
        ],
        [
            [14307, 12706],
            [40, -33],
            [5, -33],
        ],
        [
            [14352, 12640],
            [-44, -25],
            [-33, -84],
            [-42, -82],
            [-57, -23],
        ],
        [
            [14176, 12426],
            [-44, 5],
            [-55, -32],
        ],
        [
            [13917, 12472],
            [-14, 41],
            [-12, 1],
        ],
        [
            [21290, 5542],
            [-30, -3],
            [-93, 86],
            [66, 24],
            [37, -37],
            [25, -37],
            [-5, -33],
        ],
        [
            [21592, 5706],
            [7, -24],
            [1, -37],
        ],
        [
            [21600, 5645],
            [-46, -91],
            [-61, -28],
            [-8, 16],
            [6, 41],
            [31, 74],
            [70, 49],
        ],
        [
            [21092, 5803],
            [25, -33],
            [44, 10],
            [18, -52],
            [-82, -24],
            [-50, -16],
            [-38, 1],
            [25, 70],
            [39, 1],
            [19, 43],
        ],
        [
            [21445, 5803],
            [-10, -68],
            [-107, -34],
            [-93, 15],
            [0, 44],
            [55, 26],
            [45, -37],
            [47, 9],
            [63, 45],
        ],
        [
            [20434, 5964],
            [136, -12],
            [16, 50],
            [132, -58],
            [24, -79],
            [107, -22],
            [87, -74],
            [-81, -46],
            [-78, 49],
            [-63, -3],
            [-74, 9],
            [-66, 21],
            [-83, 48],
            [-52, 12],
            [-29, -15],
            [-129, 50],
            [-13, 52],
            [-64, 9],
            [48, 116],
            [86, -7],
            [57, -47],
            [30, -10],
            [9, -43],
        ],
        [
            [22282, 6033],
            [-36, -83],
            [-6, 91],
            [11, 44],
            [15, 42],
            [16, -36],
            [0, -58],
        ],
        [
            [21753, 6369],
            [-26, -40],
            [-49, 22],
            [-14, 53],
            [72, 6],
            [17, -41],
        ],
        [
            [21981, 6414],
            [26, -94],
            [-60, 51],
            [-59, 11],
            [-40, -9],
            [-49, 4],
            [17, 68],
            [87, 5],
            [78, -36],
        ],
        [
            [22727, 6475],
            [1, -399],
            [2, -398],
        ],
        [
            [22730, 5678],
            [-63, 100],
            [-73, 25],
            [-17, -35],
            [-90, -4],
            [30, 100],
            [45, 34],
            [-19, 132],
            [-34, 104],
            [-137, 103],
            [-59, 10],
            [-105, 112],
            [-21, -59],
            [-27, -11],
            [-17, 45],
            [0, 54],
            [-54, 60],
            [76, 44],
            [51, -3],
            [-6, 32],
            [-104, 1],
            [-28, 72],
            [-63, 23],
            [-30, 61],
            [95, 29],
            [37, 40],
            [114, -50],
            [10, -46],
            [20, -197],
            [73, -73],
            [59, 129],
            [82, 74],
            [63, 0],
            [61, -43],
            [52, -43],
            [76, -23],
        ],
        [
            [21611, 6966],
            [-57, -122],
            [-53, -23],
            [-68, 23],
            [-119, -6],
            [-62, -17],
            [-10, -92],
            [64, -109],
            [38, 56],
            [132, 41],
            [-5, -57],
            [-31, 19],
            [-31, -73],
            [-63, -47],
            [68, -156],
            [-13, -43],
            [63, -140],
            [0, -80],
            [-38, -36],
            [-28, 43],
            [34, 100],
            [-69, -47],
            [-18, 33],
            [9, 47],
            [-51, 72],
            [5, 119],
            [-47, -37],
            [6, -143],
            [3, -174],
            [-44, -17],
            [-30, 35],
            [20, 112],
            [-11, 118],
            [-30, 1],
            [-22, 84],
            [29, 80],
            [11, 96],
            [35, 185],
            [14, 50],
            [60, 91],
            [56, -37],
            [89, -16],
            [82, 5],
            [70, 89],
            [12, -27],
        ],
        [
            [21855, 6930],
            [-4, -107],
            [-36, 12],
            [-11, -74],
            [29, -64],
            [-20, -15],
            [-28, 78],
            [-22, 155],
            [15, 98],
            [23, 45],
            [5, -67],
            [42, -10],
            [7, -51],
        ],
        [
            [20508, 7037],
            [12, -81],
            [49, -70],
            [45, 25],
            [46, -9],
            [41, 63],
            [34, 10],
            [66, -35],
            [58, 27],
            [36, 169],
            [28, 43],
            [24, 139],
            [82, 0],
            [61, -20],
        ],
        [
            [21090, 7298],
            [-40, -111],
            [52, -116],
            [-12, -56],
            [79, -113],
            [-84, -15],
            [-23, -83],
            [3, -110],
            [-69, -84],
            [-1, -121],
            [-28, -187],
            [-10, 43],
            [-81, -55],
            [-28, 75],
            [-50, 6],
            [-36, 40],
            [-83, -44],
            [-26, 60],
            [-47, -7],
            [-58, 14],
            [-11, 163],
            [-35, 34],
            [-34, 105],
            [-10, 107],
            [8, 113],
            [42, 81],
        ],
        [
            [20236, 6077],
            [-78, -3],
            [-60, 103],
            [-91, 99],
            [-30, 74],
            [-54, 100],
            [-35, 91],
            [-54, 172],
            [-63, 101],
            [-20, 105],
            [-26, 96],
            [-64, 77],
            [-37, 104],
            [-53, 69],
            [-74, 134],
            [-6, 62],
            [45, -5],
            [110, -23],
            [63, -120],
            [54, -82],
            [39, -52],
            [67, -130],
            [72, -2],
            [60, -84],
            [41, -103],
            [54, -55],
            [-28, -100],
            [40, -42],
            [26, -4],
            [12, -85],
            [24, -68],
            [53, -11],
            [33, -77],
            [-17, -152],
            [-3, -189],
        ],
        [
            [18487, 10478],
            [-45, -55],
            [-28, -115],
            [69, -46],
            [67, -59],
            [92, -69],
            [97, -15],
            [40, -63],
            [55, -12],
            [86, -28],
            [59, 2],
            [8, 49],
            [-10, 77],
            [6, 53],
        ],
        [
            [19635, 10244],
            [5, -47],
            [-24, -22],
            [5, -75],
            [-50, 22],
            [-92, -85],
            [2, -70],
            [-39, -102],
            [-4, -59],
            [-31, -101],
            [-56, 27],
            [-3, -125],
            [-16, -42],
            [8, -52],
            [-35, -29],
        ],
        [
            [19048, 9485],
            [-11, -44],
            [-48, 1],
            [-87, -25],
            [4, -91],
            [-38, -72],
            [-102, -83],
            [-79, -144],
            [-53, -76],
            [-71, -81],
            [0, -56],
            [-35, -30],
            [-64, -44],
            [-33, -6],
            [-21, -94],
            [14, -158],
            [4, -101],
            [-30, -117],
            [0, -207],
            [-37, -6],
            [-32, -93],
            [21, -40],
            [-64, -35],
            [-24, -83],
            [-28, -36],
            [-67, 115],
            [-33, 171],
            [-27, 123],
            [-25, 58],
            [-37, 117],
            [-18, 153],
            [-12, 76],
            [-65, 168],
            [-29, 237],
            [-21, 156],
            [0, 148],
            [-14, 114],
            [-103, -73],
            [-49, 15],
            [-93, 147],
            [34, 45],
            [-21, 48],
            [-83, 103],
        ],
        [
            [17571, 9685],
            [47, 82],
            [155, -1],
            [-14, 106],
            [-39, 62],
            [-8, 93],
            [-46, 56],
            [77, 127],
            [83, -9],
            [74, 128],
            [44, 124],
            [69, 122],
            [-1, 88],
            [60, 71],
            [-57, 60],
            [-24, 82],
            [-26, 108],
            [35, 52],
            [108, -30],
            [79, 19],
            [67, 102],
        ],
        [
            [12305, 13371],
            [12, -87],
            [-54, -110],
            [-125, -72],
            [-100, 19],
            [57, 128],
            [-37, 124],
            [97, 96],
            [53, 57],
        ],
        [
            [16562, 11335],
            [62, 24],
            [51, 69],
            [47, -3],
            [30, 23],
            [51, -12],
            [78, -61],
            [57, -14],
            [81, -107],
            [53, -5],
            [6, -103],
        ],
        [
            [17054, 10435],
            [35, -64],
            [29, -74],
            [68, -54],
            [1, -107],
            [34, -20],
            [6, -57],
            [-102, -62],
            [-27, -142],
        ],
        [
            [17098, 9855],
            [-133, 37],
            [-78, 28],
            [-80, 16],
            [-30, 150],
            [-34, 21],
            [-53, -21],
            [-72, -60],
            [-87, 41],
            [-71, 94],
            [-69, 34],
            [-47, 116],
            [-52, 163],
            [-38, -20],
            [-45, 41],
            [-26, -48],
        ],
        [
            [16183, 10447],
            [-39, 64],
            [-1, 65],
            [-23, 0],
            [12, 89],
            [-37, 93],
            [-86, 66],
            [-50, 117],
            [17, 95],
            [36, 42],
            [-6, 71],
            [-46, 37],
            [-46, 146],
        ],
        [
            [15914, 11332],
            [-39, 97],
            [14, 38],
            [-22, 141],
            [48, 34],
        ],
        [
            [16205, 11472],
            [23, -90],
            [66, -25],
            [49, -61],
            [101, -21],
            [111, 32],
            [7, 28],
        ],
        [
            [16183, 10447],
            [-42, 6],
        ],
        [
            [16141, 10453],
            [-48, 10],
            [-52, -118],
        ],
        [
            [16041, 10345],
            [-132, 11],
            [-199, 245],
            [-105, 86],
            [-86, 33],
        ],
        [
            [15519, 10720],
            [-29, 148],
        ],
        [
            [15490, 10868],
            [158, 128],
            [26, 147],
            [-6, 90],
            [38, 30],
            [37, 76],
        ],
        [
            [15743, 11339],
            [29, 19],
            [83, -16],
            [25, -31],
            [34, 21],
        ],
        [
            [11717, 14909],
            [-16, -80],
            [79, -83],
            [-91, -93],
            [-205, -84],
            [-61, -22],
            [-93, 18],
            [-197, 39],
            [69, 54],
            [-154, 60],
            [126, 23],
            [-3, 36],
            [-149, 28],
            [48, 80],
            [107, 18],
            [110, -83],
            [108, 67],
            [89, -35],
            [116, 66],
            [117, -9],
        ],
        [
            [15273, 10787],
            [-13, -38],
        ],
        [
            [15260, 10749],
            [-24, 16],
            [-15, -81],
            [17, -13],
            [-18, -17],
            [-3, -33],
            [33, 17],
        ],
        [
            [15250, 10638],
            [1, -48],
            [-34, -195],
        ],
        [
            [15170, 10605],
            [21, 41],
            [-5, 7],
            [19, 56],
            [14, 92],
            [10, 31],
            [2, 1],
        ],
        [
            [15231, 10833],
            [23, 0],
            [7, 21],
            [19, 2],
        ],
        [
            [15280, 10856],
            [1, -50],
            [-10, -18],
            [2, -1],
        ],
        [
            [13843, 11461],
            [-26, -96],
            [11, -38],
            [-15, -62],
            [-55, 46],
            [-35, 13],
            [-99, 62],
            [10, 62],
            [83, -11],
            [72, 13],
            [54, 11],
        ],
        [
            [13396, 11825],
            [42, -87],
            [-10, -161],
            [-32, 8],
            [-29, -41],
            [-27, 32],
            [-2, 147],
            [-17, 71],
            [39, -6],
            [36, 37],
        ],
        [
            [13722, 12472],
            [-8, -60],
            [17, -51],
        ],
        [
            [13731, 12361],
            [-56, 17],
            [-58, -43],
            [4, -61],
            [-9, -35],
            [24, -62],
            [66, -61],
            [36, -102],
            [78, -98],
            [55, 1],
            [18, -27],
            [-20, -24],
            [63, -44],
            [52, -37],
            [61, -65],
            [8, -22],
            [-14, -44],
            [-39, 57],
            [-62, 20],
            [-30, -79],
            [52, -45],
            [-9, -63],
            [-29, -8],
            [-38, -105],
            [-30, -9],
            [0, 37],
            [15, 66],
            [15, 26],
            [-27, 71],
            [-22, 61],
            [-29, 15],
            [-21, 53],
            [-45, 23],
            [-31, 49],
            [-52, 8],
            [-56, 55],
            [-65, 79],
            [-48, 70],
            [-22, 121],
            [-35, 14],
            [-58, 41],
            [-33, -17],
            [-41, -56],
            [-29, -9],
        ],
        [
            [7252, 9050],
            [47, -11],
            [38, -29],
            [12, -33],
            [-50, -2],
            [-22, -21],
            [-38, 20],
            [-41, 44],
            [9, 28],
            [29, 8],
            [16, -4],
        ],
        [
            [15273, 10787],
            [79, -48],
            [138, 129],
        ],
        [
            [15519, 10720],
            [-13, -18],
            [-143, -61],
            [71, -123],
            [-23, -21],
            [-12, -40],
            [-54, -17],
            [-17, -44],
            [-31, -38],
            [-78, 20],
        ],
        [
            [15219, 10378],
            [-2, 17],
        ],
        [
            [15250, 10638],
            [10, 36],
            [0, 75],
        ],
        [
            [22276, 10963],
            [9, -42],
            [-40, -74],
            [-28, 39],
            [-36, -29],
            [-19, -71],
            [-46, 35],
            [0, 58],
            [39, 73],
            [41, -14],
            [29, 51],
            [51, -26],
        ],
        [
            [22726, 11328],
            [-27, -97],
            [12, -62],
            [-37, -86],
            [-90, -57],
            [-125, -7],
            [-101, -140],
            [-48, 48],
            [-3, 91],
            [-122, -27],
            [-84, -58],
            [-83, -2],
            [72, -90],
            [-48, -207],
            [-45, -53],
            [-35, 48],
            [18, 110],
            [-45, 36],
            [-29, 83],
            [67, 38],
            [37, 77],
            [72, 63],
            [52, 83],
            [140, 37],
            [76, -25],
            [74, 217],
            [47, -58],
            [104, 122],
            [41, 47],
            [44, 150],
            [-12, 137],
            [30, 78],
            [74, 22],
            [39, -170],
            [-2, -99],
            [-65, -123],
            [2, -126],
        ],
        [
            [22933, 12187],
            [50, -26],
            [50, 52],
            [16, -137],
            [-106, -34],
            [-62, -121],
            [-111, 83],
            [-38, -133],
            [-79, -2],
            [-10, 121],
            [35, 95],
            [75, 7],
            [21, 168],
            [21, 96],
            [83, -128],
            [55, -41],
        ],
        [
            [17768, 11954],
            [-40, -23],
            [-94, -84],
            [-31, -88],
            [-26, -1],
            [-19, 58],
            [-91, 4],
            [-14, 100],
            [-35, 1],
            [6, 122],
            [-85, 90],
            [-121, -10],
            [-83, -17],
            [-68, 109],
            [-58, 46],
            [-110, 89],
            [-13, 10],
            [-182, -73],
            [3, -450],
        ],
        [
            [16707, 11837],
            [-36, -5],
            [-50, 95],
            [-48, 34],
            [-81, -25],
            [-31, -41],
        ],
        [
            [16461, 11895],
            [-4, 30],
            [18, 51],
            [-14, 42],
            [-82, 42],
            [-32, 110],
            [-39, 31],
            [-3, 39],
            [69, -11],
            [3, 89],
            [60, 20],
            [62, -19],
            [13, 120],
            [-13, 75],
            [-71, -6],
            [-60, 30],
            [-82, -53],
            [-65, -26],
        ],
        [
            [16221, 12459],
            [-36, 20],
            [7, 62],
            [-45, 83],
            [-53, -4],
            [-60, 83],
            [41, 92],
            [-21, 25],
            [57, 135],
            [73, -72],
            [9, 90],
            [145, 132],
            [111, 3],
            [156, -84],
            [84, -49],
            [74, 51],
            [113, 3],
            [91, -63],
            [20, 36],
            [100, -6],
            [18, 58],
            [-115, 84],
            [68, 60],
            [-14, 33],
            [69, 32],
            [-52, 83],
            [33, 42],
            [264, 42],
            [35, 30],
            [178, 46],
            [63, 51],
            [127, -27],
            [22, -127],
            [74, 30],
            [91, -42],
            [-6, -66],
            [68, 7],
            [178, 115],
            [-26, -38],
            [90, -94],
            [158, -311],
            [38, 64],
            [98, -70],
            [102, 31],
            [39, -22],
            [34, -70],
            [49, -23],
            [30, -53],
            [91, 16],
            [38, -74],
        ],
        [
            [18426, 11964],
            [-43, 18],
            [-36, 44],
            [-105, 13],
            [-117, 3],
            [-26, -14],
            [-101, 53],
            [-40, -26],
            [-11, -72],
            [-117, 41],
            [-46, -17],
            [-16, -53],
        ],
        [
            [15689, 6586],
            [-50, -48],
            [-18, -51],
            [-26, -9],
            [-10, -86],
            [-23, -50],
            [-14, -81],
            [-28, -40],
        ],
        [
            [15520, 6221],
            [-102, 121],
            [-5, 72],
            [-256, 249],
            [-12, 13],
        ],
        [
            [15145, 6676],
            [-1, 129],
            [20, 50],
            [35, 80],
            [26, 90],
            [-31, 140],
            [-8, 61],
            [-34, 85],
        ],
        [
            [15152, 7311],
            [44, 73],
            [47, 80],
        ],
        [
            [15708, 7271],
            [-62, -139],
            [1, -444],
            [42, -102],
        ],
        [
            [17960, 11608],
            [-134, -19],
            [-87, 40],
            [-77, -9],
            [7, 70],
            [76, -21],
            [26, 38],
        ],
        [
            [17771, 11707],
            [54, -12],
            [91, 89],
            [-84, 64],
            [-50, -31],
            [-52, 46],
            [59, 79],
            [-21, 12],
        ],
        [
            [20007, 8280],
            [-17, 148],
            [46, 101],
            [91, 24],
            [67, -18],
        ],
        [
            [20194, 8535],
            [57, -48],
            [33, 84],
            [62, -44],
        ],
        [
            [20346, 8527],
            [17, -82],
            [-9, -146],
            [-118, -94],
            [30, -74],
            [-73, -9],
            [-62, -50],
        ],
        [
            [20131, 8072],
            [-59, 19],
            [-29, 63],
            [-36, 126],
        ],
        [
            [21831, 11508],
            [61, -144],
            [18, -79],
            [0, -141],
            [-27, -67],
            [-64, -24],
            [-56, -50],
            [-63, -11],
            [-8, 67],
            [13, 91],
            [-32, 128],
            [53, 20],
            [-48, 104],
        ],
        [
            [21678, 11402],
            [4, 11],
            [32, -4],
            [26, 55],
            [50, 6],
            [31, 8],
            [10, 30],
        ],
        [
            [14165, 11993],
            [14, 28],
        ],
        [
            [14179, 12021],
            [17, 8],
            [9, 42],
            [13, 6],
            [10, -17],
            [13, -7],
            [9, -21],
            [11, -5],
            [14, -23],
            [10, 1],
            [-8, -30],
            [-8, -14],
            [2, -10],
        ],
        [
            [14271, 11951],
            [-16, -4],
            [-41, -19],
            [-3, -25],
            [-9, 1],
        ],
        [
            [16141, 10453],
            [15, -54],
            [-7, -27],
            [23, -93],
        ],
        [
            [16172, 10279],
            [-50, -3],
            [-18, 58],
            [-63, 11],
        ],
        [
            [20194, 8535],
            [23, 55],
            [3, 104],
            [-57, 106],
            [-5, 120],
            [-54, 100],
            [-53, 8],
            [-15, -42],
            [-41, -3],
            [-21, 21],
            [-75, -73],
            [-2, 109],
            [18, 129],
            [-48, 5],
            [-4, 74],
            [-31, 38],
        ],
        [
            [19832, 9286],
            [15, 45],
            [60, 79],
        ],
        [
            [19978, 9536],
            [41, -97],
            [32, -111],
            [87, -1],
            [28, -106],
            [-46, -33],
            [-20, -43],
            [85, -73],
            [58, -145],
            [45, -107],
            [53, -85],
            [18, -86],
            [-13, -122],
        ],
        [
            [15231, 10833],
            [25, 100],
            [35, 86],
            [1, 4],
        ],
        [
            [15292, 11023],
            [32, -6],
            [12, -47],
            [-39, -46],
            [-17, -68],
        ],
        [
            [12198, 7325],
            [-18, -1],
            [-73, 58],
            [-65, 93],
            [-60, 67],
            [-48, 79],
        ],
        [
            [11934, 7621],
            [17, 39],
            [4, 35],
            [32, 66],
            [33, 58],
        ],
        [
            [13795, 9585],
            [-50, -46],
            [-39, 67],
            [-112, 53],
        ],
        [
            [13415, 10494],
            [35, 28],
            [6, 51],
            [-8, 51],
            [49, 48],
            [22, 39],
            [35, 34],
            [4, 94],
        ],
        [
            [13558, 10839],
            [83, -42],
            [30, 11],
            [59, -21],
            [93, -54],
            [33, -109],
            [64, -24],
            [100, -51],
            [76, -60],
            [34, 31],
            [34, 56],
            [-16, 94],
            [22, 60],
            [51, 57],
            [48, 16],
            [96, -25],
            [24, -54],
            [26, -1],
            [23, -20],
            [70, -15],
            [18, -40],
        ],
        [
            [14514, 9479],
            [0, -244],
            [-82, 0],
            [0, -52],
        ],
        [
            [14432, 9183],
            [-282, 234],
            [-283, 234],
            [-72, -66],
        ],
        [
            [18535, 7711],
            [-11, -127],
            [-30, -35],
            [-61, -28],
            [-34, 97],
            [-13, 175],
            [32, 199],
            [49, -68],
            [33, -86],
            [35, -127],
        ],
        [
            [14795, 3255],
            [25, -37],
            [-22, -59],
            [-12, -40],
            [-39, -19],
            [-13, -39],
            [-25, -12],
            [-53, 94],
            [38, 77],
            [38, 49],
            [32, 24],
            [31, -38],
        ],
        [
            [14353, 13427],
            [-6, 31],
            [8, 34],
            [-31, 20],
            [-75, 21],
        ],
        [
            [14249, 13533],
            [-14, 102],
        ],
        [
            [14235, 13635],
            [80, 38],
            [119, -8],
            [70, 12],
            [10, -26],
            [38, -7],
            [68, -59],
        ],
        [
            [14407, 13376],
            [-18, 38],
            [-36, 13],
        ],
        [
            [14235, 13635],
            [3, 92],
            [34, 77],
            [66, 42],
            [57, -91],
            [57, 2],
            [13, 94],
        ],
        [
            [14676, 13812],
            [35, -28],
            [6, -60],
            [22, -72],
        ],
        [
            [12131, 10170],
            [-11, 0],
        ],
        [
            [12120, 10170],
            [2, -65],
            [-44, -4],
            [-23, -28],
            [-32, 0],
            [-26, 16],
            [-60, -13],
            [-23, -96],
            [-22, -9],
            [-33, -153],
            [-99, -133],
            [-22, -168],
            [-29, -55],
            [-9, -44],
            [-160, -10],
            [-1, 0],
        ],
        [
            [11539, 9408],
            [3, 57],
            [28, 34],
            [23, 63],
            [-5, 41],
            [25, 86],
            [39, 78],
            [24, 20],
            [19, 71],
            [1, 65],
            [26, 75],
            [46, 45],
            [45, 125],
            [2, 2],
            [35, 46],
            [66, 14],
            [56, 83],
            [36, 32],
            [59, 103],
            [-18, 151],
            [27, 106],
            [10, 64],
            [45, 82],
            [71, 56],
            [52, 51],
            [48, 126],
            [22, 75],
            [52, 0],
            [43, -52],
            [67, 8],
            [74, -27],
            [31, -1],
        ],
        [
            [14629, 12682],
            [17, 18],
            [47, 12],
            [51, -38],
            [29, -5],
            [33, -33],
            [-6, -42],
            [26, -20],
            [11, -51],
            [24, -31],
            [-5, -18],
            [13, -12],
            [-18, -9],
            [-42, 4],
            [-7, 16],
            [-15, -9],
            [5, -22],
            [-19, -39],
            [-13, -42],
            [-17, -13],
        ],
        [
            [14743, 12348],
            [-12, 56],
            [7, 52],
            [-2, 53],
            [-41, 72],
            [-22, 53],
            [-22, 36],
            [-22, 12],
        ],
        [
            [16251, 5269],
            [19, -52],
            [18, -81],
            [11, -146],
            [18, -58],
            [-7, -58],
            [-12, -36],
            [-24, 71],
            [-14, -36],
            [14, -90],
            [-6, -52],
            [-20, -28],
            [-4, -104],
            [-27, -142],
            [-35, -169],
            [-44, -231],
            [-27, -169],
            [-32, -143],
            [-58, -28],
            [-62, -52],
            [-41, 31],
            [-56, 44],
            [-20, 64],
            [-5, 109],
            [-24, 97],
            [-7, 88],
            [13, 88],
            [32, 21],
            [1, 41],
            [33, 92],
            [7, 79],
            [-17, 57],
            [-13, 77],
            [-6, 112],
            [25, 69],
            [10, 77],
            [35, 5],
            [40, 25],
            [26, 22],
            [31, 2],
            [40, 69],
            [59, 76],
            [21, 61],
            [-10, 52],
            [31, -15],
            [39, 85],
            [1, 74],
            [23, 55],
            [24, -53],
        ],
        [
            [6215, 8567],
            [-81, 132],
            [-36, 40],
            [-58, 32],
            [-40, -9],
            [-57, -46],
            [-35, -12],
            [-51, 32],
            [-53, 23],
            [-65, 56],
            [-53, 17],
            [-81, 57],
            [-59, 59],
            [-18, 32],
            [-39, 8],
            [-73, 38],
            [-30, 56],
            [-76, 69],
            [-35, 78],
            [-17, 59],
            [23, 12],
            [-7, 35],
            [16, 31],
            [1, 42],
            [-24, 55],
            [-7, 49],
            [-22, 62],
            [-63, 121],
            [-71, 96],
            [-35, 76],
            [-61, 49],
            [-13, 30],
            [11, 75],
            [-36, 29],
            [-42, 60],
            [-18, 85],
            [-38, 10],
            [-41, 64],
            [-33, 59],
            [-3, 39],
            [-38, 92],
            [-25, 93],
            [2, 47],
            [-52, 49],
            [-23, -5],
            [-41, 33],
            [-11, -50],
            [11, -59],
            [7, -91],
            [25, -50],
            [52, -85],
            [12, -29],
            [11, -8],
            [9, -42],
            [13, 1],
            [13, -78],
            [22, -31],
            [15, -43],
            [44, -63],
            [24, -113],
            [21, -54],
            [20, -57],
            [3, -65],
            [35, -4],
            [28, -55],
            [26, -54],
            [-2, -22],
            [-30, -45],
            [-12, 1],
            [-19, 74],
            [-46, 69],
            [-52, 60],
            [-36, 31],
            [2, 89],
            [-10, 66],
            [-34, 38],
            [-48, 54],
            [-9, -16],
            [-18, 33],
            [-44, 29],
            [-42, 71],
            [5, 9],
            [30, -7],
            [26, 46],
            [3, 55],
            [-55, 87],
            [-42, 34],
            [-26, 76],
            [-26, 80],
            [-33, 98],
            [-29, 110],
        ],
        [
            [4451, 10766],
            [81, 9],
            [90, 13],
            [-7, -24],
            [107, -59],
            [161, -86],
            [141, 0],
            [57, 0],
            [0, 52],
            [123, 0],
            [26, -44],
            [35, -39],
            [42, -53],
            [23, -64],
            [18, -67],
            [37, -37],
            [59, -37],
            [44, 97],
            [58, 2],
            [50, -48],
            [36, -85],
            [25, -71],
            [41, -69],
            [16, -85],
            [20, -59],
            [54, -37],
            [51, -27],
            [27, 4],
        ],
        [
            [5866, 9952],
            [-27, -107],
            [-13, -89],
            [-5, -163],
            [-7, -59],
            [13, -68],
            [22, -59],
            [14, -95],
            [47, -90],
            [16, -71],
            [28, -60],
            [75, -32],
            [30, -51],
            [62, 34],
            [54, 13],
            [53, 21],
            [44, 21],
            [45, 50],
            [17, 72],
            [6, 102],
            [12, 36],
            [48, 32],
            [75, 28],
            [63, -4],
            [43, 10],
            [17, -26],
            [-2, -58],
            [-39, -73],
            [-16, -75],
            [13, -21],
            [-11, -53],
            [-18, -95],
            [-18, 32],
            [-15, -2],
        ],
        [
            [14271, 11951],
            [24, 8],
            [33, 2],
        ],
        [
            [11882, 8577],
            [24, 22],
            [12, 73],
            [22, 3],
            [50, -35],
            [40, 25],
            [27, -8],
            [11, 27],
            [284, 2],
            [15, 85],
            [-12, 15],
            [-34, 527],
            [-34, 527],
            [108, 3],
        ],
        [
            [13046, 9131],
            [0, -281],
            [-38, -81],
            [-6, -75],
            [-63, -20],
            [-97, -10],
            [-26, -44],
            [-46, -5],
        ],
        [
            [11929, 8311],
            [3, 38],
            [-6, 48],
            [-27, 35],
            [-14, 69],
            [-3, 76],
        ],
        [
            [13775, 11170],
            [-3, -4],
            [-6, 1],
            [-6, 6],
            [0, 13],
            [6, -3],
            [7, -8],
            [2, -5],
        ],
        [
            [13757, 11192],
            [-4, -2],
            [-5, 4],
            [-1, 2],
            [6, 3],
            [3, -3],
            [1, -3],
            [0, -1],
        ],
        [
            [19722, 8005],
            [-7, 91],
            [22, 93],
            [-24, 72],
            [6, 134],
            [-29, 63],
            [-23, 146],
            [-13, 155],
            [-30, 100],
            [-47, -61],
            [-81, -86],
            [-40, 10],
            [-44, 29],
            [25, 151],
            [-15, 115],
            [-56, 140],
            [9, 44],
            [-41, 16],
            [-51, 100],
        ],
        [
            [19832, 9286],
            [-41, -28],
            [-41, -54],
            [-49, -5],
            [-33, -132],
            [-29, -22],
            [34, -107],
            [45, -90],
            [28, -80],
            [-25, -106],
            [-25, -23],
            [17, -61],
            [47, -97],
            [8, -68],
            [-1, -58],
            [27, -111],
            [-39, -113],
            [-33, -126],
        ],
        [
            [14116, 11907],
            [-15, 9],
            [-20, 40],
            [-31, 24],
        ],
        [
            [14105, 12108],
            [19, -21],
            [10, -17],
            [23, -13],
            [27, -26],
            [-5, -10],
        ],
        [
            [18957, 12813],
            [75, 21],
            [135, 106],
            [107, 57],
            [61, -37],
            [74, -2],
            [48, -57],
            [71, -6],
            [102, -30],
            [69, 86],
            [-29, 71],
            [73, 126],
            [79, -50],
            [65, -14],
            [83, -31],
            [14, -92],
            [100, -51],
            [68, 23],
            [88, 16],
            [71, -16],
            [70, -60],
            [43, -62],
            [66, 1],
            [89, -20],
            [65, 31],
            [94, 20],
            [103, 86],
            [42, -13],
            [37, -41],
            [85, 10],
        ],
        [
            [15191, 5385],
            [53, 10],
            [85, -34],
            [18, 15],
            [50, 3],
            [25, 37],
            [43, -2],
            [77, 47],
            [57, 71],
        ],
        [
            [15599, 5532],
            [11, -55],
            [-3, -121],
            [9, -107],
            [3, -191],
            [12, -60],
            [-21, -88],
            [-28, -85],
            [-45, -75],
            [-65, -46],
            [-79, -60],
            [-81, -131],
            [-27, -22],
            [-48, -87],
            [-30, -29],
            [-6, -87],
            [34, -92],
            [13, -71],
            [1, -38],
            [12, 7],
            [-2, -120],
            [-11, -56],
            [17, -21],
            [-11, -51],
            [-29, -44],
            [-58, -41],
            [-85, -66],
            [-32, -45],
            [6, -51],
            [19, -9],
            [-6, -65],
        ],
        [
            [15069, 3525],
            [-54, 1],
        ],
        [
            [15015, 3526],
            [-6, 55],
            [-11, 55],
        ],
        [
            [14998, 3636],
            [-6, 43],
            [13, 136],
            [-19, 88],
            [-34, 171],
        ],
        [
            [14952, 4074],
            [75, 139],
            [19, 88],
            [10, 11],
            [8, 72],
            [-11, 37],
            [3, 91],
            [14, 84],
            [0, 155],
            [-37, 39],
            [-34, 9],
            [-15, 30],
            [-33, 26],
            [-59, -3],
            [-5, 46],
        ],
        [
            [14887, 4898],
            [-7, 87],
            [216, 101],
        ],
        [
            [15096, 5086],
            [41, -59],
            [19, 11],
            [28, -30],
            [4, -50],
            [-15, -57],
            [6, -86],
            [46, -75],
            [21, 85],
            [30, 25],
            [-6, 158],
            [-29, 88],
            [-25, 39],
            [-25, -2],
            [-20, 159],
            [20, 93],
        ],
        [
            [11882, 8577],
            [-47, 85],
            [-43, 89],
            [-47, 32],
            [-33, 36],
            [-39, -1],
            [-35, -27],
            [-35, 11],
            [-24, -39],
        ],
        [
            [11579, 8763],
            [-7, 65],
            [20, 60],
            [9, 116],
            [-8, 120],
            [-8, 61],
            [7, 61],
            [-19, 58],
            [-37, 53],
        ],
        [
            [11536, 9357],
            [15, 40],
            [277, 0],
            [-13, 176],
            [17, 63],
            [67, 11],
            [-3, 312],
            [233, -6],
            [0, 185],
        ],
        [
            [15096, 5086],
            [-38, 31],
            [22, 113],
            [22, 44],
            [-13, 100],
            [14, 99],
            [12, 33],
            [-18, 104],
            [-33, 55],
        ],
        [
            [15064, 5665],
            [69, -23],
            [14, -34],
            [24, -57],
            [20, -166],
        ],
        [
            [19976, 7552],
            [16, -11],
            [42, -75],
            [30, -81],
            [4, -82],
            [-8, -55],
            [7, -43],
            [5, -72],
            [25, -34],
            [28, -107],
            [-1, -42],
            [-51, -9],
            [-67, 91],
            [-84, 97],
            [-8, 62],
            [-41, 82],
            [-10, 101],
            [-25, 67],
            [7, 89],
            [-15, 52],
        ],
        [
            [19830, 7582],
            [12, 21],
            [58, -53],
            [6, -63],
            [46, 14],
            [24, 51],
        ],
        [
            [20508, 7037],
            [52, -42],
            [55, 23],
            [14, 103],
            [31, 23],
            [84, 27],
            [50, 97],
            [35, 77],
        ],
        [
            [20918, 7457],
            [54, 86],
            [36, 95],
            [29, 0],
            [36, -61],
            [4, -53],
            [46, -35],
            [59, -37],
            [-5, -48],
            [-47, -6],
            [12, -59],
            [-52, -41],
        ],
        [
            [13901, 3302],
            [-53, 92],
            [-27, 89],
            [-16, 119],
            [-18, 89],
            [-23, 188],
            [-2, 146],
            [-9, 66],
            [-27, 51],
            [-36, 101],
            [-38, 146],
            [-15, 77],
            [-58, 119],
            [-4, 94],
        ],
        [
            [14387, 4651],
            [59, 29],
            [46, -8],
            [28, -27],
            [0, -11],
        ],
        [
            [14153, 3767],
            [0, -451],
            [-63, -63],
            [-39, -9],
            [-44, 24],
            [-32, 9],
            [-12, 52],
            [-28, 33],
            [-34, -60],
        ],
        [
            [24481, 4217],
            [58, -75],
            [37, -57],
            [-27, -29],
            [-39, 33],
            [-50, 56],
            [-46, 64],
            [-47, 86],
            [-10, 41],
            [31, -2],
            [39, -41],
            [32, -41],
            [22, -35],
        ],
        [
            [13795, 9585],
            [17, -191],
            [27, -31],
            [1, -39],
            [30, -42],
            [-16, -52],
            [-27, -248],
            [-4, -159],
            [-89, -115],
            [-31, -161],
            [30, -46],
            [-1, -78],
            [45, -3],
            [-7, -57],
        ],
        [
            [13748, 8316],
            [-13, -2],
            [-48, 134],
            [-16, 5],
            [-56, -68],
            [-55, 35],
            [-38, 7],
            [-20, -17],
            [-42, 4],
            [-42, -53],
            [-36, -3],
            [-86, 64],
            [-33, -30],
            [-36, 2],
            [-26, 46],
            [-71, 46],
            [-77, -15],
            [-18, -26],
            [-10, -70],
            [-20, -50],
            [-5, -109],
        ],
        [
            [13346, 7375],
            [-74, -44],
            [-27, 6],
            [-26, -27],
            [-57, 3],
            [-38, 76],
            [-23, 88],
            [-51, 81],
            [-53, -1],
            [-63, 0],
        ],
        [
            [6676, 8147],
            [-25, 38],
            [-33, 49],
            [-15, 41],
            [-30, 38],
            [-36, 56],
            [8, 19],
            [12, -18],
            [5, 8],
        ],
        [
            [6857, 8623],
            [-6, -12],
            [-4, -27],
            [7, -44],
            [-16, -42],
            [-8, -49],
            [-2, -53],
            [4, -32],
            [2, -56],
            [-11, -11],
            [-7, -53],
            [5, -32],
            [-14, -31],
            [3, -33],
            [11, -20],
        ],
        [
            [12979, 13063],
            [36, 33],
            [62, 181],
            [97, 50],
            [59, -3],
        ],
        [
            [14206, 15233],
            [-44, -5],
            [-10, -82],
            [-134, 21],
            [-19, -69],
            [-68, 1],
            [-47, -87],
            [-71, -136],
            [-109, -171],
            [26, -42],
            [-25, -48],
            [-70, 2],
            [-46, -115],
            [4, -162],
            [46, -62],
            [-24, -143],
            [-59, -84],
            [-31, -71],
        ],
        [
            [13525, 13980],
            [-48, 76],
            [-140, -142],
            [-93, -29],
            [-99, 62],
            [-25, 132],
            [-22, 282],
            [65, 78],
            [186, 103],
            [140, 126],
            [130, 170],
            [170, 236],
            [118, 92],
            [195, 153],
            [155, 53],
            [117, -6],
            [108, 102],
            [129, -6],
            [128, 25],
            [221, -90],
            [-92, -33],
            [78, -76],
        ],
        [
            [14946, 15288],
            [-121, -49],
            [-57, -11],
        ],
        [
            [14494, 16301],
            [-158, -51],
            [-124, 29],
            [48, 32],
            [-42, 39],
            [146, 24],
            [28, -45],
            [102, -28],
        ],
        [
            [14036, 16526],
            [233, -91],
            [-178, -48],
            [-39, -90],
            [-62, -23],
            [-34, -101],
            [-86, -5],
            [-151, 75],
            [63, 43],
            [-105, 36],
            [-138, 103],
            [-56, 95],
            [194, 44],
            [39, -43],
            [100, 2],
            [27, 41],
            [104, 5],
            [89, -43],
        ],
        [
            [14546, 16613],
            [139, -44],
            [-105, -65],
            [-206, -15],
            [-208, 21],
            [-13, 33],
            [-102, 2],
            [-77, 57],
            [219, 34],
            [102, -29],
            [71, 36],
            [180, -30],
        ],
        [
            [24994, 1794],
            [16, -50],
            [50, 49],
            [21, -51],
            [0, -52],
            [-26, -56],
            [-47, -91],
            [-36, -49],
            [26, -58],
            [-55, -2],
            [-60, -46],
            [-19, -79],
            [-41, -124],
            [-55, -55],
            [-35, -34],
            [-65, 2],
            [-46, 40],
            [-77, 9],
            [-12, 45],
            [38, 91],
            [89, 120],
            [46, 23],
            [50, 46],
            [61, 64],
            [42, 64],
            [32, 91],
            [27, 31],
            [10, 67],
            [50, 58],
            [16, -53],
        ],
        [
            [25107, 2376],
            [51, -129],
            [2, 84],
            [32, -34],
            [10, -92],
            [56, -39],
            [48, -10],
            [41, 46],
            [36, -14],
            [-17, -109],
            [-22, -71],
            [-54, 3],
            [-19, -37],
            [7, -53],
            [-11, -22],
            [-27, -67],
            [-34, -83],
            [-55, -49],
            [-12, 32],
            [-29, 18],
            [40, 101],
            [-23, 67],
            [-76, 49],
            [2, 44],
            [51, 42],
            [12, 95],
            [-3, 79],
            [-29, 81],
            [2, 22],
            [-34, 50],
            [-56, 108],
            [-29, 87],
            [26, 9],
            [39, -67],
            [55, -32],
            [20, -109],
        ],
        [
            [16504, 8826],
            [-23, 85],
            [-56, 201],
        ],
        [
            [16425, 9112],
            [213, 123],
            [48, 244],
            [-33, 87],
        ],
        [
            [16737, 9836],
            [31, -84],
            [40, -44],
            [52, -16],
            [42, -22],
            [32, -70],
            [19, -40],
            [25, -16],
            [0, -27],
            [-26, -73],
            [-11, -34],
            [-30, -39],
            [-26, -84],
            [-32, 7],
            [-15, -29],
            [-12, -63],
            [9, -81],
            [-7, -15],
            [-32, 0],
            [-45, -45],
            [-6, -60],
            [-16, -25],
            [-44, 1],
            [-28, -31],
            [1, -50],
            [-35, -34],
            [-39, 11],
            [-47, -41],
            [-33, -6],
        ],
        [
            [16714, 9974],
            [21, 42],
            [8, -11],
            [-6, -50],
            [-9, -22],
        ],
        [
            [17571, 9685],
            [-52, 31],
            [-21, 89],
            [-55, 92],
            [-131, -23],
            [-114, -2],
            [-100, -17],
        ],
        [
            [7230, 7674],
            [-23, 35],
            [-15, 66],
            [17, 33],
            [-18, 9],
            [-13, 40],
            [-35, 34],
            [-31, -8],
            [-15, -42],
            [-29, -31],
            [-15, -4],
            [-7, -26],
            [34, -66],
            [-19, -16],
            [-11, -18],
            [-33, -6],
            [-12, 72],
            [-9, -20],
            [-24, 7],
            [-14, 49],
            [-29, 8],
            [-18, 15],
            [-31, 0],
            [-2, -27],
            [-8, 19],
        ],
        [
            [6900, 7960],
            [25, -43],
            [-1, -26],
            [28, -6],
            [7, 10],
            [19, -30],
            [35, 9],
            [30, 31],
            [43, 24],
            [24, 37],
            [40, -7],
            [-3, -12],
            [40, -4],
            [31, -22],
            [23, -36],
            [26, -34],
        ],
        [
            [7761, 4551],
            [-70, 70],
            [-6, 50],
            [-141, 123],
            [-127, 133],
            [-55, 76],
            [-29, 101],
            [12, 35],
            [-61, 160],
            [-68, 225],
            [-68, 243],
            [-29, 57],
            [-22, 89],
            [-55, 80],
            [-51, 49],
            [23, 55],
            [-34, 117],
            [22, 85],
            [57, 77],
        ],
        [
            [21692, 7820],
            [7, -82],
            [4, -68],
            [-24, -111],
            [-26, 124],
            [-33, -62],
            [23, -90],
            [-21, -58],
            [-83, 72],
            [-20, 88],
            [22, 58],
            [-45, 57],
            [-23, -50],
            [-33, 5],
            [-53, -68],
            [-11, 35],
            [28, 103],
            [44, 35],
            [39, 46],
            [25, -55],
            [54, 33],
            [12, 55],
            [50, 3],
            [-5, 94],
            [58, -58],
            [6, -61],
            [5, -45],
        ],
        [
            [21522, 8047],
            [-25, -40],
            [-23, -77],
            [-22, -36],
            [-44, 84],
            [15, 33],
            [18, 34],
            [8, 76],
            [39, 8],
            [-12, -83],
            [53, 118],
            [-7, -117],
        ],
        [
            [21134, 7930],
            [-94, -116],
            [35, 85],
            [51, 76],
            [43, 84],
            [37, 122],
            [12, -99],
            [-46, -69],
            [-38, -83],
        ],
        [
            [21373, 8244],
            [43, -37],
            [45, 0],
            [-2, -51],
            [-32, -52],
            [-45, -37],
            [-3, 57],
            [5, 62],
            [-11, 58],
        ],
        [
            [21630, 8277],
            [20, -136],
            [-55, 33],
            [2, -41],
            [17, -76],
            [-34, -28],
            [-3, 87],
            [-21, 6],
            [-11, 74],
            [41, -10],
            [0, 46],
            [-44, 93],
            [68, -2],
            [20, -46],
        ],
        [
            [21348, 8389],
            [-19, -107],
            [-30, 61],
            [-37, 94],
            [61, -5],
            [25, -43],
        ],
        [
            [21333, 9052],
            [44, -35],
            [22, 32],
            [6, -31],
            [-11, -50],
            [24, -89],
            [-19, -101],
            [-42, -40],
            [-11, -99],
            [16, -97],
            [38, -14],
            [31, 15],
            [89, -68],
            [-7, -66],
            [23, -29],
            [-7, -57],
            [-56, 61],
            [-26, 63],
            [-18, -44],
            [-45, 73],
            [-65, -18],
            [-35, 27],
            [4, 50],
            [22, 31],
            [-21, 28],
            [-9, -44],
            [-36, 70],
            [-9, 54],
            [-3, 117],
            [28, -41],
            [7, 192],
            [23, 110],
            [43, 0],
        ],
        [
            [23780, 5959],
            [-20, -12],
            [-30, 47],
            [-31, 77],
            [-15, 94],
            [10, 11],
            [7, -36],
            [22, -27],
            [33, -79],
            [34, -41],
            [-10, -34],
        ],
        [
            [23504, 6123],
            [-37, -10],
            [-11, -35],
            [-39, -29],
            [-36, -29],
            [-38, 0],
            [-58, 36],
            [-41, 34],
            [6, 38],
            [64, -18],
            [39, 10],
            [10, 58],
            [11, 3],
            [7, -64],
            [40, 9],
            [20, 42],
            [40, 43],
            [-8, 72],
            [42, 2],
            [15, -20],
            [-2, -67],
            [-24, -75],
        ],
        [
            [22727, 6475],
            [122, -84],
            [132, -71],
            [48, -62],
            [40, -61],
            [11, -72],
            [118, -76],
            [17, -65],
            [-65, -13],
            [16, -81],
            [63, -81],
            [45, -129],
            [41, 4],
            [-3, -54],
            [54, -21],
            [-21, -23],
            [76, -51],
            [-8, -36],
            [-47, -8],
            [-18, 31],
            [-61, 15],
            [-70, 18],
            [-55, 78],
            [-41, 67],
            [-37, 106],
            [-92, 54],
            [-60, -34],
            [-44, -42],
            [10, -89],
            [-56, -42],
            [-40, 20],
            [-72, 5],
        ],
        [
            [23587, 6242],
            [-23, -32],
            [-13, 72],
            [-17, 47],
            [-32, 40],
            [-40, 53],
            [-51, 35],
            [19, 30],
            [39, -34],
            [24, -27],
            [30, -29],
            [28, -52],
            [27, -39],
            [9, -64],
        ],
        [
            [13744, 13357],
            [48, 36],
            [110, 57],
            [90, 42],
            [70, -21],
            [6, -31],
            [68, -1],
        ],
        [
            [14136, 13439],
            [88, -14],
            [129, 2],
        ],
        [
            [14410, 13091],
            [35, -106],
            [-7, -34],
            [-36, -14],
            [-64, -102],
            [18, -55],
            [-15, 7],
        ],
        [
            [14341, 12787],
            [-68, 47],
            [-50, -17],
            [-33, 12],
            [-42, -26],
            [-36, 43],
            [-29, -16],
            [-4, 7],
        ],
        [
            [8051, 9053],
            [37, -10],
            [12, -25],
            [-18, -30],
            [-53, 0],
            [-42, -4],
            [-4, 52],
            [10, 18],
            [58, -1],
        ],
        [
            [21993, 11970],
            [10, -22],
        ],
        [
            [22003, 11948],
            [-27, 8],
            [-30, -42],
            [-22, -41],
            [3, -87],
            [-37, -28],
            [-12, -22],
            [-27, -36],
            [-47, -20],
            [-31, -32],
            [-2, -53],
            [-9, -14],
            [29, -19],
            [40, -54],
        ],
        [
            [21678, 11402],
            [-35, 23],
            [-8, -22],
            [-21, -10],
            [-3, 22],
            [-18, 12],
            [-19, 19],
            [19, 55],
            [17, 14],
            [-6, 22],
            [18, 66],
            [-5, 20],
            [-41, 13],
            [-34, 32],
        ],
        [
            [12217, 11323],
            [-29, -32],
            [-37, 18],
            [-37, -14],
            [11, 95],
            [-7, 75],
            [-31, 11],
            [-17, 48],
            [5, 79],
            [29, 44],
            [5, 50],
            [14, 74],
            [-1, 51],
            [-14, 44],
            [-3, 41],
        ],
        [
            [16341, 9816],
            [-5, 88],
            [19, 64],
            [20, 13],
            [21, -38],
            [2, -71],
            [-16, -72],
        ],
        [
            [16382, 9800],
            [-20, -8],
            [-21, 24],
        ],
        [
            [14352, 12640],
            [30, 27],
            [44, -14],
            [46, 0],
            [33, -30],
            [24, 19],
            [52, 11],
            [18, 29],
            [30, 0],
        ],
        [
            [14743, 12348],
            [31, -23],
            [33, 20],
            [33, -22],
        ],
        [
            [14840, 12323],
            [1, -31],
            [-34, -26],
            [-22, 11],
            [-20, -147],
        ],
        [
            [14348, 12195],
            [-13, 21],
            [16, 20],
            [-17, 16],
            [-22, -28],
            [-42, 36],
            [-5, 50],
            [-43, 29],
            [-8, 39],
            [-38, 48],
        ],
        [
            [22914, 12990],
            [72, -216],
            [-106, 40],
            [-43, -176],
            [69, -126],
            [-2, -85],
            [-54, 74],
            [-46, -95],
            [-13, 103],
            [8, 119],
            [-9, 131],
            [17, 92],
            [3, 164],
            [-42, 120],
            [7, 167],
            [65, 56],
            [-28, 56],
            [32, 18],
            [18, -81],
            [25, -117],
            [-2, -121],
            [29, -123],
        ],
        [
            [14136, 13439],
            [16, 54],
            [97, 40],
        ],
        [
            [353, 14925],
            [48, -31],
            [-17, 89],
            [193, -18],
            [139, -115],
            [-71, -53],
            [-116, -13],
            [-2, -119],
            [-28, -25],
            [-67, 3],
            [-54, 43],
            [-94, 35],
            [-16, 53],
            [-71, 20],
            [-81, -16],
            [-38, 43],
            [15, 45],
            [-85, -29],
            [32, -57],
            [-40, -52],
            [0, 487],
            [174, -94],
            [185, -121],
            [-6, -75],
        ],
        [
            [25488, 15443],
            [-78, -7],
            [-12, 40],
            [90, 51],
            [0, -84],
        ],
        [
            [93, 15451],
            [-93, -8],
            [0, 84],
            [9, 5],
            [60, 0],
            [103, -35],
            [-6, -17],
            [-73, -29],
        ],
        [
            [22911, 15734],
            [-108, -1],
            [-144, 14],
            [-12, 6],
            [67, 49],
            [88, 11],
            [100, -47],
            [9, -32],
        ],
        [
            [23416, 15962],
            [-82, -48],
            [-113, 11],
            [-132, 48],
            [17, 39],
            [133, -18],
            [177, -32],
        ],
        [
            [23016, 16021],
            [-56, -91],
            [-260, 3],
            [-118, -28],
            [-140, 79],
            [38, 84],
            [93, 23],
            [187, -5],
            [256, -65],
        ],
        [
            [16817, 15429],
            [-42, -11],
            [-231, 16],
            [-18, 55],
            [-129, 33],
            [-10, 66],
            [72, 26],
            [-2, 66],
            [141, 105],
            [-66, 15],
            [170, 107],
            [-19, 55],
            [158, 65],
            [234, 78],
            [235, 23],
            [122, 45],
            [138, 16],
            [49, -48],
            [-48, -38],
            [-251, -60],
            [-216, -59],
            [-220, -116],
            [-106, -119],
            [-110, -118],
            [14, -101],
            [135, -101],
        ],
        [
            [16221, 12459],
            [-33, -72],
            [-68, -20],
            [-71, -127],
            [65, -115],
            [-7, -83],
            [77, -144],
        ],
        [
            [15573, 12097],
            [-90, 103],
            [-81, 46],
            [-62, 71],
            [52, 21],
            [59, 102],
            [-40, 48],
            [105, 50],
            [-2, 26],
            [-64, -19],
        ],
        [
            [15450, 12545],
            [2, 54],
            [37, 35],
            [69, 9],
            [11, 40],
            [-16, 67],
            [29, 64],
            [-1, 36],
            [-105, 39],
            [-41, -1],
            [-44, 58],
            [-54, -19],
            [-90, 42],
            [1, 24],
            [-24, 53],
            [-57, 6],
            [-5, 38],
            [17, 25],
            [-45, 69],
            [-74, -12],
            [-21, 6],
            [-18, -28],
            [-27, 5],
        ],
        [
            [14726, 14056],
            [79, 68],
            [-73, 58],
        ],
        [
            [14946, 15288],
            [73, 42],
            [117, -74],
            [193, -29],
            [268, -138],
            [54, -58],
            [5, -81],
            [-79, -64],
            [-116, -34],
            [-315, 94],
            [-52, -16],
            [115, -90],
            [5, -56],
            [4, -124],
            [91, -38],
            [55, -32],
            [9, 60],
            [-42, 52],
            [45, 46],
            [171, -76],
            [60, 30],
            [-48, 89],
            [166, 120],
            [64, -7],
            [66, -43],
            [42, 85],
            [-59, 72],
            [34, 73],
            [-52, 76],
            [199, -40],
            [40, -68],
            [-90, -15],
            [1, -68],
            [56, -41],
            [109, 26],
            [18, 78],
            [147, 58],
            [247, 105],
            [54, -6],
            [-70, -75],
            [88, -12],
            [51, 41],
            [132, 4],
            [105, 51],
            [81, -74],
            [80, 81],
            [-74, 71],
            [37, 40],
            [210, -37],
            [97, -38],
            [257, -140],
            [47, 65],
            [-72, 64],
            [-2, 26],
            [-85, 12],
            [23, 58],
            [-38, 95],
            [-2, 40],
            [131, 110],
            [46, 110],
            [53, 24],
            [187, -32],
            [15, -67],
            [-67, -99],
            [44, -39],
            [22, -86],
            [-16, -167],
            [79, -75],
            [-31, -82],
            [-139, -173],
            [81, -18],
            [29, 44],
            [78, 32],
            [18, 60],
            [62, 58],
            [-42, 69],
            [34, 81],
            [-78, 10],
            [-17, 68],
            [57, 122],
            [-93, 100],
            [127, 82],
            [-16, 87],
            [35, 3],
            [38, -68],
            [-28, -117],
            [76, -22],
            [-33, 87],
            [119, 48],
            [146, 7],
            [131, -70],
            [-63, 102],
            [-7, 130],
            [123, 25],
            [171, -5],
            [153, 15],
            [-58, 64],
            [82, 80],
            [82, 3],
            [138, 62],
            [186, 16],
            [24, 33],
            [186, 12],
            [58, -28],
            [158, 65],
            [131, -2],
            [19, 52],
            [68, 52],
            [167, 50],
            [121, -39],
            [-96, -30],
            [160, -19],
            [19, -60],
            [65, 30],
            [208, -2],
            [158, -59],
            [57, -46],
            [-17, -64],
            [-78, -36],
            [-186, -68],
            [-53, -36],
            [87, -17],
            [105, -31],
            [63, 23],
            [36, -78],
            [32, 32],
            [113, 19],
            [228, -20],
            [17, -58],
            [296, -18],
            [4, 94],
            [150, -21],
            [113, 0],
            [114, -65],
            [33, -78],
            [-42, -51],
            [89, -95],
            [111, -51],
            [69, 129],
            [114, -55],
            [119, 33],
            [138, -38],
            [52, 34],
            [116, -17],
            [-51, 113],
            [94, 53],
            [638, -79],
            [61, -72],
            [185, -94],
            [286, 24],
            [141, -21],
            [59, -51],
            [-9, -89],
            [88, -34],
            [95, 25],
            [125, 3],
            [134, -24],
            [134, 14],
            [123, -109],
            [88, 39],
            [-58, 78],
            [32, 54],
            [226, -34],
            [148, 7],
            [203, -58],
            [99, -53],
            [0, -487],
            [-1, 0],
            [-91, -54],
            [-92, 9],
            [64, -65],
            [43, -101],
            [32, -33],
            [9, -50],
            [-19, -32],
            [-132, 26],
            [-197, -91],
            [-63, -15],
            [-109, -86],
            [-103, -75],
            [-26, -55],
            [-100, 84],
            [-185, -95],
            [-33, 45],
            [-68, -52],
            [-95, 16],
            [-23, -81],
            [-84, -118],
            [3, -49],
            [80, -27],
            [-10, -178],
            [-65, -5],
            [-30, -101],
            [29, -53],
            [-124, -63],
            [-24, -139],
            [-106, -30],
            [-21, -124],
            [-103, -114],
            [-26, 84],
            [-30, 179],
            [-40, 271],
            [34, 169],
            [60, 74],
            [4, 56],
            [110, 28],
            [127, 154],
            [122, 125],
            [127, 97],
            [57, 173],
            [-87, -11],
            [-42, -100],
            [-179, -134],
            [-58, 150],
            [-183, -42],
            [-177, -204],
            [59, -76],
            [-159, -32],
            [-109, -12],
            [5, 89],
            [-111, 18],
            [-87, -60],
            [-216, 22],
            [-234, -38],
            [-229, -237],
            [-271, -288],
            [111, -16],
            [35, -77],
            [69, -27],
            [46, 61],
            [77, -8],
            [102, -134],
            [2, -104],
            [-54, -122],
            [-6, -145],
            [-32, -196],
            [-107, -177],
            [-24, -84],
            [-96, -143],
            [-96, -140],
            [-46, -72],
            [-93, -73],
            [-45, -1],
            [-44, 60],
            [-96, -90],
            [-11, -41],
        ],
        [
            [20184, 16356],
            [-400, -47],
            [129, 160],
            [59, 14],
            [53, -8],
            [180, -69],
            [-21, -50],
        ],
        [
            [16364, 16630],
            [-95, -16],
            [-63, -9],
            [-10, -20],
            [-83, -21],
            [-77, 29],
            [41, 38],
            [-158, 4],
            [139, 22],
            [107, 2],
            [15, -33],
            [41, 29],
            [65, 20],
            [106, -26],
            [-28, -19],
        ],
        [
            [19819, 16426],
            [-153, -15],
            [-198, 35],
            [-118, 47],
            [-54, 87],
            [-96, 25],
            [183, 83],
            [154, 28],
            [138, -62],
            [162, -118],
            [-18, -110],
        ],
        [
            [14897, 6653],
            [29, -69],
            [-4, -71],
            [-21, -16],
        ],
        [
            [14838, 6628],
            [17, -13],
            [42, 38],
        ],
        [
            [11536, 9357],
            [3, 51],
        ],
        [
            [15772, 8789],
            [-9, 52],
            [-20, 36],
            [-6, 49],
            [-36, 44],
            [-38, 102],
            [-20, 100],
            [-49, 84],
            [-32, 20],
            [-47, 116],
            [-8, 85],
            [3, 73],
            [-41, 135],
            [-33, 47],
            [-38, 26],
            [-24, 70],
            [4, 28],
            [-20, 63],
            [-20, 27],
            [-28, 91],
            [-43, 99],
            [-35, 83],
            [-36, 0],
            [11, 67],
            [4, 42],
            [8, 50],
        ],
        [
            [16172, 10279],
            [28, -105],
            [35, -28],
            [11, -43],
            [48, -52],
            [5, -50],
            [-7, -40],
            [9, -41],
            [20, -34],
            [9, -40],
            [11, -30],
        ],
        [
            [16382, 9800],
            [14, -47],
        ],
        [
            [16425, 9112],
            [-203, -46],
            [-66, -55],
            [-51, -129],
            [-33, -20],
            [-18, 40],
            [-27, -6],
            [-69, 13],
            [-13, 12],
            [-82, -3],
            [-19, -11],
            [-29, 33],
            [-19, -61],
            [7, -52],
            [-31, -38],
        ],
        [
            [15149, 7948],
            [-10, 2],
            [1, 61],
            [-8, 42],
            [-37, 49],
            [-8, 87],
            [8, 90],
            [-33, 9],
            [-4, -28],
            [-43, -6],
            [17, -35],
            [6, -73],
            [-39, -68],
            [-35, -88],
            [-37, -13],
            [-59, 71],
            [-27, -25],
            [-8, -35],
            [-36, -23],
            [-3, -25],
            [-69, 0],
            [-10, 25],
            [-51, 4],
            [-26, -21],
            [-19, 11],
            [-37, 71],
            [-12, 33],
            [-51, -17],
            [-20, -56],
            [-18, -109],
            [-24, -23],
            [-22, -13],
        ],
        [
            [14435, 7845],
            [-6, 6],
        ],
        [
            [14363, 8153],
            [0, 30],
            [-26, 35],
            [0, 71],
            [-15, 47],
            [-25, -7],
            [7, 46],
            [18, 50],
            [-8, 51],
            [24, 37],
            [-15, 29],
            [19, 75],
            [32, 90],
            [61, -8],
            [-3, 484],
        ],
        [
            [15354, 9479],
            [23, -120],
            [-16, -22],
            [10, -126],
            [27, -146],
            [27, -30],
            [38, -45],
        ],
        [
            [15149, 7962],
            [0, -14],
        ],
        [
            [15149, 7948],
            [1, -95],
        ],
        [
            [15152, 7311],
            [-44, -56],
            [-50, 1],
            [-57, -30],
            [-45, 28],
            [-29, -34],
        ],
        [
            [14483, 7798],
            [-48, 47],
        ],
        [
            [11561, 8453],
            [-29, 94],
            [-36, 44],
            [31, 23],
            [35, 86],
            [17, 63],
        ],
        [
            [11563, 8304],
            [-11, 95],
        ],
        [
            [24222, 5511],
            [19, -42],
            [-48, 1],
            [-27, 76],
            [42, -30],
            [14, -5],
        ],
        [
            [24133, 5586],
            [-28, -2],
            [-44, 12],
            [-14, 19],
            [4, 48],
            [47, -19],
            [23, -26],
            [12, -32],
        ],
        [
            [24191, 5620],
            [-10, -23],
            [-53, 106],
            [-15, 72],
            [24, 0],
            [26, -97],
            [28, -58],
        ],
        [
            [24063, 5773],
            [3, -24],
            [-55, 51],
            [-39, 45],
            [-27, 41],
            [11, 12],
            [32, -29],
            [59, -56],
            [16, -40],
        ],
        [
            [23898, 5895],
            [-15, -7],
            [-31, 28],
            [-29, 50],
            [4, 20],
            [42, -52],
            [29, -39],
        ],
        [
            [11934, 7621],
            [-19, 9],
            [-51, 49],
            [-37, 65],
            [-13, 45],
            [-8, 91],
        ],
        [
            [6528, 8427],
            [-8, -29],
            [-41, 2],
            [-25, 12],
            [-30, 24],
            [-39, 8],
            [-20, 26],
        ],
        [
            [15798, 8192],
            [23, -22],
            [14, -51],
            [32, -52],
            [36, 0],
            [66, 32],
            [78, 14],
            [62, 38],
            [35, 8],
            [26, 22],
            [40, 5],
        ],
        [
            [16210, 8186],
            [0, -2],
            [-1, -50],
            [0, -124],
            [0, -64],
            [-32, -74],
            [-49, -103],
        ],
        [
            [16210, 8186],
            [23, 2],
            [31, 18],
            [38, 12],
            [34, 42],
            [26, 0],
            [2, -33],
            [-7, -71],
            [1, -64],
            [-15, -45],
            [-20, -131],
            [-34, -137],
            [-44, -156],
            [-60, -179],
            [-61, -136],
            [-83, -167],
            [-71, -99],
            [-106, -122],
            [-66, -92],
            [-77, -148],
            [-16, -65],
            [-16, -29],
        ],
        [
            [8698, 7522],
            [84, -25],
            [8, 23],
            [57, 8],
            [77, -33],
        ],
        [
            [8920, 7235],
            [-12, -54],
            [-6, -55],
            [-19, -52],
        ],
        [
            [14341, 12787],
            [-20, -32],
            [-14, -49],
        ],
        [
            [13715, 12350],
            [16, 11],
        ],
        [
            [14436, 14853],
            [-122, -34],
            [-69, -85],
            [11, -75],
            [-111, -98],
            [-137, -105],
            [-52, -172],
            [50, -86],
            [68, -68],
            [-65, -138],
            [-74, -29],
            [-27, -204],
            [-40, -115],
            [-86, 12],
            [-39, -97],
            [-82, -5],
            [-23, 115],
            [-59, 139],
            [-54, 172],
        ],
        [
            [13952, 13801],
            [-50, -102],
            [5, -46],
            [55, 141],
            [-10, 7],
        ],
        [
            [14115, 13871],
            [1, 4],
            [-19, 0],
            [-5, -6],
            [-27, -2],
            [-40, -46],
            [4, -19],
            [-5, -15],
            [8, -20],
            [-6, -27],
            [15, 4],
            [10, 26],
            [18, 9],
            [-1, 12],
            [18, 8],
            [3, 9],
            [-12, 0],
            [-2, 25],
            [40, 38],
        ],
        [
            [14220, 14587],
            [-3, -6],
            [1, -8],
            [-8, -15],
            [9, -1],
            [10, 16],
            [7, 14],
            [-16, 0],
        ],
        [
            [15015, 3526],
            [-15, -54],
            [-41, -13],
            [-43, 66],
            [0, 43],
            [19, 46],
            [7, 35],
            [20, 9],
            [36, -22],
        ],
        [
            [15292, 11023],
            [-6, 94],
            [17, 50],
        ],
        [
            [15303, 11167],
            [19, 26],
            [19, 28],
            [4, 68],
            [23, -24],
            [78, 34],
            [38, -23],
            [58, 1],
            [82, 45],
            [38, -2],
            [81, 19],
        ],
        [
            [12876, 7543],
            [-57, -26],
        ],
        [
            [20007, 8280],
            [-64, 56],
            [-60, -2],
            [10, 96],
            [-62, 0],
            [-6, -135],
            [-38, -178],
            [-23, -108],
            [5, -89],
            [46, -3],
            [29, -111],
            [12, -107],
            [40, -69],
            [43, -15],
            [37, -63],
        ],
        [
            [19830, 7582],
            [-28, 46],
            [-12, 61],
            [-38, 68],
            [-33, 59],
            [-12, -72],
            [-14, 68],
            [8, 76],
            [21, 117],
        ],
        [
            [17546, 11329],
            [40, 123],
            [-15, 92],
            [-52, 29],
            [18, 53],
            [60, -6],
            [33, 68],
            [23, 79],
            [94, 28],
            [-14, -57],
            [9, -34],
            [29, 3],
        ],
        [
            [16562, 11335],
            [-13, 86],
            [10, 129],
            [-55, 41],
            [18, 83],
            [-47, 7],
            [15, 104],
            [67, -31],
            [63, 40],
            [-52, 73],
            [-20, 70],
            [-57, -31],
            [-8, -90],
            [-22, 79],
        ],
        [
            [16707, 11837],
            [79, 2],
            [-12, 61],
            [61, 42],
            [60, 71],
            [95, -64],
            [8, -97],
            [27, -25],
            [77, 5],
            [24, -22],
            [34, -126],
            [82, -84],
            [45, -58],
            [74, -59],
            [94, -53],
            [-2, -75],
        ],
        [
            [21592, 5706],
            [8, 28],
            [61, 28],
            [50, 4],
            [22, 15],
            [26, -15],
            [-25, -33],
            [-74, -53],
            [-60, -35],
        ],
        [
            [8377, 8107],
            [41, 15],
            [15, -4],
            [-3, -92],
            [-60, -13],
            [-12, 11],
            [20, 33],
            [-1, 50],
        ],
        [
            [13340, 11305],
            [77, 49],
            [50, -15],
            [-2, -62],
            [60, 45],
            [5, -23],
            [-36, -60],
            [0, -57],
            [25, -30],
            [-10, -106],
            [-46, -61],
            [13, -66],
            [37, -2],
            [18, -59],
            [27, -19],
        ],
        [
            [15303, 11167],
            [-26, 56],
            [27, 46],
            [-43, -11],
            [-59, 28],
            [-49, -70],
            [-107, -14],
            [-58, 66],
            [-76, 4],
            [-17, -51],
            [-49, -14],
            [-68, 65],
            [-77, -2],
            [-42, 121],
            [-51, 67],
            [34, 96],
            [-45, 58],
            [79, 116],
            [108, 5],
            [30, 94],
            [135, -16],
            [85, 79],
            [83, 34],
            [117, 2],
            [123, -85],
            [102, -47],
            [83, 18],
            [61, -10],
            [83, 63],
        ],
        [
            [14727, 11922],
            [8, -46],
            [61, -40],
            [-13, -29],
            [-83, -7],
            [-30, -39],
            [-60, -65],
            [-22, 56],
            [1, 26],
        ],
        [
            [21366, 9771],
            [-43, -195],
            [-31, -101],
            [-37, 104],
            [-8, 90],
            [42, 120],
            [57, 93],
            [32, -37],
            [-12, -74],
        ],
        [
            [15520, 6221],
            [-33, -151],
            [4, -69],
            [45, -44],
            [3, -32],
            [-20, -74],
            [4, -37],
            [-5, -59],
            [25, -76],
            [30, -120],
            [26, -27],
        ],
        [
            [15064, 5665],
            [-41, 36],
            [-45, 21],
            [-28, 20],
            [-30, 31],
        ],
        [
            [14897, 6653],
            [25, 16],
            [78, -2],
            [145, 9],
        ],
        [
            [15450, 12545],
            [-56, -10],
            [-48, -40],
            [-66, -6],
            [-60, -45],
            [4, -76],
            [34, -29],
            [72, 7],
            [-14, -45],
            [-77, -21],
            [-96, -70],
            [-39, 25],
            [15, 57],
            [-77, 36],
            [12, 24],
            [68, 40],
            [-20, 28],
            [-111, 31],
            [-4, 45],
            [-66, -15],
            [-26, -67],
            [-55, -91],
        ],
        [
            [8965, 2667],
            [-31, -76],
            [-80, -68],
            [-52, 24],
            [-39, -13],
            [-64, 53],
            [-48, -4],
            [-43, 67],
        ],
        [
            [1732, 9123],
            [-10, -21],
            [-18, 18],
            [2, 34],
            [-12, 44],
            [4, 13],
            [12, 21],
            [-5, 24],
            [4, 12],
            [6, -3],
            [27, -20],
            [12, -11],
            [11, -16],
            [18, -44],
            [-2, -6],
            [-27, -26],
            [-22, -19],
        ],
        [
            [1694, 9313],
            [-24, -8],
            [-12, 25],
            [-8, 10],
            [-1, 8],
            [7, 10],
            [25, -11],
            [19, -19],
            [-6, -15],
        ],
        [
            [1646, 9378],
            [-2, -13],
            [-38, 4],
            [5, 15],
            [35, -6],
        ],
        [
            [1582, 9396],
            [-3, -7],
            [-6, 2],
            [-24, 4],
            [-9, 27],
            [-3, 5],
            [19, 17],
            [6, -8],
            [20, -40],
        ],
        [
            [1462, 9476],
            [-8, -12],
            [-24, 22],
            [4, 9],
            [10, 13],
            [17, -2],
            [1, -30],
        ],
        [
            [7991, 12304],
            [12, -39],
            [-76, -59],
            [-73, -42],
            [-75, -36],
            [-36, -73],
            [-12, -28],
            [-1, -65],
            [22, -64],
            [30, -3],
            [-8, 44],
            [22, -27],
            [-6, -35],
            [-47, -19],
            [-34, 2],
            [-52, -21],
            [-31, -6],
            [-41, -6],
            [-60, -35],
            [105, 22],
            [21, -23],
            [-100, -37],
            [-45, 0],
            [2, 16],
            [-21, -35],
            [20, -6],
            [-15, -87],
            [-51, -94],
            [-6, 31],
            [-15, 7],
            [-24, 30],
            [15, -65],
            [18, -22],
            [1, -46],
            [-23, -48],
            [-40, -98],
            [-6, 5],
            [22, 83],
            [-37, 47],
            [-8, 102],
            [-14, -53],
            [16, -78],
            [-47, 19],
            [49, -39],
            [3, -116],
            [20, -8],
            [7, -43],
            [10, -122],
            [-45, -91],
            [-73, -36],
            [-46, -71],
            [-35, -8],
            [-36, -46],
            [-10, -40],
            [-78, -79],
            [-40, -58],
            [-34, -73],
            [-11, -87],
            [13, -85],
            [24, -104],
            [31, -87],
            [1, -52],
            [33, -142],
            [-2, -83],
            [-3, -47],
            [-18, -74],
            [-21, -16],
            [-35, 15],
            [-11, 54],
            [-27, 28],
            [-38, 104],
            [-33, 94],
            [-10, 48],
            [14, 81],
            [-20, 67],
            [-55, 102],
            [-28, 19],
            [-70, -55],
            [-13, 6],
            [-34, 57],
            [-45, 30],
            [-80, -15],
            [-63, 13],
            [-54, -8],
            [-29, -19],
            [12, -33],
            [-1, -49],
            [15, -24],
            [-13, -16],
            [-27, 18],
            [-26, -23],
            [-52, 4],
            [-53, 64],
            [-61, -15],
            [-51, 28],
            [-44, -9],
            [-60, -28],
            [-65, -91],
            [-70, -53],
            [-39, -58],
            [-16, -55],
            [-1, -85],
            [4, -58],
            [13, -41],
        ],
        [
            [4451, 10766],
            [-12, 62],
            [-46, 70],
            [-33, 14],
            [-7, 36],
            [-40, 6],
            [-26, 33],
            [-65, 12],
            [-18, 20],
            [-8, 67],
            [-69, 123],
            [-59, 169],
            [2, 28],
            [-31, 41],
            [-55, 103],
            [-10, 99],
            [-38, 66],
            [16, 102],
            [-2, 105],
            [-23, 93],
            [28, 116],
            [8, 110],
            [9, 111],
            [-13, 164],
            [-22, 105],
            [-21, 56],
            [9, 24],
            [102, -41],
            [38, -116],
            [17, 32],
            [-11, 101],
            [-24, 100],
        ],
        [
            [1911, 13768],
            [-71, -47],
            [-36, 32],
            [-11, 57],
            [64, 44],
            [38, 18],
            [47, -8],
            [30, -38],
            [-61, -58],
        ],
        [
            [1021, 14109],
            [-43, -19],
            [-47, 23],
            [-43, 33],
            [70, 21],
            [56, -11],
            [7, -47],
        ],
        [
            [586, 14582],
            [43, -24],
            [45, 13],
            [57, -32],
            [69, -16],
            [-5, -14],
            [-53, -25],
            [-54, 26],
            [-27, 22],
            [-63, -7],
            [-17, 11],
            [5, 46],
        ],
        [
            [3502, 13486],
            [-39, 46],
            [-62, 38],
            [-21, 106],
            [-91, 100],
            [-38, 115],
            [-67, 8],
            [-113, 3],
            [-83, 35],
            [-147, 127],
            [-68, 23],
            [-123, 44],
            [-98, -11],
            [-140, 56],
            [-84, 52],
            [-79, -26],
            [15, -84],
            [-38, -8],
            [-82, -26],
            [-63, -41],
            [-79, -26],
            [-10, 72],
            [32, 120],
            [76, 37],
            [-20, 31],
            [-90, -68],
            [-49, -81],
            [-102, -87],
            [52, -60],
            [-67, -87],
            [-75, -51],
            [-71, -37],
            [-18, -54],
            [-111, -64],
            [-22, -57],
            [-83, -52],
            [-49, 9],
            [-66, -34],
            [-72, -41],
            [-58, -41],
            [-122, -36],
            [-11, 21],
            [78, 58],
            [69, 37],
            [75, 67],
            [88, 13],
            [35, 50],
            [98, 74],
            [16, 25],
            [52, 43],
            [13, 92],
            [36, 72],
            [-82, -37],
            [-23, 21],
            [-38, -45],
            [-47, 62],
            [-19, -43],
            [-26, 60],
            [-71, -48],
            [-44, 0],
            [-6, 72],
            [13, 46],
            [-45, 43],
            [-92, -23],
            [-60, 57],
            [-48, 29],
            [-1, 69],
            [-54, 52],
            [27, 71],
            [58, 68],
            [25, 62],
            [58, 9],
            [48, -19],
            [56, 58],
            [52, -10],
            [54, 38],
            [-13, 56],
            [-40, 22],
            [53, 47],
            [-44, -1],
            [-74, -27],
            [-22, -27],
            [-56, 27],
            [-100, -14],
            [-104, 30],
            [-30, 49],
            [-90, 70],
            [100, 51],
            [158, 61],
            [59, 0],
            [-10, -62],
            [149, 5],
            [-57, 76],
            [-87, 46],
            [-51, 61],
            [-68, 52],
            [-97, 39],
            [40, 64],
            [126, 4],
            [89, 56],
            [17, 59],
            [71, 58],
            [70, 14],
            [134, 55],
            [65, -9],
            [109, 66],
            [107, -26],
            [51, -56],
            [32, 25],
            [119, -9],
            [-4, -28],
            [109, -20],
            [72, 12],
            [149, -39],
            [136, -11],
            [55, -16],
            [94, 20],
            [108, -37],
            [76, -17],
        ],
        [
            [7694, 8230],
            [-2, -29],
            [-42, -14],
            [24, -55],
            [-1, -65],
            [-32, -70],
            [27, -97],
            [31, 8],
            [16, 88],
            [-22, 43],
            [-4, 93],
            [88, 49],
            [-10, 58],
            [25, 38],
            [25, -85],
            [50, -2],
            [46, -68],
            [3, -40],
            [63, -2],
            [76, 13],
            [41, -56],
            [54, -15],
            [40, 38],
            [1, 32],
            [87, 7],
            [85, 2],
            [-61, -37],
            [25, -57],
            [56, -9],
            [54, -60],
            [11, -98],
            [37, 3],
            [28, -29],
        ],
        [
            [20394, 9424],
            [-95, -104],
            [-59, -116],
            [-15, -85],
            [54, -128],
            [66, -160],
            [64, -75],
            [43, -98],
            [33, -226],
            [-10, -215],
            [-59, -80],
            [-81, -80],
            [-58, -101],
            [-87, -113],
            [-26, 78],
            [20, 82],
            [-53, 69],
        ],
        [
            [24628, 4781],
            [-24, -16],
            [-23, 53],
            [2, 33],
            [45, -70],
        ],
        [
            [24575, 4968],
            [12, -99],
            [-19, 16],
            [-15, -7],
            [-10, 34],
            [-2, 94],
            [34, -38],
        ],
        [
            [16504, 8826],
            [-51, -33],
            [-14, -54],
            [-2, -42],
            [-70, -52],
            [-114, -57],
            [-62, -86],
            [-31, -6],
            [-22, 7],
            [-41, -51],
            [-45, -23],
            [-60, -7],
            [-18, -6],
            [-15, -33],
            [-19, -8],
            [-11, -32],
            [-35, 2],
            [-23, -16],
            [-49, 6],
            [-18, 72],
            [2, 67],
            [-12, 35],
            [-14, 91],
            [-20, 51],
            [14, 5],
            [-7, 56],
            [8, 24],
            [-3, 53],
        ],
        [
            [14827, 4093],
            [29, -1],
            [35, -21],
            [24, 15],
            [37, -12],
        ],
        [
            [15069, 3525],
            [-18, -88],
            [-9, -101],
            [-18, -55],
            [-48, -63],
            [-14, -17],
            [-30, -62],
            [-20, -63],
            [-40, -87],
            [-80, -126],
            [-50, -73],
            [-53, -56],
            [-74, -48],
            [-36, -6],
            [-10, -34],
            [-43, 18],
            [-35, -23],
            [-77, 23],
            [-43, -15],
            [-29, 7],
            [-73, -48],
            [-60, -20],
            [-44, -46],
            [-32, -3],
            [-30, 44],
            [-24, 2],
            [-30, 54],
            [-4, -17],
            [-9, 33],
            [0, 73],
            [-23, 81],
            [23, 22],
            [-2, 94],
            [-46, 115],
            [-36, 103],
        ],
        [
            [13952, 3143],
            [-51, 159],
        ],
        [
            [14887, 4898],
            [-54, -17],
            [-40, -49],
            [-9, -42],
            [-25, -9],
            [-61, -100],
            [-39, -80],
            [-24, -3],
            [-23, 14],
            [-79, 13],
        ],
    ],
    transform: {
        scale: [0.014124293785310738, 0.008187634415757927],
        translate: [-180, -55.61015614321084],
    },
    objects: {
        countries1: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2, 3, 4, 5]],
                    type: 'Polygon',
                    properties: { name: 'Afghanistan', 'Alpha-2': 'AF' },
                    id: 'AFG',
                },
                {
                    arcs: [[[6, 7, 8, 9]], [[10, 11, 12]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Angola', 'Alpha-2': 'AO' },
                    id: 'AGO',
                },
                {
                    arcs: [[13, 14, 15, 16, 17]],
                    type: 'Polygon',
                    properties: { name: 'Albania', 'Alpha-2': 'AL' },
                    id: 'ALB',
                },
                {
                    arcs: [[18, 19, 20, 21, 22]],
                    type: 'Polygon',
                    properties: {
                        name: 'United Arab Emirates',
                        'Alpha-2': 'AE',
                    },
                    id: 'ARE',
                },
                {
                    arcs: [[[23, 24]], [[25, 26, 27, 28, 29, 30]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Argentina', 'Alpha-2': 'AR' },
                    id: 'ARG',
                },
                {
                    arcs: [[31, 32, 33, 34, 35]],
                    type: 'Polygon',
                    properties: { name: 'Armenia', 'Alpha-2': 'AM' },
                    id: 'ARM',
                },
                {
                    arcs: [[36]],
                    type: 'Polygon',
                    properties: {
                        name: 'French Southern and Antarctic Lands',
                        'Alpha-2': 'TF',
                    },
                    id: 'ATF',
                },
                {
                    arcs: [[[37]], [[38]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Australia', 'Alpha-2': 'AU' },
                    id: 'AUS',
                },
                {
                    arcs: [[39, 40, 41, 42, 43, 44, 45]],
                    type: 'Polygon',
                    properties: { name: 'Austria', 'Alpha-2': 'AT' },
                    id: 'AUT',
                },
                {
                    arcs: [[[46, -35]], [[47, 48, 49, -33, 50, 51]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Azerbaijan', 'Alpha-2': 'AZ' },
                    id: 'AZE',
                },
                {
                    arcs: [[52, 53, 54]],
                    type: 'Polygon',
                    properties: { name: 'Burundi', 'Alpha-2': 'BI' },
                    id: 'BDI',
                },
                {
                    arcs: [[55, 56, 57, 58, 59, 60]],
                    type: 'Polygon',
                    properties: { name: 'Belgium', 'Alpha-2': 'BE' },
                    id: 'BEL',
                },
                {
                    arcs: [[61, 62, 63, 64, 65]],
                    type: 'Polygon',
                    properties: { name: 'Benin', 'Alpha-2': 'BJ' },
                    id: 'BEN',
                },
                {
                    arcs: [[66, 67, 68, -64, 69, 70]],
                    type: 'Polygon',
                    properties: { name: 'Burkina Faso', 'Alpha-2': 'BF' },
                    id: 'BFA',
                },
                {
                    arcs: [[71, 72, 73]],
                    type: 'Polygon',
                    properties: { name: 'Bangladesh', 'Alpha-2': 'BD' },
                    id: 'BGD',
                },
                {
                    arcs: [[74, 75, 76, 77, 78, 79]],
                    type: 'Polygon',
                    properties: { name: 'Bulgaria', 'Alpha-2': 'BG' },
                    id: 'BGR',
                },
                {
                    arcs: [[[80]], [[81]], [[82]]],
                    type: 'MultiPolygon',
                    properties: { name: 'The Bahamas', 'Alpha-2': 'BS' },
                    id: 'BHS',
                },
                {
                    arcs: [[83, 84, 85]],
                    type: 'Polygon',
                    properties: {
                        name: 'Bosnia and Herzegovina',
                        'Alpha-2': 'BA',
                    },
                    id: 'BIH',
                },
                {
                    arcs: [[86, 87, 88, 89, 90]],
                    type: 'Polygon',
                    properties: { name: 'Belarus', 'Alpha-2': 'BY' },
                    id: 'BLR',
                },
                {
                    arcs: [[91, 92, 93]],
                    type: 'Polygon',
                    properties: { name: 'Belize', 'Alpha-2': 'BZ' },
                    id: 'BLZ',
                },
                {
                    arcs: [
                        [
                            94,
                            95,
                            96,
                            97,
                            98,
                            99,
                            100,
                            101,
                            102,
                            103,
                            104,
                            105,
                            106,
                            107,
                            108,
                            109,
                            110,
                            111,
                        ],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Bermuda', 'Alpha-2': 'BM' },
                    id: 'BMU',
                },
                {
                    arcs: [[112, 113, 114, 115, -31]],
                    type: 'Polygon',
                    properties: { name: 'Bolivia', 'Alpha-2': 'BO' },
                    id: 'BOL',
                },
                {
                    arcs: [
                        [
                            -27,
                            116,
                            -115,
                            117,
                            118,
                            119,
                            120,
                            121,
                            122,
                            123,
                            124,
                        ],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Brazil', 'Alpha-2': 'BR' },
                    id: 'BRA',
                },
                {
                    arcs: [[125, 126]],
                    type: 'Polygon',
                    properties: { name: 'Brunei', 'Alpha-2': 'BN' },
                    id: 'BRN',
                },
                {
                    arcs: [[127, 128]],
                    type: 'Polygon',
                    properties: { name: 'Bhutan', 'Alpha-2': 'BT' },
                    id: 'BTN',
                },
                {
                    arcs: [[129, 130, 131, 132]],
                    type: 'Polygon',
                    properties: { name: 'Botswana', 'Alpha-2': 'BW' },
                    id: 'BWA',
                },
                {
                    arcs: [[133, 134, 135, 136, 137, 138, 139]],
                    type: 'Polygon',
                    properties: {
                        name: 'Central African Republic',
                        'Alpha-2': 'CF',
                    },
                    id: 'CAF',
                },
                {
                    arcs: [
                        [[140]],
                        [[141]],
                        [[142]],
                        [[143]],
                        [[144]],
                        [[145]],
                        [[146]],
                        [[147]],
                        [[148]],
                        [[149]],
                        [[150, 151, 152, 153]],
                        [[154]],
                        [[155]],
                        [[156]],
                        [[157]],
                        [[158]],
                        [[159]],
                        [[160]],
                        [[161]],
                        [[162]],
                        [[163]],
                        [[164]],
                        [[165]],
                        [[166]],
                        [[167]],
                        [[168]],
                        [[169]],
                        [[170]],
                        [[171]],
                        [[172]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Canada', 'Alpha-2': 'CA' },
                    id: 'CAN',
                },
                {
                    arcs: [[-43, 173, 174, 175]],
                    type: 'Polygon',
                    properties: { name: 'Switzerland', 'Alpha-2': 'CH' },
                    id: 'CHE',
                },
                {
                    arcs: [[[-24, 176]], [[-30, 177, 178, 179, -113]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Chile', 'Alpha-2': 'CL' },
                    id: 'CHL',
                },
                {
                    arcs: [
                        [[180]],
                        [
                            [
                                181,
                                182,
                                183,
                                184,
                                185,
                                186,
                                187,
                                -129,
                                188,
                                189,
                                190,
                                191,
                                -4,
                                192,
                                193,
                                194,
                                195,
                                196,
                                197,
                            ],
                        ],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'China', 'Alpha-2': 'CN' },
                    id: 'CHN',
                },
                {
                    arcs: [[198, 199, 200, 201, -67, 202]],
                    type: 'Polygon',
                    properties: { name: 'Ivory Coast', 'Alpha-2': 'CI' },
                    id: 'CIV',
                },
                {
                    arcs: [[203, 204, 205, 206, 207, 208, -140, 209]],
                    type: 'Polygon',
                    properties: { name: 'Cameroon', 'Alpha-2': 'CM' },
                    id: 'CMR',
                },
                {
                    arcs: [
                        [
                            210,
                            211,
                            -53,
                            212,
                            213,
                            -10,
                            214,
                            -13,
                            215,
                            -138,
                            216,
                            217,
                        ],
                    ],
                    type: 'Polygon',
                    properties: {
                        name: 'Democratic Republic of the Congo',
                        'Alpha-2': 'CD',
                    },
                    id: 'COD',
                },
                {
                    arcs: [[-12, 218, 219, -210, -139, -216]],
                    type: 'Polygon',
                    properties: {
                        name: 'Republic of the Congo',
                        'Alpha-2': 'CG',
                    },
                    id: 'COG',
                },
                {
                    arcs: [[220, 221, 222, 223, 224, -119, 225]],
                    type: 'Polygon',
                    properties: { name: 'Colombia', 'Alpha-2': 'CO' },
                    id: 'COL',
                },
                {
                    arcs: [[226, 227, 228, 229]],
                    type: 'Polygon',
                    properties: { name: 'Costa Rica', 'Alpha-2': 'CR' },
                    id: 'CRI',
                },
                {
                    arcs: [[230]],
                    type: 'Polygon',
                    properties: { name: 'Cuba', 'Alpha-2': 'CU' },
                    id: 'CUB',
                },
                {
                    arcs: [[231, 232]],
                    type: 'Polygon',
                    properties: { name: 'Northern Cyprus', 'Alpha-2': null },
                    id: '-99',
                },
                {
                    arcs: [[233, -233]],
                    type: 'Polygon',
                    properties: { name: 'Cyprus', 'Alpha-2': 'CY' },
                    id: 'CYP',
                },
                {
                    arcs: [[-45, 234, 235, 236]],
                    type: 'Polygon',
                    properties: { name: 'Czech Republic', 'Alpha-2': 'CZ' },
                    id: 'CZE',
                },
                {
                    arcs: [
                        [
                            237,
                            238,
                            -235,
                            -44,
                            -176,
                            239,
                            240,
                            -57,
                            241,
                            242,
                            243,
                        ],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Germany', 'Alpha-2': 'DE' },
                    id: 'DEU',
                },
                {
                    arcs: [[244, 245, 246, 247]],
                    type: 'Polygon',
                    properties: { name: 'Djibouti', 'Alpha-2': 'DJ' },
                    id: 'DJI',
                },
                {
                    arcs: [[[248]], [[-244, 249]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Denmark', 'Alpha-2': 'DK' },
                    id: 'DNK',
                },
                {
                    arcs: [[250, 251]],
                    type: 'Polygon',
                    properties: { name: 'Dominican Republic', 'Alpha-2': 'DO' },
                    id: 'DOM',
                },
                {
                    arcs: [[252, 253, 254, 255, 256, 257, 258, 259]],
                    type: 'Polygon',
                    properties: { name: 'Algeria', 'Alpha-2': 'DZ' },
                    id: 'DZA',
                },
                {
                    arcs: [[260, -221, 261]],
                    type: 'Polygon',
                    properties: { name: 'Ecuador', 'Alpha-2': 'EC' },
                    id: 'ECU',
                },
                {
                    arcs: [[262, 263, 264, 265, 266]],
                    type: 'Polygon',
                    properties: { name: 'Egypt', 'Alpha-2': 'EG' },
                    id: 'EGY',
                },
                {
                    arcs: [[267, 268, 269, -248]],
                    type: 'Polygon',
                    properties: { name: 'Eritrea', 'Alpha-2': 'ER' },
                    id: 'ERI',
                },
                {
                    arcs: [[270, 271, 272, 273]],
                    type: 'Polygon',
                    properties: { name: 'Spain', 'Alpha-2': 'ES' },
                    id: 'ESP',
                },
                {
                    arcs: [[274, 275, 276]],
                    type: 'Polygon',
                    properties: { name: 'Estonia', 'Alpha-2': 'EE' },
                    id: 'EST',
                },
                {
                    arcs: [[-268, -247, 277, 278, 279, 280, 281, 282]],
                    type: 'Polygon',
                    properties: { name: 'Ethiopia', 'Alpha-2': 'ET' },
                    id: 'ETH',
                },
                {
                    arcs: [[283, 284, 285, 286]],
                    type: 'Polygon',
                    properties: { name: 'Finland', 'Alpha-2': 'FI' },
                    id: 'FIN',
                },
                {
                    arcs: [[[287]], [[288]], [[289]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Fiji', 'Alpha-2': 'FJ' },
                    id: 'FJI',
                },
                {
                    arcs: [[290]],
                    type: 'Polygon',
                    properties: { name: 'Falkland Islands', 'Alpha-2': 'FK' },
                    id: 'FLK',
                },
                {
                    arcs: [
                        [[291]],
                        [[292, -240, -175, 293, 294, -272, 295, -59]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'France', 'Alpha-2': 'FR' },
                    id: 'FRA',
                },
                {
                    arcs: [[296, 297, -204, -220]],
                    type: 'Polygon',
                    properties: { name: 'Gabon', 'Alpha-2': 'GA' },
                    id: 'GAB',
                },
                {
                    arcs: [[[298, 299]], [[300, 301]]],
                    type: 'MultiPolygon',
                    properties: { name: 'United Kingdom', 'Alpha-2': 'GB' },
                    id: 'GBR',
                },
                {
                    arcs: [[302, 303, -51, -32, 304]],
                    type: 'Polygon',
                    properties: { name: 'Georgia', 'Alpha-2': 'GE' },
                    id: 'GEO',
                },
                {
                    arcs: [[305, -203, -71, 306]],
                    type: 'Polygon',
                    properties: { name: 'Ghana', 'Alpha-2': 'GH' },
                    id: 'GHA',
                },
                {
                    arcs: [[307, 308, 309, 310, 311, 312, -201]],
                    type: 'Polygon',
                    properties: { name: 'Guinea', 'Alpha-2': 'GN' },
                    id: 'GIN',
                },
                {
                    arcs: [[313, 314]],
                    type: 'Polygon',
                    properties: { name: 'Gambia', 'Alpha-2': 'GM' },
                    id: 'GMB',
                },
                {
                    arcs: [[315, 316, -311]],
                    type: 'Polygon',
                    properties: { name: 'Guinea Bissau', 'Alpha-2': 'GW' },
                    id: 'GNB',
                },
                {
                    arcs: [[317, -205, -298]],
                    type: 'Polygon',
                    properties: { name: 'Equatorial Guinea', 'Alpha-2': 'GQ' },
                    id: 'GNQ',
                },
                {
                    arcs: [[[318]], [[319, -14, 320, -78, 321]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Greece', 'Alpha-2': 'GR' },
                    id: 'GRC',
                },
                {
                    arcs: [[322]],
                    type: 'Polygon',
                    properties: { name: 'Greenland', 'Alpha-2': 'GL' },
                    id: 'GRL',
                },
                {
                    arcs: [[323, 324, -94, 325, 326, 327]],
                    type: 'Polygon',
                    properties: { name: 'Guatemala', 'Alpha-2': 'GT' },
                    id: 'GTM',
                },
                {
                    arcs: [[328, 329, 330, -123]],
                    type: 'Polygon',
                    properties: { name: 'French Guiana', 'Alpha-2': 'GF' },
                    id: 'GUF',
                },
                {
                    arcs: [[331, 332, -121, 333]],
                    type: 'Polygon',
                    properties: { name: 'Guyana', 'Alpha-2': 'GY' },
                    id: 'GUY',
                },
                {
                    arcs: [[334, 335, -327, 336, 337]],
                    type: 'Polygon',
                    properties: { name: 'Honduras', 'Alpha-2': 'HN' },
                    id: 'HND',
                },
                {
                    arcs: [[338, -86, 339, 340, 341, 342]],
                    type: 'Polygon',
                    properties: { name: 'Croatia', 'Alpha-2': 'HR' },
                    id: 'HRV',
                },
                {
                    arcs: [[-252, 343]],
                    type: 'Polygon',
                    properties: { name: 'Haiti', 'Alpha-2': 'HT' },
                    id: 'HTI',
                },
                {
                    arcs: [[-40, 344, 345, 346, 347, -343, 348]],
                    type: 'Polygon',
                    properties: { name: 'Hungary', 'Alpha-2': 'HU' },
                    id: 'HUN',
                },
                {
                    arcs: [
                        [[349]],
                        [[350, 351]],
                        [[352]],
                        [[353]],
                        [[354]],
                        [[355]],
                        [[356]],
                        [[357]],
                        [[358, 359]],
                        [[360]],
                        [[361]],
                        [[362, 363]],
                        [[364]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Indonesia', 'Alpha-2': 'ID' },
                    id: 'IDN',
                },
                {
                    arcs: [[-191, 365, -189, -128, -188, 366, -74, 367, 368]],
                    type: 'Polygon',
                    properties: { name: 'India', 'Alpha-2': 'IN' },
                    id: 'IND',
                },
                {
                    arcs: [[369, -299]],
                    type: 'Polygon',
                    properties: { name: 'Ireland', 'Alpha-2': 'IE' },
                    id: 'IRL',
                },
                {
                    arcs: [[370, -6, 371, 372, 373, 374, -47, -34, -50, 375]],
                    type: 'Polygon',
                    properties: { name: 'Iran', 'Alpha-2': 'IR' },
                    id: 'IRN',
                },
                {
                    arcs: [[-374, 376, 377, 378, 379, 380, 381]],
                    type: 'Polygon',
                    properties: { name: 'Iraq', 'Alpha-2': 'IQ' },
                    id: 'IRQ',
                },
                {
                    arcs: [[382]],
                    type: 'Polygon',
                    properties: { name: 'Iceland', 'Alpha-2': 'IS' },
                    id: 'ISL',
                },
                {
                    arcs: [[383, 384, 385, -266, 386, 387, 388]],
                    type: 'Polygon',
                    properties: { name: 'Israel', 'Alpha-2': 'IL' },
                    id: 'ISR',
                },
                {
                    arcs: [[[389]], [[390]], [[391, 392, -294, -174, -42]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Italy', 'Alpha-2': 'IT' },
                    id: 'ITA',
                },
                {
                    arcs: [[393]],
                    type: 'Polygon',
                    properties: { name: 'Jamaica', 'Alpha-2': 'JM' },
                    id: 'JAM',
                },
                {
                    arcs: [[-384, 394, -380, 395, 396, -386, 397]],
                    type: 'Polygon',
                    properties: { name: 'Jordan', 'Alpha-2': 'JO' },
                    id: 'JOR',
                },
                {
                    arcs: [[[398]], [[399]], [[400]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Japan', 'Alpha-2': 'JP' },
                    id: 'JPN',
                },
                {
                    arcs: [[401, 402, 403, 404, -195, 405]],
                    type: 'Polygon',
                    properties: { name: 'Kazakhstan', 'Alpha-2': 'KZ' },
                    id: 'KAZ',
                },
                {
                    arcs: [[406, 407, 408, 409, -280, 410]],
                    type: 'Polygon',
                    properties: { name: 'Kenya', 'Alpha-2': 'KE' },
                    id: 'KEN',
                },
                {
                    arcs: [[-406, -194, 411, 412]],
                    type: 'Polygon',
                    properties: { name: 'Kyrgyzstan', 'Alpha-2': 'KG' },
                    id: 'KGZ',
                },
                {
                    arcs: [[413, 414, 415, 416]],
                    type: 'Polygon',
                    properties: { name: 'Cambodia', 'Alpha-2': 'KH' },
                    id: 'KHM',
                },
                {
                    arcs: [[417, 418]],
                    type: 'Polygon',
                    properties: { name: 'South Korea', 'Alpha-2': 'KR' },
                    id: 'KOR',
                },
                {
                    arcs: [[-17, 419, 420, 421]],
                    type: 'Polygon',
                    properties: { name: 'Kosovo', 'Alpha-2': null },
                    id: 'CS-KM',
                },
                {
                    arcs: [[422, 423, -378]],
                    type: 'Polygon',
                    properties: { name: 'Kuwait', 'Alpha-2': 'KW' },
                    id: 'KWT',
                },
                {
                    arcs: [[424, 425, -186, 426, -415]],
                    type: 'Polygon',
                    properties: { name: 'Laos', 'Alpha-2': 'LA' },
                    id: 'LAO',
                },
                {
                    arcs: [[-388, 427, 428]],
                    type: 'Polygon',
                    properties: { name: 'Lebanon', 'Alpha-2': 'LB' },
                    id: 'LBN',
                },
                {
                    arcs: [[429, 430, -308, -200]],
                    type: 'Polygon',
                    properties: { name: 'Liberia', 'Alpha-2': 'LR' },
                    id: 'LBR',
                },
                {
                    arcs: [[431, -260, 432, 433, -264, 434, 435]],
                    type: 'Polygon',
                    properties: { name: 'Libya', 'Alpha-2': 'LY' },
                    id: 'LBY',
                },
                {
                    arcs: [[436]],
                    type: 'Polygon',
                    properties: { name: 'Sri Lanka', 'Alpha-2': 'LK' },
                    id: 'LKA',
                },
                {
                    arcs: [[437]],
                    type: 'Polygon',
                    properties: { name: 'Lesotho', 'Alpha-2': 'LS' },
                    id: 'LSO',
                },
                {
                    arcs: [[438, 439, 440, -87, 441]],
                    type: 'Polygon',
                    properties: { name: 'Lithuania', 'Alpha-2': 'LT' },
                    id: 'LTU',
                },
                {
                    arcs: [[-241, -293, -58]],
                    type: 'Polygon',
                    properties: { name: 'Luxembourg', 'Alpha-2': 'LU' },
                    id: 'LUX',
                },
                {
                    arcs: [[442, -277, 443, -88, -441]],
                    type: 'Polygon',
                    properties: { name: 'Latvia', 'Alpha-2': 'LV' },
                    id: 'LVA',
                },
                {
                    arcs: [[-257, 444, 445, 446]],
                    type: 'Polygon',
                    properties: { name: 'Morocco', 'Alpha-2': 'MA' },
                    id: 'MAR',
                },
                {
                    arcs: [[447, 448]],
                    type: 'Polygon',
                    properties: { name: 'Moldova', 'Alpha-2': 'MD' },
                    id: 'MDA',
                },
                {
                    arcs: [[449]],
                    type: 'Polygon',
                    properties: { name: 'Madagascar', 'Alpha-2': 'MG' },
                    id: 'MDG',
                },
                {
                    arcs: [[-92, -325, 450, 451, 452]],
                    type: 'Polygon',
                    properties: { name: 'Mexico', 'Alpha-2': 'MX' },
                    id: 'MEX',
                },
                {
                    arcs: [[-422, 453, -79, -321, -18]],
                    type: 'Polygon',
                    properties: { name: 'Macedonia', 'Alpha-2': 'MK' },
                    id: 'MKD',
                },
                {
                    arcs: [[454, -254, 455, -68, -202, -313, 456]],
                    type: 'Polygon',
                    properties: { name: 'Mali', 'Alpha-2': 'ML' },
                    id: 'MLI',
                },
                {
                    arcs: [[[457]], [[458]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Malta', 'Alpha-2': 'MT' },
                    id: 'MLT',
                },
                {
                    arcs: [[459, -72, -367, -187, -426, 460]],
                    type: 'Polygon',
                    properties: { name: 'Myanmar', 'Alpha-2': 'MM' },
                    id: 'MMR',
                },
                {
                    arcs: [[-16, 461, -340, -85, 462, -420]],
                    type: 'Polygon',
                    properties: { name: 'Montenegro', 'Alpha-2': 'ME' },
                    id: 'MNE',
                },
                {
                    arcs: [[463, -197]],
                    type: 'Polygon',
                    properties: { name: 'Mongolia', 'Alpha-2': 'MN' },
                    id: 'MNG',
                },
                {
                    arcs: [[464, 465, 466, 467, 468, 469, 470, 471]],
                    type: 'Polygon',
                    properties: { name: 'Mozambique', 'Alpha-2': 'MZ' },
                    id: 'MOZ',
                },
                {
                    arcs: [[472, 473, 474, -255, -455]],
                    type: 'Polygon',
                    properties: { name: 'Mauritania', 'Alpha-2': 'MR' },
                    id: 'MRT',
                },
                {
                    arcs: [[-472, 475, 476]],
                    type: 'Polygon',
                    properties: { name: 'Malawi', 'Alpha-2': 'MW' },
                    id: 'MWI',
                },
                {
                    arcs: [[[477, 478]], [[-363, 479, -127, 480]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Malaysia', 'Alpha-2': 'MY' },
                    id: 'MYS',
                },
                {
                    arcs: [[481, -8, 482, -131, 483]],
                    type: 'Polygon',
                    properties: { name: 'Namibia', 'Alpha-2': 'NA' },
                    id: 'NAM',
                },
                {
                    arcs: [[484]],
                    type: 'Polygon',
                    properties: { name: 'New Caledonia', 'Alpha-2': 'NC' },
                    id: 'NCL',
                },
                {
                    arcs: [[-69, -456, -253, -432, 485, -208, 486, -65]],
                    type: 'Polygon',
                    properties: { name: 'Niger', 'Alpha-2': 'NE' },
                    id: 'NER',
                },
                {
                    arcs: [[487, -66, -487, -207]],
                    type: 'Polygon',
                    properties: { name: 'Nigeria', 'Alpha-2': 'NG' },
                    id: 'NGA',
                },
                {
                    arcs: [[488, -338, 489, -228]],
                    type: 'Polygon',
                    properties: { name: 'Nicaragua', 'Alpha-2': 'NI' },
                    id: 'NIC',
                },
                {
                    arcs: [[-242, -56, -61, 490]],
                    type: 'Polygon',
                    properties: { name: 'Netherlands', 'Alpha-2': 'NL' },
                    id: 'NLD',
                },
                {
                    arcs: [[[-287, 491, 492, 493]], [[494]], [[495]], [[496]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Norway', 'Alpha-2': 'NO' },
                    id: 'NOR',
                },
                {
                    arcs: [[-366, -190]],
                    type: 'Polygon',
                    properties: { name: 'Nepal', 'Alpha-2': 'NP' },
                    id: 'NPL',
                },
                {
                    arcs: [[[497]], [[498]]],
                    type: 'MultiPolygon',
                    properties: { name: 'New Zealand', 'Alpha-2': 'NZ' },
                    id: 'NZL',
                },
                {
                    arcs: [[[499, 500, -22, 501]], [[-20, 502]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Oman', 'Alpha-2': 'OM' },
                    id: 'OMN',
                },
                {
                    arcs: [[-192, -369, 503, -372, -5]],
                    type: 'Polygon',
                    properties: { name: 'Pakistan', 'Alpha-2': 'PK' },
                    id: 'PAK',
                },
                {
                    arcs: [[504, -230, 505, -223]],
                    type: 'Polygon',
                    properties: { name: 'Panama', 'Alpha-2': 'PA' },
                    id: 'PAN',
                },
                {
                    arcs: [[-180, 506, -262, -226, -118, -114]],
                    type: 'Polygon',
                    properties: { name: 'Peru', 'Alpha-2': 'PE' },
                    id: 'PER',
                },
                {
                    arcs: [
                        [[507]],
                        [[508]],
                        [[509]],
                        [[510]],
                        [[511]],
                        [[512]],
                        [[513]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Philippines', 'Alpha-2': 'PH' },
                    id: 'PHL',
                },
                {
                    arcs: [[[514]], [[515]], [[-359, 516]], [[517]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Papua New Guinea', 'Alpha-2': 'PG' },
                    id: 'PNG',
                },
                {
                    arcs: [[-239, 518, 519, -442, -91, 520, 521, -236]],
                    type: 'Polygon',
                    properties: { name: 'Poland', 'Alpha-2': 'PL' },
                    id: 'POL',
                },
                {
                    arcs: [[522]],
                    type: 'Polygon',
                    properties: { name: 'Puerto Rico', 'Alpha-2': 'PR' },
                    id: 'PRI',
                },
                {
                    arcs: [[523, 524, -419, 525, -183]],
                    type: 'Polygon',
                    properties: { name: 'North Korea', 'Alpha-2': 'KP' },
                    id: 'PRK',
                },
                {
                    arcs: [[-274, 526]],
                    type: 'Polygon',
                    properties: { name: 'Portugal', 'Alpha-2': 'PT' },
                    id: 'PRT',
                },
                {
                    arcs: [[-116, -117, -26]],
                    type: 'Polygon',
                    properties: { name: 'Paraguay', 'Alpha-2': 'PY' },
                    id: 'PRY',
                },
                {
                    arcs: [[527, 528]],
                    type: 'Polygon',
                    properties: { name: 'Qatar', 'Alpha-2': 'QA' },
                    id: 'QAT',
                },
                {
                    arcs: [[529, -449, 530, 531, -75, 532, -347]],
                    type: 'Polygon',
                    properties: { name: 'Romania', 'Alpha-2': 'RO' },
                    id: 'ROU',
                },
                {
                    arcs: [
                        [[533]],
                        [[-520, 534, -439]],
                        [[535]],
                        [[536]],
                        [[537]],
                        [[538]],
                        [[539]],
                        [[540]],
                        [[541]],
                        [
                            [
                                -182,
                                -198,
                                -464,
                                -196,
                                -405,
                                542,
                                -48,
                                -52,
                                -304,
                                543,
                                544,
                                -89,
                                -444,
                                -276,
                                545,
                                -284,
                                -494,
                                546,
                                -524,
                            ],
                        ],
                        [[547]],
                        [[548]],
                        [[549]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Russia', 'Alpha-2': 'RU' },
                    id: 'RUS',
                },
                {
                    arcs: [[550, -54, -212, 551]],
                    type: 'Polygon',
                    properties: { name: 'Rwanda', 'Alpha-2': 'RW' },
                    id: 'RWA',
                },
                {
                    arcs: [[-445, -256, -475, 552, -446]],
                    type: 'Polygon',
                    properties: { name: 'Western Sahara', 'Alpha-2': 'EH' },
                    id: 'ESH',
                },
                {
                    arcs: [
                        [553, -396, -379, -424, 554, -529, 555, -23, -501, 556],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Saudi Arabia', 'Alpha-2': 'SA' },
                    id: 'SAU',
                },
                {
                    arcs: [
                        [557, 558, -135, 559, -435, -263, 560, -269, -283, 561],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Sudan', 'Alpha-2': 'SD' },
                    id: 'SDN',
                },
                {
                    arcs: [[562, -281, -410, 563, -217, -137, 564, -558]],
                    type: 'Polygon',
                    properties: { name: 'South Sudan', 'Alpha-2': null },
                    id: 'SSD',
                },
                {
                    arcs: [[565, -473, -457, -312, -317, 566, -315]],
                    type: 'Polygon',
                    properties: { name: 'Senegal', 'Alpha-2': 'SN' },
                    id: 'SEN',
                },
                {
                    arcs: [[[567]], [[568]], [[569]], [[570]], [[571]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Solomon Islands', 'Alpha-2': 'SB' },
                    id: 'SLB',
                },
                {
                    arcs: [[572, -309, -431]],
                    type: 'Polygon',
                    properties: { name: 'Sierra Leone', 'Alpha-2': 'SL' },
                    id: 'SLE',
                },
                {
                    arcs: [[573, -328, -336]],
                    type: 'Polygon',
                    properties: { name: 'El Salvador', 'Alpha-2': 'SV' },
                    id: 'SLV',
                },
                {
                    arcs: [[-278, -246, 574, 575]],
                    type: 'Polygon',
                    properties: { name: 'Somaliland', 'Alpha-2': null },
                    id: '-99',
                },
                {
                    arcs: [[-411, -279, -576, 576]],
                    type: 'Polygon',
                    properties: { name: 'Somalia', 'Alpha-2': 'SO' },
                    id: 'SOM',
                },
                {
                    arcs: [[-80, -454, -421, -463, -84, -339, -348, -533]],
                    type: 'Polygon',
                    properties: { name: 'Republic of Serbia', 'Alpha-2': 'RS' },
                    id: 'SRB',
                },
                {
                    arcs: [[577, -330, 578, -122, -333]],
                    type: 'Polygon',
                    properties: { name: 'Suriname', 'Alpha-2': 'SR' },
                    id: 'SUR',
                },
                {
                    arcs: [[-522, 579, -345, -46, -237]],
                    type: 'Polygon',
                    properties: { name: 'Slovakia', 'Alpha-2': 'SK' },
                    id: 'SVK',
                },
                {
                    arcs: [[-41, -349, -342, 580, -392]],
                    type: 'Polygon',
                    properties: { name: 'Slovenia', 'Alpha-2': 'SI' },
                    id: 'SVN',
                },
                {
                    arcs: [[-492, -286, 581], [582], [583], [584]],
                    type: 'Polygon',
                    properties: { name: 'Sweden', 'Alpha-2': 'SE' },
                    id: 'SWE',
                },
                {
                    arcs: [[585, -468]],
                    type: 'Polygon',
                    properties: { name: 'Swaziland', 'Alpha-2': 'SZ' },
                    id: 'SWZ',
                },
                {
                    arcs: [[-395, -389, -429, 586, 587, -381]],
                    type: 'Polygon',
                    properties: { name: 'Syria', 'Alpha-2': 'SY' },
                    id: 'SYR',
                },
                {
                    arcs: [[-486, -436, -560, -134, -209]],
                    type: 'Polygon',
                    properties: { name: 'Chad', 'Alpha-2': 'TD' },
                    id: 'TCD',
                },
                {
                    arcs: [[588, -307, -70, -63]],
                    type: 'Polygon',
                    properties: { name: 'Togo', 'Alpha-2': 'TG' },
                    id: 'TGO',
                },
                {
                    arcs: [[589, -479, 590, -461, -425, -414]],
                    type: 'Polygon',
                    properties: { name: 'Thailand', 'Alpha-2': 'TH' },
                    id: 'THA',
                },
                {
                    arcs: [[-412, -193, -3, 591]],
                    type: 'Polygon',
                    properties: { name: 'Tajikistan', 'Alpha-2': 'TJ' },
                    id: 'TJK',
                },
                {
                    arcs: [[-371, 592, -403, 593, -1]],
                    type: 'Polygon',
                    properties: { name: 'Turkmenistan', 'Alpha-2': 'TM' },
                    id: 'TKM',
                },
                {
                    arcs: [[594, -351]],
                    type: 'Polygon',
                    properties: { name: 'East Timor', 'Alpha-2': 'TL' },
                    id: 'TLS',
                },
                {
                    arcs: [[595]],
                    type: 'Polygon',
                    properties: {
                        name: 'Trinidad and Tobago',
                        'Alpha-2': 'TT',
                    },
                    id: 'TTO',
                },
                {
                    arcs: [[-259, 596, -433]],
                    type: 'Polygon',
                    properties: { name: 'Tunisia', 'Alpha-2': 'TN' },
                    id: 'TUN',
                },
                {
                    arcs: [
                        [[-305, -36, -375, -382, -588, 597]],
                        [[-322, -77, 598]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Turkey', 'Alpha-2': 'TR' },
                    id: 'TUR',
                },
                {
                    arcs: [[599]],
                    type: 'Polygon',
                    properties: { name: 'Taiwan', 'Alpha-2': 'TW' },
                    id: 'TWN',
                },
                {
                    arcs: [[-408, 600, -465, -477, 601, -213, -55, -551, 602]],
                    type: 'Polygon',
                    properties: {
                        name: 'United Republic of Tanzania',
                        'Alpha-2': 'TZ',
                    },
                    id: 'TZA',
                },
                {
                    arcs: [[-552, -211, -218, -564, -409, -603]],
                    type: 'Polygon',
                    properties: { name: 'Uganda', 'Alpha-2': 'UG' },
                    id: 'UGA',
                },
                {
                    arcs: [
                        [-545, 603, -531, -448, -530, -346, -580, -521, -90],
                    ],
                    type: 'Polygon',
                    properties: { name: 'Ukraine', 'Alpha-2': 'UA' },
                    id: 'UKR',
                },
                {
                    arcs: [[-125, 604, -28]],
                    type: 'Polygon',
                    properties: { name: 'Uruguay', 'Alpha-2': 'UY' },
                    id: 'URY',
                },
                {
                    arcs: [
                        [[605]],
                        [[606]],
                        [[607]],
                        [[608]],
                        [[609]],
                        [[610, -452, 611, -151]],
                        [[612]],
                        [[613]],
                        [[614]],
                        [[-153, 615]],
                    ],
                    type: 'MultiPolygon',
                    properties: {
                        name: 'United States of America',
                        'Alpha-2': 'US',
                    },
                    id: 'USA',
                },
                {
                    arcs: [[-594, -402, -413, -592, -2]],
                    type: 'Polygon',
                    properties: { name: 'Uzbekistan', 'Alpha-2': 'UZ' },
                    id: 'UZB',
                },
                {
                    arcs: [[616, -334, -120, -225]],
                    type: 'Polygon',
                    properties: { name: 'Venezuela', 'Alpha-2': 'VE' },
                    id: 'VEN',
                },
                {
                    arcs: [[617, -416, -427, -185]],
                    type: 'Polygon',
                    properties: { name: 'Vietnam', 'Alpha-2': 'VN' },
                    id: 'VNM',
                },
                {
                    arcs: [[[618]], [[619]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Vanuatu', 'Alpha-2': 'VU' },
                    id: 'VUT',
                },
                {
                    arcs: [[-398, -385]],
                    type: 'Polygon',
                    properties: { name: 'West Bank', 'Alpha-2': 'PS' },
                    id: 'PSE',
                },
                {
                    arcs: [[620, -557, -500]],
                    type: 'Polygon',
                    properties: { name: 'Yemen', 'Alpha-2': 'YE' },
                    id: 'YEM',
                },
                {
                    arcs: [
                        [-484, -130, 621, -469, -586, -467, 622, 623],
                        [-438],
                    ],
                    type: 'Polygon',
                    properties: { name: 'South Africa', 'Alpha-2': 'ZA' },
                    id: 'ZAF',
                },
                {
                    arcs: [[-476, -471, 624, -132, -483, -7, -214, -602]],
                    type: 'Polygon',
                    properties: { name: 'Zambia', 'Alpha-2': 'ZM' },
                    id: 'ZMB',
                },
                {
                    arcs: [[-622, -133, -625, -470]],
                    type: 'Polygon',
                    properties: { name: 'Zimbabwe', 'Alpha-2': 'ZW' },
                    id: 'ZWE',
                },
            ],
        },
        countries2: {
            type: 'GeometryCollection',
            geometries: [
                {
                    type: null,
                    properties: { 'Alpha-2': 'AF', 'Alpha-3': 'AFG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AL', 'Alpha-3': 'ALB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DZ', 'Alpha-3': 'DZA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AS', 'Alpha-3': 'ASM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AD', 'Alpha-3': 'AND' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AO', 'Alpha-3': 'AGO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AI', 'Alpha-3': 'AIA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AQ', 'Alpha-3': 'ATA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AG', 'Alpha-3': 'ATG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AR', 'Alpha-3': 'ARG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AM', 'Alpha-3': 'ARM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AW', 'Alpha-3': 'ABW' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AU', 'Alpha-3': 'AUS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AT', 'Alpha-3': 'AUT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AZ', 'Alpha-3': 'AZE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BS', 'Alpha-3': 'BHS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BH', 'Alpha-3': 'BHR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BD', 'Alpha-3': 'BGD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BB', 'Alpha-3': 'BRB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BY', 'Alpha-3': 'BLR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BE', 'Alpha-3': 'BEL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BZ', 'Alpha-3': 'BLZ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BJ', 'Alpha-3': 'BEN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BM', 'Alpha-3': 'BMU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BT', 'Alpha-3': 'BTN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BO', 'Alpha-3': 'BOL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BO', 'Alpha-3': 'BOL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BA', 'Alpha-3': 'BIH' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BW', 'Alpha-3': 'BWA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BV', 'Alpha-3': 'BVT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BR', 'Alpha-3': 'BRA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IO', 'Alpha-3': 'IOT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BN', 'Alpha-3': 'BRN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BN', 'Alpha-3': 'BRN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BG', 'Alpha-3': 'BGR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BF', 'Alpha-3': 'BFA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'BI', 'Alpha-3': 'BDI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KH', 'Alpha-3': 'KHM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CM', 'Alpha-3': 'CMR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CA', 'Alpha-3': 'CAN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CV', 'Alpha-3': 'CPV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KY', 'Alpha-3': 'CYM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CF', 'Alpha-3': 'CAF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TD', 'Alpha-3': 'TCD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CL', 'Alpha-3': 'CHL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CN', 'Alpha-3': 'CHN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CX', 'Alpha-3': 'CXR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CC', 'Alpha-3': 'CCK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CO', 'Alpha-3': 'COL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KM', 'Alpha-3': 'COM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CG', 'Alpha-3': 'COG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CD', 'Alpha-3': 'COD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CK', 'Alpha-3': 'COK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CR', 'Alpha-3': 'CRI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CI', 'Alpha-3': 'CIV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CI', 'Alpha-3': 'CIV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HR', 'Alpha-3': 'HRV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CU', 'Alpha-3': 'CUB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CY', 'Alpha-3': 'CYP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CZ', 'Alpha-3': 'CZE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DK', 'Alpha-3': 'DNK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DJ', 'Alpha-3': 'DJI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DM', 'Alpha-3': 'DMA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DO', 'Alpha-3': 'DOM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'EC', 'Alpha-3': 'ECU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'EG', 'Alpha-3': 'EGY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SV', 'Alpha-3': 'SLV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GQ', 'Alpha-3': 'GNQ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ER', 'Alpha-3': 'ERI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'EE', 'Alpha-3': 'EST' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ET', 'Alpha-3': 'ETH' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FK', 'Alpha-3': 'FLK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FO', 'Alpha-3': 'FRO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FJ', 'Alpha-3': 'FJI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FI', 'Alpha-3': 'FIN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FR', 'Alpha-3': 'FRA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GF', 'Alpha-3': 'GUF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PF', 'Alpha-3': 'PYF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TF', 'Alpha-3': 'ATF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GA', 'Alpha-3': 'GAB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GM', 'Alpha-3': 'GMB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GE', 'Alpha-3': 'GEO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'DE', 'Alpha-3': 'DEU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GH', 'Alpha-3': 'GHA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GI', 'Alpha-3': 'GIB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GR', 'Alpha-3': 'GRC' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GL', 'Alpha-3': 'GRL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GD', 'Alpha-3': 'GRD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GP', 'Alpha-3': 'GLP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GU', 'Alpha-3': 'GUM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GT', 'Alpha-3': 'GTM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GG', 'Alpha-3': 'GGY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GN', 'Alpha-3': 'GIN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GW', 'Alpha-3': 'GNB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GY', 'Alpha-3': 'GUY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HT', 'Alpha-3': 'HTI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HM', 'Alpha-3': 'HMD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VA', 'Alpha-3': 'VAT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HN', 'Alpha-3': 'HND' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HK', 'Alpha-3': 'HKG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'HU', 'Alpha-3': 'HUN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IS', 'Alpha-3': 'ISL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IN', 'Alpha-3': 'IND' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ID', 'Alpha-3': 'IDN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IR', 'Alpha-3': 'IRN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IQ', 'Alpha-3': 'IRQ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IE', 'Alpha-3': 'IRL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IM', 'Alpha-3': 'IMN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IL', 'Alpha-3': 'ISR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'IT', 'Alpha-3': 'ITA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'JM', 'Alpha-3': 'JAM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'JP', 'Alpha-3': 'JPN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'JE', 'Alpha-3': 'JEY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'JO', 'Alpha-3': 'JOR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KZ', 'Alpha-3': 'KAZ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KE', 'Alpha-3': 'KEN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KI', 'Alpha-3': 'KIR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KP', 'Alpha-3': 'PRK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KR', 'Alpha-3': 'KOR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KR', 'Alpha-3': 'KOR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KW', 'Alpha-3': 'KWT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KG', 'Alpha-3': 'KGZ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LA', 'Alpha-3': 'LAO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LV', 'Alpha-3': 'LVA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LB', 'Alpha-3': 'LBN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LS', 'Alpha-3': 'LSO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LR', 'Alpha-3': 'LBR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LY', 'Alpha-3': 'LBY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LY', 'Alpha-3': 'LBY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LI', 'Alpha-3': 'LIE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LT', 'Alpha-3': 'LTU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LU', 'Alpha-3': 'LUX' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MO', 'Alpha-3': 'MAC' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MK', 'Alpha-3': 'MKD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MG', 'Alpha-3': 'MDG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MW', 'Alpha-3': 'MWI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MY', 'Alpha-3': 'MYS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MV', 'Alpha-3': 'MDV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ML', 'Alpha-3': 'MLI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MT', 'Alpha-3': 'MLT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MH', 'Alpha-3': 'MHL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MQ', 'Alpha-3': 'MTQ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MR', 'Alpha-3': 'MRT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MU', 'Alpha-3': 'MUS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'YT', 'Alpha-3': 'MYT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MX', 'Alpha-3': 'MEX' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'FM', 'Alpha-3': 'FSM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MD', 'Alpha-3': 'MDA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MC', 'Alpha-3': 'MCO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MN', 'Alpha-3': 'MNG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ME', 'Alpha-3': 'MNE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MS', 'Alpha-3': 'MSR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MA', 'Alpha-3': 'MAR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MZ', 'Alpha-3': 'MOZ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MM', 'Alpha-3': 'MMR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MM', 'Alpha-3': 'MMR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NA', 'Alpha-3': 'NAM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NR', 'Alpha-3': 'NRU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NP', 'Alpha-3': 'NPL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NL', 'Alpha-3': 'NLD' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AN', 'Alpha-3': 'ANT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NC', 'Alpha-3': 'NCL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NZ', 'Alpha-3': 'NZL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NI', 'Alpha-3': 'NIC' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NE', 'Alpha-3': 'NER' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NG', 'Alpha-3': 'NGA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NU', 'Alpha-3': 'NIU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NF', 'Alpha-3': 'NFK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'MP', 'Alpha-3': 'MNP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'NO', 'Alpha-3': 'NOR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'OM', 'Alpha-3': 'OMN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PK', 'Alpha-3': 'PAK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PW', 'Alpha-3': 'PLW' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PS', 'Alpha-3': 'PSE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PA', 'Alpha-3': 'PAN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PG', 'Alpha-3': 'PNG' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PY', 'Alpha-3': 'PRY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PE', 'Alpha-3': 'PER' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PH', 'Alpha-3': 'PHL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PN', 'Alpha-3': 'PCN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PL', 'Alpha-3': 'POL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PT', 'Alpha-3': 'PRT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PR', 'Alpha-3': 'PRI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'QA', 'Alpha-3': 'QAT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RE', 'Alpha-3': 'REU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RO', 'Alpha-3': 'ROU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RU', 'Alpha-3': 'RUS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RU', 'Alpha-3': 'RUS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RW', 'Alpha-3': 'RWA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SH', 'Alpha-3': 'SHN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'KN', 'Alpha-3': 'KNA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LC', 'Alpha-3': 'LCA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'PM', 'Alpha-3': 'SPM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VC', 'Alpha-3': 'VCT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VC', 'Alpha-3': 'VCT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VC', 'Alpha-3': 'VCT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'WS', 'Alpha-3': 'WSM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SM', 'Alpha-3': 'SMR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ST', 'Alpha-3': 'STP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SA', 'Alpha-3': 'SAU' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SN', 'Alpha-3': 'SEN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'RS', 'Alpha-3': 'SRB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SC', 'Alpha-3': 'SYC' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SL', 'Alpha-3': 'SLE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SG', 'Alpha-3': 'SGP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SK', 'Alpha-3': 'SVK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SI', 'Alpha-3': 'SVN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SB', 'Alpha-3': 'SLB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SO', 'Alpha-3': 'SOM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ZA', 'Alpha-3': 'ZAF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GS', 'Alpha-3': 'SGS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ES', 'Alpha-3': 'ESP' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'LK', 'Alpha-3': 'LKA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SD', 'Alpha-3': 'SDN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SR', 'Alpha-3': 'SUR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SJ', 'Alpha-3': 'SJM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SZ', 'Alpha-3': 'SWZ' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SE', 'Alpha-3': 'SWE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'CH', 'Alpha-3': 'CHE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'SY', 'Alpha-3': 'SYR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TW', 'Alpha-3': 'TWN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TW', 'Alpha-3': 'TWN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TJ', 'Alpha-3': 'TJK' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TZ', 'Alpha-3': 'TZA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TH', 'Alpha-3': 'THA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TL', 'Alpha-3': 'TLS' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TG', 'Alpha-3': 'TGO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TK', 'Alpha-3': 'TKL' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TO', 'Alpha-3': 'TON' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TT', 'Alpha-3': 'TTO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TT', 'Alpha-3': 'TTO' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TN', 'Alpha-3': 'TUN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TR', 'Alpha-3': 'TUR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TM', 'Alpha-3': 'TKM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TC', 'Alpha-3': 'TCA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'TV', 'Alpha-3': 'TUV' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'UG', 'Alpha-3': 'UGA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'UA', 'Alpha-3': 'UKR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'AE', 'Alpha-3': 'ARE' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'GB', 'Alpha-3': 'GBR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'US', 'Alpha-3': 'USA' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'UM', 'Alpha-3': 'UMI' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'UY', 'Alpha-3': 'URY' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'UZ', 'Alpha-3': 'UZB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VU', 'Alpha-3': 'VUT' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VE', 'Alpha-3': 'VEN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VE', 'Alpha-3': 'VEN' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VN', 'Alpha-3': 'VNM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VN', 'Alpha-3': 'VNM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VG', 'Alpha-3': 'VGB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'VI', 'Alpha-3': 'VIR' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'WF', 'Alpha-3': 'WLF' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'EH', 'Alpha-3': 'ESH' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'YE', 'Alpha-3': 'YEM' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ZM', 'Alpha-3': 'ZMB' },
                },
                {
                    type: null,
                    properties: { 'Alpha-2': 'ZW', 'Alpha-3': 'ZWE' },
                },
            ],
        },
    },
};

export default world;
