// React
import React from 'react';

// Packages
import t from 'prop-types';

// Data
import sdgsColors from 'utilities/data/sdgColors';

const SdgNumberComponent = ({ number }) => {
    return (
        <span
            className="inline-block px-5 pt-4 mr-8 leading-none !text-white w-[30px] t-h5 rounded-4 text-center"
            style={{
                backgroundColor: sdgsColors[number - 1],
            }}>
            <span style={{ color: 'white' }}>{number}</span>
        </span>
    );
};

SdgNumberComponent.propTypes = {
    number: t.oneOfType([t.number, t.string]),
};

SdgNumberComponent.defaultProps = {};

export default SdgNumberComponent;
