// Packages
import create from 'zustand';
import _get from 'lodash.get';
import _set from 'lodash.set';
import _unset from 'lodash.unset';
import _uniqBy from 'lodash.uniqby';

// Utilities
import initiativeGetters from 'utilities/initiativeGetters';
import { authStore } from 'utilities/store';
import ew from 'utilities/api/elseware';

// Configuration
import { CONSTANTS } from 'utilities/configuration';

function _updateAuth() {
    const { getState } = authStore;
    getState().updateUserTimeout();
}

const useInitiativeDataStore = create((set, get) => ({
    CONSTANTS: { ...CONSTANTS },

    initiative: {
        _activities: {},
        _activityEmployees: {},
        _activityGoals: {},
        _regulations: {},
        _activitySuccessMetrics: {},
        _collaborators: {},
        _contents: {},
        _employeesFunded: {},
        _employeeFundedFundings: {},
        _funders: {},
        _fundingRecipients: {},
        _fundings: {},
        _goals: {},
        _kpis: {},
        _locations: {},
        _milestoneActivities: {},
        _milestoneKpis: {},
        _milestones: {},
        _reportDetailEntries: {},
        _reportDetailGoals: {},
        _reportDetails: {},
        _reports: {},
        _resultActivities: {},
        _resultGoals: {},
        _results: {},
        _tags: {},
        _teamMembers: {},
        _updateContents: {},
        _updates: {},
        _funderTags: null,
    },

    utilities: {
        ...initiativeGetters(get, CONSTANTS),

        // Update initiative with new data
        updateInitiative(data) {
            set(state => ({
                initiative: { ...state.initiative, ...data },
            }));
        },
        // Updates nested initiative objects with new data
        updateInitiativeData(path, data) {
            let initiative = get().initiative;
            _set(initiative, `${path}[${data.Id}]`, data);
            set(() => ({
                initiative,
            }));
        },
        // Updates data relations
        updateInitiativeDataRelations(path, data) {
            let initiative = get().initiative;
            _set(initiative, path, { ..._get(initiative, path), ...data });
            set(() => ({
                initiative,
            }));
        },
        // Removes initiative data
        removeInitiativeData(path, id) {
            let initiative = get().initiative;
            _unset(initiative, `${path}[${id}]`);
            set(() => ({
                initiative,
            }));
        },
        // Removes data relations
        removeInitiativeDataRelations(path, fnRelation) {
            let initiative = get().initiative;
            Object.values(_get(initiative, path))
                .filter(fnRelation)
                .forEach(item => _unset(initiative, `${path}[${item.Id}]`));
            set(() => ({
                initiative,
            }));
        },
    },

    // Get initiative and all sub data based on initiative ID
    async populateInitiative(id) {
        if (
            id &&
            id !== '[initiativeId]' &&
            id !== 'new' &&
            get().initiative.Id !== id
        ) {
            // Reset
            get().reset();

            const { data } = await ew.get({
                path: 'initiative/initiative',
                params: { id, expand: true },
            });

            // Update state
            set(state => ({
                initiative: {
                    ...state.initiative,
                    ...data,
                },
            }));

            // Update auth
            _updateAuth();
        }
    },

    // Get all funder tags for given report
    async populateFunderTags() {
        const utilities = get().utilities;

        const funders = _uniqBy(utilities.funders.getAll(), x => x.Account__c);

        let funderTags = {};
        for (const funder of funders) {
            const { data } = await ew.get({
                path: 'tag/all-funder-tags',
                params: { id: funder.Account__c },
            });

            funderTags = { ...funderTags, ...data };
        }

        get().utilities.updateInitiative({ _funderTags: funderTags });

        // Update auth
        _updateAuth();
    },

    reset() {
        set(() => ({
            initiative: {
                _activities: {},
                _activityEmployees: {},
                _activityGoals: {},
                _regulations: {},
                _activitySuccessMetrics: {},
                _collaborators: {},
                _contents: {},
                _employeesFunded: {},
                _employeeFundedFundings: {},
                _funders: {},
                _fundingRecipients: {},
                _fundings: {},
                _goals: {},
                _kpis: {},
                _locations: {},
                _milestoneActivities: {},
                _milestoneKpis: {},
                _milestones: {},
                _reportDetailEntries: {},
                _reportDetailGoals: {},
                _reportDetails: {},
                _reports: {},
                _resultActivities: {},
                _resultGoals: {},
                _results: {},
                _tags: {},
                _teamMembers: {},
                _updateContents: {},
                _updates: {},
                _funderTags: null,
            },
        }));
    },
}));

export { useInitiativeDataStore };
