// React
import React from 'react';

// Packages
import cc from 'classcat';

// Utilities
import { asId } from 'utilities';
import { useLabels } from 'utilities/hooks';

// Components
import UpdateButton from 'components/updateButton';

const SectionContainerComponent = ({ children, item }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className={cc(['p-24 md:p-32 rounded-8 bg-white'])}>
            <h4
                id={asId(item?.baseUrl)}
                className="flex flex-col mb-48 offset-anchor sm:items-center sm:justify-between sm:flex-row">
                <div className="flex flex-col order-2 mt-12 mr-24 sm:order-1 sm:mt-0">
                    <span className="t-h4">{label(item?.title)}</span>
                    <span className="t-caption">
                        {label(`${item?.title}Strapline`)}
                    </span>
                </div>
                <span className="self-start order-1 sm:order-2">
                    <UpdateButton
                        {...{
                            context: 'report',
                            baseUrl: item?.baseUrl,
                            rules: item?.permissions?.update,
                        }}
                    />
                </span>
            </h4>

            <div className="flex flex-col space-y-24">{children}</div>
        </div>
    );
};

SectionContainerComponent.propTypes = {};

SectionContainerComponent.defaultProps = {};

export default SectionContainerComponent;
