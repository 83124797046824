// React
import React, { useState, useRef, useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import Button from 'components/button';

// Icons
import { FiChevronDown, FiCheck } from 'react-icons/fi';

const DropdownComponent = ({
    label,
    items,
    theme,
    className,
    variant,
    showActive,
}) => {
    // ///////////////////
    // REFS
    // ///////////////////

    // Ref: Mobile navigation wrapper
    const dropdownRef = useRef(null);

    // ///////////////////
    // STATE
    // ///////////////////

    const [open, setOpen] = useState(false);

    // ///////////////////
    // METHODS
    // ///////////////////

    // Function: Event wrapper for closing outside click
    function handleClick(event) {
        if (
            open &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setOpen(false);
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const styles = {
        blue: 'text-blue-100',
        teal: 'text-teal-100',
        coral: 'text-coral-100',
        amber: 'text-amber-100',
    };

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: Catch outside clicks and close
    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [open]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex">
            <div className="relative flex flex-col">
                <Button
                    theme={theme}
                    variant={variant}
                    className="self-start"
                    action={() => setOpen(!open)}
                    icon={FiChevronDown}
                    iconPosition="right"
                    iconType="stroke">
                    {label}
                </Button>
                <AnimatePresence>
                    {open && (
                        <motion.div
                            ref={dropdownRef}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{
                                y: 5,
                                opacity: 0,
                                transition: { duration: 0.2 },
                            }}
                            style={{
                                top: 'calc(100% + 8px)',
                                minWidth: 'calc(100% + 50px',
                            }}
                            onClick={() =>
                                setTimeout(() => setOpen(!open), 100)
                            }
                            className={cc([
                                'absolute flex flex-col self-start gap-12 px-16 pt-20 pb-16 bg-white shadow-md z-tooltip t-caption rounded-8',
                                styles[theme],
                            ])}>
                            {items.map((item, index) => (
                                <div
                                    className="relative flex items-center cursor-pointer"
                                    key={index}
                                    onClick={() => item.action()}>
                                    {showActive && item.active && (
                                        <FiCheck className="absolute" />
                                    )}
                                    <span
                                        className={cc([
                                            { 'ml-24': showActive },
                                        ])}>
                                        {item.label}
                                    </span>
                                </div>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

DropdownComponent.propTypes = {
    /* Adds additional class names */
    className: t.string,
    /* Button variant */
    variant: t.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
    /* Theme */
    theme: t.oneOf(['blue', 'teal', 'coral', 'amber']),
};

DropdownComponent.defaultProps = {
    variant: 'primary',
    theme: 'blue',
    className: '',
};

export default DropdownComponent;
