// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';
import Pill from 'components/pill';

const CollaboratorComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            <CardContentSection
                {...{
                    theme,
                }}>
                <p>{item?.Description__c}</p>
            </CardContentSection>

            {/* Description */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Collaborator__c.Input_Type__c'
                    ),
                }}>
                <div className="flex gap-8">
                    {item?.Input_Type__c?.split(';').map(x => (
                        <Pill key={x}>{x}</Pill>
                    ))}
                </div>
            </CardContentSection>

            {/* Start date / End date  */}
            {item?.Start_Date__c && item?.End_Date__c && (
                <div className="flex !mt-24 space-x-16">
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Collaborator__c.Start_Date__c'
                            ),
                        }}>
                        <p>{item?.Start_Date__c ? item?.Start_Date__c : '-'}</p>
                    </CardContentSectionBox>

                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Collaborator__c.End_Date__c'
                            ),
                        }}>
                        <p>{item?.End_Date__c ? item?.End_Date__c : '-'}</p>
                    </CardContentSectionBox>
                </div>
            )}
        </div>
    );
};

CollaboratorComponent.propTypes = {
    item: t.object,
};

CollaboratorComponent.defaultProps = {};

export default CollaboratorComponent;
