// React
import React from 'react';

// Packages

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';
import { useInitiativeDataStore } from 'utilities/store';

// Components
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';
import ReportRow from 'components/reportRow';

const AboutComponent = ({ initiative, report, utilities }) => {
    // ///////////////////
    // STORES
    // ///////////////////
    const { utilities: liveUtilities } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Name } = initiative;
    const funding = utilities.fundings.get(report.Funding_Report__c);
    const funders = utilities.funders.getFromFundingId(funding?.Id);
    const recipients = utilities.fundingRecipients.getFromFundingId(
        funding?.Id
    );

    // Get livereport for status
    const liveReport = liveUtilities.reports.get(report.Id);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <FullWidthWrapper paddingX={false}>
            <div className="flex justify-center w-full bg-white page-px">
                <div className="max-w-[900px] w-full flex-col flex py-64 text-blue-100">
                    <p className="mb-8 text-coral-200">
                        {format.fundingRecipentNames(recipients)}
                    </p>
                    <h1 className="mb-48 t-h1">{Name}</h1>
                    <h4 className="mb-16 t-h4">
                        {label('ReportViewAboutThisReport')}
                    </h4>
                    <div className="border-2 border-blue-20 rounded-8 ">
                        <ReportRow {...{ item: liveReport }}>
                            <p className="inline-block mb-12 t-caption-bold t-blue-60 sm:hidden">
                                {getValueLabel(
                                    'Initiative_Report__c.Report_Type__c',
                                    report?.Report_Type__c
                                )}{' '}
                                {label('TitleReport')}
                            </p>
                            <div className="flex flex-col">
                                <span className="text-blue-60 t-footnote">
                                    {funding?.Application_Id__c}
                                </span>
                                <span className="text-blue-100 t-h5">
                                    {format.funderNamesFull(funders)}
                                </span>
                            </div>
                            <div>
                                <p className="t-caption text-blue-60">
                                    {getValueLabel(
                                        'Initiative_Funding__c.Funding_Type__c',
                                        funding?.Funding_Type__c
                                    )}
                                </p>
                                <h5 className="t-sh5 text-blue-80">
                                    {format.currency(
                                        funding?.Amount_Funded__c,
                                        funding?.CurrencyIsoCode
                                    )}
                                </h5>
                            </div>
                        </ReportRow>
                    </div>
                </div>
            </div>
        </FullWidthWrapper>
    );
};

AboutComponent.propTypes = {};

AboutComponent.defaultProps = {};

export default AboutComponent;
