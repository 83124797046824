// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components
import CardContentSection from 'components/cardContentSection';

const ResultsCustomOutputComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}
        </div>
    );
};

ResultsCustomOutputComponent.propTypes = {
    item: t.object,
};

ResultsCustomOutputComponent.defaultProps = {};

export default ResultsCustomOutputComponent;
