// Packages
import { useEffect, useState } from 'react';

// Packages
import useSWR from 'swr';

// Utilities
import { useContext } from 'utilities/hooks';
import { useInitiativeDataStore, useLayoutStore } from 'utilities/store';
import { simpleJson } from 'utilities/api';

const useInitiativeAndReportData = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities, CONSTANTS } = useInitiativeDataStore();
    const {
        setReportVersion: setReportVersionInLayoutStore,
    } = useLayoutStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [reportVersion, setReportVersion] = useState(null);

    const [legacyMode, setLegacyMode] = useState(false);

    const [initiativeData, setInitiativeData] = useState();
    const [reportData, setReportData] = useState(
        utilities.reports.get(REPORT_ID)
    );

    // ///////////////////
    // DATA
    // ///////////////////

    // Fetcher stand by for json data if report version number matches
    const { data: initiativeFromJson } = useSWR(
        ['1.0', '1.1', '4.0', '5.0'].includes(reportVersion)
            ? utilities.reports.get(REPORT_ID).Exported_Report_URL__c
            : null,
        simpleJson.fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: React to new report ids and set version accordingly
    useEffect(() => {
        if (utilities.reports.get(REPORT_ID)?.Id) {
            setReportVersion(
                utilities.reports.get(REPORT_ID)?.Report_Viewer_Version__c ??
                    'default'
            );
            setReportVersionInLayoutStore(
                utilities.reports.get(REPORT_ID)?.Report_Viewer_Version__c ??
                    'default'
            );
        }
    }, [REPORT_ID, utilities.reports.get(REPORT_ID)?.Report_Viewer_Version__c]);

    // Effect: Populate data
    useEffect(() => {
        if (initiativeFromJson) {
            setInitiativeData({ ...initiativeFromJson, ___FROM_JSON: true });
            setReportData(initiativeFromJson?._reports[REPORT_ID]);
        } else {
            setInitiativeData({
                ...utilities.initiative.get(),
                ___FROM_JSON: false,
            });
            setReportData(utilities.reports.get(REPORT_ID));
        }
    }, [initiativeFromJson, utilities.initiative.get()]);

    // Effect: Is 5.0 in legacy mode (4.0)
    useEffect(() => {
        if (reportVersion === '4.0') {
            setLegacyMode(true);
        } else {
            setLegacyMode(false);
        }
    }, [reportVersion]);

    // ///////////////////
    // RETURN
    // ///////////////////

    return {
        legacyMode,
        reportVersion,
        initiativeData,
        reportData,
    };
};

export default useInitiativeAndReportData;
