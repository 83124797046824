// React
import React, { useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

// Utilities
import {
    useContext,
    useLabels,
    useFormat,
    useTagging,
    useInitiativeAndReportData,
} from 'utilities/hooks';

// Components
import BorderBox from 'components/borderBox';
import Pill from 'components/pill';

const ReportUpdateComponent = ({
    utilities,
    metrics,
    reflection,
    status,
    setHasUpdate,
    hasUpdate,
    setUpdates,
    updates,
    CONSTANTS,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { legacyMode } = useInitiativeAndReportData();
    const { REPORT_ID } = useContext();
    const { label, getValueLabel, tagLabel } = useLabels();
    const format = useFormat();
    const tagging = useTagging({
        relationKey: 'Initiative_Activity_Success_Metric__c',
    });

    // ///////////////////
    // DATA
    // ///////////////////

    // Default itemKeys
    const itemKeys = {
        reflection: reflection?.itemKey ?? 'Id',
    };

    const currentReport = utilities?.reports.get(REPORT_ID) ?? {};

    // Current report details (reflections)
    const reportDetails =
        utilities?.reportDetails.getFromReportId(REPORT_ID) ?? [];

    // ///////////////////
    // DATA - STATUS
    // ///////////////////

    const pillThemes = {
        Complete: 'teal',
        'Ahead of time': 'teal',
        'On time': 'blue',
        'Not started': 'amber',
        Delayed: 'coral',
    };

    // Get current status based on relationKey if any
    const currentStatus = status
        ? reportDetails.find(
              reportDetail =>
                  reportDetail[status.relationKey] === status.item?.Id
          )
        : null;

    const currentStatusCount = [
        'Status__c',
        'Completion_Date__c',
        'Status_Comments__c',
    ].reduce((acc, key) => (acc = currentStatus?.[key] ? acc + 1 : acc), 0);

    // ///////////////////
    // DATA - METRICS
    // ///////////////////

    // Sort array itemsArray of objects by value Priority__c in the order 'Primary', 'Secondary', 'Additional'
    const priorityOrder = [
        CONSTANTS?.ACTIVITY_SUCCESS_METRICS.PRIORITY_PRIMARY,
        CONSTANTS?.ACTIVITY_SUCCESS_METRICS.PRIORITY_SECONDARY,
        CONSTANTS?.ACTIVITY_SUCCESS_METRICS.PRIORITY_ADDITIONAL,
    ];

    // Get current metrics
    let currentMetrics =
        utilities?.activitySuccessMetrics.getFromActivityId(
            metrics?.item?.Id
        ) ?? [];

    if (legacyMode) {
        currentMetrics.filter(metric => {
            if (currentReport.Report_Period_Start_Date__c) {
                return dayjs(
                    metric.Current_Status_Last_Modified_Date__c
                ).isBetween(
                    currentReport.Report_Period_Start_Date__c,
                    dayjs(currentReport.Due_Date__c).add(60, 'day'),
                    'day',
                    '[]'
                );
            }
            return true;
        });
    }

    currentMetrics.sort((a, b) => {
        const aIndex = priorityOrder.indexOf(a.Priority__c);
        const bIndex = priorityOrder.indexOf(b.Priority__c);
        if (aIndex === -1 && bIndex === -1) {
            return 0;
        } else if (aIndex === -1) {
            return 1;
        } else if (bIndex === -1) {
            return -1;
        } else {
            return aIndex - bIndex;
        }
    });

    // ///////////////////
    // DATA - REFLECTION
    // ///////////////////

    // Get current reflection based on relationKey if any
    const currentReflection = reflection
        ? reportDetails.find(
              reportDetail =>
                  reportDetail[reflection.relationKey] ===
                  reflection.item[itemKeys.reflection]
          )
        : null;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        setHasUpdate(
            [
                currentReflection?.Description__c,
                currentStatusCount > 0,
                legacyMode ? currentMetrics.length > 0 : false,
            ].some(x => x)
        );
    }, [currentReflection, currentMetrics, currentStatusCount]);

    useEffect(() => {
        setUpdates({
            reflection: currentReflection?.Description__c,
            metrics: currentMetrics,
            status: currentStatus,
        });
    }, [hasUpdate]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return hasUpdate ? (
        <div className="flex flex-col justify-between">
            {/* LEARNINGS AND STATUS */}
            {(reflection || status) && (
                <div className="mb-32">
                    <h5 className="mb-16 t-h5 text-blue-80">
                        {label('ReportViewUpdatesStatusOrLearningsThisReport')}
                    </h5>
                    {updates.reflection || updates.status ? (
                        <div className="flex flex-col">
                            {/* Status Status */}
                            {updates.status?.Status__c && (
                                <UpdateTextBox
                                    {...{
                                        title: label(
                                            'ReportViewUpdatesStatusStatus'
                                        ),
                                    }}>
                                    <Pill
                                        theme={
                                            pillThemes[
                                                updates.status?.Status__c
                                            ]
                                        }>
                                        {updates.status?.Status__c}
                                    </Pill>
                                </UpdateTextBox>
                            )}

                            {/* Status Date */}
                            {updates.status?.Completion_Date__c && (
                                <UpdateTextBox
                                    {...{
                                        title: label(
                                            'ReportViewUpdatesStatusCompletionDate'
                                        ),
                                    }}>
                                    {format.date(
                                        updates.status?.Completion_Date__c
                                    )}
                                </UpdateTextBox>
                            )}

                            {/* Status Comment */}
                            {updates.status?.Status_Comments__c && (
                                <UpdateTextBox
                                    {...{
                                        title: label(
                                            'ReportViewUpdatesStatusComments'
                                        ),
                                    }}>
                                    {updates.status?.Status_Comments__c}
                                </UpdateTextBox>
                            )}

                            {/* Learnings */}
                            {updates.reflection && (
                                <UpdateTextBox
                                    {...{
                                        title: label(
                                            'ReportViewUpdatesLearnings'
                                        ),
                                    }}>
                                    {updates.reflection}
                                </UpdateTextBox>
                            )}
                        </div>
                    ) : (
                        <BorderBox
                            theme="blue"
                            background
                            dark
                            className="items-center">
                            <p className="text-blue-60">
                                {label(
                                    'ReportViewUpdatesHasNoStatusOrLearnings'
                                )}
                            </p>
                        </BorderBox>
                    )}
                </div>
            )}

            {/* METRICS */}
            {metrics && (
                <div>
                    <h5 className="mb-16 t-h5 text-blue-80">
                        {label('ReportViewMetricsForThisReport')}
                    </h5>
                    {updates.metrics?.length > 0 ? (
                        <div className="grid grid-cols-8 gap-16">
                            {updates.metrics?.map(metric => {
                                // Get audience or not
                                const isAudience =
                                    metric?.Priority__c &&
                                    metric?.Type__c ===
                                        CONSTANTS.ACTIVITY_SUCCESS_METRICS
                                            .PEOPLE;

                                // Get title
                                const title = isAudience
                                    ? tagLabel(
                                          tagging.getCurrentTags(metric)[0]
                                              ?.Tag__r
                                      ) ?? ''
                                    : metric?.Name;

                                // Get post title
                                let postTitle = null;
                                if (isAudience) {
                                    const gender = metric?.Gender__c
                                        ? `${getValueLabel(
                                              'Initiative_Activity_Success_Metric__c.Gender__c',
                                              metric?.Gender__c
                                          )}${
                                              metric?.Gender_Other__c
                                                  ? ` (${metric?.Gender_Other__c})`
                                                  : ''
                                          } ${label('MetricGender')}`
                                        : null;
                                    postTitle = [
                                        gender,
                                        metric?.Geographical_Reach__c
                                            ? `${
                                                  metric?.Geographical_Reach__c
                                              } ${label('MetricGeoReach')}`
                                            : null,
                                    ].filter(x => x);
                                } else {
                                    postTitle = [
                                        metric?.CurrencyIsoCode ?? null,
                                    ].filter(x => x);
                                }

                                return (
                                    <div
                                        key={metric.Id}
                                        className="flex items-center justify-between col-span-8 px-24 py-12 bg-blue-20 rounded-8">
                                        <div className="flex flex-col">
                                            <p className="text-blue-60 t-sh6">
                                                {isAudience
                                                    ? getValueLabel(
                                                          'Initiative_Activity_Success_Metric__c.Priority__c',
                                                          metric?.Priority__c
                                                      )
                                                    : label(
                                                          'Metric_Additional'
                                                      )}
                                            </p>

                                            <p className="mt-16 mb-8 t-sh7 text-blue-60">
                                                {label('MetricsTargetGroups')}
                                            </p>
                                            <h6 className="text-blue-100 t-h6">
                                                {console.log('title', title)}
                                                {title}
                                            </h6>

                                            {/* Post title */}
                                            {postTitle && postTitle.length > 0 && (
                                                <>
                                                    <p className="mt-16 mb-4 t-sh7 text-blue-60">
                                                        {label(
                                                            'MetricsOtherInformation'
                                                        )}
                                                    </p>
                                                    <div className="flex flex-col space-y-8 text-blue-100 t-footnote">
                                                        {postTitle?.map(
                                                            (x, index) => (
                                                                <span
                                                                    key={index}>
                                                                    - {x}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {/* Result */}
                                        <div className="flex flex-col text-right">
                                            <h3 className="text-blue-100 t-h3">
                                                {format.number(
                                                    metric?.Current_Status__c
                                                )}{' '}
                                                {!metric.Target__c &&
                                                metric.Target__c !==
                                                    0 ? null : (
                                                    <span className="text-blue-40">
                                                        /{' '}
                                                        {format.number(
                                                            metric.Target__c
                                                        )}
                                                    </span>
                                                )}
                                            </h3>
                                            <p className="t-sh7 text-blue-60">
                                                {label('MetricsReachedSoFar')}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <BorderBox
                            theme="blue"
                            background
                            dark
                            className="items-center">
                            <p className="text-blue-60">
                                {label('ReportViewHasNoUpdates')}
                            </p>
                        </BorderBox>
                    )}
                </div>
            )}
        </div>
    ) : (
        <BorderBox
            theme="blue"
            background
            dark
            className="flex items-center mt-12">
            <p className="text-blue-60">{label('ReportViewHasNoUpdates')}</p>
        </BorderBox>
    );
};

const UpdateTextBox = ({ title, children, className }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'p-16 pb-32 text-blue-100 bg-white border-2 border-blue-20 rounded-4 t-small',
                className,
            ])}>
            {title && (
                <h6 className="mb-4 text-blue-80 t-caption-bold">{title}</h6>
            )}
            {children}
        </div>
    );
};

ReportUpdateComponent.propTypes = {
    reflection: t.shape({
        item: t.object.isRequired,
        itemKey: t.string,
        relationKey: t.string.isRequired,
    }),
    metrics: t.shape({
        item: t.object.isRequired,
    }),
    status: t.shape({
        item: t.object.isRequired,
        relationKey: t.string.isRequired,
        type: t.string.isRequired,
    }),
};

ReportUpdateComponent.defaultProps = {
    reflection: null,
    tagging: null,
    status: null,
    metrics: null,
};

export default ReportUpdateComponent;
