// React
import React, { useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

// Utilities
import {
    useLayoutStore,
    useInitiativeDataStore,
    useWizardNavigationStore,
} from 'utilities/store';
import { useResponsive, useLabels } from 'utilities/hooks';

// Components
import Button from 'components/button';
import AsideNavigation from 'components/_wizard/asideNavigation';
import AsideMetadata from 'components/_wizard/asideMetadata';
import AsideHelp from 'components/_wizard/asideHelp';
import BottomNavigation from 'components/_wizard/bottomNavigation';

// Icons
import { FiAlignLeft, FiChevronsLeft } from 'react-icons/fi';

// Images
import FGLogo from 'assets/images/fg-logo.svg';

const WizardLayoutComponent = ({ children, layoutSettings }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        rightWizardNavigationActive,
        setRightWizardNavigationActive,
        leftWizardNavigationActive,
        setLeftWizardNavigationActive,
    } = useLayoutStore();
    const { onUrlOrContextChange, items } = useWizardNavigationStore();
    const { utilities } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const router = useRouter();
    const { label } = useLabels();
    const bp = useResponsive();

    // ///////////////////
    // DATA
    // ///////////////////

    const smallBps = ['2xs', 'xs', 'sm', 'md'];
    const largeBps = ['lg', 'xl', '2xl', '3xl'];
    const {
        hideAside,
        hideNavigation,
        hideHelp,
        hideBottomExit,
        hideBottomBack,
        hideBottomFeedback,
        bottomCtaLabel,
        bottomExitMode,
        bottomCtaMode,
    } = layoutSettings;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Listen to breakpoint and toggle menu accordingly
    useEffect(() => {
        if (smallBps.includes(bp)) {
            setLeftWizardNavigationActive(false);
            setRightWizardNavigationActive(false);
        }
        if (largeBps.includes(bp)) {
            setLeftWizardNavigationActive(true);
            setRightWizardNavigationActive(false);
        }
    }, [bp]);

    // Handle path change
    useEffect(() => {
        setTimeout(() => {
            const splitRoute = router.pathname.split('/');
            onUrlOrContextChange(splitRoute[splitRoute.length - 1]);
        }, 1);
    }, [router.asPath, items]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Aside wrapper */}
            <div
                style={{ willChange: 'transform' }}
                className={cc([
                    'fixed flex-col h-screen w-[300px] max-w-full lg:w-1/5 flex bg-white transition-medium bottom-0 z-aside border-r border-teal-10 page-px lg:px-16 xl:page-px',
                    {
                        'pointer-events-auto': leftWizardNavigationActive,
                        '-translate-x-full pointer-events-none':
                            !leftWizardNavigationActive ||
                            !utilities.initiative.get().Id,
                        '!hidden': hideAside,
                    },
                ])}>
                {/* Logo */}
                <div className="flex items-center h-header print:hidden">
                    <Link href="/">
                        <FGLogo className="text-blue-300 fill-current" />
                    </Link>
                </div>

                {/* Aside navigation wrapper */}
                <div className="flex py-8">
                    <Button
                        variant="tertiary"
                        theme="teal"
                        icon={FiChevronsLeft}
                        iconPosition="center"
                        iconType="stroke"
                        className="!px-8 self-start my-8 lg:hidden"
                        action={() => {
                            setLeftWizardNavigationActive(false);
                        }}
                    />
                </div>

                {/* Aside content */}
                <div className="grow mt-20 overflow-y-auto scrolling-touch">
                    <AsideMetadata />
                    {!hideNavigation && <AsideNavigation />}
                </div>
            </div>

            {/* Help wrapper */}
            <div
                style={{ willChange: 'transform' }}
                className={cc([
                    'fixed flex-col right-0 bottom-0 top-0 w-[400px] max-w-full lg:w-1/4 flex bg-amber-10 3lg:w-[400px] transition-medium z-help page-px',
                    {
                        'pointer-events-auto': rightWizardNavigationActive,
                        'translate-x-full pointer-events-none': !rightWizardNavigationActive,
                        '!hidden': hideHelp,
                    },
                ])}>
                <div className="flex items-center justify-end h-header">
                    <Button
                        variant="tertiary"
                        action={() => {
                            setRightWizardNavigationActive(false);
                        }}>
                        {label('ButtonCloseHelp')}
                    </Button>
                </div>
                {/* Help content */}
                <div className="mt-32 overflow-y-auto scrolling-touch">
                    <AsideHelp />
                </div>
            </div>

            {/* Button navigation */}
            <div className="fixed left-0 right-0 flex items-center py-8 bg-white pointer-events-none transition-default lg:bg-transparent h-header lg:justify-end page-px z-below-aside">
                {!hideAside && (
                    <Button
                        title={label('ButtonEdit')}
                        variant="tertiary"
                        theme="teal"
                        icon={FiAlignLeft}
                        iconPosition="center"
                        iconType="stroke"
                        className="!px-8 pointer-events-auto lg:hidden"
                        action={() => {
                            if (smallBps.includes(bp)) {
                                setLeftWizardNavigationActive(true);
                                setRightWizardNavigationActive(false);
                            }
                            if (largeBps.includes(bp)) {
                                setLeftWizardNavigationActive(true);
                            }
                        }}
                    />
                )}

                {!hideHelp && (
                    <Button
                        className="ml-auto pointer-events-auto "
                        variant="secondary"
                        theme="teal"
                        action={() => {
                            if (smallBps.includes(bp)) {
                                setLeftWizardNavigationActive(false);
                                setRightWizardNavigationActive(true);
                            }
                            if (largeBps.includes(bp)) {
                                setRightWizardNavigationActive(true);
                            }
                        }}>
                        {label('ButtonHelp')}
                    </Button>
                )}
            </div>

            {/* White background for this layout */}
            <div className="fixed inset-0 bg-white z-below"></div>

            {/* Content wrapper for aligning */}
            <div
                style={{ willChange: 'left, right' }}
                className={cc([
                    'absolute flex justify-center transition-medium left-0 right-0 mb-24 top-48 lg:top-0 sm:top-56',
                    {
                        'lg:left-[20%] 3lg:left-[300px]':
                            leftWizardNavigationActive &&
                            !hideAside &&
                            utilities.initiative.get().Id,
                        'lg:right-[25%] 3lg:right-[400px]':
                            rightWizardNavigationActive && !hideHelp,
                    },
                ])}>
                {/* Content */}
                <div className="w-full transition-medium max-w-[600px] page-mx mt-80 pb-64 lg:pb-96">
                    {children}
                </div>

                {/* Bottom bar wrapper for aligning */}
                <div
                    style={{ willChange: 'left, right' }}
                    className={cc([
                        'fixed bottom-0 left-0 right-0 h-48 lg:h-64 flex justify-center transition-medium z-below-aside',
                        {
                            'lg:left-[20%] 3lg:left-[300px]':
                                leftWizardNavigationActive &&
                                !hideAside &&
                                utilities.initiative.get().Id,
                            'lg:right-[25%] 3lg:right-[400px]':
                                rightWizardNavigationActive && !hideHelp,
                        },
                    ])}>
                    <BottomNavigation
                        {...{
                            hideBottomExit,
                            hideBottomBack,
                            hideBottomFeedback,
                            bottomCtaLabel,
                            bottomExitMode,
                            bottomCtaMode,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

WizardLayoutComponent.propTypes = {
    pageProps: t.object,
};

WizardLayoutComponent.defaultProps = {
    pageProps: {},
};

export default WizardLayoutComponent;
