// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import ActivityTags from '../_common/activityTags';
import CardContentSection from 'components/cardContentSection';

const ActivityUpgradeComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();
    const { object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Things_To_Do_Description__c,
        Activity_Start_Date__c,
        Activity_End_Date__c,
    } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Things_To_Do_Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Things_To_Do_Description__c}</p>
                </CardContentSection>
            )}

            {/* Start date / End date  */}
            {Activity_Start_Date__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Dates_Label'
                        ),
                    }}>
                    <p>
                        {format.date(Activity_Start_Date__c)}
                        {Activity_End_Date__c
                            ? ` - ${format.date(Activity_End_Date__c)}`
                            : ''}
                    </p>
                </CardContentSection>
            )}

            {/* Details */}
            <ActivityUpgradeDetails {...{ item, theme }} />

            {/* Tags */}
            <ActivityTags {...{ item, theme }} />
        </div>
    );
};

const ActivityUpgradeDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { dataSet, object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const country = item?.Initiative_Location__c
        ? dataSet('Countries').find(
              c => c.value === item?.Initiative_Location__c
          )?.label ?? null
        : null;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Location */}
            {country && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Initiative_Location__c'
                        ),
                    }}>
                    <p>{country}</p>
                </CardContentSection>
            )}
        </>
    );
};

ActivityUpgradeComponent.propTypes = {
    item: t.object,
};

ActivityUpgradeComponent.defaultProps = {};

export { ActivityUpgradeDetails };
export default ActivityUpgradeComponent;
