// React
import React from 'react';

// Utilities

// Components

const SectionHeaderComponent = ({ section }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className="page-px flex h-[300px] w-full bg-coral-20 flex-col justify-center text-blue-100 items-center mt-64 mb-64"
            style={{
                pageBreakBefore: 'always',
            }}>
            <div className="w-full flex h-[200px] space-x-128 justify-between">
                <div className="flex flex-col justify-center">
                    <h2 className="t-h2">{section.title}</h2>
                    <p
                        className="t-body"
                        dangerouslySetInnerHTML={{
                            __html: section.byline,
                        }}
                    />
                </div>
                <div className="relative flex items-center w-[200px] shrink-0">
                    {section.image && (
                        <img
                            src={section.image}
                            className="max-h-[130px] object-cover"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

SectionHeaderComponent.propTypes = {};

SectionHeaderComponent.defaultProps = {};

export default SectionHeaderComponent;
