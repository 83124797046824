// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import ActivityTags from '../_common/activityTags';
import CardContentSection from 'components/cardContentSection';

const ActivityTeamEducationComponent = ({
    item,
    theme = 'teal',
    utilities,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();
    const { object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const employees = utilities.activityEmployees.getFromActivityId(item?.Id);

    const {
        Things_To_Do_Description__c,
        Activity_Start_Date__c,
        Activity_End_Date__c,
    } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Things_To_Do_Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Things_To_Do_Description__c}</p>
                </CardContentSection>
            )}

            {/* Start date / End date  */}
            {Activity_Start_Date__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Dates_Label'
                        ),
                    }}>
                    <p>
                        {format.date(Activity_Start_Date__c)}
                        {Activity_End_Date__c
                            ? ` - ${format.date(Activity_End_Date__c)}`
                            : ''}
                    </p>
                </CardContentSection>
            )}

            {/* Details */}
            <ActivityTeamEducationDetails {...{ item, theme, employees }} />

            {/* Tags */}
            <ActivityTags {...{ item, theme }} />
        </div>
    );
};

const ActivityTeamEducationDetails = ({ item, theme, employees }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Employees */}
            {employees.length > 0 && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Employee__c'
                        ),
                    }}>
                    <ul>
                        {employees.map(x => (
                            <li key={x.Id}>
                                -{' '}
                                {`${x?.Initiative_Employee_Funded__r?.Full_Name__c} - ${x?.Initiative_Employee_Funded__r?.Job_Title__c}`}
                            </li>
                        ))}
                    </ul>
                </CardContentSection>
            )}
        </>
    );
};

ActivityTeamEducationComponent.propTypes = {
    item: t.object,
};

ActivityTeamEducationComponent.defaultProps = {};

export { ActivityTeamEducationDetails };
export default ActivityTeamEducationComponent;
