// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

// Components
import ChartWrapper from '../chartWrapper';

const TableChartComponent = ({ data, annotation, ...rest }) => (
    <ChartWrapper
        {...{
            maxHeight: true,
            chart: <Chart {...{ data, annotation }} />,
            ...rest,
        }}
    />
);

TableChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(t.array.isRequired),
    annotation: t.shape({ columns: t.array.isRequired }).isRequired,
};

TableChartComponent.defaultProps = {};

const Chart = ({ data, annotation }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <table className="w-full mb-12">
            <thead>
                <tr>
                    {annotation?.columns?.map((x, index) => (
                        <th
                            key={index}
                            className="px-16 py-8 text-left border-b-2 border-blue-10 text-blue-60 t-footnote-bold">
                            {x}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((x, index) => (
                            <td
                                key={index}
                                className={cc([
                                    'px-16 pt-16 pb-[12px] text-left border-b-2 border-blue-10 text-blue-80',
                                    {
                                        't-caption-bold': index > 0,
                                        't-caption': index === 0,
                                    },
                                ])}>
                                {x}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableChartComponent;
