// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components
import EmptyState from 'components/_report/templates/report_5_0_pdf/emptyState';
import { BaseCard } from 'components/_report/templates/report_5_0_pdf/_cards';

const CollectionComponent = ({ collection, card }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return collection?.items.length > 0 ? (
        collection?.items.map(item => (
            <BaseCard
                key={item.Id}
                {...{
                    preTitle: card.preTitle && card.preTitle(item),
                    title: card.title && card.title(item),
                    postTitle: card.postTitle && card.postTitle(item),
                    components: card.components(item),
                }}
            />
        ))
    ) : (
        <EmptyState
            {...{
                text: collection?.emptyLabel,
            }}
        />
    );
};

CollectionComponent.propTypes = {
    collection: t.shape({
        items: t.array.isRequired,
        emptyLabel: t.string,
    }),
    card: t.shape({
        preTitle: t.func,
        title: t.func.isRequired,
        postTitle: t.func,
        components: t.func.isRequired,
    }),
};

CollectionComponent.defaultProps = {
    collection: {
        items: [],
    },
    card: {
        preTitle: '',
        title: '',
        postTitle: '',
        components: null,
    },
};

export default CollectionComponent;
