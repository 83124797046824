function getYears() {
    const years = [];
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 10; // 10 years back
    let endYear = currentYear + 10; // 10 years in the future
    while (startYear <= endYear) {
        const year = startYear++;
        years.push({
            label: `${year}`,
            value: `${year}-01-01`,
        });
    }
    return years;
}

export default getYears;
