// Utilities
import { useInitiativeDataStore } from 'utilities/store';
import { useContext, useInitiativeAndReportData } from 'utilities/hooks';
import dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const useHasReportUpdates = ({ utilities = null }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities: initiativeUtilities } = useInitiativeDataStore();
    const { legacyMode } = useInitiativeAndReportData();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();

    // ///////////////////
    // VARIABLES
    // ///////////////////

    const localUtilities = utilities ? utilities : initiativeUtilities;

    // ///////////////////
    // METHODS
    // ///////////////////

    function metrics(baseUrl) {
        // What type of update (default or custom)
        const [type] = reportUpdatesDictionary[baseUrl] ?? [false];

        // Default
        if (type === 'default') {
            // Keys
            const [, items] = reportUpdatesDictionary[baseUrl];

            // Current report
            const currentReport = localUtilities.reports.get(REPORT_ID);

            // Metrics for all activities
            const currentMetrics = items()
                .map(item =>
                    localUtilities.activitySuccessMetrics.getFromActivityId(
                        item.Id
                    )
                )
                .flat();

            // Current report details
            const currentMetricUpdates = currentMetrics.filter(metric => {
                if (currentReport.Report_Period_Start_Date__c) {
                    return dayjs(
                        metric.Current_Status_Last_Modified_Date__c
                    ).isBetween(
                        currentReport.Report_Period_Start_Date__c,
                        dayjs(currentReport.Due_Date__c).add(60, 'day'),
                        'day',
                        '[]'
                    );
                }
                return true;
            });

            return currentMetricUpdates;
        }

        return [];
    }

    function status(baseUrl) {
        // What type of update (default or custom)
        const [type] = reportUpdatesDictionary[baseUrl] ?? [false];

        // Default
        if (type === 'default') {
            // Keys
            const [
                ,
                items,
                itemRelationKey,
                itemKey = 'Id',
            ] = reportUpdatesDictionary[baseUrl];

            // Current report details
            const currentReportDetails = localUtilities.reportDetails
                .getFromReportId(REPORT_ID)
                .filter(detail =>
                    items()
                        .map(x => x[itemKey])
                        .includes(detail[itemRelationKey])
                );

            // Get Status
            const statusBulk = currentReportDetails.filter(detail =>
                items()
                    .map(x => x[itemKey])
                    .includes(detail[itemRelationKey])
            );

            let status = 0;
            for (let status of statusBulk) {
                const count = [
                    'Status__c',
                    'Completion_Date__c',
                    'Status_Comments__c',
                ].reduce((acc, key) => (acc = status[key] ? acc + 1 : acc), 0);
                status = status + count;
            }

            // Any updates?
            return status;
        }

        return 0;
    }

    function reflections(baseUrl) {
        // What type of update (default or custom)
        const [type] = reportUpdatesDictionary[baseUrl] ?? [false];

        // Default
        if (type === 'default') {
            // Keys
            const [
                ,
                items,
                itemRelationKey,
                itemKey = 'Id',
            ] = reportUpdatesDictionary[baseUrl];

            // Current report details
            const currentReportDetails = localUtilities.reportDetails
                .getFromReportId(REPORT_ID)
                .filter(detail =>
                    items()
                        .map(x => x[itemKey])
                        .includes(detail[itemRelationKey])
                );

            return currentReportDetails.filter(
                detail => detail?.Description__c
            );
        }

        return [];
    }

    function hasUpdates(baseUrl) {
        // What type of update (default or custom)
        const [type] = reportUpdatesDictionary[baseUrl] ?? [false];

        // Default
        if (type === 'default') {
            // Any updates?
            return [
                legacyMode ? metrics(baseUrl).length > 0 : false,
                reflections(baseUrl).length > 0,
                status(baseUrl) > 0,
            ].some(x => x);
        }

        // Custom type
        if (type === 'custom') {
            const [, customCheck] = reportUpdatesDictionary[baseUrl];
            return customCheck();
        }

        return false;
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const reportUpdatesDictionary = {
        activities: [
            'default',
            localUtilities.activities.getAll,
            'Initiative_Activity__c',
        ],
        collaborators: [
            'default',
            localUtilities.collaborators.getAllRelevant,
            'Initiative_Collaborator__c',
        ],
        'employees-funded': [
            'default',
            localUtilities.employeesFunded.getAll,
            'Initiative_Employee_Funded__c',
        ],
        'end-of-grant-reflections': [
            'custom',
            () =>
                [
                    localUtilities.reports.get(REPORT_ID)?.Project_Purpose__c,
                    localUtilities.reports.get(REPORT_ID)
                        ?.Progress_Towards_Grant_Area_Themes__c,
                    localUtilities.reports.get(REPORT_ID)?.Important_Results__c,
                ].some(x => x),
        ],
        facilities: [
            'default',
            localUtilities.locations.getAll,
            'Initiative_Location__c',
        ],
        funding: [
            'default',
            localUtilities.fundings.getAll,
            'Initiative_Funding__c',
        ],
        goals: [
            'default',
            localUtilities.goals.getTypeCustom,
            'Initiative_Goal__c',
        ],
        regulations: [
            'default',
            localUtilities.regulations.getAll,
            'Initiative_Activity_Regulation__c',
        ],
        'report-summary': [
            'custom',
            () =>
                [
                    localUtilities.reports.get(REPORT_ID)?.Initiative_Status__c,
                    localUtilities.reports.get(REPORT_ID)
                        ?.Summary_Of_Activities__c,
                    localUtilities.reports.get(REPORT_ID)
                        ?.Summary_Of_Challenges_And_Learnings__c,
                ].some(x => x),
        ],
        results: [
            'default',
            localUtilities.results.getAll,
            'Initiative_Result__c',
        ],
        risks: [
            'custom',
            () =>
                [
                    localUtilities.reports.get(REPORT_ID)
                        ?.Summary_Of_Initiative_Risks__c,
                ].some(x => x),
        ],
    };

    // ///////////////////
    // RETURN
    // ///////////////////

    return {
        hasUpdates,
        status,
        reflections,
        metrics,
    };
};

export default useHasReportUpdates;
