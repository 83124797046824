import create from 'zustand';

const useLayoutStore = create(set => ({
    // ///////////////////
    // MAIN
    // ///////////////////

    mainNavigationWide: false,
    setMainNavigationWide: toggle =>
        set(() => ({
            mainNavigationWide: toggle,
        })),

    mainNavigationActive: false,
    setMainNavigationActive: toggle =>
        set(() => ({
            mainNavigationActive: toggle,
        })),

    // ///////////////////
    // SECONDARY
    // ///////////////////

    secondaryNavigationActive: false,
    setSecondaryNavigationActive: toggle =>
        set(() => ({
            secondaryNavigationActive: toggle,
        })),

    secondaryNavigationAnimationOnly: false,
    setSecondaryNavigationAnimationOnly: toggle =>
        set(() => ({
            secondaryNavigationAnimationOnly: toggle,
        })),

    // ///////////////////
    // WIZARD
    // ///////////////////

    // Right menu
    rightWizardNavigationActive: false,
    setRightWizardNavigationActive: toggle =>
        set(() => ({
            rightWizardNavigationActive: toggle,
        })),

    // Left menu
    leftWizardNavigationActive: false,
    setLeftWizardNavigationActive: toggle =>
        set(() => ({
            leftWizardNavigationActive: toggle,
        })),

    // ///////////////////
    // REPORT VERSION
    // ///////////////////

    reportVersion: null,
    setReportVersion: version => set(() => ({ reportVersion: version })),
}));

export { useLayoutStore };
