// Packages
import create from 'zustand';
import _uniqBy from 'lodash.uniqby';

// Utilities
import ew from 'utilities/api/elseware';

const useDynamicLabelsDataStore = create((set, get) => ({
    currentInitiative: null,
    funderTagLabels: {},

    // Get all funder tag labels for a given initiative
    async populateTagLabels(initiative) {
        if (initiative.Id && get().currentInitiative?.Id !== initiative.Id) {
            // Get funders accounts
            const funders = _uniqBy(
                Object.values(initiative._funders),
                x => x.Account__c
            );

            let funderTagLabels = {};
            for (const funder of funders) {
                const { data } = await ew.get({
                    path: 'label/funder-tag-labels',
                    params: { id: funder.Account__c },
                });

                funderTagLabels = { ...funderTagLabels, ...data };
            }

            set(() => ({
                currentInitiative: initiative,
                funderTagLabels,
            }));
        }
    },

    reset() {
        set(() => ({
            funderTagLabels: {},
        }));
    },
}));

export { useDynamicLabelsDataStore };
