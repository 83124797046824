// Packages
import create from 'zustand';
import _groupBy from 'lodash.groupby';
import _uniqBy from 'lodash.uniqby';
import dayjs from 'dayjs';

// Utilities
import ew from 'utilities/api/elseware';
import { authStore } from 'utilities/store';

// Configuration
import { CONSTANTS } from 'utilities/configuration';

function _updateAuth() {
    const { getState } = authStore;
    getState().updateUserTimeout();
}

const useDashboardStore = create((set, get) => ({
    CONSTANTS: { ...CONSTANTS },

    // Filters
    filterObject: {},
    taggingFilters: [],
    categoryFilters: [],
    initiativeDateFilter: null,
    fundingDateFilter: null,
    filterCount: 0,
    updateFilters(data = {}) {
        const taggingFilters =
            data.Tagging?.length > 0
                ? data.Tagging.map(x => x.selectValue)
                : [];

        const categoryFilters =
            data.Categories?.length > 0
                ? data.Categories.map(x => x.selectValue)
                : [];

        const initiativeDateFilter = data.InitiativeDates?.from
            ? data.InitiativeDates
            : null;

        const fundingDateFilter = data.FundingDates?.from
            ? data.FundingDates
            : null;

        set(() => ({
            taggingFilters,
            categoryFilters,
            initiativeDateFilter,
            fundingDateFilter,
            filterCount:
                taggingFilters.length +
                categoryFilters.length +
                (initiativeDateFilter ? 1 : 0) +
                (fundingDateFilter ? 1 : 0),
            filterObject: data,
        }));
    },

    filteredInitiatives() {
        // Filters present
        const taggingFilters = get().taggingFilters;
        const categoryFilters = get().categoryFilters;
        const initiativeDateFilter = get().initiativeDateFilter;
        const fundingDateFilter = get().fundingDateFilter;

        // Base for filtering
        let filteredInitiatives = get().initiatives;

        // Tagging filters
        if (taggingFilters.length > 0) {
            filteredInitiatives = filteredInitiatives.filter(
                initiative =>
                    initiative._tags.filter(tag =>
                        taggingFilters.includes(tag.Tag__c)
                    ).length > 0
            );
        }

        // Category filters
        if (categoryFilters.length > 0) {
            filteredInitiatives = filteredInitiatives.filter(initiative =>
                categoryFilters.includes(initiative.Category__c)
            );
        }

        // Initiative date filters
        if (initiativeDateFilter) {
            // From and To
            if (initiativeDateFilter.from && initiativeDateFilter.to) {
                filteredInitiatives = filteredInitiatives
                    .filter(
                        initiative =>
                            initiative.Grant_Start_Date__c &&
                            initiative.Grant_End_Date__c
                    )
                    .filter(
                        initiative =>
                            dayjs(initiative.Grant_Start_Date__c).isAfter(
                                dayjs(initiativeDateFilter.from)
                            ) &&
                            dayjs(initiative.Grant_End_Date__c).isBefore(
                                dayjs(initiativeDateFilter.to)
                            )
                    );
            }

            // From
            if (initiativeDateFilter.from) {
                filteredInitiatives = filteredInitiatives
                    .filter(initiative => initiative.Grant_Start_Date__c)
                    .filter(initiative =>
                        dayjs(initiative.Grant_Start_Date__c).isAfter(
                            dayjs(initiativeDateFilter.from)
                        )
                    );
            }
        }

        // Funding date filters
        if (fundingDateFilter) {
            filteredInitiatives = filteredInitiatives.filter(
                initiative => initiative._fundings.length > 0
            );

            // From and To
            if (fundingDateFilter.from && fundingDateFilter.to) {
                filteredInitiatives = filteredInitiatives.filter(initiative =>
                    initiative._fundings
                        .map(funding => ({
                            from: dayjs(funding.Grant_Start_Date__c),
                            to: dayjs(funding.Grant_End_Date__c),
                        }))
                        .some(
                            period =>
                                period.from.isAfter(
                                    dayjs(fundingDateFilter.from)
                                ) &&
                                period.to.isBefore(dayjs(fundingDateFilter.to))
                        )
                );
            }

            // From only
            if (fundingDateFilter.from) {
                filteredInitiatives = filteredInitiatives.filter(initiative =>
                    initiative._fundings
                        .map(funding => ({
                            from: dayjs(funding.Grant_Start_Date__c),
                        }))
                        .some(period =>
                            period.from.isAfter(dayjs(fundingDateFilter.from))
                        )
                );
            }
        }

        return filteredInitiatives;
    },

    // Getters
    getInitiatives({ funderAccount = null, granteeAccount = null }) {
        let initiatives = get().filteredInitiatives();

        // Funder account
        if (funderAccount) {
            initiatives = initiatives.filter(initiative =>
                initiative._funders
                    .map(funder => funder.Account__c)
                    .includes(funderAccount)
            );
        }

        // Grantee account
        if (granteeAccount) {
            initiatives = initiatives.filter(initiative =>
                initiative._fundingRecipients
                    .map(recipient => recipient.Account__c)
                    .includes(granteeAccount)
            );
        }

        return initiatives;
    },
    getFunders({
        funderAccount = null,
        granteeAccount = null,
        funderType = null,
    }) {
        const initiatives = get().getInitiatives({
            funderAccount,
            granteeAccount,
        });
        let funders = initiatives.map(initiative => initiative._funders).flat();

        // Funder type
        if (funderType) {
            funders = funders.filter(funder => funder.Type__c === funderType);
        }

        return funders;
    },
    getFundingRecipients({
        funderAccount = null,
        granteeAccount = null,
        granteeType = null,
    }) {
        const initiatives = get().getInitiatives({
            funderAccount,
            granteeAccount,
        });
        let fundingRecipients = initiatives
            .map(initiative => initiative._fundingRecipients)
            .flat();

        // Recipient type
        if (granteeType) {
            fundingRecipients = fundingRecipients.filter(
                recipient => recipient.Relationship__c === granteeType
            );
        }

        return fundingRecipients;
    },
    getCollaborators({
        funderAccount = null,
        granteeAccount = null,
        collaboratorType = null,
    }) {
        const initiatives = get().getInitiatives({
            funderAccount,
            granteeAccount,
        });
        let collaborators = initiatives
            .map(initiative => initiative._collaborators)
            .flat();

        // Collaborator type
        if (collaboratorType) {
            collaborators = collaborators.filter(
                collaborator => collaborator.Type__c === collaboratorType
            );
        }

        return collaborators;
    },
    getFundings({ ids }) {
        return get()
            .getInitiatives({})
            .map(initiative => initiative._fundings)
            .flat()
            .filter(initiativeFunding => ids.includes(initiativeFunding.Id));
    },

    // Utilities
    utilities: {
        initiatives: {
            getAll({ funderAccount, granteeAccount }) {
                return get().getInitiatives({
                    funderAccount,
                    granteeAccount,
                });
            },
            getByCategories({ categories, funderAccount, granteeAccount }) {
                const initiatives = get().getInitiatives({
                    funderAccount,
                    granteeAccount,
                });
                return categories.reduce(
                    (acc, category) => ({
                        ...acc,
                        [category.value]: {
                            items: initiatives.filter(
                                initiative =>
                                    initiative.Category__c === category.value
                            ),
                            category,
                        },
                    }),
                    {}
                );
            },
            getBySdgs({ sdgs, funderAccount, granteeAccount }) {
                const initiatives = get().getInitiatives({
                    funderAccount,
                    granteeAccount,
                });
                return sdgs.reduce(
                    (acc, sdg) => ({
                        ...acc,
                        [sdg.value]: {
                            items: initiatives.filter(initiative =>
                                initiative.Problem_Effect__c?.split(
                                    ';'
                                ).includes(sdg.value)
                            ),
                            sdg,
                        },
                    }),
                    {}
                );
            },
            getByCountry({ countries, funderAccount, granteeAccount }) {
                const initiatives = get().getInitiatives({
                    funderAccount,
                    granteeAccount,
                });
                return countries.reduce(
                    (acc, country) => ({
                        ...acc,
                        [country.label]: {
                            items: initiatives.filter(initiative =>
                                initiative.Where_Is_Problem__c?.split(
                                    ';'
                                ).includes(country.value)
                            ),
                            country,
                        },
                    }),
                    {}
                );
            },
            getByStrategicThemes({ themes, funderAccount, granteeAccount }) {
                const initiatives = get().getInitiatives({
                    funderAccount,
                    granteeAccount,
                });
                return themes.reduce(
                    (acc, theme) => ({
                        ...acc,
                        [theme.Tag_Name__c]: {
                            items: initiatives.filter(initiative => {
                                return initiative._strategicThemes
                                    .map(sTheme => sTheme.Tag__c)
                                    .includes(theme.Id);
                            }),
                            theme,
                        },
                    }),
                    {}
                );
            },
            getByFundingRecipientTypeMainApplicant({
                granteeAccount,
                funderAccount,
            }) {
                const fundingRecipients = get().getFundingRecipients({
                    funderAccount,
                    granteeAccount,
                    granteeType: CONSTANTS.FUNDERS.MAIN_APPLICANTS,
                });

                return fundingRecipients.reduce(
                    (acc, fundingRecipient) => ({
                        ...acc,
                        [fundingRecipient.Account__r?.Name]: [
                            ...new Set([
                                ...(acc[fundingRecipient.Account__r?.Name] ??
                                    []),
                                fundingRecipient.Initiative_Funding__r
                                    ?.Initiative__c,
                            ]),
                        ],
                    }),
                    {}
                );
            },
            getByFunderTypeLeadFunder({ granteeAccount, funderAccount }) {
                const funders = get().getFunders({
                    granteeAccount,
                    funderAccount,
                    funderType: CONSTANTS.FUNDERS.LEAD_FUNDER,
                });

                return funders.reduce(
                    (acc, funder) => ({
                        ...acc,
                        [funder.Account__r?.Name]: [
                            ...new Set([
                                ...(acc[funder.Account__r?.Name] ?? []),
                                funder.Initiative__c,
                            ]),
                        ],
                    }),
                    {}
                );
            },
            getByCollaboratorTypeAdditionalCollaborator({
                funderAccount,
                granteeAccount,
            }) {
                const collaborators = get().getCollaborators({
                    funderAccount,
                    granteeAccount,
                    collaboratorType:
                        CONSTANTS.COLLABORATORS.ADDITIONAL_COLLABORATORS,
                });

                return collaborators.reduce(
                    (acc, collaborator) => ({
                        ...acc,
                        [collaborator.Account__r?.Name]: [
                            ...new Set([
                                ...(acc[collaborator.Account__r?.Name] ?? []),
                                collaborator.Initiative__c,
                            ]),
                        ],
                    }),
                    {}
                );
            },
        },
        fundings: {
            funderView: {
                getCurrencyCodes({ funderAccount, funderType }) {
                    const funders = get().getFunders({
                        funderAccount,
                        funderType,
                    });

                    return [
                        ...new Set(
                            funders
                                .filter(
                                    funder =>
                                        funder.Initiative_Funding__r
                                            ?.Amount_Funded__c &&
                                        funder.Initiative_Funding__r
                                            ?.Amount_Funded__c > 0
                                )
                                .map(
                                    funder =>
                                        funder.Initiative_Funding__r
                                            ?.CurrencyIsoCode
                                )
                        ),
                    ];
                },
                getByCurrencyAndFundingType({
                    currencyCode,
                    fundingTypes,
                    funderAccount,
                    funderType,
                }) {
                    // Get all funders with currency code
                    const fundingIds = get()
                        .getFunders({
                            funderAccount,
                            funderType,
                        })
                        .filter(
                            funder =>
                                funder?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .map(funder => funder.Initiative_Funding__c);

                    // Get fundings based on funders
                    const fundings = get().getFundings({ ids: fundingIds });

                    let returnFundings = {};
                    const unassignedFundingAmount = fundings
                        .filter(funding => !funding.Funding_Type__c)
                        .reduce(
                            (acc, funding) =>
                                (acc = acc + funding.Amount_Funded__c),
                            0
                        );

                    for (const fundingType of fundingTypes) {
                        let amount =
                            returnFundings[fundingType.label]?.amount ?? 0;
                        for (const funding of fundings) {
                            if (funding.Funding_Type__c === fundingType.value) {
                                amount = amount + funding.Amount_Funded__c;
                            }
                        }

                        returnFundings = {
                            ...returnFundings,
                            [fundingType.label]: {
                                ...returnFundings[fundingType.label],
                                amount,
                            },
                            Unassigned: { amount: unassignedFundingAmount },
                        };
                    }

                    return returnFundings;
                },
                getByCurrencyAndMainRecipient({
                    currencyCode,
                    funderAccount,
                    funderType,
                }) {
                    // Get all funders with currency code
                    const fundingIds = get()
                        .getFunders({
                            funderAccount,
                            funderType,
                        })
                        .filter(
                            funder =>
                                funder?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .map(funder => funder.Initiative_Funding__c);

                    // Get fundings based on funders
                    const fundings = get().getFundings({ ids: fundingIds });

                    // Get all funding recipients with currency code that also includes a funding
                    // id from funders funding ids
                    const fundingRecipients = get()
                        .getFundingRecipients({
                            funderAccount,
                            granteeType:
                                CONSTANTS.FUNDING_RECIPIENTS.MAIN_APPLICANTS,
                        })
                        .filter(
                            fundingRecipient =>
                                fundingRecipient?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .filter(fundingRecipient =>
                            fundingIds.includes(
                                fundingRecipient.Initiative_Funding__c
                            )
                        );

                    // Get funding ids from main recipient funding recipients
                    const fundingRecipientFundingIds = fundingRecipients.map(
                        fundingRecipient =>
                            fundingRecipient.Initiative_Funding__c
                    );

                    // Count fundings amount where funding id is not existing in the fundingRecipientFundingIds
                    const fundingsWithUnassignedFundingRecipientAmount = fundings
                        .filter(
                            funding =>
                                !fundingRecipientFundingIds.includes(funding.Id)
                        )
                        .reduce(
                            (acc, funding) =>
                                (acc = acc + funding.Amount_Funded__c),
                            0
                        );

                    return fundingRecipients.reduce((acc, fundingRecipient) => {
                        // Get data for accumulation
                        const recipient = fundingRecipient?.Account__r?.Name;
                        const previousAmount = acc[recipient]?.amount ?? 0;
                        const amount =
                            fundingRecipient?.Initiative_Funding__r
                                ?.Amount_Funded__c;

                        // Accumulate
                        return {
                            ...acc,
                            [recipient]: {
                                ...acc[recipient],
                                amount: previousAmount + amount,
                            },
                            ...(fundingsWithUnassignedFundingRecipientAmount > 0
                                ? {
                                      Unassigned: {
                                          amount: fundingsWithUnassignedFundingRecipientAmount,
                                      },
                                  }
                                : {}),
                        };
                    }, {});
                },
                getByCurrencyAndFundingRanges({
                    currencyCode,
                    funderAccount,
                    funderType,
                    fundingRanges,
                }) {
                    // Get all funders with currency code
                    const fundingIds = get()
                        .getFunders({
                            funderAccount,
                            funderType,
                        })
                        .filter(
                            funder =>
                                funder?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .map(funder => funder.Initiative_Funding__c);

                    // Get fundings based on funders
                    const fundings = get().getFundings({ ids: fundingIds });

                    // Reduce to initiative to get full amount
                    const initiativeFundings = fundings.reduce(
                        (acc, funding) => ({
                            ...acc,
                            [funding.Initiative__c]: {
                                ...(acc[funding.Initiative__c] ?? {}),
                                Amount_Funded__c:
                                    acc[funding.Initiative__c]
                                        ?.Amount_Funded__c ??
                                    0 + funding.Amount_Funded__c,
                                Category__c: funding.Initiative__r.Category__c,
                            },
                        }),
                        {}
                    );

                    let returnFundingRanges = {};
                    for (const fundingRange of fundingRanges) {
                        for (const funding of Object.values(
                            initiativeFundings
                        )) {
                            // Get amount for funder
                            const amount = funding.Amount_Funded__c ?? 1500000;

                            // Check if inside current range
                            const insideRange = [
                                amount >= fundingRange.min,
                                amount <= fundingRange.max,
                            ].every(x => x);

                            returnFundingRanges = {
                                ...returnFundingRanges,
                                [fundingRange.label]: [
                                    ...(returnFundingRanges[
                                        fundingRange.label
                                    ] ?? []),
                                    ...(insideRange ? [funding] : []),
                                ],
                            };
                        }
                    }

                    return returnFundingRanges;
                },
            },
            granteeView: {
                getCurrencyCodes({ granteeAccount, granteeType }) {
                    const fundingRecipients = get().getFundingRecipients({
                        granteeAccount,
                        granteeType: granteeType,
                    });

                    return [
                        ...new Set(
                            fundingRecipients
                                .filter(
                                    grantee =>
                                        grantee.Initiative_Funding__r
                                            ?.Amount_Funded__c &&
                                        grantee.Initiative_Funding__r
                                            ?.Amount_Funded__c > 0
                                )
                                .map(
                                    grantee =>
                                        grantee.Initiative_Funding__r
                                            ?.CurrencyIsoCode
                                )
                        ),
                    ];
                },
                getByCurrencyAndFundingType({
                    currencyCode,
                    fundingTypes,
                    granteeAccount,
                    granteeType,
                }) {
                    // Get all grantess with currency code
                    const fundingIds = get()
                        .getFundingRecipients({
                            granteeAccount,
                            granteeType,
                        })
                        .filter(
                            grantee =>
                                grantee?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .filter(
                            grantee => grantee.Account__c === granteeAccount
                        )
                        .map(grantee => grantee.Initiative_Funding__c);

                    // Get fundings based on funders
                    const fundings = get().getFundings({ ids: fundingIds });

                    let returnFundings = {};
                    const unassignedFundingAmount = fundings
                        .filter(funding => !funding.Funding_Type__c)
                        .reduce(
                            (acc, funding) =>
                                (acc = acc + funding.Amount_Funded__c),
                            0
                        );

                    for (const fundingType of fundingTypes) {
                        let amount =
                            returnFundings[fundingType.label]?.amount ?? 0;
                        for (const funding of fundings) {
                            if (funding.Funding_Type__c === fundingType.value) {
                                amount = amount + funding.Amount_Funded__c;
                            }
                        }

                        returnFundings = {
                            ...returnFundings,
                            [fundingType.label]: {
                                ...returnFundings[fundingType.label],
                                amount,
                            },
                            Unassigned: { amount: unassignedFundingAmount },
                        };
                    }

                    return returnFundings;
                },
                getByCurrency({ currencyCode, granteeAccount, granteeType }) {
                    // Get all grantess with currency code
                    const fundingRecipients = get()
                        .getFundingRecipients({
                            granteeAccount,
                            granteeType,
                        })
                        .filter(
                            grantee =>
                                grantee?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .filter(
                            grantee => grantee.Account__c === granteeAccount
                        );

                    return fundingRecipients.reduce((acc, fundingRecipient) => {
                        // Get data for accumulation
                        const recipient = fundingRecipient?.Account__r?.Name;
                        const previousAmount = acc[recipient]?.amount ?? 0;
                        const amount =
                            fundingRecipient?.Initiative_Funding__r
                                ?.Amount_Funded__c;

                        // Accumulate
                        return {
                            ...acc,
                            [recipient]: {
                                ...acc[recipient],
                                amount: previousAmount + amount,
                            },
                        };
                    }, {});
                },
                getByCurrencyAndLeadFunder({
                    currencyCode,
                    granteeAccount,
                    granteeType,
                }) {
                    // Get all relevant fundings
                    const relevantFundings = get()
                        .getFundingRecipients({
                            granteeAccount,
                            granteeType,
                        })
                        .filter(
                            grantee =>
                                grantee?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .filter(
                            grantee => grantee.Account__c === granteeAccount
                        )
                        .map(x => x.Initiative_Funding__c);

                    // Get all lead funders with currencyCode
                    const funders = get()
                        .getFunders({
                            granteeAccount,
                        })
                        .filter(
                            funder =>
                                funder.Type__c === CONSTANTS.FUNDERS.LEAD_FUNDER
                        )
                        .filter(
                            funder =>
                                funder?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .filter(x =>
                            relevantFundings.includes(x.Initiative_Funding__c)
                        );

                    return funders.reduce((acc, funder) => {
                        // Get data for accumulation
                        const mainFunder = funder?.Account__r?.Name;
                        const previousAmount = acc[mainFunder]?.amount ?? 0;
                        const amount =
                            funder?.Initiative_Funding__r?.Amount_Funded__c;

                        // Accumulate
                        return {
                            ...acc,
                            [mainFunder]: {
                                ...acc[mainFunder],
                                amount: previousAmount + amount,
                            },
                        };
                    }, {});
                },
                getByCurrencyAndFundingRanges({
                    currencyCode,
                    granteeAccount,
                    granteeType,
                    fundingRanges,
                }) {
                    // Get all grantess with currency code
                    const fundingIds = get()
                        .getFundingRecipients({
                            granteeAccount,
                            granteeType,
                        })
                        .filter(
                            grantee =>
                                grantee?.Initiative_Funding__r
                                    ?.CurrencyIsoCode === currencyCode
                        )
                        .map(grantee => grantee.Initiative_Funding__c);

                    // Get fundings based on funders
                    const fundings = get().getFundings({ ids: fundingIds });

                    // Reduce to initiative to get full amount
                    const initiativeFundings = fundings.reduce(
                        (acc, funding) => ({
                            ...acc,
                            [funding.Initiative__c]: {
                                ...(acc[funding.Initiative__c] ?? {}),
                                Amount_Funded__c:
                                    acc[funding.Initiative__c]
                                        ?.Amount_Funded__c ??
                                    0 + funding.Amount_Funded__c,
                                Category__c: funding.Initiative__r.Category__c,
                            },
                        }),
                        {}
                    );

                    let returnFundingRanges = {};
                    for (const fundingRange of fundingRanges) {
                        for (const funding of Object.values(
                            initiativeFundings
                        )) {
                            // Get amount for funder
                            const amount = funding.Amount_Funded__c ?? 1500000;

                            // Check if inside current range
                            const insideRange = [
                                amount >= fundingRange.min,
                                amount <= fundingRange.max,
                            ].every(x => x);

                            returnFundingRanges = {
                                ...returnFundingRanges,
                                [fundingRange.label]: [
                                    ...(returnFundingRanges[
                                        fundingRange.label
                                    ] ?? []),
                                    ...(insideRange ? [funding] : []),
                                ],
                            };
                        }
                    }

                    return returnFundingRanges;
                },
            },
        },
        organisations: {
            getFundingRecipients({ funderAccount, granteeAccount }) {
                return get().getFundingRecipients({
                    funderAccount,
                    granteeAccount,
                });
            },
            getCollaborators({ funderAccount, granteeAccount }) {
                return get().getCollaborators({
                    funderAccount,
                    granteeAccount,
                });
            },
            getFunders({ funderAccount, granteeAccount }) {
                return get().getFunders({
                    funderAccount,
                    granteeAccount,
                });
            },
        },
    },

    // Initiatives
    initiatives: [],
    initiativesLoaded: false,

    async getAllInitiatives(accountType, accountId) {
        if (!get().initiativesLoaded) {
            // Reset
            get().reset();

            const { data } = await ew.get({
                path: 'initiative/initiatives-dashboard',
                params: { accountType, accountId },
            });

            // Update state
            set(() => ({
                initiatives: data,
                initiativesLoaded: true,
            }));

            // Update auth
            _updateAuth();
        }
    },

    reset() {
        set(() => ({
            initiatives: [],
            initiativesLoaded: false,
        }));
    },
}));

export { useDashboardStore };
