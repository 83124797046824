// Match values from SalesForce (Enums)
const ACCOUNTS = {
    funder: 'Foundation',
    grantee: 'Grantee',
    organisation: 'Organisation',
    super: 'Super',
};

// Match values from SalesForce (Enums)
const ROLES = {
    admin: 'Admin',
    collaborator: 'Collaborator',
    member: 'Member',
    viewer: 'Viewer',
    customerManager: 'Customer Manager',
};

export { ACCOUNTS, ROLES };
