// React
import React from 'react';

// Packages
import cc from 'classcat';

// Utilities

// Components
import ActiveLink from 'components/activeLink';

const MainNavigationItem = ({
    Icon,
    href,
    additionalHrefs,
    label,
    showLabel = true,
}) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ActiveLink
            href={href}
            additionalHrefs={additionalHrefs}
            active="!text-blue-300 !bg-opacity-100">
            <a
                className={cc([
                    'block whitespace-nowrap relative w-full h-32 py-8 text-blue-100 bg-opacity-0 t-caption bg-blue-10 rounded-4 hover:bg-opacity-100 transition-medium',
                ])}>
                <Icon
                    className={cc([
                        'w-16 h-16 transform-gpu absolute -translate-x-1/2 transition-medium',
                        {
                            'left-16 delay-150': showLabel,
                            'left-20': !showLabel,
                        },
                    ])}
                />
                <span
                    className={cc([
                        'absolute left-32 top-8 transition-medium whitespace-nowrap',
                        {
                            'opacity-0 pointer-events-none': !showLabel,
                            'opacity-100 transition-medium delay-200': showLabel,
                        },
                    ])}>
                    {label}
                </span>
            </a>
        </ActiveLink>
    );
};

MainNavigationItem.propTypes = {};

MainNavigationItem.defaultProps = {};

export default MainNavigationItem;
