// React
import { useEffect, useRef } from 'react';

// Packages
import { useRouter } from 'next/router';

const usePreserveScroll = () => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const router = useRouter();

    // ///////////////////
    // REFS
    // ///////////////////

    const scrollPositions = useRef({});
    const isBack = useRef(false);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        router.beforePopState(() => {
            isBack.current = true;
            return true;
        });

        const onRouteChangeStart = () => {
            const url = router.asPath;
            scrollPositions.current[url] = window.scrollY;
        };

        const onRouteChangeComplete = url => {
            if (isBack.current && scrollPositions.current[url]) {
                setTimeout(() => {
                    window.scroll({
                        top: scrollPositions.current[url],
                        behavior: 'auto',
                    });
                }, 300);
            }

            isBack.current = false;
        };

        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, [router]);
};

export default usePreserveScroll;
