// Packages
import { useEffect, useState } from 'react';

// Utilities
import { useUser } from 'utilities/hooks';
import { ACCOUNTS, ROLES } from 'utilities/configuration/permission';

// ///////////////////
// METHODS
// ///////////////////

// Grant permission - base method
function grantPermissionBase(
    rules = [],
    userAccountType,
    userInitiativeTeamRole,
    userAccountId
) {
    // Get simple rules - just a string
    const simpleRules = rules.filter(rule => typeof rule === 'string');

    // Other rules must be held within one object
    const otherRules = rules.filter(rule => typeof rule === 'object')[0] ?? {};

    // Validation of the simpler rules
    const validateSimpleRules = simpleRules.some(rule => {
        const [account, role] = rule.split('.');
        if (account && role) {
            return (
                userAccountType === ACCOUNTS[account] &&
                userInitiativeTeamRole === ROLES[role]
            );
        } else if (account) {
            return userAccountType === ACCOUNTS[account];
        } else return false;
    });

    // Validation of the other rules
    const validateOtherRules = Object.entries(otherRules).every(
        ([ruleName, ruleData]) => {
            switch (ruleName) {
                case 'granteeAdmin':
                    if (
                        userAccountType === ACCOUNTS.grantee &&
                        userInitiativeTeamRole === ROLES.admin
                    ) {
                        return ruleData?.includes(userAccountId);
                    }
                    return true;
                case 'granteeCollaborator':
                    if (
                        userAccountType === ACCOUNTS.grantee &&
                        userInitiativeTeamRole === ROLES.collaborator
                    ) {
                        return ruleData?.includes(userAccountId);
                    }
                    return true;
                case 'funderAdmin':
                    if (
                        userAccountType === ACCOUNTS.funder &&
                        userInitiativeTeamRole === ROLES.admin
                    ) {
                        return ruleData?.includes(userAccountId);
                    }
                    return true;
                case 'funderCollaborator':
                    if (
                        userAccountType === ACCOUNTS.funder &&
                        userInitiativeTeamRole === ROLES.collaborator
                    ) {
                        return ruleData?.includes(userAccountId);
                    }
                    return true;
                case 'granteeAdminInitiatorFundingDelete':
                    if (
                        userAccountType === ACCOUNTS.grantee &&
                        userInitiativeTeamRole === ROLES.admin
                    ) {
                        return !ruleData;
                    }
                    return true;
                default:
                    return false;
            }
        }
    );

    // Are anything validated
    return [validateSimpleRules, validateOtherRules].every(rule => rule);
}

const usePermissions = () => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const {
        getUserAccountType,
        getUserInitiativeTeamRole,
        getUserAccountId,
    } = useUser();

    // ///////////////////
    // STATE
    // ///////////////////

    const [permissionUserUpdated, setPermissionUserUpdated] = useState(0);

    // ///////////////////
    // METHODS
    // ///////////////////

    // Grant permission
    function grantPermission(rules) {
        return grantPermissionBase(
            rules,
            getUserAccountType(),
            getUserInitiativeTeamRole(),
            getUserAccountId()
        );
    }

    // Disable input field if not...
    function enableInputField(rules) {
        return !grantPermission(rules);
    }

    // Require input field if not...
    function requireInputField(rules) {
        return grantPermission(rules);
    }

    // Filter input values based on rules
    // E.g. { 'Lead funder': ['super'] }
    function valuePermissions(valueRules, options) {
        return options.flatMap(option => {
            const rules = valueRules[option.value];
            if (rules) {
                return grantPermission(valueRules[option.value]) ? option : [];
            }
            return option;
        });
    }

    // Button action enabler
    function enableAction(rules, action) {
        return grantPermission(rules) ? action : null;
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        setPermissionUserUpdated(permissionUserUpdated + 1);
    }, [getUserAccountType(), getUserInitiativeTeamRole()]);

    return {
        grantPermission,
        permissionUserUpdated,
        enableInputField,
        requireInputField,
        valuePermissions,
        enableAction,
        ACCOUNTS,
        ROLES,
    };
};

export default usePermissions;

export { grantPermissionBase };
