// React
import React from 'react';

// Packages
import t from 'prop-types';
import { useLabels, useFormat } from 'utilities/hooks';

// Utilities

// Components
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsPatentsComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsPatentsDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsPatentsDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Patent_Id__c, Result_Start_Date__c, Result_End_Date__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Patent_Id__c].some(x => x) && (
                <CardContentPairBox>
                    {Patent_Id__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Patent_Id__c'
                                ),
                                value: Patent_Id__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}

            {/* Start date / End date  */}
            {Result_Start_Date__c && (
                <div className="flex !mt-24 space-x-16">
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_Start_Date__c___Patent'
                            ),
                        }}>
                        <p>{format.date(Result_Start_Date__c)}</p>
                    </CardContentSectionBox>
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_End_Date__c___Patent'
                            ),
                        }}>
                        <p>
                            {Result_End_Date__c
                                ? format.date(Result_End_Date__c)
                                : '-'}
                        </p>
                    </CardContentSectionBox>
                </div>
            )}
        </>
    );
};

ResultsPatentsComponent.propTypes = {
    item: t.object,
};

ResultsPatentsComponent.defaultProps = {};

export { ResultsPatentsDetails };
export default ResultsPatentsComponent;
