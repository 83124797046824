// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import { useRouter } from 'next/router';

// Utilities
import { useLabels, useContext, useElseware } from 'utilities/hooks';
import {
    useWizardNavigationStore,
    useInitiativeDataStore,
} from 'utilities/store';

// Components
import Spinner from 'components/spinner';
import Button from 'components/button';

const BottomNavigationComponent = ({
    hideBottomExit,
    hideBottomBack,
    hideBottomFeedback,
    bottomCtaLabel,
    bottomExitMode,
    bottomCtaMode,
}) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();
    const { nextItemUrl, handleSubmit } = useWizardNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const router = useRouter();
    const { INITIATIVE_ID, REPORT_ID, CONTEXT, CONTEXTS } = useContext();
    const { label } = useLabels();
    const { ewDelete } = useElseware();

    // ///////////////////
    // STATE
    // ///////////////////

    const [loading, setLoading] = useState(false);

    // ///////////////////
    // METHODS
    // ///////////////////

    async function deleteInitiative() {
        await ewDelete('initiative/initiative', utilities.initiative.get().Id);
    }

    async function onHandleContinue() {
        setLoading(true);
        try {
            switch (bottomCtaMode) {
                // Just save
                case 'save':
                    await handleSubmit();

                    setTimeout(() => {
                        setLoading(false);
                    }, 700);
                    break;

                // Save and run exit method
                case 'saveAndExit':
                    await handleSubmit();

                    setTimeout(() => {
                        setLoading(false);
                        onHandleExit();
                    }, 700);
                    break;

                // Default: Save and go to next page in flow
                default:
                    await handleSubmit();

                    // Go to next in flow
                    setTimeout(() => {
                        router.push(
                            nextItemUrl({
                                initiativeId:
                                    INITIATIVE_ID === 'new'
                                        ? utilities.initiative.get().Id
                                        : INITIATIVE_ID,
                                reportId: REPORT_ID,
                            })
                        );
                        setLoading(false);
                    }, 300);
                    break;
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function onHandleExit() {
        switch (bottomExitMode) {
            // Navigate back to previous page
            case 'back':
                router.back();
                break;

            // Default exit method
            default:
                // Try to delete
                if (utilities.initiative.get().Name === '___') {
                    await deleteInitiative();
                    router.push('/');
                }

                // Check if no initiative is created yet
                else if (INITIATIVE_ID === 'new') {
                    router.push('/');
                }
                // If initiative (or report) created, navigate to root of it
                else {
                    switch (CONTEXT) {
                        case CONTEXTS.CREATE:
                            // Delete if nothing have happened with initiative
                            if (utilities.initiative.get().Name === '___') {
                                await deleteInitiative();
                                router.push('/');
                            } else {
                                router.push(`/${INITIATIVE_ID}/overview`);
                            }
                            break;
                        case CONTEXTS.INITIATIVE:
                            router.push(`/${INITIATIVE_ID}/overview`);
                            break;
                        case CONTEXTS.REPORT:
                            router.push(
                                `/${INITIATIVE_ID}/reports/${REPORT_ID}`
                            );
                            break;
                        default:
                            router.push(`/${INITIATIVE_ID}/overview`);
                            break;
                    }
                }
                break;
        }
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="w-full py-4 lg:py-12 transition-slow max-w-[600px] page-mx bg-white flex items-center">
            <div className="flex items-center justify-between w-full space-x-16">
                <Button
                    className={cc([
                        'transition-default',
                        {
                            'opacity-100 pointer-events-auto': !hideBottomExit,
                            'opacity-0 pointer-events-none': hideBottomExit,
                        },
                    ])}
                    theme="coral"
                    variant="secondary"
                    action={onHandleExit}>
                    {label('ButtonExit')}
                </Button>
                <div className="flex space-x-16">
                    <p
                        className={cc([
                            'hidden t-footnote text-coral-60 md:flex transition-default',
                            {
                                'opacity-0': !loading || hideBottomFeedback,
                                'opacity-100': loading,
                            },
                        ])}>
                        {label('MessageSaved')}
                    </p>
                    <Spinner
                        theme="coral"
                        className={cc([
                            'transition-default',
                            {
                                'opacity-0': !loading,
                                'opacity-100': loading,
                            },
                        ])}
                    />
                </div>
                <div className="flex space-x-12">
                    <Button
                        className={cc([
                            'transition-default',
                            {
                                'opacity-100 pointer-events-auto': !hideBottomBack,
                                'opacity-0 pointer-events-none': hideBottomBack,
                            },
                        ])}
                        theme="coral"
                        variant="secondary"
                        disabled={loading}
                        action={router.back}>
                        {label('ButtonBack')}
                    </Button>

                    <Button
                        theme="coral"
                        action={onHandleContinue}
                        disabled={loading || !utilities.initiative.get().Id}>
                        {!utilities.initiative.get().Id ? (
                            <Spinner
                                theme="coral"
                                className="ml-2 -mt-6 transition-default"
                            />
                        ) : (
                            label(bottomCtaLabel)
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};

BottomNavigationComponent.propTypes = {};

BottomNavigationComponent.defaultProps = {};

export default BottomNavigationComponent;
