// React
import React, { useEffect, useState } from 'react';

// Packages
import t from 'prop-types';
import { useRouter } from 'next/router';

// Utilities
import { useLabels, useInitiativeGetters } from 'utilities/hooks';
import {
    useInitiativeDataStore,
    useReportNavigationStore,
} from 'utilities/store';

// Components
import FadeIn from 'components/fadeIn';
import Button from 'components/button';
import HeaderImage from './headerImage';

// Normal
import About from './about';
import SectionHeader from './sectionHeader';
import SectionOverview from './sectionOverview';
import SectionInputs from './sectionInputs';
import SectionActivities from './sectionActivities';
import SectionResults from './sectionResults';
import SectionLearnings from './sectionLearnings';
import SectionLogbook from './sectionLogbook';

const Report_5_0Component = ({
    initiativeData = {},
    reportData = {},
    status,
    fromJson,
}) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();
    const { items } = useReportNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { wizardSection, label } = useLabels();
    const { utilities } = useInitiativeGetters(initiativeData);
    const router = useRouter();

    // ///////////////////
    // STATE
    // ///////////////////

    const [initiative, setInitiative] = useState(null);
    const [report, setReport] = useState(null);
    const [inSync, setInSync] = useState(true);

    // ///////////////////
    // DATA
    // ///////////////////

    // Based on section in config
    const sectionComponents = {
        Overview(props) {
            return <SectionOverview {...props} />;
        },
        Inputs(props) {
            return <SectionInputs {...props} />;
        },
        Activities(props) {
            return <SectionActivities {...props} />;
        },
        Results(props) {
            return <SectionResults {...props} />;
        },
        Learnings(props) {
            return <SectionLearnings {...props} />;
        },
    };

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Initial Load
    useEffect(() => {
        if (initiativeData?.Id && reportData?.Id) {
            setInitiative(initiativeData);
            setReport(reportData);
        }
    }, [initiativeData, status]);

    useEffect(() => {
        if (
            !fromJson &&
            report?.Status__c === CONSTANTS.REPORTS.REPORT_PUBLISHED
        ) {
            setInSync(false);
        }
    }, [report, fromJson]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <FadeIn className="pt-80">
                {initiative && utilities && (
                    <>
                        {!inSync && (
                            <div className="flex items-center justify-center p-16 space-x-16 text-center text-white bg-coral-300">
                                <span>{label('ReportNotInSync')}</span>
                                <Button
                                    title={label('ButtonRefresh')}
                                    variant="secondary"
                                    theme="coral"
                                    iconPosition="center"
                                    iconType="stroke"
                                    className="!px-8"
                                    action={() => {
                                        // Use router to reload page
                                        router.reload();
                                    }}>
                                    {label('ButtonRefresh')}
                                </Button>
                            </div>
                        )}
                        <HeaderImage {...{ initiative }} />
                        <About {...{ initiative, report, utilities }} />
                        <>
                            {items.map(parent => {
                                const section = wizardSection(parent.id);
                                return (
                                    <div key={parent.id}>
                                        {/* Header */}
                                        <SectionHeader
                                            {...{ id: parent.id, section }}
                                        />

                                        {/* Section component */}
                                        {sectionComponents[parent.section]({
                                            utilities,
                                            CONSTANTS,
                                            items: parent.items,
                                        })}
                                    </div>
                                );
                            })}
                            <SectionLogbook {...{ utilities }} />
                        </>
                    </>
                )}
            </FadeIn>
        </>
    );
};

Report_5_0Component.propTypes = {
    initiativeData: t.object,
    reportData: t.object,
};

Report_5_0Component.defaultProps = {
    report: {},
};

export default Report_5_0Component;
