// React
import React, { useEffect, useState, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';

// Utilities
import { useLayoutStore, useInitiativeDataStore } from 'utilities/store';
import {
    useLabels,
    useUser,
    useContext,
    useModalState,
    useElseware,
    useInitiativeAndReportData,
} from 'utilities/hooks';

// Components
import DropdownButton from 'components/dropdownButton';
import Button from 'components/button';
import Permission from 'components/permission';
import ConfirmModal from 'components/_modals/confirmModal';
import PdfModal from 'components/_modals/pdfModal';
import Report_4_0_PDF from 'components/_report/templates/report_4_0_pdf';
import Report_5_0_PDF from 'components/_report/templates/report_5_0_pdf';

// Icons
import { FiUser, FiChevronDown, FiMenu, FiEdit2, FiEye } from 'react-icons/fi';

const HeaderComponent = ({ transparent }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        setMainNavigationActive,
        setSecondaryNavigationActive,
    } = useLayoutStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { getUserLoggedIn, getUserName, userLogout, user } = useUser();
    const {
        INITIATIVE_ID,
        REPORT_ID,
        PORTFOLIO_ID,
        CONTEXT,
        CONTEXTS,
    } = useContext();

    // ///////////////////
    // REFS
    // ///////////////////

    // Ref: Mobile navigation wrapper
    const userNavigationRef = useRef(null);

    // ///////////////////
    // STATE
    // ///////////////////

    const [userNavActive, setUserNavActive] = useState(false);

    // ///////////////////
    // METHODS
    // ///////////////////

    // Function: Event wrapper for closing outside click
    function handleClick(event) {
        if (
            userNavActive &&
            userNavigationRef.current &&
            !userNavigationRef.current.contains(event.target)
        ) {
            setUserNavActive(false);
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: Catch outside clicks and close
    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [userNavActive]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return getUserLoggedIn() && !transparent ? (
        <>
            <div
                className={cc([
                    'animate-fade-in fixed left-0 top-0 flex items-center w-full z-header h-header page-px print:hidden ',
                    {
                        transparent: transparent,
                        'bg-amber-10': !transparent,
                    },
                ])}>
                {/* Mobile navigation toggle */}
                <Button
                    variant="tertiary"
                    theme="blue"
                    icon={FiMenu}
                    iconPosition="center"
                    iconType="stroke"
                    className="!px-8 !ring-0"
                    action={() => {
                        setMainNavigationActive(true);
                        setSecondaryNavigationActive(true);
                    }}
                />

                {/* Call to actions and user details */}
                <div className="flex items-center ml-auto space-x-32">
                    {/* Call to actions */}
                    <div className="flex items-center space-x-12">
                        {INITIATIVE_ID && REPORT_ID && <ReportActions />}
                        {PORTFOLIO_ID && CONTEXT === CONTEXTS.PORTFOLIO && (
                            <PortfolioActions />
                        )}
                    </div>

                    {/* User details */}
                    <button
                        className={cc([
                            'flex ml-auto items-center h-32 px-4 text-blue-300 transition-default bg-white border hover:text-blue-300 hover:border-blue-40 rounded-4 border-blue-20 focus:outline-none active:outline-none whitespace-nowrap',
                            {
                                'border-blue-40': userNavActive,
                            },
                        ])}
                        onClick={() => setUserNavActive(!userNavActive)}>
                        <div className="flex items-center justify-center w-20 h-20 mr-8 text-white rounded-full bg-blue-20">
                            <FiUser className="w-16 h-16 stroke-current" />
                        </div>
                        {getUserName() && (
                            <div className="flex items-center mt-2 t-aside-nav">
                                {getUserName()}
                                <FiChevronDown className="relative ml-2 -top-1 w-14 h-14" />
                            </div>
                        )}
                    </button>
                </div>
            </div>

            {/* User menu */}
            <AnimatePresence>
                {userNavActive && (
                    <motion.div
                        ref={userNavigationRef}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{
                            y: 5,
                            opacity: 0,
                            transition: { duration: 0.2 },
                        }}
                        className="fixed bg-white p-16 rounded-8 t-caption right-0 page-mr flex flex-col space-y-16 min-w-[192px] z-logo print:hidden -mt-8 shadow-md text-blue-100">
                        <div className="flex items-center">
                            <div className="flex items-center justify-center w-32 h-32 mr-12 text-white rounded-full bg-blue-20">
                                <FiUser className="w-24 h-24 stroke-current" />
                            </div>
                            <div className="flex flex-col">
                                <span>{getUserName()}</span>
                                <span className="-mt-2 text-blue-60 t-footnote">
                                    {user.User_Account_Name__c ||
                                        (user.User_Account_Type__c ===
                                            'Super' &&
                                            user.User_Account_Type__c)}
                                </span>
                            </div>
                        </div>
                        <div className="border-t border-blue-20"></div>
                        <button
                            onClick={() => {
                                userLogout();
                            }}
                            className="flex items-center transition-default hover:text-blue-300">
                            {label('LogOut')}
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    ) : null;
};

const ReportActions = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities, CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const router = useRouter();
    const { label } = useLabels();
    const { REPORT_ID } = useContext();
    const { ewUpdate } = useElseware();
    const {
        modalState: completeModalState,
        modalOpen: completeModalOpen,
        modalClose: completeModalClose,
        modalSaving: completeModalSaving,
        modalNotSaving: completeModalNotSaving,
    } = useModalState();
    const {
        modalState: publishModalState,
        modalOpen: publishModalOpen,
        modalClose: publishModalClose,
        modalSaving: publishModalSaving,
        modalNotSaving: publishModalNotSaving,
    } = useModalState();
    const {
        modalState: pdfModalState,
        modalOpen: pdfModalOpen,
        modalClose: pdfModalClose,
    } = useModalState();

    const { reportVersion } = useInitiativeAndReportData();

    // ///////////////////
    // METHODS
    // ///////////////////

    async function reportInProgress() {
        try {
            if (
                currentReport.Status__c !== CONSTANTS.REPORTS.REPORT_IN_PROGRESS
            ) {
                const { data: reportData } = await ewUpdate(
                    'initiative-report/initiative-report',
                    REPORT_ID,
                    {
                        Status__c: CONSTANTS.REPORTS.REPORT_IN_PROGRESS,
                    }
                );

                // Update store
                utilities.updateInitiativeData('_reports', reportData);
            }

            // Change location
            router.push(
                `/report/${
                    utilities.initiative.get()?.Id
                }/report-start/${REPORT_ID}`
            );
        } catch (error) {
            console.warn(error);
        }
    }

    async function completeReport() {
        // Modal save button state
        completeModalSaving();
        try {
            const { data: reportData } = await ewUpdate(
                'initiative-report/initiative-report',
                REPORT_ID,
                {
                    Status__c: CONSTANTS.REPORTS.REPORT_IN_REVIEW,
                }
            );

            // Update store
            utilities.updateInitiativeData('_reports', reportData);

            // Close modal
            completeModalClose();

            // Modal save button state
            completeModalNotSaving();
        } catch (error) {
            // Modal save button state
            completeModalNotSaving();
            console.warn(error);
        }
    }

    async function publishReport() {
        // Modal save button state
        publishModalSaving();
        try {
            const { data: reportData } = await ewUpdate(
                'initiative-report/initiative-report',
                REPORT_ID,
                {
                    Status__c: CONSTANTS.REPORTS.REPORT_PUBLISHED,
                }
            );

            // Update store
            utilities.updateInitiativeData('_reports', reportData);

            // Close modal
            publishModalClose();

            // Modal save button state
            publishModalNotSaving();

            router.reload();
        } catch (error) {
            // Modal save button state
            publishModalNotSaving();
            console.warn(error);
        }
    }

    function getReportLabel() {
        switch (currentReport.Status__c) {
            case CONSTANTS.REPORTS.REPORT_NOT_STARTED:
                return 'ButtonStartReporting';

            case CONSTANTS.REPORTS.REPORT_IN_PROGRESS:
                return 'ButtonContinueReporting';

            case CONSTANTS.REPORTS.REPORT_IN_REVIEW:
                return 'ButtonViewReporting';

            default:
                return 'ButtonContinueReporting';
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const currentReport = utilities.reports.get(REPORT_ID);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <Permission
                {...{
                    rules: ['grantee.admin', 'grantee.collaborator', 'super'],
                    additionalRules: [
                        currentReport.Status__c !==
                            CONSTANTS.REPORTS.REPORT_PUBLISHED,
                    ],
                }}>
                <Button
                    theme="blue"
                    variant="primary"
                    action={reportInProgress}>
                    {label(getReportLabel())}
                </Button>
            </Permission>
            {(reportVersion === 'default' ||
                reportVersion === '4.0' ||
                reportVersion === '5.0') && (
                <Button theme="blue" variant="tertiary" action={pdfModalOpen}>
                    {label('ButtonSaveAsPdf')}
                </Button>
            )}
            <Permission
                {...{
                    rules: ['grantee.admin', 'grantee.collaborator', 'super'],
                    additionalRules: [
                        currentReport.Status__c !==
                            CONSTANTS.REPORTS.REPORT_PUBLISHED,
                    ],
                }}>
                <Button
                    theme="blue"
                    variant="tertiary"
                    disabled={
                        currentReport.Status__c ===
                        CONSTANTS.REPORTS.REPORT_IN_REVIEW
                    }
                    action={completeModalOpen}>
                    {label('ButtonSubmit')}
                </Button>
            </Permission>
            <Permission
                {...{
                    rules: ['funder.admin', 'super'],
                    additionalRules: [
                        currentReport.Status__c ===
                            CONSTANTS.REPORTS.REPORT_IN_REVIEW,
                    ],
                }}>
                <Button
                    theme="blue"
                    variant="primary"
                    disabled={
                        currentReport.Status__c ===
                        CONSTANTS.REPORTS.REPORT_PUBLISHED
                    }
                    action={publishModalOpen}>
                    {label('ButtonPublishReport')}
                </Button>
            </Permission>
            <ConfirmModal
                {...{
                    onCancel() {
                        completeModalClose();
                    },
                    async onSave() {
                        await completeReport();
                    },
                    saveText: label('ButtonSubmit'),
                    title: label('ModalReportCompleteHeader'),
                    ...completeModalState,
                }}>
                <p className="t-preamble">{label('ModalReportCompleteBody')}</p>
            </ConfirmModal>
            <ConfirmModal
                {...{
                    onCancel() {
                        publishModalClose();
                    },
                    async onSave() {
                        await publishReport();
                    },
                    saveText: label('ButtonPublishReport'),
                    title: label('ModalReportPublishHeader'),
                    ...publishModalState,
                }}>
                <p className="t-preamble">{label('ModalReportPublishBody')}</p>
            </ConfirmModal>
            <PdfModal
                {...{
                    ...pdfModalState,
                    title: currentReport.Name,
                    onCancel: pdfModalClose,
                }}>
                {currentReport.Report_Viewer_Version__c === 4.0 ? (
                    <Report_4_0_PDF />
                ) : (
                    <Report_5_0_PDF />
                )}
            </PdfModal>
        </>
    );
};

const PortfolioActions = () => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const router = useRouter();
    const { label } = useLabels();
    const { ACTION, ACTIONS } = useContext();

    // ///////////////////
    // DATA
    // ///////////////////

    const isViewing = ACTION === ACTIONS.VIEW;
    const isEditing = ACTION === ACTIONS.EDIT;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <DropdownButton
            variant="tertiary"
            label={
                <div className="flex space-x-8">
                    {isEditing && (
                        <>
                            <FiEdit2 />{' '}
                            <span>{label('PortfolioStateEditing')}</span>
                        </>
                    )}
                    {isViewing && (
                        <>
                            <FiEye />{' '}
                            <span>{label('PortfolioStateViewing')}</span>
                        </>
                    )}
                </div>
            }
            items={[
                ...(isEditing
                    ? [
                          {
                              label: (
                                  <div className="flex space-x-8">
                                      <FiEye />{' '}
                                      <span>{label('PortfolioStateView')}</span>
                                  </div>
                              ),
                              action() {
                                  router.push(
                                      `/portfolio/${router.query?.portfolioId}/`
                                  );
                              },
                          },
                      ]
                    : []),
                ...(isViewing
                    ? [
                          {
                              label: (
                                  <div className="flex space-x-8">
                                      <FiEdit2 />{' '}
                                      <span>{label('PortfolioStateEdit')}</span>
                                  </div>
                              ),
                              action() {
                                  router.push(
                                      `/portfolio/${router.query?.portfolioId}/edit`
                                  );
                              },
                          },
                      ]
                    : []),
            ]}
        />
    );
};

HeaderComponent.propTypes = {
    transparent: t.bool,
};

HeaderComponent.defaultProps = {
    transparent: false,
};

export default HeaderComponent;
