// React
import { useState, useEffect } from 'react';

// Utilities
import { s3 } from 'utilities/api';

const useSignedUrl = url => {
    // ///////////////////
    // STATE
    // ///////////////////

    const [signedUrl, setSignedUrl] = useState('');

    // ///////////////////
    // METHODS
    // ///////////////////

    async function getSignedUrl(url) {
        const response = await s3.getSignedDownloadUrlForFile(url);
        return response;
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        const fetchSignedUrl = async () => {
            const newUrl = await getSignedUrl(url);
            setSignedUrl(newUrl);
        };
        fetchSignedUrl();
    }, [url]);

    return {
        getSignedUrl,
        signedUrl,
    };
};

export default useSignedUrl;
