// React
import React from 'react';

// Utilities
import { useLabels } from 'utilities/hooks';

import Image from "next/legacy/image";

// Data

// Components
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';
import Button from 'components/button';

const SectionLogbookComponent = ({ utilities }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const initiative = utilities.initiative.get();
    const items = utilities.updates.getTypeLogbookUpdate();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <FullWidthWrapper paddingX={false}>
            <div className="flex flex-col items-center justify-center w-full text-blue-100 bg-white page-px py-80">
                <div className="max-w-[900px] w-full flex space-x-96 xl:space-x-192 justify-between ">
                    <div className="flex flex-col justify-center">
                        <h2 className="t-h2">
                            {items.length}{' '}
                            {label('ReportLogbookUpdatesHeading')}
                        </h2>
                        <p className="t-body">
                            {label('ReportLogbookUpdatesBody')}
                        </p>
                        <Button
                            className="self-start mt-24"
                            action={`/${initiative?.Id}/data/logbook`}
                            variant="secondary">
                            {label('ReportLogbookUpdatesButton')}
                        </Button>
                    </div>
                    <div className="hidden relative md:flex w-[200px] min-h-[200px] h-full shrink-0">
                        <Image
                            layout="fill"
                            objectFit="contain"
                            src="https://www.datocms-assets.com/69268/1666004313-report-logbook.png"
                        />
                    </div>
                </div>
            </div>
        </FullWidthWrapper>
    );
};

SectionLogbookComponent.propTypes = {};

SectionLogbookComponent.defaultProps = {};

export default SectionLogbookComponent;
