// React
import React from 'react';

// Packages

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import BorderBox from 'components/borderBox';

const SummaryContentBox = ({ title, content }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <BorderBox theme="blue" padding dark background className="mb-24">
            <h6 className="mb-12 t-h6">{title}</h6>
            {content ? (
                <p className="t-body">{content}</p>
            ) : (
                <BorderBox theme="blue" background className="items-center">
                    <p className="text-blue-60">
                        {label('ReportViewHasNoUpdates')}
                    </p>
                </BorderBox>
            )}
        </BorderBox>
    );
};

SummaryContentBox.propTypes = {};

SummaryContentBox.defaultProps = {};

export default SummaryContentBox;
