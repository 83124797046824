// React
import React from 'react';

// Packages
import t from 'prop-types';
import { useLabels, useFormat } from 'utilities/hooks';

// Utilities

// Components
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsProductsAndInventionsComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsProductsAndInventionsDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsProductsAndInventionsDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Clinical_Trial_Id__c,
        Clinical_Phase__c,
        Result_Start_Date__c,
        Result_End_Date__c,
        Field_Trial_Type__c,
    } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Clinical_Trial_Id__c, Clinical_Phase__c].some(x => x) && (
                <CardContentPairBox>
                    {Field_Trial_Type__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Field_Trial_Type__c'
                                ),
                                value: Field_Trial_Type__c,
                            }}
                        />
                    )}

                    {Clinical_Trial_Id__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Clinical_Trial_Id__c'
                                ),
                                value: Clinical_Trial_Id__c,
                            }}
                        />
                    )}

                    {Clinical_Phase__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Clinical_Phase__c'
                                ),
                                value: Clinical_Phase__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}

            {/* Start date / End date  */}
            {Result_Start_Date__c && (
                <div className="flex !mt-24 space-x-16">
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_Start_Date__c'
                            ),
                        }}>
                        <p>{format.date(Result_Start_Date__c)}</p>
                    </CardContentSectionBox>
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_End_Date__c'
                            ),
                        }}>
                        <p>
                            {Result_End_Date__c
                                ? format.date(Result_End_Date__c)
                                : '-'}
                        </p>
                    </CardContentSectionBox>
                </div>
            )}
        </>
    );
};

ResultsProductsAndInventionsComponent.propTypes = {
    item: t.object,
};

ResultsProductsAndInventionsComponent.defaultProps = {};

export { ResultsProductsAndInventionsDetails };
export default ResultsProductsAndInventionsComponent;
