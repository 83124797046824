// Packages
import { useRouter } from 'next/router';
import numeral from 'numeral';
require('numeral/locales/da-dk');
require('numeral/locales/en-gb');
import dayjs from 'dayjs';
import _uniqBy from 'lodash.uniqby';
import arrayMoveImmutable from 'array-move-e5';

// Utilities
import { useInitiativeDataStore } from 'utilities/store';
import { useLabels, useUser } from 'utilities/hooks';

const useFormat = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { locale } = useRouter();
    const { getUserAccountId } = useUser();

    // ///////////////////
    // METHODS
    // ///////////////////

    function getNumeralLocale(locale) {
        const localeDict = {
            en: 'en-gb',
            da: 'da-dk',
        };
        return localeDict[locale];
    }

    function number(amount) {
        return amount?.toString().includes('.')
            ? numberWithDecimals(amount)
            : numberNoDecimals(amount);
    }

    function numberWithDecimals(amount) {
        numeral.locale(getNumeralLocale(locale));
        return numeral(amount).format('0,0.00');
    }

    function numberNoDecimals(amount) {
        numeral.locale(getNumeralLocale(locale));
        return numeral(amount).format('0,0');
    }

    function currency(amount, currency) {
        return currency ? `${currency} ${number(amount)}` : number(amount);
    }

    function date(date) {
        return dayjs(date).format('DD.MM.YYYY');
    }

    function dateTime(date) {
        return dayjs(date).format('DD.MM.YYYY, HH:mm');
    }

    function year(date) {
        return dayjs(date).format('YYYY');
    }

    function removeHtml(string) {
        return string?.replace(/<[^>]*>?/gm, '');
    }

    function booleanToWords(bool) {
        return bool ? label('Yes') : label('No');
    }

    function funderNames(funders) {
        let userAccountIndex = null;
        let uniqueFunders = _uniqBy(funders, x => x.Account__c);
        if (getUserAccountId() && uniqueFunders?.length > 0) {
            userAccountIndex = uniqueFunders.findIndex(
                x => x.Account__c === getUserAccountId()
            );
        }
        if (userAccountIndex) {
            uniqueFunders = arrayMoveImmutable(
                uniqueFunders,
                userAccountIndex,
                0
            );
        }
        return uniqueFunders.length > 1
            ? `${uniqueFunders[0]?.Account__r?.Name} + ${
                  uniqueFunders.length - 1
              } ${label('Others')}`
            : uniqueFunders[0]?.Account__r?.Name;
    }

    function funderNamesFull(funders) {
        let userAccountIndex = null;
        let uniqueFunders = _uniqBy(funders, x => x.Account__c);
        if (getUserAccountId() && uniqueFunders?.length > 0) {
            userAccountIndex = uniqueFunders.findIndex(
                x => x.Account__c === getUserAccountId()
            );
        }
        if (userAccountIndex) {
            uniqueFunders = arrayMoveImmutable(
                uniqueFunders,
                userAccountIndex,
                0
            );
        }
        return uniqueFunders.map(funder => funder?.Account__r?.Name).join(', ');
    }

    function fundingRecipentNames(fundingRecipients) {
        let userAccountIndex = null;
        let uniqueRecipients = _uniqBy(fundingRecipients, x => x.Account__c);

        if (getUserAccountId() && uniqueRecipients?.length > 0) {
            userAccountIndex = uniqueRecipients.findIndex(
                x => x.Account__c === getUserAccountId()
            );
        }
        if (userAccountIndex) {
            uniqueRecipients = arrayMoveImmutable(
                uniqueRecipients,
                userAccountIndex,
                0
            );
        }
        return uniqueRecipients.length > 1
            ? `${uniqueRecipients[0]?.Account__r?.Name} + ${
                  uniqueRecipients.length - 1
              } ${label('Others')}`
            : uniqueRecipients[0]?.Account__r?.Name;
    }

    function fundingRecipientNamesFull(fundingRecipients) {
        let userAccountIndex = null;
        let uniqueRecipients = _uniqBy(fundingRecipients, x => x?.Account__c);

        if (getUserAccountId() && uniqueRecipients?.length > 0) {
            userAccountIndex = uniqueRecipients.findIndex(
                x => x.Account__c === getUserAccountId()
            );
        }
        if (userAccountIndex) {
            uniqueRecipients = arrayMoveImmutable(
                uniqueRecipients,
                userAccountIndex,
                0
            );
        }
        return uniqueRecipients.map(x => x?.Account__r?.Name).join(', ');
    }

    return {
        number,
        numberNoDecimals,
        currency,
        date,
        dateTime,
        year,
        removeHtml,
        booleanToWords,
        funderNames,
        funderNamesFull,
        fundingRecipentNames,
        fundingRecipientNamesFull,
    };
};

export default useFormat;
