// Packages
import { useRouter } from 'next/router';

// Data
import { CONTEXTS, ACTIONS } from 'utilities/configuration';

const useContext = () => {
    const { query, asPath, locale } = useRouter();
    const asPathArray = asPath.split('/');

    return {
        // All contexts
        CONTEXTS,
        // Current context
        CONTEXT: asPathArray[1].toLowerCase(),
        // All actions
        ACTIONS,
        // Current action
        ACTION: ACTIONS[query.action?.toUpperCase()],
        // Path (e.g. introduction)
        PATH: asPathArray[asPathArray.length - 1].toLowerCase(),
        // Various
        FOCUS: query?.focus,
        REPORT_ID: query?.reportId,
        INITIATIVE_ID: query?.initiativeId,
        PORTFOLIO_ID: query?.portfolioId,
        DATA_PAGE: query?.dataPage,
        LOCALE: locale,
    };
};

export default useContext;
export { CONTEXTS };
