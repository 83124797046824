// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useFormat, useLabels } from 'utilities/hooks';

// Components
import Pill from 'components/pill';
import ReportType from 'components/reportType';

// Data
import { reportStatusPillTheme } from 'utilities/data/reportThemes';

const ReportRowComponent = ({ item, children }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();
    const format = useFormat();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col justify-between p-16 bg-white md:flex-row rounded-8">
            <div className="flex">
                <ReportType {...{ type: item?.Report_Type__c }} />

                {/* Content */}
                <div className="flex flex-col mr-24 space-y-8 sm:ml-16 sm:justify-center">
                    {children}
                </div>
            </div>

            {/* Dates etc */}
            <div className="flex justify-end mt-24 space-x-12 shrink-0 md:justify-start md:w-1/4 md:space-y-12 md:flex-col md:space-x-0 md:mt-0">
                {item?.Due_Date__c && (
                    <div className="p-8 space-y-4 border-4 sm:w-1/2 md:w-auto border-amber-20 rounded-4">
                        <div className="t-sh7 text-blue-60">
                            {label('ReportManagerCardDeadline')}
                        </div>
                        <div className="text-blue-100 t-caption-bold">
                            <span className="mr-8">
                                {format.date(item?.Due_Date__c)}
                            </span>
                        </div>
                    </div>
                )}
                {item?.Status__c && (
                    <div className="p-8 space-y-4 border-4 sm:w-1/2 md:w-auto border-amber-20 rounded-4">
                        <div className="t-sh7 text-blue-60">
                            {label('ReportManagerCardStatus')}
                        </div>
                        <div className="t-caption-bold">
                            <Pill
                                theme={reportStatusPillTheme[item?.Status__c]}
                                className="mt-8">
                                {getValueLabel(
                                    'Initiative_Report__c.Status__c',
                                    item?.Status__c
                                )}
                            </Pill>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ReportRowComponent.propTypes = {
    initiativeId: t.string,
    reportId: t.string,
    applicationId: t.string,
    type: t.string,
    grantee: t.string,
    headline: t.string,
    leadFunder: t.string,
    status: t.string,
    dueDate: t.string,
};

ReportRowComponent.defaultProps = {};

export default ReportRowComponent;
