import FlagDa from 'assets/images/da.svg';
import FlagGb from 'assets/images/gb.svg';

const languages = {
    da: {
        Flag: FlagDa,
        label: 'MenuLanguageDK',
    },
    en: {
        Flag: FlagGb,
        label: 'MenuLanguageEN',
    },
};

export default languages;
