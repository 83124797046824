// React
import React, { useState, useEffect } from 'react';

// Packages
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { useRouter } from 'next/router';

// Utilities
import { asId, getPermissionRules } from 'utilities';
import {
    useLayoutStore,
    useInitiativeDataStore,
    useReportNavigationStore,
    usePortfolioStore,
    useDashboardStore,
} from 'utilities/store';
import {
    useLabels,
    useContext,
    useHasReportUpdates,
    useFormat,
    useModalState,
    useUser,
    useInitiativeGetters,
    useInitiativeAndReportData,
} from 'utilities/hooks';
import { ACCOUNTS } from 'utilities/configuration/permission';

// Components
import SecondaryNavigationItem from 'components/_layout/secondaryNavigationItem';
import Permission from 'components/permission';
import Button from 'components/button';
import DashboardFilterModal from 'components/_modals/dashboardFilterModal';

// Icons
import {
    FiCompass,
    FiCalendar,
    FiFlag,
    FiFileText,
    FiPackage,
    FiDownload,
    FiShare,
    FiEye,
    FiServer,
    FiChevronDown,
    FiChevronUp,
    FiHeart,
    FiDollarSign,
    FiBriefcase,
    FiX,
} from 'react-icons/fi';

const SecondaryNavigationComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        mainNavigationWide,
        secondaryNavigationActive,
        secondaryNavigationAnimationOnly,
        reportVersion,
        setMainNavigationActive,
        setSecondaryNavigationActive,
    } = useLayoutStore();
    const { utilities, CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID, PORTFOLIO_ID } = useContext();
    const { asPath } = useRouter();

    // ///////////////////
    // DATA
    // ///////////////////

    // Change navigation context based on viewing a report or not
    const isReport = REPORT_ID ? true : false;

    const isDashboard = asPath.includes('dashboard');

    const isPortfolio = PORTFOLIO_ID ? true : false;

    const isExport = asPath.includes('export');

    // ///////////////////
    // METHODS
    // ///////////////////

    function renderContents() {
        if (isPortfolio) {
            return <PortfolioNavigation />;
        }

        if (isReport) {
            <ReportNavigation version={reportVersion} />;
        }

        if (isDashboard) {
            return <DashboardNavigation />;
        }

        if (isExport) {
            return <ExportNavigation />;
        }

        return <InitiativeNavigation />;
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            style={{ willChange: 'transform' }}
            className={cc([
                'flex print:hidden transform-gpu left-0 w-nav-wide flex-col fixed top-0 h-screen border-r z-secondary-nav border-blue-20 transition-medium',
                // Background based on context
                {
                    'bg-white': isReport || isDashboard || isPortfolio,
                    'bg-blue-10': !isReport && !isDashboard,
                },
                // Disable when interaction is disabllowed (we keep the component for animations)
                {
                    '-translate-x-full pointer-events-none': secondaryNavigationAnimationOnly,
                },
                // Animations when interaction allowed
                ...[
                    !secondaryNavigationAnimationOnly && {
                        'pointer-events-auto !left-nav-narrow':
                            secondaryNavigationActive && !mainNavigationWide,
                        '-translate-x-full pointer-events-none': !secondaryNavigationActive,
                    },
                ],
            ])}>
            {/* Close navigation */}
            <div className="absolute top-0 flex items-center justify-end w-full px-16 lg:hidden h-header bg-opacity-40">
                {/* Mobile navigation toggle */}
                <Button
                    variant="tertiary"
                    theme="blue"
                    icon={FiX}
                    iconPosition="center"
                    iconType="stroke"
                    className="!px-8 !ring-0"
                    action={() => {
                        setMainNavigationActive(false);
                        setSecondaryNavigationActive(false);
                    }}
                />
            </div>
            {renderContents()}
        </div>
    );
};

const InitiativeNavigation = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();

    // ///////////////////
    // STATE
    // ///////////////////

    const [dataExpanded, setDataExpanded] = useState(false);

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { INITIATIVE_ID } = useContext();
    const { asPath } = useRouter();

    // ///////////////////
    // DATA
    // ///////////////////

    const initiative = utilities.initiative.get();

    const navigationItems = [
        {
            Icon: FiCompass,
            label: label('SecondaryNavigationOverview'),
            href: `/${INITIATIVE_ID}/overview`,
        },
        // {
        //     Icon: FiCalendar,
        //     label: label('SecondaryNavigationTimeline'),
        //     href: `/${INITIATIVE_ID}/timeline`,
        // },
        {
            Icon: FiFlag,
            label: label('SecondaryNavigationMilestones'),
            href: `/${INITIATIVE_ID}/milestones`,
        },
        {
            Icon: FiFileText,
            label: label('SecondaryNavigationReporting'),
            href: `/${INITIATIVE_ID}/reports`,
        },
    ];

    const dataNavigationItems = [
        {
            Icon: FiDownload,
            label: label('SecondaryNavigationInputs'),
            href: `/${INITIATIVE_ID}/data/inputs`,
        },
        {
            Icon: FiServer,
            label: label('SecondaryNavigationActivities'),
            href: `/${INITIATIVE_ID}/data/activities`,
        },
        {
            Icon: FiShare,
            label: label('SecondaryNavigationResults'),
            href: `/${INITIATIVE_ID}/data/results`,
        },
        {
            Icon: FiEye,
            label: label('SecondaryNavigationLogbook'),
            href: `/${INITIATIVE_ID}/data/logbook`,
        },
    ];

    const additionalNavigationItems = [
        {
            label: label('SecondaryNavigationConfiguration'),
            href: `/create/${INITIATIVE_ID}/name-and-category`,
            rules: getPermissionRules('create', 'nameAndCategory', 'update'),
        },
    ];

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (asPath.indexOf('/data/') > -1) {
            setDataExpanded(true);
        }
    }, [asPath]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            {initiative?.Id && (
                <>
                    {/* Meta data */}
                    <div>
                        <p className="flex items-center py-12 pl-24 t-sh7 text-blue-60 min-h-header pr-76">
                            {label('SecondaryNavigationTitleInitiative')}
                        </p>
                        <h2 className="px-24 mt-8 text-blue-100 t-h6">
                            {initiative.Name}
                        </h2>
                    </div>
                    {/* Wrapper */}
                    <div className="flex flex-col px-24 space-y-24">
                        {/* Navigation */}
                        <div className="flex flex-col space-y-8">
                            {navigationItems.map((item, index) => (
                                <SecondaryNavigationItem
                                    key={index}
                                    {...item}
                                />
                            ))}

                            {/* Data Navigation */}
                            <button
                                onClick={() => setDataExpanded(!dataExpanded)}
                                className="flex items-center w-full h-32 px-16 py-8 space-x-8 text-blue-100 bg-opacity-0 focus:outline-none active:outline-none whitespace-nowrap t-caption bg-blue-20 rounded-4 hover:bg-opacity-100 transition-default">
                                <FiPackage className="w-16 h-16 shrink-0" />
                                <span className="relative top-2 whitespace-nowrap">
                                    {label('SecondaryNavigationInitiativeData')}
                                </span>
                                {dataExpanded ? (
                                    <FiChevronUp />
                                ) : (
                                    <FiChevronDown />
                                )}
                            </button>
                            <AnimateHeight
                                duration={200}
                                height={dataExpanded ? 'auto' : 0}>
                                <div className="flex flex-col ml-24 space-y-8">
                                    {dataNavigationItems.map((item, index) => (
                                        <SecondaryNavigationItem
                                            key={index}
                                            {...item}
                                        />
                                    ))}
                                </div>
                            </AnimateHeight>
                        </div>

                        {/* Line */}
                        <div className="border-t border-blue-20" />

                        {/* Additional navigation items */}
                        <div className="flex flex-col space-y-8">
                            {additionalNavigationItems.map((item, index) =>
                                item.rules ? (
                                    <Permission rules={item.rules} key={index}>
                                        <SecondaryNavigationItem {...item} />
                                    </Permission>
                                ) : (
                                    <SecondaryNavigationItem
                                        key={index}
                                        {...item}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const DashboardNavigation = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { filterCount, initiatives } = useDashboardStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { user } = useUser();
    const format = useFormat();
    const { modalState, modalOpen, modalClose } = useModalState();

    // ///////////////////
    // DATA
    // ///////////////////

    const navigationItems = [
        {
            Icon: FiHeart,
            label: label('SecondaryNavigationDashboardInitiative'),
            href: `/dashboard/initiatives`,
        },
        {
            Icon: FiDollarSign,
            label: label('SecondaryNavigationDashboardFunding'),
            href: `/dashboard/funding`,
        },
        {
            Icon: FiBriefcase,
            label: label('SecondaryNavigationDashboardOrganisations'),
            href: `/dashboard/organisations`,
        },
    ];

    // const additionalNavigationItems = [
    //     {
    //         label: label('SecondaryNavigationConfiguration'),
    //         href: `/create/${INITIATIVE_ID}/name-and-category`,
    //         rules: getPermissionRules('create', 'nameAndCategory', 'update'),
    //     },
    // ];

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            {/* Meta data */}
            <div>
                <p className="flex items-center py-12 pl-24 t-sh7 text-blue-60 min-h-header pr-76">
                    {label('SecondaryNavigationTitleDashboards')}
                </p>
                <h2 className="px-24 text-blue-100 t-h6">
                    {user?.User_Account_Name__c ||
                        (user?.User_Account_Type__c === 'Super' &&
                            user?.User_Account_Type__c)}
                </h2>
            </div>

            {/* Wrapper */}
            <div className="flex flex-col px-24 space-y-24">
                {/* Filters */}
                <div>
                    <p className="mb-16 t-sh7 text-blue-80">
                        {label('SecondaryNavigationFiltersGlobalFilters')}
                    </p>
                    {filterCount > 0 ? (
                        <p className="text-blue-300 t-footnote-bold">
                            {filterCount}{' '}
                            {label('SecondaryNavigationFiltersFiltersApplied')}
                        </p>
                    ) : (
                        <p className="t-footnote text-blue-60">
                            {label(
                                'SecondaryNavigationFiltersNoFiltersApplied'
                            )}
                        </p>
                    )}
                    <p className="text-blue-100 t-footnote">
                        {format.number(initiatives.length)}{' '}
                        {label('SecondaryNavigationFiltersInitiativesShowing')}
                    </p>
                    <div className="flex mt-16">
                        <Button variant="secondary" action={modalOpen}>
                            {filterCount > 0
                                ? label(
                                      'SecondaryNavigationFiltersModifyFilter'
                                  )
                                : label('SecondaryNavigationFiltersAddFilter')}
                        </Button>
                    </div>
                </div>

                {/* Line */}
                <div className="border-t border-blue-20" />

                {/* Navigation */}
                <div className="flex flex-col space-y-8">
                    {navigationItems.map((item, index) => (
                        <SecondaryNavigationItem
                            key={index}
                            variant="light"
                            {...item}
                        />
                    ))}
                </div>
            </div>

            {/* Filter modal */}
            <DashboardFilterModal
                {...{
                    onCancel() {
                        modalClose();
                    },
                    ...modalState,
                }}
            />
        </div>
    );
};

const ReportNavigation = ({ version }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();
    const { items } = useReportNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();
    const { label, wizardSection } = useLabels();
    const { initiativeData } = useInitiativeAndReportData();
    const { utilities: reportUtilities } = useInitiativeGetters(initiativeData);
    const { hasUpdates } = useHasReportUpdates({ utilities: reportUtilities });

    // ///////////////////
    // STATE
    // ///////////////////

    const [navigation, setNavigation] = useState('default');
    const [navigationItems, setNavigationItems] = useState(items);

    // ///////////////////
    // DATA
    // ///////////////////

    const initiative = utilities.initiative.get();
    const report = utilities.reports.get(REPORT_ID);

    // ///////////////////
    // EFFECT
    // ///////////////////

    useEffect(() => {
        switch (version) {
            case '1.0':
                setNavigation('none');
                break;
            case '1.1':
                setNavigation('ids');
                break;
            default:
                setNavigation('default');
                break;
        }
    }, [version]);

    useEffect(() => {
        switch (navigation) {
            case 'ids':
                const elements = document.querySelectorAll('[data-nav]');
                let idItems = [];
                for (const node of elements) {
                    idItems = [
                        ...idItems,
                        { href: node.id, label: node.dataset.label },
                    ];
                }
                setNavigationItems(idItems);
                break;
            default:
                break;
        }
    }, [navigation]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            {initiative?.Id && (
                <>
                    {/* Meta data */}
                    <div>
                        <p className="flex items-center py-12 pl-24 t-sh7 text-blue-60 min-h-header pr-76">
                            {`${report?.Report_Type__c} ${label(
                                'TitleReport'
                            )}`}
                        </p>
                        <h2 className="px-24 mt-8 text-blue-100 t-h6">
                            {initiative.Name}
                        </h2>
                    </div>

                    {/* Wrapper */}
                    <div className="flex flex-col px-24 space-y-24">
                        <Button
                            className="self-start"
                            variant="secondary"
                            action={`/${initiative?.Id}/overview`}>
                            {label('ReportViewViewInitiative')}
                        </Button>

                        {/* Line */}
                        <div className="border-t border-blue-20" />

                        {/* Navigation box */}
                        <nav
                            style={{ height: 'calc(100vh - 300px)' }}
                            className="flex flex-col pb-48 space-y-24 overflow-x-hidden overflow-y-auto">
                            {navigation === 'ids' &&
                                navigationItems?.map(item => {
                                    return (
                                        <div
                                            key={item.href}
                                            className="flex flex-col space-y-16 text-blue-100">
                                            <a
                                                href={`#${item.href}`}
                                                className="t-caption">
                                                {label(item.label)}
                                            </a>
                                        </div>
                                    );
                                })}

                            {navigation === 'default' &&
                                items?.map(parent => {
                                    const { title } = wizardSection(parent.id);
                                    return (
                                        <div
                                            key={parent.id}
                                            className="flex flex-col space-y-16 text-blue-100">
                                            <a
                                                href={`#${asId(parent.id)}`}
                                                className="t-caption-bold">
                                                {title}
                                            </a>
                                            {parent?.items?.map(child => (
                                                <a
                                                    key={child.baseUrl}
                                                    className="t-caption"
                                                    href={`#${asId(
                                                        child.baseUrl
                                                    )}`}>
                                                    {label(child.title)}
                                                    {hasUpdates(
                                                        child?.baseUrl
                                                    ) && (
                                                        <span className="relative inline-block w-5 h-5 bg-blue-200 rounded-full -top-4 -right-6" />
                                                    )}
                                                </a>
                                            ))}
                                        </div>
                                    );
                                })}
                        </nav>
                    </div>
                </>
            )}
        </div>
    );
};

const PortfolioNavigation = () => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { utilities } = usePortfolioStore();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Id, Owner } = utilities.portfolio.get();

    const navigationItems = [
        {
            title: 'ReportPortfolioIntroduction',
            href: 'introduction',
        },
        {
            title: 'ReportPortfolioOverview',
            href: 'overview',
        },
        {
            title: 'ReportPortfolioBreakdown',
            href: 'breakdown',
        },
    ];

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            {Id && (
                <>
                    {/* Meta data */}
                    <div>
                        <p className="flex items-center py-12 pl-24 t-sh7 text-blue-60 min-h-header pr-76">
                            {Owner?.User_Account_Name__c || Owner?.Name}
                        </p>
                        <h2 className="px-24 mt-8 text-blue-100 t-h6">
                            {label('PortfolioReport')}
                        </h2>
                    </div>

                    {/* Wrapper */}
                    <div className="flex flex-col px-24 space-y-24">
                        <Button
                            className="self-start"
                            variant="secondary"
                            action={`/portfolio`}>
                            {label('PortfolioReportBack')}
                        </Button>

                        {/* Line */}
                        <div className="border-t border-blue-20" />

                        {/* Navigation box */}
                        <nav
                            style={{ height: 'calc(100vh - 300px)' }}
                            className="flex flex-col pb-48 space-y-24 overflow-x-hidden overflow-y-auto text-blue-100">
                            {navigationItems?.map(item => (
                                <a
                                    key={item.href}
                                    className="t-caption-bold"
                                    href={`#${asId(item.href)}`}>
                                    {label(item.title)}
                                </a>
                            ))}
                        </nav>
                    </div>
                </>
            )}
        </div>
    );
};

const ExportNavigation = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { user, getUserAccountType } = useUser();

    // ///////////////////
    // DATA
    // ///////////////////

    const initiative = utilities.initiative.get();
    const isSuper = getUserAccountType() === ACCOUNTS.super;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            <>
                {/* Meta data */}
                <div>
                    <p className="flex items-center py-12 pl-24 t-sh7 text-blue-60 min-h-header pr-76">
                        {label('SecondaryNavigationTitleDataExport')}
                    </p>
                    <h2 className="px-24 mt-8 text-blue-100 t-h6">
                        {initiative.Name}
                    </h2>
                </div>
                {/* Wrapper */}
                <div className="flex flex-col px-24 space-y-24">
                    {/* Navigation */}
                    <div className="flex flex-col space-y-8">
                        {(user?.isExcelExportEnabledForContact || isSuper) && (
                            <SecondaryNavigationItem
                                {...{
                                    label: label(
                                        'SecondaryNavigationExportReports'
                                    ),
                                    href: '/export/reports',
                                }}
                            />
                        )}
                        {(user?.isJSONExportEnabledForContact || isSuper) && (
                            <SecondaryNavigationItem
                                {...{
                                    label: label(
                                        'SecondaryNavigationExportInitiatives'
                                    ),
                                    href: '/export/initiatives',
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

SecondaryNavigationComponent.propTypes = {};

SecondaryNavigationComponent.defaultProps = {};

export default SecondaryNavigationComponent;
