// Data
import { p as create } from 'utilities/configuration/wizards/create';
import { p as initiative } from 'utilities/configuration/wizards/initiative';
import { p as report } from 'utilities/configuration/wizards/report';

const dictionary = {
    initiative,
    create,
    report,
};

// Get permissions
function getPermissionRules(context, path, permissionObject) {
    if (context && path && permissionObject) {
        return (
            dictionary?.[context]?.[path]?.permissions?.[permissionObject] ?? []
        );
    }
    return [];
}

export default getPermissionRules;
