// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useContext } from 'utilities/hooks';

// Components
import Header from 'components/_layout/header';
import MainNavigation from 'components/_layout/mainNavigation';
import SecondaryNavigation from 'components/_layout/secondaryNavigation';

// Layouts
import DefaultLayout from 'components/_layout/defaultLayout';
import WizardLayout from 'components/_layout/wizardLayout';
import ReportLayout from 'components/_layout/reportLayout';
import InitiativeLayout from 'components/_layout/initiativeLayout';
import DataExportLayout from '../dataExportLayout';
import DashboardLayout from 'components/_layout/dashboardLayout';
import PortfolioLayout from 'components/_layout/portfolioLayout';
import PortfolioCreateLayout from 'components/_layout/portfolioCreateLayout';
import BlankLayout from 'components/_layout/blankLayout';

// Layouts and their settings
const allLayouts = {
    portfolio({ settings }) {
        const defaultSettings = {
            fullWidth: false,
        };

        return {
            layout: PortfolioLayout,
            layoutSettings: {
                ...defaultSettings,
                ...settings,
            },
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    portfolioCreate() {
        return {
            layout: PortfolioCreateLayout,
            layoutSettings: null,
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    wizard({ focus, action, settings }) {
        const defaultSettings = {
            hideAside: false,
            hideNavigation: false,
            hideHelp: false,
            hideBottomExit: false,
            hideBottomBack: false,
            hideBottomFeedback: false,
            bottomCtaLabel: 'ButtonContinue',
            bottomExitMode: 'default', // default | back
            bottomCtaMode: 'default', // default | save | saveAndExit
        };

        function presets() {
            if (focus) {
                return {
                    hideNavigation: true,
                    hideBottomBack: true,
                    bottomCtaLabel: 'ButtonSaveAndExit',
                    bottomExitMode: 'back',
                    bottomCtaMode: 'saveAndExit',
                };
            }
        }

        return {
            layout: WizardLayout,
            transparent: true,
            layoutSettings: {
                ...defaultSettings,
                ...settings,
                ...presets(),
            },
            mainNavigation: false,
            secondaryNavigation: false,
        };
    },
    initiative({ settings }) {
        const defaultSettings = {
            useDataLayout: false,
        };

        return {
            layout: InitiativeLayout,
            layoutSettings: {
                ...defaultSettings,
                ...settings,
            },
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    dataExport({ settings }) {
        return {
            layout: DataExportLayout,
            layoutSettings: {
                ...settings,
            },
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    dashboard() {
        return {
            layout: DashboardLayout,
            layoutSettings: null,
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    report() {
        return {
            layout: ReportLayout,
            layoutSettings: null,
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
    blank() {
        return {
            layout: BlankLayout,
            transparent: true,
            layoutSettings: null,
            mainNavigation: false,
            secondaryNavigation: false,
        };
    },
    default({ settings }) {
        const defaultSettings = {
            fullWidth: false,
        };

        return {
            layout: DefaultLayout,
            layoutSettings: {
                ...defaultSettings,
                ...settings,
            },
            mainNavigation: true,
            secondaryNavigation: true,
        };
    },
};

const LayoutWrapperComponent = ({
    children,
    layout = 'default',
    layoutSettings = {},
    pageProps,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { ACTION, FOCUS } = useContext();

    // ///////////////////
    // DATA
    // ///////////////////

    // Get the layout based on component
    const Layout = allLayouts[layout]({
        focus: FOCUS,
        action: ACTION,
        settings: layoutSettings,
    });

    return (
        <>
            <Header transparent={Layout.transparent} />
            {Layout.mainNavigation && <MainNavigation />}
            {Layout.secondaryNavigation && <SecondaryNavigation />}
            <Layout.layout
                {...{ pageProps, layoutSettings: Layout.layoutSettings }}>
                {children}
            </Layout.layout>
        </>
    );
};

LayoutWrapperComponent.propTypes = {
    pageProps: t.object,
};

LayoutWrapperComponent.defaultProps = {
    pageProps: {},
};

export default LayoutWrapperComponent;
