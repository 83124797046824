// React
import React, { useMemo } from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useFormat } from 'utilities/hooks';

// Components
import ChartWrapper from '../chartWrapper';

const ColumnChartComponent = props => (
    <ChartWrapper
        {...{
            Chart,
            ...props,
        }}
    />
);

ColumnChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(t.shape({ name: t.string, value: t.number })).isRequired,
    dataLabel: t.func.isRequired,
};

ColumnChartComponent.defaultProps = {};

const Chart = ({ data, dataLabel }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    // Get max value (with buffer)
    const maxValue = useMemo(() => Math.max(...data.map(x => x.value)) * 1.1, [
        data,
    ]);

    // Create chart data
    const chartData = useMemo(
        () =>
            data.map(x => ({
                name: dataLabel(x),
                value: x.value ?? 0,
                percentage: x.value ? (x.value / maxValue) * 100 : 0,
            })),
        [maxValue]
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex justify-between">
            {chartData.map((x, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center min-h-[300px]">
                    {/* Value */}
                    <div className="t-footnote text-blue-80">
                        {format.number(x.value)}
                    </div>

                    {/* Column */}
                    <div className="relative grow w-6 h-full my-16 rounded-full bg-blue-10">
                        <span
                            className="absolute bottom-0 left-0 inline-block w-6 rounded-full bg-blue-60 transition-slow"
                            style={{
                                height: `${x.percentage}%`,
                            }}></span>
                    </div>

                    {/* Label */}
                    <div className="mx-8 t-footnote">{x.name}</div>
                </div>
            ))}
        </div>
    );
};

export default ColumnChartComponent;
