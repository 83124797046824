// React
import React from 'react';

// Packages

// Utilities
import { useLabels, useTagging } from 'utilities/hooks';

// Components
import Pill from 'components/pill';
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';
import Tags from 'components/tags';

const FundingComponent = ({ item, theme = 'teal', utilities }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel, label } = useLabels();
    const tagging = useTagging({
        relationKey: 'Initiative_Funding__c',
    });

    // ///////////////////
    // DATA
    // ///////////////////

    const leadFunders = utilities.funders.getTypeLeadFromFundingId(item.Id);
    const addFunders = utilities.funders.getTypeAdditionalFromFundingId(
        item.Id
    );

    const mainApplicant = utilities.fundingRecipients.getTypeMainApplicantFromFundingId(
        item.Id
    );
    const coApplicants = utilities.fundingRecipients.getTypeCoApplicantsFromFundingId(
        item.Id
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Funders */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Funder__c.Account__c__Lead'
                    ),
                }}>
                <ul>
                    {leadFunders.map(funder => (
                        <li key={funder.Id}>- {funder?.Account__r?.Name}</li>
                    ))}
                </ul>
            </CardContentSection>

            {/* Funders */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label('Initiative_Funder__c.Account__c__Co'),
                }}>
                <ul>
                    {addFunders.map(funder => (
                        <li key={funder.Id}>- {funder?.Account__r?.Name}</li>
                    ))}
                </ul>
            </CardContentSection>

            {/* Grant ID */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Funding__c.Application_Id__c'
                    ),
                }}>
                <p>{item?.Application_Id__c}</p>
            </CardContentSection>

            {/* Main applicant */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Collaborator__c.Account__c__Main'
                    ),
                }}>
                <ul>
                    <li>- {mainApplicant?.Account__r?.Name}</li>
                </ul>
            </CardContentSection>

            {/* Co applicants */}
            {coApplicants.length > 0 && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Collaborator__c.Account__c__Additional'
                        ),
                    }}>
                    <ul>
                        {coApplicants.map(applicant => (
                            <li key={applicant.Id}>
                                - {applicant?.Account__r?.Name}
                            </li>
                        ))}
                    </ul>
                </CardContentSection>
            )}

            {/* Start date / End date  */}
            <div className="flex space-x-16">
                <CardContentSectionBox
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Funding__c.Grant_Start_Date__c'
                        ),
                    }}>
                    <p>
                        {item?.Grant_Start_Date__c
                            ? item?.Grant_Start_Date__c
                            : '-'}
                    </p>
                </CardContentSectionBox>
                <CardContentSectionBox
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Funding__c.Grant_End_Date__c'
                        ),
                    }}>
                    <p>
                        {item?.Grant_End_Date__c
                            ? item?.Grant_End_Date__c
                            : '-'}
                    </p>
                </CardContentSectionBox>
            </div>

            {/* Tags */}
            {tagging?.getCurrentTags(item).length > 0 && (
                <CardContentSection
                    {...{
                        theme,
                        title: label('DetailsTagsFunding'),
                    }}>
                    <Tags
                        {...{
                            theme,
                            tags: tagging?.getCurrentTags(item),
                            className: 'mt-4',
                        }}
                    />
                </CardContentSection>
            )}
        </div>
    );
};

FundingComponent.propTypes = {};

FundingComponent.defaultProps = {};

export default FundingComponent;
