// React
import React, { useState, useMemo } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import { motion } from 'framer-motion';

// Utilities
import { useFormat } from 'utilities/hooks';

// Components
import ChartWrapper from '../chartWrapper';
import ToolTip from 'components/tooltip';
import Attention from '../attention';
import Legend from '../legend';

const StackedBarChartComponent = ({
    data,
    dataLabel,
    tooltip,
    legend,
    attention,
    ...rest
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const colors = [
        '#507C93', // bg-teal-60
        '#545E92', // bg-blue-60
        '#995B57', // bg-coral-60
        '#977958', // bg-amber-60
        '#1C5471', // bg-teal-100
        '#223070', // bg-blue-100
        '#782C28', // bg-coral-100
        '#76502A', // bg-amber-100
        '#548DBB', // bg-teal-300
        '#4355B8', // bg-blue-300
        '#B15446', // bg-coral-300
        '#B7894D', // bg-amber-300
        '#AAC5D4',
        '#5D75F8',
        '#F8B55D',
        '#F86C5D',
        '#5DBDF8',
        '#384170',
        '#705838',
        '#703E38',
        '#385B70',
    ];

    // Get max value for bars
    const maxValue = useMemo(
        () =>
            data.reduce((acc, dataItem) => {
                const itemsMax = dataItem.items.reduce(
                    (xAcc, x) => xAcc + x.value,
                    0
                );
                if (itemsMax > acc) {
                    acc = itemsMax;
                }
                return acc;
            }, 0),
        [data]
    );

    // Create chart data
    const chartData = useMemo(() => {
        const baseData = data.map(dataItem => ({
            ...dataItem,
            items: dataItem.items
                .map((item, index) => ({
                    ...item,
                    color: colors[index],
                    value: item.value ?? 0,
                    height: item.value ? (item.value / maxValue) * 100 : 0,
                    label: dataItem.label,
                }))
                .reverse(),
        }));

        return baseData;
    }, [maxValue]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ChartWrapper
            {...{
                chart: (
                    <Chart
                        {...{
                            data: chartData,
                            dataLabel,
                            tooltip,
                        }}
                    />
                ),
                legend: (
                    <Legend
                        {...{
                            data: [...(chartData[0]?.items ?? [])].reverse(),
                            legend,
                        }}
                    />
                ),
                attention: attention ? (
                    <Attention {...{ data: chartData, attention }} />
                ) : null,
                ...rest,
            }}
        />
    );
};

StackedBarChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(t.shape({ name: t.string, value: t.number })).isRequired,
    dataLabel: t.func.isRequired,
    tooltip: t.object,
};

StackedBarChartComponent.defaultProps = {};

const Chart = ({ data, dataLabel, tooltip }) => {
    // ///////////////////
    // STATE
    // ///////////////////

    const [activeSection, setActiveSection] = useState(null);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <ToolTip {...{ item: activeSection, tooltip }} />
            <div className="flex justify-between">
                {data.map((dataItem, dataItemIndex) => {
                    const chartDataItems = dataItem.items.filter(
                        item => item.value > 0
                    );

                    return (
                        <div
                            className="flex flex-col items-center gap-16 shrink-0"
                            key={dataItemIndex}>
                            <div className="t-footnote text-blue-80">
                                {dataLabel(dataItem)}
                            </div>
                            <div className="h-[400px] xl:h-[500px] flex flex-col py-16 justify-end items-center shrink-0">
                                {chartDataItems.map((item, itemIndex) => (
                                    <motion.div
                                        onMouseEnter={() =>
                                            setActiveSection(item)
                                        }
                                        onMouseLeave={() => {
                                            setActiveSection(null);
                                        }}
                                        key={itemIndex}
                                        className={cc([
                                            'block w-20 cursor-pointer',
                                            {
                                                'rounded-t-4': itemIndex === 0,
                                                'rounded-b-4':
                                                    itemIndex ===
                                                    chartDataItems.length - 1,
                                            },
                                        ])}
                                        initial={{ height: 0, width: 20 }}
                                        animate={{
                                            height: `${item.height}%`,
                                        }}
                                        whileHover={{
                                            width: 26,
                                            transition: {
                                                duration: 0.2,
                                                delay: 0,
                                            },
                                        }}
                                        transition={{
                                            height: {
                                                delay: 0.3,
                                                duration: 0.3,
                                            },
                                        }}
                                        style={{ background: item.color }}
                                    />
                                ))}
                            </div>
                            <div className="t-footnote text-blue-80">
                                {dataItem.label}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default StackedBarChartComponent;
