// Packages
import create from 'zustand';
import _groupBy from 'lodash.groupby';
import _get from 'lodash.get';
import _set from 'lodash.set';
import _unset from 'lodash.unset';
import dayjs from 'dayjs';

// Utilities
import ew from 'utilities/api/elseware';
import { authStore } from 'utilities/store';

// Configuration
import { CONSTANTS } from 'utilities/configuration';

function _updateAuth() {
    const { getState } = authStore;
    getState().updateUserTimeout();
}

const usePortfolioStore = create((set, get) => ({
    CONSTANTS: { ...CONSTANTS },

    // Filters
    filterObject: {},
    taggingFilters: [],
    categoryFilters: [],
    initiativeDateFilter: null,
    fundingDateFilter: null,
    filterCount: 0,
    updateFilters(data = {}) {
        const taggingFilters =
            data.Tagging?.length > 0
                ? data.Tagging.map(x => x.selectValue)
                : [];

        const categoryFilters =
            data.Categories?.length > 0
                ? data.Categories.map(x => x.selectValue)
                : [];

        const initiativeDateFilter = data.InitiativeDates?.from
            ? data.InitiativeDates
            : null;

        const fundingDateFilter = data.FundingDates?.from
            ? data.FundingDates
            : null;

        set(() => ({
            taggingFilters,
            categoryFilters,
            initiativeDateFilter,
            fundingDateFilter,
            filterCount:
                taggingFilters.length +
                categoryFilters.length +
                (initiativeDateFilter ? 1 : 0) +
                (fundingDateFilter ? 1 : 0),
            filterObject: data,
        }));
    },

    filteredInitiatives() {
        // Filters present
        const taggingFilters = get().taggingFilters;
        const categoryFilters = get().categoryFilters;
        const initiativeDateFilter = get().initiativeDateFilter;
        const fundingDateFilter = get().fundingDateFilter;

        // Base for filtering
        let filteredInitiatives = get().initiatives;

        // Category filters
        if (categoryFilters.length > 0) {
            filteredInitiatives = filteredInitiatives.filter(initiative =>
                categoryFilters.includes(initiative.Category__c)
            );
        }

        // Initiative date filters
        if (initiativeDateFilter) {
            // From and To
            if (initiativeDateFilter.from && initiativeDateFilter.to) {
                filteredInitiatives = filteredInitiatives
                    .filter(
                        initiative =>
                            initiative.Grant_Start_Date__c &&
                            initiative.Grant_End_Date__c
                    )
                    .filter(
                        initiative =>
                            dayjs(initiative.Grant_Start_Date__c).isAfter(
                                dayjs(initiativeDateFilter.from)
                            ) &&
                            dayjs(initiative.Grant_End_Date__c).isBefore(
                                dayjs(initiativeDateFilter.to)
                            )
                    );
            }

            // From
            if (initiativeDateFilter.from) {
                filteredInitiatives = filteredInitiatives
                    .filter(initiative => initiative.Grant_Start_Date__c)
                    .filter(initiative =>
                        dayjs(initiative.Grant_Start_Date__c).isAfter(
                            dayjs(initiativeDateFilter.from)
                        )
                    );
            }
        }

        // Funding date filters
        if (fundingDateFilter) {
            filteredInitiatives = filteredInitiatives.filter(
                initiative => initiative._fundings.length > 0
            );

            // From and To
            if (fundingDateFilter.from && fundingDateFilter.to) {
                filteredInitiatives = filteredInitiatives.filter(initiative =>
                    initiative._fundings
                        .map(funding => ({
                            from: dayjs(funding.Grant_Start_Date__c),
                            to: dayjs(funding.Grant_End_Date__c),
                        }))
                        .some(
                            period =>
                                period.from.isAfter(
                                    dayjs(fundingDateFilter.from)
                                ) &&
                                period.to.isBefore(dayjs(fundingDateFilter.to))
                        )
                );
            }

            // From only
            if (fundingDateFilter.from) {
                filteredInitiatives = filteredInitiatives.filter(initiative =>
                    initiative._fundings
                        .map(funding => ({
                            from: dayjs(funding.Grant_Start_Date__c),
                        }))
                        .some(period =>
                            period.from.isAfter(dayjs(fundingDateFilter.from))
                        )
                );
            }
        }

        // Tagging filters
        if (taggingFilters.length > 0) {
            console.log(taggingFilters);
            console.log(
                filteredInitiatives
                    .map(initiative =>
                        initiative._tags.map(tag => tag.Tag__r.Name)
                    )
                    .flat()
            );
            filteredInitiatives = filteredInitiatives.filter(
                initiative =>
                    initiative._tags.filter(tag =>
                        taggingFilters.includes(tag.Tag__c)
                    ).length > 0
            );
        }

        return filteredInitiatives;
    },

    // Getters
    getInitiatives({ funderAccount = null }) {
        let initiatives = get().filteredInitiatives();

        // Funder account
        if (funderAccount) {
            initiatives = initiatives.filter(initiative =>
                initiative._funders
                    .map(funder => funder.Account__c)
                    .includes(funderAccount)
            );
        }

        return initiatives;
    },

    // Utilities
    utilities: {
        portfolio: {
            // Returns object
            get() {
                return get().portfolio;
            },
        },
        reportInitiatives: {
            // Returns array
            getAll() {
                return Object.values(get().portfolio._reportInitiatives);
            },
        },

        // Update portfolio with new data
        updatePortfolio(data) {
            set(state => ({
                portfolio: { ...state.portfolio, ...data },
            }));
        },
        // Updates nested portfolio objects with new data
        updatePortfolioData(path, data) {
            let portfolio = get().portfolio;
            _set(portfolio, `${path}[${data.Id}]`, data);
            set(() => ({
                portfolio,
            }));
        },
        // Updates nested portfolio objects with new data
        updatePortfolioDataGently(path, data) {
            let portfolio = get().portfolio;
            let currentData = _get(portfolio, `${path}[${data.Id}]`);
            _set(portfolio, `${path}[${data.Id}]`, { ...currentData, ...data });
            set(() => ({
                portfolio,
            }));
        },
        // Removes portfolio data
        removePortfolioData(path, id) {
            let portfolio = get().portfolio;
            _unset(portfolio, `${path}[${id}]`);
            set(() => ({
                portfolio,
            }));
        },
    },

    // Initiatives
    initiatives: [],
    initiativesLoaded: false,

    // Portfolio
    portfolio: {
        _reportInitiatives: {},
    },

    async getAllInitiatives() {
        if (!get().initiativesLoaded) {
            // Reset
            set(() => ({
                initiatives: [],
            }));

            const { data } = await ew.get({
                path: 'initiative/initiatives-portfolio',
            });

            // Update state
            set(() => ({
                initiatives: data,
                initiativesLoaded: true,
            }));

            // Update auth
            _updateAuth();
        }
    },

    async populatePortfolio(id) {
        if (id && get().portfolio.Id !== id) {
            // Reset
            get().reset();

            const { data } = await ew.get({
                path: 'portfolio-report/portfolio-report',
                params: { id },
            });

            // Update state
            set(state => ({
                portfolio: {
                    ...state.portfolio,
                    ...data,
                },
            }));

            // Update auth
            _updateAuth();
        }
    },

    reset() {
        set(() => ({
            initiatives: [],
            initiativesLoaded: false,
            portfolio: {
                _reportInitiatives: {},
            },
        }));
    },
}));

export { usePortfolioStore };
