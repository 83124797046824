// React
import React from 'react';

// Packages
import _groupBy from 'lodash.groupby';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components

const EmployeesFundedOverviewComponent = ({ employees }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const employeesByType = _groupBy(employees, x => x.Role_Type__c);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex space-x-32">
            {Object.entries(employeesByType).map(([key, value]) => (
                <div key={key}>
                    <span className="t-h1">{value.length}</span>
                    <p className="t-sh7 text-blue-60">
                        {getValueLabel(
                            'Initiative_Employee_Funded__c.Role_Type__c',
                            key
                        )}
                    </p>
                </div>
            ))}
        </div>
    );
};

EmployeesFundedOverviewComponent.propTypes = {};

EmployeesFundedOverviewComponent.defaultProps = {};

export default EmployeesFundedOverviewComponent;
