// Packages
import { useState, useEffect } from 'react';

// Data
import { fgConfig } from 'utilities/configuration';

const useConfig = ({ initiativeType = null, activityType = null }) => {
    // ///////////////////
    // STATE
    // ///////////////////

    const [initiative, setInitiative] = useState(null);
    const [activity, setActivity] = useState(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    function indicatorMetricTargetOn() {
        if (!initiative || !activity) {
            console.error('Config: Initiative or activity not set');
            return false;
        }
        return fgConfig[initiative]?.activities?.[activity]
            ?.indicatorMetricTargetOn;
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (initiativeType) {
            setInitiative(initiativeType);
        }
        if (activityType) {
            setActivity(activityType);
        }
    }, [initiativeType, activityType]);

    return {
        indicatorMetricTargetOn,
    };
};

export default useConfig;
