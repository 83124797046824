import axios from 'axios';
import { nanoid } from 'nanoid';

function removeAmazonUrlPrefix(url) {
    return url
        .replace(
            'https://development-foundgood-files.s3.eu-west-1.amazonaws.com/',
            ''
        )
        .replace(
            'https://production-foundgood-files.s3.eu-west-1.amazonaws.com/',
            ''
        );
}

async function getSignedUrl(file) {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/upload-media-signed-url`,
            { filename: `${nanoid(8)}-${file.name}` },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function getSignedUrlForFile({ fileName, folder, initiativeId }) {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/upload-file-signed-url`,
            {
                filename: `${initiativeId}/${folder}/${nanoid(8)}-${fileName}`,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function getSignedDownloadUrlForFile(fileName) {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/download-file-signed-url`,
            {
                filename: removeAmazonUrlPrefix(fileName),
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function uploadFileWithSignedUrl(file, url, getProgress = () => {}) {
    try {
        const response = await axios.put(url, file, {
            onUploadProgress: progressEvent => {
                getProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
            headers: {
                'Content-Type': file.type,
            },
        });

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function uploadMediaFile(file, getProgress = () => {}) {
    try {
        // Add as form data
        const formData = new FormData();
        formData.append('file', file, `${nanoid()}-${file.name}`);

        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/upload-media`,
            formData,
            {
                onUploadProgress: progressEvent => {
                    getProgress(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function deleteMediaFile(file) {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/delete-media`,
            { file },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

async function deleteFile(file) {
    try {
        const response = await axios.post(
            `${process.env.NEXT_PUBLIC_ELSEWARE_URL}/api/s3/delete-file`,
            { file: removeAmazonUrlPrefix(file) },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        // Convert non-ok HTTP responses into errors:
        if (response.status !== 200) {
            throw {
                statusText: response.statusText,
                response,
            };
        }

        return response.data;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

export default {
    deleteFile,
    getSignedUrl,
    getSignedUrlForFile,
    deleteMediaFile,
    uploadMediaFile,
    uploadFileWithSignedUrl,
    getSignedDownloadUrlForFile,
};
