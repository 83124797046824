// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import _groupBy from 'lodash.groupby';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Pill from 'components/pill';

const Tags = ({ tags, theme, variant, className }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { tagLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const tagObjects = _groupBy(tags, x => x.Tag__r?.Account__r?.Name);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className={cc(['flex flex-col space-y-12', className])}>
            {Object.keys(tagObjects)?.length > 0
                ? Object.entries(tagObjects).map(([funder, tags]) => {
                      const tagCollections = _groupBy(tags, x =>
                          tagLabel(x.Tag__r?.Collection__r)
                      );
                      return (
                          <div key={funder}>
                              <p
                                  className={cc([
                                      't-sh6',
                                      {
                                          'text-teal-60': theme === 'teal',
                                          'text-blue-60': theme === 'blue',
                                      },
                                  ])}>
                                  {funder}
                              </p>
                              <div className="flex flex-col">
                                  {Object.entries(tagCollections)?.map(
                                      ([collection, tags], index) => (
                                          <div key={collection + index}>
                                              {collection && (
                                                  <p
                                                      className={cc([
                                                          't-footnote mb-2',
                                                          {
                                                              'text-teal-80':
                                                                  theme ===
                                                                  'teal',
                                                              'text-blue-80':
                                                                  theme ===
                                                                  'blue',
                                                          },
                                                      ])}>
                                                      {collection}
                                                  </p>
                                              )}
                                              <div className="flex flex-wrap gap-x-8 gap-y-0">
                                                  {tags.map(tag => (
                                                      <Pill
                                                          key={tag.Id}
                                                          variant={variant}
                                                          theme={theme}>
                                                          {tagLabel(
                                                              tag?.Tag__r
                                                          )}
                                                      </Pill>
                                                  ))}
                                              </div>
                                          </div>
                                      )
                                  )}
                              </div>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

Tags.propTypes = {
    tags: t.array.isRequired,
    theme: t.oneOf(['blue', 'teal']),
    variant: t.oneOf(['default', 'light', 'dark', 'darkest']),
};

Tags.defaultProps = {
    tags: [],
    theme: 'teal',
    variant: 'default',
};

export default Tags;
