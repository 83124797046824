// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Button from 'components/button';
import Pill from 'components/pill';

// Icons
import { FiChevronDown, FiChevronUp, FiMessageCircle } from 'react-icons/fi';

const BaseCardComponent = ({ preTitle, title, postTitle, components }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [expandedContent, setExpandedContent] = useState(false);
    const [expandedContentCard, setExpandedContentCard] = useState(false);
    const [hasUpdate, setHasUpdate] = useState(false);
    const [updates, setUpdates] = useState({});

    // ///////////////////
    // DATA
    // ///////////////////

    const pillThemes = {
        Complete: 'teal',
        'Ahead of time': 'teal',
        'On time': 'blue',
        'Not started': 'amber',
        Delayed: 'coral',
    };

    const canExpand =
        components?.reportUpdate ||
        components?.cardContent ||
        components?.relatedItems;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div
                className={cc([
                    'p-24 border-2 border-blue-20 bg-white rounded-8 text-blue-100 transition-default',
                ])}>
                {/* Content wrapper */}
                <div className="flex flex-col">
                    {/* Top row wrapper */}
                    <div className="grid grid-cols-10">
                        <div className="flex flex-col justify-center order-1 col-span-8 grow-0 md:col-span-6">
                            {/* Card preTitle */}
                            <div className="mb-4 t-footnote text-blue-60">
                                {preTitle}
                            </div>

                            {/* Title wrapper */}
                            <div className="flex mb-4 space-x-12 text-blue-100 t-h5 md:mr-32">
                                <span className="break-keep">{title}</span>
                                {updates.status?.Status__c && (
                                    <Pill
                                        theme={
                                            pillThemes[
                                                updates.status?.Status__c
                                            ]
                                        }
                                        className="relative -top-1">
                                        {updates.status?.Status__c}
                                    </Pill>
                                )}
                            </div>

                            {/* Card postTitle */}
                            <div className="t-body text-blue-60 !-mt-2">
                                {postTitle}
                            </div>
                        </div>

                        <div className="flex items-center order-3 col-span-10 mt-12 text-blue-200 md:justify-center md:col-span-3 t-footnote md:order-2 md:mt-0">
                            {hasUpdate && (
                                <>
                                    <FiMessageCircle className="relative mr-4 -top-1" />{' '}
                                    {label('ReportViewHasUpdates')}
                                </>
                            )}
                        </div>

                        {/* Card controls */}
                        <div className="flex items-center justify-end order-2 col-span-2 space-x-4 md:col-span-1 md:order-3 ">
                            {/* Expand */}
                            {canExpand && (
                                <Button
                                    title={label('ButtonExpandCollapse')}
                                    variant="tertiary"
                                    theme="blue"
                                    icon={
                                        expandedContent
                                            ? FiChevronUp
                                            : FiChevronDown
                                    }
                                    iconPosition="center"
                                    iconType="stroke"
                                    className="!px-8"
                                    action={() =>
                                        setExpandedContent(!expandedContent)
                                    }
                                />
                            )}
                        </div>
                    </div>

                    {canExpand && (
                        <AnimateHeight
                            className="-mx-24 "
                            duration={300}
                            animateOpacity={true}
                            height={expandedContent ? 'auto' : 0}>
                            <div className="flex flex-col py-24 mt-24 space-y-24 border-t border-b border-blue-20 bg-blue-10">
                                {/* Card provide report update wrapper */}
                                {components?.reportUpdate &&
                                    components?.reportUpdate({
                                        setHasUpdate,
                                        hasUpdate,
                                        setUpdates,
                                        updates,
                                    })}

                                {/* Wrapper for details */}
                                {/* Card type content wrapper */}
                                {(components?.cardContent ||
                                    components?.relatedItems ||
                                    components?.childCollection) && (
                                    <div className="px-24">
                                        <div className="flex flex-col p-16 bg-white rounded-8">
                                            <AnimateHeight
                                                duration={300}
                                                animateOpacity={true}
                                                height={
                                                    expandedContentCard
                                                        ? 'auto'
                                                        : 0
                                                }>
                                                <div className="flex flex-col pb-24">
                                                    {/* Card content */}
                                                    {components?.cardContent}

                                                    {/* Card related items wrapper */}
                                                    {components?.relatedItems}

                                                    {/* Card child items wrapper */}
                                                    {
                                                        components?.childCollection
                                                    }
                                                </div>
                                            </AnimateHeight>

                                            <div
                                                role="button"
                                                onClick={() =>
                                                    setExpandedContentCard(
                                                        !expandedContentCard
                                                    )
                                                }
                                                className="relative flex justify-center text-blue-300 select-none top-2 t-h6">
                                                {expandedContentCard
                                                    ? label(
                                                          'ReportViewHideDetails'
                                                      )
                                                    : label(
                                                          'ReportViewShowDetails'
                                                      )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </AnimateHeight>
                    )}
                </div>
            </div>
        </>
    );
};

BaseCardComponent.propTypes = {
    preTitle: t.string,
    title: t.string.isRequired,
    postTitle: t.string,
    components: t.shape({
        cardContent: t.element,
        relatedItems: t.element,
        childCollection: t.element,
    }),
};

BaseCardComponent.defaultProps = {
    preTitle: '',
    title: '',
    postTitle: '',
    components: {
        cardContent: null,
        relatedItems: null,
        childCollection: null,
    },
};

export default BaseCardComponent;
