// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities

// Components

const BorderBox = ({
    padding,
    theme,
    background,
    dark,
    children,
    className,
}) => {
    return (
        <>
            <div
                className={cc([
                    'p-16 rounded-8 flex flex-col',
                    className,
                    {
                        'border-4 border-gray-10':
                            theme === 'gray' && !background,
                        'border-4 border-amber-10':
                            theme === 'amber' && !background,
                        'border-4 border-blue-10':
                            theme === 'blue' && !background,
                        'bg-gray-10': background && theme === 'gray',
                        'bg-amber-10': background && theme === 'amber',
                        'bg-blue-10': background && theme === 'blue',
                        'bg-gray-20': background && dark && theme === 'gray',
                        'bg-amber-20': background && dark && theme === 'amber',
                        'bg-blue-20': background && dark && theme === 'blue',
                        'md:p-32': padding,
                    },
                ])}>
                {children}
            </div>
        </>
    );
};

BorderBox.propTypes = {
    background: t.bool,
    dark: t.bool,
    theme: t.oneOf(['gray', 'blue', 'amber']),
    padding: t.bool,
    className: t.string,
};

BorderBox.defaultProps = {
    background: false,
    theme: 'gray',
    padding: false,
    className: '',
};

export default BorderBox;
