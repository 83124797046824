import axios from 'axios';
import { saveAs } from 'file-saver';

function getAssetUrl() {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEVELOPMENT') {
        return 'https://staging-platform.foundgood.org';
    }
    return 'https://platform.foundgood.org';
}

async function getStyles() {
    return await fetch('/foundgood.css')
        .then(response => response.text())
        .then(data => data);
}

async function getTemplate(title, body) {
    return `
        <!doctype html>

        <html lang="en">
        <head>
            <meta charset="utf-8">

            <title>${title}</title>

            <style>
                ${await getStyles()}

                /* Regular */
                @font-face {
                    font-family: 'tt-commons';
                    src: url('${getAssetUrl()}/fonts/TT-Commons-Regular.woff') format('woff'),
                        url('${getAssetUrl()}/fonts/TT-Commons-Regular.ttf') format('truetype');
                    font-weight: 400;
                    font-style: normal;
                    font-display: swap;
                }

                /* Medium */
                @font-face {
                    font-family: 'tt-commons';
                    src: url('${getAssetUrl()}/fonts/TT-Commons-Medium.woff') format('woff'),
                        url('${getAssetUrl()}/fonts/TT-Commons-Medium.ttf') format('truetype');
                    font-weight: 500;
                    font-style: normal;
                    font-display: swap;
                }

                /* Bold */
                @font-face {
                    font-family: 'tt-commons';
                    src: url('${getAssetUrl()}/fonts/TT-Commons-DemiBold.woff') format('woff'),
                        url('${getAssetUrl()}/fonts/TT-Commons-DemiBold.ttf') format('truetype');
                    font-weight: 600;
                    font-style: normal;
                    font-display: swap;
                }

            </style>


        </head>

        <body class="!bg-transparent">
            <style>

            footer {
                flow: static(footer-html);
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            footer img {
                display: block;
                margin-bottom: 4px;
            }

            footer p {
                font-size: .6rem;
                font-family: 'tt-commons';
                color: rgba(96, 105, 159, 1);
                margin-bottom: 4px;
                content: "Made with Foundgood";
            }
            footer span {
                display: block;
                font-size: .8rem;
                font-family: 'tt-commons';
                color: rgba(96, 105, 159, 1);
                content: "Page " counter(page) " of " counter(pages)
            }

            @page {
                size: A4;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;
                margin-bottom: 30mm;
                padding-left: 10mm;
                padding-right: 10mm;
                padding-top: 10mm;
                padding-bottom: 0;
                @bottom {
                    content: flow(footer-html);
                }
            }

            </style>
                <footer>
                    <img src="${getAssetUrl()}/logo.png" width="20" height="20" />
                    <p></p>
                    <span></span>
                </footer>
                ${body}
            </body>
        </html>
    `;
}

async function createPdf({ title = 'default', content }) {
    try {
        await axios
            .post(
                `https://api.docraptor.com/docs`,
                {
                    user_credentials: process.env.NEXT_PUBLIC_DOCRAPTOR_API,
                    doc: {
                        document_content: await getTemplate(title, content),
                        type: 'pdf',
                        test:
                            process.env.NEXT_PUBLIC_ENVIRONMENT ===
                            'DEVELOPMENT',
                        prince_options: {
                            media: 'screen',
                        },
                    },
                },
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(response => {
                saveAs(new Blob([response.data]), `${title}.pdf`);
            });

        return;
    } catch (error) {
        console.warn(error);
        return error;
    }
}

export default {
    createPdf,
};
