// React
import React from 'react';

// Packages
import AnimateHeight from 'react-animate-height';
import { useRouter } from 'next/router';
import cc from 'classcat';

// Utilities
import { useWizardNavigationStore } from 'utilities/store';
import { useLabels, useContext } from 'utilities/hooks';

// Components

// Icons
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const AsideNavigationComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { items } = useWizardNavigationStore();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ul className="mt-48">
            {items?.map((item, index) =>
                !item.hideInNavigation ? (
                    <li className="mt-32" key={`nav-${index}`}>
                        {item.hideSectionTitle ? (
                            <ul className="block">
                                {item.items?.map((childItem, i) =>
                                    !childItem.hideInNavigation ? (
                                        <li className="mt-24" key={`nav-${i}`}>
                                            <Children item={childItem} />
                                        </li>
                                    ) : null
                                )}
                            </ul>
                        ) : (
                            <Section item={item} />
                        )}
                    </li>
                ) : null
            )}
        </ul>
    );
};

const Section = ({ item }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { toggleSection, openSection } = useWizardNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { wizardSection } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const { items } = item;
    const { title = 'N/A' } = wizardSection(item.id);

    // Checking toggled section or not
    const sectionToggled = openSection === item.id;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <span
                className="flex t-caption-bold md:cursor-pointer text-teal-60"
                onClick={() => toggleSection(item)}>
                <i className="mr-16 text-teal-60">
                    {sectionToggled && <FiChevronUp />}
                    {!sectionToggled && <FiChevronDown />}
                </i>
                {title}
            </span>

            <AnimateHeight
                duration={300}
                animateOpacity={true}
                height={sectionToggled ? 'auto' : 0}>
                <ul className="block">
                    {items?.map((childItem, i) =>
                        !childItem.hideInNavigation ? (
                            <li key={`nav-${i}`} className="mt-20 ml-32">
                                <Children item={childItem} />
                            </li>
                        ) : null
                    )}
                </ul>
            </AnimateHeight>
        </>
    );
};

const Children = ({ item }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { handleSubmit } = useWizardNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { asPath, push } = useRouter();
    const { INITIATIVE_ID, REPORT_ID } = useContext();

    // ///////////////////
    // METHODS
    // ///////////////////

    async function onHandleNavigate() {
        try {
            // Submit throws if there is any validation errors
            await handleSubmit();

            // Go to next in flow
            push(
                url({
                    initiativeId: INITIATIVE_ID,
                    reportId: REPORT_ID,
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const { baseUrl, title, url } = item;
    const inProgress = asPath.split('/')[3] === baseUrl;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <button
            onClick={() => onHandleNavigate()}
            className="text-left md:cursor-pointers">
            <span
                className={cc([
                    'flex t-caption ',
                    {
                        'text-teal-60': !inProgress,
                        't-caption-bold text-teal-300 transition-default': inProgress,
                    },
                ])}>
                {label(title)}
            </span>
        </button>
    );
};

AsideNavigationComponent.propTypes = {};

AsideNavigationComponent.defaultProps = {};

export default AsideNavigationComponent;
