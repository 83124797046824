// React
import React, { useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import _camelCase from 'lodash.camelcase';
import { useRouter } from 'next/router';

// Utilities
import { getPermissionRules } from 'utilities';

import {
    useInitiativeDataStore,
    useInitiativeNavigationStore,
    useLayoutStore,
} from 'utilities/store';
import {
    useResponsive,
    useContext,
    useLabels,
    usePermissions,
} from 'utilities/hooks';

// Components
import PageTop from 'components/_layout/pageTop';

const InitiativeLayoutComponent = ({ children, layoutSettings }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        setSecondaryNavigationActive,
        setSecondaryNavigationAnimationOnly,
        setMainNavigationWide,
        setMainNavigationActive,
    } = useLayoutStore();

    const { utilities } = useInitiativeDataStore();
    const { getSection } = useInitiativeNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { DATA_PAGE } = useContext();
    const bp = useResponsive();
    const { label } = useLabels();
    const { enableAction } = usePermissions();
    const router = useRouter();

    // ///////////////////
    // DATA
    // ///////////////////

    const smallBps = ['2xs', 'xs', 'sm', 'md'];
    const largeBps = ['lg', 'xl', '2xl', '3xl'];
    const { useDataLayout, dataSection } = layoutSettings;

    const section = getSection(dataSection);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (utilities.initiative.get().Id) {
            setMainNavigationWide(false);
            setSecondaryNavigationActive(true);
            setSecondaryNavigationAnimationOnly(false);
        } else {
            setMainNavigationWide(false);
            setSecondaryNavigationActive(false);
        }
        setSecondaryNavigationAnimationOnly(false);
    }, [utilities.initiative.get().Id]);

    useEffect(() => {
        if (utilities.initiative.get().Id) {
            if (smallBps.includes(bp)) {
                setSecondaryNavigationActive(false);
                setMainNavigationActive(false);
            }
            if (largeBps.includes(bp)) {
                setSecondaryNavigationActive(true);
                setMainNavigationActive(true);
            }
        }
    }, [bp, utilities.initiative.get().Id]);

    function redirect() {
        if (!DATA_PAGE && section?.items?.length > 0) {
            const item = section?.items[0];
            const sectionRedirectUrl = `/${
                utilities.initiative.get().Id
            }/data/${section?.dataUrl}/${item.baseUrl}`;
            router.push(sectionRedirectUrl);
        }
    }

    useEffect(() => {
        if (router.asPath.includes('/data/')) {
            const timeout = setTimeout(() => {
                redirect();
            }, 10);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [router.asPath]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div
                className={cc([
                    'mt-header pt-80',
                    {
                        'lg:ml-nav-combined': utilities.initiative.get().Id,
                    },
                ])}>
                <div
                    className={cc([
                        'page-mx',
                        {
                            'max-w-[900px]': utilities.initiative.get().Id,
                        },
                    ])}>
                    {/* Special layout setting for data pages */}
                    {useDataLayout && utilities.initiative.get().Id && (
                        <div className="animate-fade-in" key={section?.id}>
                            <PageTop
                                {...{
                                    heading: label(section?.id),
                                    tabs: section?.items?.map(item => ({
                                        label: label(item.title),
                                        href: `/${
                                            utilities.initiative.get().Id
                                        }/data/${section.dataUrl}/${
                                            item.baseUrl
                                        }`,
                                    })),
                                    methods: {
                                        primary: {
                                            action: enableAction(
                                                getPermissionRules(
                                                    'initiative',
                                                    _camelCase(DATA_PAGE),
                                                    'update'
                                                ),
                                                `/initiative/${
                                                    utilities.initiative.get()
                                                        .Id
                                                }/${DATA_PAGE}/update`
                                            ),
                                            label: label('Update'),
                                        },
                                    },
                                }}
                            />
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </>
    );
};

InitiativeLayoutComponent.propTypes = {
    pageProps: t.object,
};

InitiativeLayoutComponent.defaultProps = {
    pageProps: {},
};

export default InitiativeLayoutComponent;
