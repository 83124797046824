// React

// Packages

// Utilities
import { useTagging } from 'utilities/hooks';
import { useInitiativeDataStore } from 'utilities/store';

const useActivityTagging = ({ types }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    // Activity tagging
    const activity = useTagging({
        labelKey: 'Activity',
        relationKey: 'Initiative_Activity__c',
        types: [...types, CONSTANTS.TAGS.ACTIVITY],
    });

    // Primary tagging
    const primaryMetric = useTagging({
        identifier: 'primary',
        relationKey: 'Initiative_Activity_Success_Metric__c',
        types: [CONSTANTS.TAGS.SUCCESS_METRIC_PEOPLE],
        fallbackToDefaultTags: true,
    });

    // Secondary tagging
    const secondaryMetric = useTagging({
        identifier: 'secondary',
        relationKey: 'Initiative_Activity_Success_Metric__c',
        types: [CONSTANTS.TAGS.SUCCESS_METRIC_PEOPLE],
        fallbackToDefaultTags: true,
    });

    // Additional tagging (people)
    const additionalMetricPeople = useTagging({
        identifier: 'additional',
        relationKey: 'Initiative_Activity_Success_Metric__c',
        types: [CONSTANTS.TAGS.SUCCESS_METRIC_PEOPLE],
    });

    // Additional tagging financial
    const additionalMetricFinancial = useTagging({
        identifier: 'additional',
        labelKey: CONSTANTS.ACTIVITY_SUCCESS_METRICS.FINANCIAL,
        relationKey: 'Initiative_Activity_Success_Metric__c',
        types: [
            CONSTANTS.TAGS.SUCCESS_METRIC_FINANCIAL,
            CONSTANTS.TAGS.SUCCESS_METRIC,
        ],
    });

    // Additional tagging custom
    const additionalMetricCustom = useTagging({
        identifier: 'additional',
        labelKey: CONSTANTS.ACTIVITY_SUCCESS_METRICS.CUSTOM,
        relationKey: 'Initiative_Activity_Success_Metric__c',
        types: [
            CONSTANTS.TAGS.SUCCESS_METRIC_CUSTOM,
            CONSTANTS.TAGS.SUCCESS_METRIC,
        ],
    });

    // ///////////////////
    // RETURN
    // ///////////////////

    return {
        activity,
        primaryMetric,
        secondaryMetric,
        additionalMetricPeople,
        additionalMetricFinancial,
        additionalMetricCustom,
    };
};

export default useActivityTagging;
