const CONTEXTS = {
    REPORT: 'report',
    INITIATIVE: 'initiative',
    CREATE: 'create',
    PORTFOLIO: 'portfolio',
};

const ACTIONS = {
    ADD: 'add',
    UPDATE: 'update',
    STATUS: 'status',
    REFLECT: 'reflect',
    EDIT: 'edit',
    VIEW: 'view',
};

export { CONTEXTS, ACTIONS };
