// React
import React, { useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

import { useLayoutStore } from 'utilities/store';
import { useResponsive, useLabels } from 'utilities/hooks';

// Components
import PageTop from 'components/_layout/pageTop';

const DataExportLayoutComponent = ({ children, layoutSettings }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        setSecondaryNavigationActive,
        setSecondaryNavigationAnimationOnly,
        setMainNavigationWide,
        setMainNavigationActive,
    } = useLayoutStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const bp = useResponsive();

    // ///////////////////
    // DATA
    // ///////////////////

    const smallBps = ['2xs', 'xs', 'sm', 'md'];
    const largeBps = ['lg', 'xl', '2xl', '3xl'];

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        setMainNavigationWide(false);
        setSecondaryNavigationActive(true);
        setSecondaryNavigationAnimationOnly(false);
    }, []);

    useEffect(() => {
        if (smallBps.includes(bp)) {
            setSecondaryNavigationActive(false);
            setMainNavigationActive(false);
        }
        if (largeBps.includes(bp)) {
            setSecondaryNavigationActive(true);
            setMainNavigationActive(true);
        }
    }, [bp]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div className={cc(['mt-header pt-80 lg:ml-nav-combined'])}>
                <div className={cc(['page-mx max-w-[900px]'])}>{children}</div>
            </div>
        </>
    );
};

DataExportLayoutComponent.propTypes = {
    pageProps: t.object,
};

DataExportLayoutComponent.defaultProps = {
    pageProps: {},
};

export default DataExportLayoutComponent;
