// React
import React, { useState, useRef, useLayoutEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup,
} from 'react-simple-maps';
import { useWindowResize } from 'use-events';

// Utilities
import { useFormat, useResponsive } from 'utilities/hooks';

// Components
import ChartWrapper from '../chartWrapper';
import ToolTip from 'components/tooltip';
import Attention from '../attention';

// Data
import world from 'utilities/data/world';

const MapChartComponent = ({
    data,
    annotation,
    tooltip,
    attention,
    ...rest
}) => (
    <ChartWrapper
        {...{
            chart: <Chart {...{ data, annotation, tooltip }} />,
            attention: attention ? (
                <Attention {...{ data, attention }} />
            ) : null,
            ...rest,
        }}
    />
);

MapChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(
        t.shape({
            country: t.shape({
                name: t.string.isRequired,
                code: t.string.isRequired,
            }),
            value: t.number,
        })
    ).isRequired,
    annotation: t.shape({ columns: t.array.isRequired }).isRequired,
};

MapChartComponent.defaultProps = {};

const Chart = ({ data, annotation, tooltip }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();
    const bp = useResponsive();

    // ///////////////////
    // REFS
    // ///////////////////

    const map = useRef();
    const table = useRef();

    // ///////////////////
    // STATE
    // ///////////////////

    const [activeCountry, setActiveCountry] = useState(null);

    // ///////////////////
    // DATA
    // ///////////////////

    const largeBps = ['md', 'lg', 'xl', '2xl', '3xl'];

    // ///////////////////
    // EFFECTS
    // ///////////////////

    if (typeof window !== 'undefined') {
        useLayoutEffect(() => {
            if (largeBps.includes(bp)) {
                table.current.style.height =
                    map.current.getBoundingClientRect().height + 'px';
            } else {
                table.current.style.height = '400px';
            }
        }, [useWindowResize(), bp]);
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className="grid grid-cols-12 gap-32"
            style={{ gridAutoRows: '1fr' }}>
            {/* Map */}
            <div className="hidden col-span-12 xl:col-span-8 md:block">
                <div className="bg-blue-10 rounded-8" ref={map}>
                    <ComposableMap projection="geoEqualEarth" height={480}>
                        <ZoomableGroup center={[15, 0]} zoom={1}>
                            <Geographies geography={world}>
                                {({ geographies }) =>
                                    geographies.map(geo => {
                                        const country =
                                            data.find(
                                                x =>
                                                    x.country?.code ===
                                                    geo?.properties?.['Alpha-2']
                                            ) ?? {};
                                        const isMarked = country?.value > 0;
                                        return (
                                            <Geography
                                                key={geo.rsmKey}
                                                geography={geo}
                                                onMouseEnter={() => {
                                                    setActiveCountry(
                                                        isMarked
                                                            ? country
                                                            : null
                                                    );
                                                }}
                                                onMouseLeave={() => {
                                                    setActiveCountry(null);
                                                }}
                                                style={{
                                                    default: {
                                                        fill: isMarked
                                                            ? 'hsl(231, 25%, 50%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        stroke: isMarked
                                                            ? 'hsl(231, 25%, 50%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        outline: 'none',
                                                    },
                                                    hover: {
                                                        fill: isMarked
                                                            ? 'hsl(231, 33%, 75%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        stroke: isMarked
                                                            ? 'hsl(231, 33%, 75%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        cursor: isMarked
                                                            ? 'pointer'
                                                            : 'default',
                                                        outline: 'none',
                                                    },
                                                    pressed: {
                                                        fill: isMarked
                                                            ? 'hsl(231, 25%, 50%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        stroke: isMarked
                                                            ? 'hsl(231, 25%, 50%)'
                                                            : 'hsl(231, 33%, 92%)',
                                                        outline: 'none',
                                                    },
                                                }}
                                            />
                                        );
                                    })
                                }
                            </Geographies>
                        </ZoomableGroup>
                    </ComposableMap>
                </div>
            </div>
            {/* Table */}
            <div
                className="col-span-12 overflow-y-scroll xl:col-span-4"
                ref={table}>
                <table className="w-full max-w-full table-fixed">
                    <thead>
                        <tr>
                            {annotation?.columns?.map((x, index) => (
                                <th
                                    key={index}
                                    className="sticky top-0 px-16 py-8 text-left bg-white border-b-2 border-blue-10 text-blue-60 t-footnote-bold">
                                    {x}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((x, index) => (
                            <tr key={index}>
                                <td className="px-16 pt-16 pb-[12px] text-left border-b-2 border-blue-10 text-blue-80 t-caption">
                                    {x.country.name}
                                </td>
                                <td className="px-16 pt-16 pb-[12px] text-left border-b-2 border-blue-10 text-blue-80 t-caption-bold">
                                    {format.number(x.value)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ToolTip {...{ item: activeCountry, tooltip }} />
        </div>
    );
};

export default MapChartComponent;
