// React
import React from 'react';

// Utilities
import {
    useInitiativeDataStore,
    useDashboardStore,
    usePortfolioStore,
} from 'utilities/store';
import { useUser, useContext } from 'utilities/hooks';

// Packages
import t from 'prop-types';

// Components
import Preloader from 'components/preloader';

const FadeInComponent = ({ children, force, className, mode }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities } = useInitiativeDataStore();
    const { utilities: portfolioUtilities } = usePortfolioStore();
    const { initiativesLoaded } = useDashboardStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getUserInitiativeTeamRole, getUserAccountType } = useUser();
    const { INITIATIVE_ID } = useContext();

    // ///////////////////
    // DATA
    // ///////////////////

    let allow;
    switch (mode) {
        case 'initiative':
            allow = [
                utilities.initiative.get().Id &&
                    getUserInitiativeTeamRole() !== 'not-set',
                utilities.initiative.get().Id &&
                    getUserAccountType() === 'Super',
                INITIATIVE_ID === 'new',
                force,
            ].some(x => x);
            break;
        case 'portfolio':
            allow = [portfolioUtilities.portfolio.get().Id, force].some(x => x);
            break;
        case 'dashboard':
            allow = [initiativesLoaded, force].some(x => x);
            break;
        default:
            break;
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {allow ? (
                <div className="animate-fade-in">{children}</div>
            ) : (
                <div className={className}>
                    <Preloader />
                </div>
            )}
        </>
    );
};

FadeInComponent.propTypes = {
    force: t.bool,
    mode: t.oneOf(['initiative', 'dashboard', 'portfolio']),
};

FadeInComponent.defaultProps = {
    force: false,
    mode: 'initiative',
};

export default FadeInComponent;
