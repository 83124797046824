// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Pill from 'components/pill';
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';

const EmployeesFundedComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Importance */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Activity_Regulation__c.Importance__c'
                    ),
                }}>
                <Pill className="mt-4" theme={theme}>
                    {getValueLabel(
                        'Initiative_Activity_Regulation__c.Importance__c',
                        item?.Importance__c
                    )}
                </Pill>
            </CardContentSection>

            {/* Issuing body */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Activity_Regulation__c.Issuing_Body__c'
                    ),
                }}>
                <p>{item?.Issuing_Body__c}</p>
            </CardContentSection>

            {/* Description */}
            <CardContentSection
                {...{
                    theme,
                }}>
                <p>{item?.Description__c}</p>
            </CardContentSection>

            {/* Start date / End date  */}
            <div className="flex space-x-16">
                <CardContentSectionBox
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity_Regulation__c.Date_Applied__c'
                        ),
                    }}>
                    <p>{item?.Date_Applied__c ? item?.Date_Applied__c : '-'}</p>
                </CardContentSectionBox>

                <CardContentSectionBox
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity_Regulation__c.Date_Received__c'
                        ),
                    }}>
                    <p>
                        {item?.Date_Received__c ? item?.Date_Received__c : '-'}
                    </p>
                </CardContentSectionBox>
            </div>
        </div>
    );
};

EmployeesFundedComponent.propTypes = {
    description: t.string,
    location: t.string,
};

EmployeesFundedComponent.defaultProps = {};

export default EmployeesFundedComponent;
