// React
import React, { useMemo } from 'react';

// Utilities
import {
    useLabels,
    useFormat,
    useContext,
    useInitiativeAndReportData,
} from 'utilities/hooks';
import { useReportNavigationStore } from 'utilities/store';

// Packages

// Components
import BorderBox from 'components/borderBox';
import SectionContainer from 'components/_report/templates/report_5_0_pdf/sectionContainer';
import SummaryContentBox from 'components/_report/templates/report_5_0_pdf/summaryContentBox';
import Collection from 'components/_report/templates/report_5_0_pdf/collection';
import Regulation from 'components/_cardContents/regulation';
import { ReportUpdate } from 'components/_report/templates/report_5_0_pdf/_cards';
import SdgNumber from 'components/sdgNumber';

const SectionOverviewComponent = ({ utilities, items, CONSTANTS }) => {
    // ///////////////////
    // STORE
    // ///////////////////

    const { hasSection } = useReportNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { legacyMode } = useInitiativeAndReportData();
    const { REPORT_ID } = useContext();

    // ///////////////////
    // DATA
    // ///////////////////

    const report = utilities.reports.get(REPORT_ID);

    // Based on baseUrl in config
    const sectionComponents = {
        overview(props) {
            return <InitiativeOverviewSection {...props} />;
        },
        openToUsers(props) {
            return <OpenToUsersSection {...props} />;
        },
        acknowledgementPolicy(props) {
            return <AcknowledgementPolicySection {...props} />;
        },
        regulations(props) {
            return <RegulationsSection {...props} />;
        },
        goals(props) {
            return <GoalSection {...props} />;
        },
    };

    // ///////////////////
    // LEGACY GOALS - YUCK-CODE
    // ///////////////////

    // Get type predefined goals
    const goalsPredefined = [
        ...utilities.goals
            .getTypePredefined()
            // Filter out where Objective C exists as we get those seperately
            .filter(goal => !goal.Funder_Objective__c)
            .map(goal => ({
                ...goal,
                _tag: utilities.tags.getFromRelationKeyId(
                    'Initiative_Goal__c',
                    goal.Id
                )[0],
            }))
            .sort((a, b) =>
                a?._tag?.Tag__r?.Account__r?.Name?.localeCompare(
                    b?._tag?.Tag__r?.Account__r?.Name
                )
            ),
        ...utilities.goals
            .getWithFunderObjective()
            .map(goal => ({ ...goal, legacy: true })),
    ];

    // Get type custom goals
    const goalsCustom = utilities.goals.getTypeCustom();

    // Are we showing custom goals?
    const showLegacyGoals =
        legacyMode &&
        !hasSection('goals') &&
        [...goalsCustom, ...goalsPredefined].length > 0;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <React.Fragment>
            {items.map(navigationItem => (
                <React.Fragment key={navigationItem.baseUrl}>
                    {sectionComponents[navigationItem.baseUrl] &&
                        sectionComponents[navigationItem.baseUrl]({
                            report,
                            utilities,
                            CONSTANTS,
                            navigationItem,
                        })}
                </React.Fragment>
            ))}
            {showLegacyGoals && (
                <LegacyGoalSection
                    {...{
                        utilities,
                        CONSTANTS,
                    }}
                />
            )}
        </React.Fragment>
    );
};

const InitiativeOverviewSection = ({ utilities, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, dataSet, pickList } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Category__c,
        Grant_End_Date__c,
        Grant_Start_Date__c,
        Id,
        Problem_Effect__c,
        Summary__c,
        Where_Is_Problem__c,
    } = utilities.initiative.get();

    const location = useMemo(
        () =>
            Where_Is_Problem__c?.split(';')
                .map(
                    location =>
                        dataSet('Countries').find(c => c.value === location)
                            ?.label
                )
                .join(', '),
        [Id]
    );

    const developmentGoals = useMemo(() => {
        const sdgNums = Problem_Effect__c?.split(';');
        const sdgs = pickList('Initiative__c.Problem_Effect__c');

        return (
            sdgNums?.map(num => ({
                title: sdgs.find(sdg => sdg.value === num)?.label ?? null,
                number: num,
            })) ?? []
        );
    }, [Id]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            {/* Summary */}
            <div className="flex flex-col mb-32">
                <p className="t-preamble">{Summary__c}</p>
            </div>

            {/* Details */}
            <div className="flex flex-col space-y-24">
                <BorderBox className="flex flex-col space-y-12" theme="amber">
                    {Category__c && (
                        <div>
                            <h4 className="t-sh6 text-blue-60">
                                {label('InitiativeViewGrantGivingArea')}
                            </h4>
                            <h3 className="t-h5">{Category__c}</h3>
                        </div>
                    )}

                    {Grant_Start_Date__c && (
                        <div>
                            <h4 className="t-sh6 text-blue-60">
                                {label('InitiativeViewInitiativePeriod')}
                            </h4>
                            <h3 className="t-h5">
                                {`${format.date(
                                    Grant_Start_Date__c
                                )} - ${format.date(Grant_End_Date__c)}`}
                            </h3>
                        </div>
                    )}
                </BorderBox>
                <BorderBox className="flex flex-col space-y-12" theme="amber">
                    {Where_Is_Problem__c && (
                        <div>
                            <h4 className="t-sh6 text-blue-60">
                                {label('InitiativeViewInitiativeLocation')}
                            </h4>
                            <h3 className="t-h5">{location}</h3>
                        </div>
                    )}

                    {developmentGoals.length > 0 && (
                        <div>
                            <h4 className="mb-8 t-sh6 text-blue-60">
                                {label('InitiativeViewSDGSs')}
                            </h4>
                            <ul className="space-y-8">
                                {developmentGoals &&
                                    developmentGoals.map((problem, index) => (
                                        <li
                                            key={`g-${index}`}
                                            className="t-h5 pl-36">
                                            <span className="inline-block w-36 -ml-36">
                                                <SdgNumber
                                                    {...{
                                                        number: problem.number,
                                                    }}
                                                />
                                            </span>
                                            {problem.title}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </BorderBox>
            </div>
        </SectionContainer>
    );
};

const RegulationsSection = ({ utilities, CONSTANTS, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const items = utilities.regulations.getAll();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label(
                            'EmptyStateWizardPageActivityRegulation'
                        ),
                    },
                    card: {
                        preTitle(item) {
                            return getValueLabel(
                                'Initiative_Activity_Regulation__c.Type__c',
                                item?.Type__c
                            );
                        },
                        title(item) {
                            return item?.Id__c;
                        },
                        postTitle(item) {
                            return getValueLabel(
                                'Initiative_Activity_Regulation__c.Importance__c',
                                item?.Importance__c
                            );
                        },
                        components(item) {
                            return {
                                cardContent: (
                                    <Regulation
                                        {...{
                                            item,
                                            theme: 'blue',
                                        }}
                                    />
                                ),
                                reportUpdate(additinalProps) {
                                    return (
                                        <ReportUpdate
                                            {...{
                                                ...additinalProps,
                                                status: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Activity_Regulation__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .REGULATION,
                                                },
                                                reflection: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Activity_Regulation__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .REGULATION,
                                                },
                                            }}
                                        />
                                    );
                                },
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

const OpenToUsersSection = ({ report, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();
    const format = useFormat();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Infrastructure_Open__c'
                    ),
                    content: getValueLabel(
                        'Initiative_Report__c.Infrastructure_Open__c',
                        report?.Infrastructure_Open__c
                    ),
                }}
            />

            {report?.Infrastructure_Open__c === 'Yes' && (
                <SummaryContentBox
                    {...{
                        title: object.label(
                            'Initiative_Report__c.Infrastructure_Open_Frequency__c'
                        ),
                        content: getValueLabel(
                            'Initiative_Report__c.Infrastructure_Open_Frequency__c',
                            report?.Infrastructure_Open_Frequency__c
                        ),
                    }}
                />
            )}

            {report?.Infrastructure_Open__c === 'Yes' &&
                report?.Infrastructure_Open_Frequency__c === 'Fully' && (
                    <SummaryContentBox
                        {...{
                            title: object.label(
                                'Initiative_Report__c.Infrastructure_Open_Date__c___Fully'
                            ),
                            content: format.date(
                                report?.Infrastructure_Open_Date__c
                            ),
                        }}
                    />
                )}

            {report?.Infrastructure_Open__c === 'Yes' &&
                report?.Infrastructure_Open_Frequency__c === 'Partially' && (
                    <SummaryContentBox
                        {...{
                            title: object.label(
                                'Initiative_Report__c.Infrastructure_Open_Date__c___Partially'
                            ),
                            content: format.date(
                                report?.Infrastructure_Open_Date__c
                            ),
                        }}
                    />
                )}

            {(report?.Infrastructure_Open__c === 'No' ||
                report?.Infrastructure_Open_Frequency__c === 'Partially') && (
                <SummaryContentBox
                    {...{
                        title: object.label(
                            'Initiative_Report__c.Infrastructure_Additional_Information__c'
                        ),
                        content:
                            report?.Infrastructure_Additional_Information__c,
                    }}
                />
            )}
        </SectionContainer>
    );
};

const AcknowledgementPolicySection = ({ report, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Acknowledgement_Researcher_Statement__c'
                    ),
                    content: report?.Acknowledgement_Researcher_Statement__c,
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Acknowledgement_Require_Id__c'
                    ),
                    content: getValueLabel(
                        'Initiative_Report__c.Acknowledgement_Require_Id__c',
                        report?.Acknowledgement_Require_Id__c
                    ),
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Acknowledgement_Requirements__c'
                    ),
                    content: report?.Acknowledgement_Requirements__c,
                }}
            />
        </SectionContainer>
    );
};

const GoalSection = ({ utilities, CONSTANTS, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const itemsPredefined = [
        ...utilities.goals
            .getTypePredefined()
            // Filter out where Objective C exists as we get those seperately
            .filter(goal => !goal.Funder_Objective__c)
            .map(goal => ({
                ...goal,
                _tag: utilities.tags.getFromRelationKeyId(
                    'Initiative_Goal__c',
                    goal.Id
                )[0],
            }))
            .sort((a, b) =>
                a?._tag?.Tag__r?.Account__r?.Name?.localeCompare(
                    b?._tag?.Tag__r?.Account__r?.Name
                )
            ),
        ...utilities.goals
            .getWithFunderObjective()
            .map(goal => ({ ...goal, legacy: true })),
    ];
    const itemsCustom = utilities.goals.getTypeCustom();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <SectionContainer
                {...{
                    item: {
                        baseUrl: 'strategic-themes',
                        title: 'CreateWizardMenuStrategicThemes',
                    },
                }}>
                <Collection
                    {...{
                        collection: {
                            items: itemsPredefined,
                            emptyLabel: label(
                                'EmptyStateWizardPageStrategicThemes'
                            ),
                        },
                        card: {
                            preTitle(item) {
                                return !item?.legacy
                                    ? utilities.tags.getFromRelationKeyId(
                                          'Initiative_Goal__c',
                                          item.Id
                                      )[0]?.Tag__r?.Account__r?.Name
                                    : null;
                            },
                            title(item) {
                                return item?.legacy
                                    ? item?.Funder_Objective__c
                                    : item?.Goal__c;
                            },
                            components(item) {},
                        },
                    }}
                />
            </SectionContainer>
            <SectionContainer
                {...{
                    item: navigationItem,
                }}>
                <Collection
                    {...{
                        collection: {
                            items: itemsCustom,
                            emptyLabel: label('EmptyStateWizardPageGoals'),
                        },
                        card: {
                            title(item) {
                                return item?.Goal__c;
                            },
                            components(item) {
                                return {
                                    reportUpdate(additinalProps) {
                                        return (
                                            <ReportUpdate
                                                {...{
                                                    ...additinalProps,
                                                    tagging: {
                                                        item,
                                                        relationKey:
                                                            'Initiative_Goal__c',
                                                        types: [
                                                            CONSTANTS.TAGS.GOAL,
                                                        ],
                                                    },
                                                    reflection: {
                                                        item,
                                                        relationKey:
                                                            'Initiative_Goal__c',
                                                        type:
                                                            CONSTANTS
                                                                .REPORT_DETAILS
                                                                .GOAL,
                                                    },
                                                }}
                                            />
                                        );
                                    },
                                };
                            },
                        },
                    }}
                />
            </SectionContainer>
        </>
    );
};

const LegacyGoalSection = ({ utilities, CONSTANTS }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const itemsPredefined = [
        ...utilities.goals
            .getTypePredefined()
            // Filter out where Objective C exists as we get those seperately
            .filter(goal => !goal.Funder_Objective__c)
            .map(goal => ({
                ...goal,
                _tag: utilities.tags.getFromRelationKeyId(
                    'Initiative_Goal__c',
                    goal.Id
                )[0],
            }))
            .sort((a, b) =>
                a?._tag?.Tag__r?.Account__r?.Name?.localeCompare(
                    b?._tag?.Tag__r?.Account__r?.Name
                )
            ),
        ...utilities.goals
            .getWithFunderObjective()
            .map(goal => ({ ...goal, legacy: true })),
    ];
    const itemsCustom = utilities.goals.getTypeCustom();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col space-y-24">
            <SectionContainer
                {...{
                    item: {
                        baseUrl: 'strategic-themes',
                        title: 'CreateWizardMenuStrategicThemes',
                    },
                }}>
                <Collection
                    {...{
                        collection: {
                            items: itemsPredefined,
                            emptyLabel: label(
                                'EmptyStateWizardPageStrategicThemes'
                            ),
                        },
                        card: {
                            preTitle(item) {
                                return !item?.legacy
                                    ? utilities.tags.getFromRelationKeyId(
                                          'Initiative_Goal__c',
                                          item.Id
                                      )[0]?.Tag__r?.Account__r?.Name
                                    : null;
                            },
                            title(item) {
                                return item?.legacy
                                    ? item?.Funder_Objective__c
                                    : item?.Goal__c;
                            },
                            components(item) {},
                        },
                    }}
                />
            </SectionContainer>
            <SectionContainer
                {...{
                    item: {
                        baseUrl: 'goals',
                        title: 'ReportWizardMenuGoals',
                    },
                }}>
                <Collection
                    {...{
                        collection: {
                            items: itemsCustom,
                            emptyLabel: label('EmptyStateWizardPageGoals'),
                        },
                        card: {
                            title(item) {
                                return item?.Goal__c;
                            },
                            components(item) {
                                return {
                                    reportUpdate(additinalProps) {
                                        return (
                                            <ReportUpdate
                                                {...{
                                                    ...additinalProps,
                                                    tagging: {
                                                        item,
                                                        relationKey:
                                                            'Initiative_Goal__c',
                                                        types: [
                                                            CONSTANTS.TAGS.GOAL,
                                                        ],
                                                    },
                                                    reflection: {
                                                        item,
                                                        relationKey:
                                                            'Initiative_Goal__c',
                                                        type:
                                                            CONSTANTS
                                                                .REPORT_DETAILS
                                                                .GOAL,
                                                    },
                                                }}
                                            />
                                        );
                                    },
                                };
                            },
                        },
                    }}
                />
            </SectionContainer>
        </div>
    );
};

SectionOverviewComponent.propTypes = {};

SectionOverviewComponent.defaultProps = {};

export default SectionOverviewComponent;
