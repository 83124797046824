// React
import React, { useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities

import { usePortfolioStore, useLayoutStore } from 'utilities/store';
import { useResponsive } from 'utilities/hooks';

const PortfolioCreateLayoutComponent = ({ children, pageProps }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        setSecondaryNavigationActive,
        setSecondaryNavigationAnimationOnly,
        setMainNavigationWide,
        setMainNavigationActive,
    } = useLayoutStore();

    const { utilities } = usePortfolioStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const bp = useResponsive();

    // ///////////////////
    // DATA
    // ///////////////////

    const smallBps = ['2xs', 'xs', 'sm', 'md'];
    const largeBps = ['lg', 'xl', '2xl', '3xl'];

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (utilities.portfolio.get().Id) {
            setMainNavigationWide(false);
            setSecondaryNavigationActive(true);
            setSecondaryNavigationAnimationOnly(false);
        } else {
            setMainNavigationWide(false);
            setSecondaryNavigationActive(false);
        }
        setSecondaryNavigationAnimationOnly(false);
    }, [utilities.portfolio.get().Id]);

    useEffect(() => {
        if (utilities.portfolio.get().Id) {
            if (smallBps.includes(bp)) {
                setSecondaryNavigationActive(false);
                setMainNavigationActive(false);
            }
            if (largeBps.includes(bp)) {
                setSecondaryNavigationActive(true);
                setMainNavigationActive(true);
            }
        }
    }, [bp, utilities.portfolio.get().Id]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div
                className={cc([
                    'flex justify-center mt-header',
                    {
                        'lg:ml-nav-combined': utilities.portfolio.get().Id,
                    },
                ])}>
                <div className="w-full max-w-[900px] page-mx">{children}</div>
            </div>
        </>
    );
};

PortfolioCreateLayoutComponent.propTypes = {
    pageProps: t.object,
};

PortfolioCreateLayoutComponent.defaultProps = {
    pageProps: {},
};

export default PortfolioCreateLayoutComponent;
