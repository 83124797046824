// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsResearchComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsResearchDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsResearchDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Research_Importance__c,
        Publication_Title__c,
        Year__c,
        Publisher__c,
        URL__c,
        Author__c,
        DOI__c,
    } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Description */}
            {Research_Importance__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Result__c.Research_Importance__c'
                        ),
                    }}>
                    <p>{Research_Importance__c}</p>
                </CardContentSection>
            )}

            {/* Various Info */}
            {[
                Publication_Title__c,
                Year__c,
                Publisher__c,
                URL__c,
                Author__c,
                DOI__c,
            ].some(x => x) && (
                <CardContentPairBox>
                    {Publication_Title__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Publication_Title__c'
                                ),
                                value: Publication_Title__c,
                            }}
                        />
                    )}

                    {Year__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Research'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {Publisher__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Publisher__c'
                                ),
                                value: Publisher__c,
                            }}
                        />
                    )}

                    {URL__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}

                    {Author__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Author__c'
                                ),
                                value: Author__c,
                            }}
                        />
                    )}

                    {DOI__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.DOI__c'
                                ),
                                value: DOI__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}
        </>
    );
};

ResultsResearchComponent.propTypes = {
    item: t.object,
};

ResultsResearchComponent.defaultProps = {};

export { ResultsResearchDetails };
export default ResultsResearchComponent;
