// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import {
    CardContentPairBox,
    CardContentPair,
} from 'components/cardContentPairBox';

const ResultsInfluenceOnPolicyComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}

            {/* Details */}
            <ResultsInfluenceOnPolicyDetails {...{ item, theme }} />
        </div>
    );
};

const ResultsInfluenceOnPolicyDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, dataSet } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Geographical_Reach__c,
        Additional_Reach_Info__c,
        Influence_Areas__c,
        Year__c,
        URL__c,
    } = item;

    const country = Geographical_Reach__c
        ? dataSet('Countries').find(c => c.value === Geographical_Reach__c)
              ?.label ?? null
        : null;

    const otherLocation = Additional_Reach_Info__c;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Influence_Areas__c, Year__c, URL__c].some(x => x) && (
                <CardContentPairBox>
                    {Influence_Areas__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Influence_Areas__c'
                                ),
                                value: Influence_Areas__c,
                            }}
                        />
                    )}

                    {Year__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Influence_On_Policy'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {URL__c && (
                        <CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c___Influence_On_Policy'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}
                </CardContentPairBox>
            )}

            {/* Reach */}
            {[country, otherLocation].some(x => x) && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Result__c.Geographical_Reach__c'
                        ),
                    }}>
                    <p>
                        {otherLocation && otherLocation}
                        {otherLocation && country && `, `}
                        {country && country}
                    </p>
                </CardContentSection>
            )}
        </>
    );
};

ResultsInfluenceOnPolicyComponent.propTypes = {
    item: t.object,
};

ResultsInfluenceOnPolicyComponent.defaultProps = {};

export { ResultsInfluenceOnPolicyDetails };
export default ResultsInfluenceOnPolicyComponent;
