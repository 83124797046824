// Packages
import create from 'zustand';
import _uniqBy from 'lodash.uniqby';

// Utilities
import ew from 'utilities/api/elseware';

const useDefaultTagsStore = create((set, get) => ({
    tags: {},

    // Get all funder tags for given report
    async populateDefaultTags() {
        // only get tags if they haven't been set yet
        if (Object.keys(get().tags).length) return;

        const { data } = await ew.get({
            path: 'tag/all-funder-tags',
            params: { id: process.env.NEXT_PUBLIC_DEFAULT_TAG_ACCOUNT },
        });

        set(state => ({
            tags: data,
        }));
    },

    getDefaultCollection() {
        if (!Object.keys(get().tags).length) return [];

        // Get default tag collection
        const c = get().tags?.[process.env.NEXT_PUBLIC_DEFAULT_TAG_COLLECTION];

        // Format as Initiative Tag
        return [
            {
                DefaultCollection: true,
                Account_Name__c: 'Test label for stuff',
                Tag_Type__c: 'Collection',
                Tag__c: c.Id,
                Tag__r: c,
            },
        ];
    },

    getDefaultTags(type, collectionId) {
        return (
            Object.values(get().tags).filter(
                item =>
                    item.Type__c === type && item.Collection__c === collectionId
            ) || []
        );
    },

    reset() {
        set(() => ({
            tags: {},
        }));
    },
}));

export { useDefaultTagsStore };
