// Helper function to compare strings (ascending, with null/undefined values last)
function compareNullableStrings(a, b) {
    // If both are null or undefined, consider them equal
    if (a === null && b === null) {
        return 0;
    }

    // If a is null or undefined, it should come after b
    if (a === null || a === undefined) {
        return 1;
    }

    // If b is null or undefined, it should come before a
    if (b === null || b === undefined) {
        return -1;
    }

    // Compare normally for non-null/undefined values
    return a.localeCompare(b);
}

function sortTags(tags) {
    tags.sort(function (a, b) {
        // Compare tag type (ascending, with null values last)
        const typeComparison = compareNullableStrings(a.Type__c, b.Type__c);

        // If tag types are different, return the type comparison result
        if (typeComparison !== 0) {
            return typeComparison;
        }

        // Compare category (ascending, with null values last)
        const categoryComparison = compareNullableStrings(
            a.Category__c,
            b.Category__c
        );

        // If categories are different, return the category comparison result
        if (categoryComparison !== 0) {
            return categoryComparison;
        }

        // Compare sort order (ascending)
        const sortOrderComparison =
            (a.Sort_Order__c || 0) - (b.Sort_Order__c || 0);

        // If sort orders are different, return the sort order comparison result
        if (sortOrderComparison !== 0) {
            return sortOrderComparison;
        }

        // Compare name (ascending)
        return a.Tag_Name__c.localeCompare(b.Tag_Name__c);
    });

    return tags;
}

export default sortTags;
