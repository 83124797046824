// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

const PillComponent = ({ children, size, theme, className, variant }) => {
    const styles = {
        size: {
            large: 't-sh5 px-16 pt-7 pb-5',
            default: 't-sh7 px-8 pt-5 pb-3',
            small: 't-sh7 px-5 pt-2',
        },
        theme: {
            blue: {
                default: 'text-blue-100 bg-blue-20',
                light: 'text-blue-300 bg-blue-10',
                dark: 'text-blue-100 bg-blue-40',
                darkest: 'bg-blue-60 text-white',
                pop: 'bg-blue-300 text-white',
            },
            teal: {
                default: 'text-teal-100 bg-teal-20',
                light: 'text-teal-300 bg-teal-10',
                dark: 'text-teal-10 bg-teal-40',
                darkest: 'bg-teal-60 text-white',
                pop: 'bg-teal-300 text-white',
            },
            coral: {
                default: 'text-coral-100 bg-coral-20',
                light: 'text-coral-300 bg-coral-10',
                dark: 'text-coral-100 bg-coral-40',
                darkest: 'bg-coral-60 text-white',
                pop: 'bg-coral-300 text-white',
            },
            amber: {
                default: 'text-amber-100 bg-amber-20',
                light: 'text-amber-300 bg-amber-10',
                dark: 'text-amber-100 bg-amber-40',
                darkest: 'bg-amber-60 text-white',
                pop: 'bg-amber-300 text-white',
            },
        },
    };

    return (
        <span className="inline">
            <span
                className={cc([
                    'rounded-4 whitespace-nowrap',
                    className,
                    styles.size[size],
                    styles.theme[theme][variant],
                ])}>
                {children}
            </span>
        </span>
    );
};

PillComponent.propTypes = {
    /* Adds additional class names */
    className: t.string,
    /* Button size */
    size: t.oneOf(['small', 'default', 'large']),
    /* Theme */
    theme: t.oneOf(['blue', 'teal', 'coral', 'amber']),
    /* Variant */
    variant: t.oneOf(['default', 'light', 'dark', 'darkest', 'pop']),
};

PillComponent.defaultProps = {
    iconType: 'fill',
    size: 'default',
    theme: 'blue',
    className: '',
    variant: 'default',
};

export default PillComponent;
