// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';

const EvaluationEvaluationComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel, object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Evaluation_Method__c, Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Evaluation type */}
            {Evaluation_Method__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Report_Detail__c.Evaluation_Method__c'
                        ),
                    }}>
                    <p>
                        {getValueLabel(
                            'Initiative_Report_Detail__c.Evaluation_Method__c',
                            Evaluation_Method__c
                        )}
                    </p>
                </CardContentSection>
            )}

            {/* Description */}
            {Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Description__c}</p>
                </CardContentSection>
            )}
        </div>
    );
};

EvaluationEvaluationComponent.propTypes = {
    item: t.object,
};

EvaluationEvaluationComponent.defaultProps = {};

export default EvaluationEvaluationComponent;
