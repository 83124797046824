import {
    FiFileText,
    FiBook,
    FiFileMinus,
    FiFlag,
    FiAward,
} from 'react-icons/fi';

const reportTypeTheme = {
    Annual: [
        'bg-blue-20',
        'text-blue-100',
        'blue',
        <FiFileText className="w-48 h-48" />,
        <FiFileText className="w-24 h-24" />,
    ],
    Final: [
        'bg-teal-20',
        'text-teal-100',
        'teal',
        <FiBook className="w-48 h-48" />,
        <FiBook className="w-24 h-24" />,
    ],
    Status: [
        'bg-coral-20',
        'text-coral-100',
        'coral',
        <FiFileMinus className="w-48 h-48" />,
        <FiFileMinus className="w-24 h-24" />,
    ],
    // Legacy - to be removed at some point
    Milestone: [
        'bg-amber-20',
        'text-amber-100',
        'amber',
        <FiFlag className="w-48 h-48" />,
        <FiFlag className="w-24 h-24" />,
    ],
    PostGrant: [
        'bg-blue-10',
        'text-blue-300',
        'blue',
        <FiAward className="w-48 h-48" />,
        <FiAward className="w-24 h-24" />,
    ],
};

const reportStatusPillTheme = {
    Complete: 'teal',
    'In review': 'teal',
    'In progress': 'blue',
    'Not started': 'amber',
    Overdue: 'coral',
};

export { reportTypeTheme, reportStatusPillTheme };
