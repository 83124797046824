// React
import React from 'react';

// Utilities
import {
    useLabels,
    useContext,
    useInitiativeAndReportData,
} from 'utilities/hooks';

// Packages

// Data

// Components
import SectionContainer from 'components/_report/templates/report_5_0/sectionContainer';
import Collection from 'components/_report/templates/report_5_0/collection';
import { ReportUpdate } from 'components/_report/templates/report_5_0/_cards';

// Card Contents
import ResultsAwardCard from 'components/_cardContents/resultsAward';
import ResultsCompanyCard from 'components/_cardContents/resultsCompany';
import ResultsDisseminationCard from 'components/_cardContents/resultsDissemination';
import ResultsInfluenceCard from 'components/_cardContents/resultsInfluenceOnPolicy';
import ResultsIntellectualPropertyCard from 'components/_cardContents/resultsIntellectualProperty';
import ResultsInventionDisclosureCard from 'components/_cardContents/resultsInventionDisclosure';
import ResultsInnovationCard from 'components/_cardContents/resultsInnovation';
import ResultsKnowledgeCard from 'components/_cardContents/resultsKnowledge';
import ResultsOutputCard from 'components/_cardContents/resultsCustomOutput';
import ResultsPatentsCard from 'components/_cardContents/resultsPatents';
import ResultsProductsAndInventionsCard from 'components/_cardContents/resultsProductsAndInventions';
import ResultsResearchCard from 'components/_cardContents/resultsResearch';

const SectionResultsComponent = ({
    utilities,
    items: navigationItems,
    CONSTANTS,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();
    const { label, getValueLabel } = useLabels();
    const { legacyMode } = useInitiativeAndReportData();

    // ///////////////////
    // DATA
    // ///////////////////

    const reflectionIds = utilities.reportDetails
        .getTypeResultsFromReportId(REPORT_ID)
        .map(reflection => reflection.Initiative_Result__c);

    // Get items with reflection
    const items = legacyMode
        ? utilities.results.getAll()
        : utilities.results
              .getAll()
              .filter(item => reflectionIds.includes(item.Id))
              .sort(
                  (a, b) =>
                      new Date(b.LastModifiedDate) -
                      new Date(a.LastModifiedDate)
              );

    const navigationItem = navigationItems[0] ?? {};

    const contentCards = {
        [CONSTANTS.RESULTS.AWARD](props) {
            return <ResultsAwardCard {...props} />;
        },
        [CONSTANTS.RESULTS.COMPANY](props) {
            return <ResultsCompanyCard {...props} />;
        },
        [CONSTANTS.RESULTS.CUSTOM_OUTPUT](props) {
            return <ResultsOutputCard {...props} />;
        },
        [CONSTANTS.RESULTS.DISSEMINATION](props) {
            return <ResultsDisseminationCard {...props} />;
        },
        [CONSTANTS.RESULTS.INFLUENCE_ON_POLICY](props) {
            return <ResultsInfluenceCard {...props} />;
        },
        [CONSTANTS.RESULTS.INTELLECTUAL_PROPERTY](props) {
            return <ResultsIntellectualPropertyCard {...props} />;
        },
        [CONSTANTS.RESULTS.INVENTION_DISCLOSURE](props) {
            return <ResultsInventionDisclosureCard {...props} />;
        },
        [CONSTANTS.RESULTS.KNOWLEDGE](props) {
            return <ResultsKnowledgeCard {...props} />;
        },
        [CONSTANTS.RESULTS.PATENTS](props) {
            return <ResultsPatentsCard {...props} />;
        },
        [CONSTANTS.RESULTS.PRODUCTS_AND_INVENTIONS](props) {
            return <ResultsProductsAndInventionsCard {...props} />;
        },
        [CONSTANTS.RESULTS.RESEARCH](props) {
            return <ResultsResearchCard {...props} />;
        },
        // Legacy
        [CONSTANTS.RESULTS.INNOVATION](props) {
            return <ResultsInnovationCard {...props} />;
        },
    };

    const postTitles = {
        [CONSTANTS.RESULTS.AWARD](item) {
            return getValueLabel(
                'Initiative_Result__c.Award_Type__c',
                item.Award_Type__c
            );
        },
        [CONSTANTS.RESULTS.COMPANY](item) {
            return getValueLabel(
                'Initiative_Result__c.Company_Type__c',
                item.Company_Type__c
            );
        },
        [CONSTANTS.RESULTS.CUSTOM_OUTPUT](item) {
            return null;
        },
        [CONSTANTS.RESULTS.DISSEMINATION](item) {
            return getValueLabel(
                'Initiative_Result__c.Publication_Method__c',
                item.Publication_Method__c
            );
        },
        [CONSTANTS.RESULTS.INFLUENCE_ON_POLICY](item) {
            return getValueLabel(
                'Initiative_Result__c.Influence_Type__c',
                item.Influence_Type__c
            );
        },
        [CONSTANTS.RESULTS.INTELLECTUAL_PROPERTY](item) {
            return null;
        },
        [CONSTANTS.RESULTS.INVENTION_DISCLOSURE](item) {
            return getValueLabel(
                'Initiative_Result__c.Invention_Type__c',
                item.Invention_Type__c
            );
        },
        [CONSTANTS.RESULTS.KNOWLEDGE](item) {
            return getValueLabel(
                'Initiative_Result__c.Knowledge_Type__c',
                item.Knowledge_Type__c
            );
        },
        [CONSTANTS.RESULTS.PATENTS](item) {
            return getValueLabel(
                'Initiative_Result__c.Patent_Status__c',
                item.Patent_Status__c
            );
        },
        [CONSTANTS.RESULTS.PRODUCTS_AND_INVENTIONS](item) {
            return getValueLabel(
                'Initiative_Result__c.Intervention_Method__c',
                item.Intervention_Method__c
            );
        },
        [CONSTANTS.RESULTS.RESEARCH](item) {
            return getValueLabel(
                'Initiative_Result__c.Research_Importance__c',
                item.Research_Importance__c
            );
        },
        // Legacy
        [CONSTANTS.RESULTS.INNOVATION](item) {
            return null;
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col py-48 space-y-24">
            <SectionContainer
                {...{
                    item: navigationItem,
                }}>
                <Collection
                    {...{
                        collection: {
                            items,
                            emptyLabel: label('EmptyStateWizardPageResults'),
                        },
                        card: {
                            preTitle(item) {
                                return getValueLabel(
                                    'Initiative_Result__c.Type__c',
                                    item.Type__c
                                );
                            },
                            title(item) {
                                return item?.Name__c || item?.Name;
                            },
                            postTitle(item) {
                                return (
                                    postTitles[item?.Type__c]?.(item) || null
                                );
                            },
                            components(item) {
                                return {
                                    cardContent: contentCards[item?.Type__c]
                                        ? contentCards[item?.Type__c]({
                                              item,
                                              theme: 'blue',
                                              utilities,
                                              CONSTANTS,
                                          })
                                        : null,
                                    reportUpdate(additinalProps) {
                                        return (
                                            <ReportUpdateResult
                                                {...{
                                                    additinalProps,
                                                    item,
                                                    CONSTANTS,
                                                    utilities,
                                                }}
                                            />
                                        );
                                    },
                                };
                            },
                        },
                    }}
                />
            </SectionContainer>
        </div>
    );
};

const ReportUpdateResult = ({ additinalProps, item, CONSTANTS, utilities }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <ReportUpdate
            {...{
                ...additinalProps,
                CONSTANTS,
                utilities,
                reflection: {
                    item,
                    relationKey: 'Initiative_Result__c',
                    type: CONSTANTS.REPORT_DETAILS.RESULTS,
                },
            }}
        />
    );
};

SectionResultsComponent.propTypes = {};

SectionResultsComponent.defaultProps = {};

export default SectionResultsComponent;
