// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Components

// Icons

const SpinnerComponent = ({ theme, className }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const color = {
        teal: 'text-teal-100',
        blue: 'text-blue-100',
        blueAlt: 'text-blue-10',
        amber: 'text-amber-100',
        coral: 'text-coral-100',
        white: 'text-blue-10',
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <svg
            className={cc([
                'className="w-20 h-20 mr-3 -ml-1 animate-spin',
                color[theme],
                className,
            ])}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
            />
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>
    );
};

SpinnerComponent.propTypes = {
    theme: t.oneOf(['teal', 'blue', 'amber', 'coral', 'white', 'blue-alt']),
};

SpinnerComponent.defaultProps = {
    theme: 'teal',
};

export default SpinnerComponent;
