// React
import React, { useState } from 'react';

// Packages
import t from 'prop-types';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import Button from 'components/button';
import Tags from 'components/tags';

// Icons
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const ChildCollectionComponent = ({ title, item, collection }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const format = useFormat();

    // ///////////////////
    // STATE
    // ///////////////////

    const [expandedContent, setExpandedContent] = useState(false);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div className="flex flex-col p-16 mt-16 bg-blue-10 rounded-8">
                {/* Top row wrapper */}
                <h6 className="flex items-center justify-between t-h6">
                    {/* Title */}
                    <span className="relative top-2">
                        {title} ({collection?.items(item).length})
                    </span>
                    {/* Collection controls */}
                    <div className="flex h-40 space-x-4">
                        {/* Expand */}
                        {collection?.items(item).length > 0 && (
                            <Button
                                title={label('ButtonExpandCollapse')}
                                variant="tertiary"
                                theme="blue"
                                icon={
                                    expandedContent
                                        ? FiChevronUp
                                        : FiChevronDown
                                }
                                iconPosition="center"
                                iconType="stroke"
                                className="!px-8"
                                action={() =>
                                    setExpandedContent(!expandedContent)
                                }
                            />
                        )}
                    </div>
                </h6>
                {/* Collection items */}
                <AnimateHeight
                    duration={300}
                    animateOpacity={true}
                    height={expandedContent ? 'auto' : 0}>
                    <div className="flex flex-col mt-16 space-y-12">
                        {collection?.items(item).map(childItem => (
                            // Item wrapper
                            <div
                                key={childItem.Id}
                                className="flex flex-col p-16 space-y-12 md:space-y-0 md:space-x-24 md:items-center md:justify-between md:flex-row bg-blue-20 rounded-8">
                                {/* Metric */}
                                <div className="flex flex-col justify-center shrink col-span-5">
                                    {/* Pre title */}
                                    {collection?.preTitle &&
                                        collection?.preTitle(childItem) && (
                                            <span className="t-sh6 text-blue-60">
                                                {collection?.preTitle(
                                                    childItem
                                                )}
                                            </span>
                                        )}
                                    {/* Title */}
                                    <h5 className="text-blue-100 truncate t-h5">
                                        {collection?.title(childItem)}
                                    </h5>
                                    {/* Post title */}
                                    {collection?.postTitle &&
                                        collection?.postTitle(childItem) && (
                                            <span className="t-footnote text-blue-60">
                                                {collection?.postTitle(
                                                    childItem
                                                )}
                                            </span>
                                        )}
                                    {/* Tagging */}
                                    {collection?.taggingItems && (
                                        <Tags
                                            {...{
                                                tags: collection?.taggingItems(
                                                    childItem
                                                ),
                                                theme: 'blue',
                                                variant: 'darkest',
                                                className: 'mt-12',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </AnimateHeight>
            </div>
        </>
    );
};

ChildCollectionComponent.propTypes = {
    title: t.string.isRequired,
    item: t.object.isRequired,
    collection: t.shape({
        preTitle: t.func,
        title: t.func.isRequired,
        postTitle: t.func,
        items: t.func.isRequired,
    }),
};

ChildCollectionComponent.defaultProps = {
    title: '',
    collection: {
        title: '',
        preTitle: null,
        postTitle: null,
        items: [],
    },
};

export default ChildCollectionComponent;
