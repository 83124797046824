// React
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { Transition } from '@headlessui/react';

// Utilities
import { useLabels } from 'utilities/hooks';
import { docRaptor } from 'utilities/api';

// Components
import Button from 'components/button';
import Spinner from 'components/spinner';

const PdfModalComponent = ({ isOpen, onCancel, title, children }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [generating, setGenerating] = useState(false);
    const [showContent, setShowContent] = useState(false);

    // ///////////////////
    // REFS
    // ///////////////////

    const modalRef = useRef(null);
    const pdfRef = useRef(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    async function generatePdf() {
        setGenerating(true);
        await docRaptor.createPdf({
            content: pdfRef.current.innerHTML,
            title,
        });
        setTimeout(() => {
            setGenerating(false);
            onCancel();
        }, 5000);
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: Delay content fade in
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setShowContent(true);
            }, 200);
        } else {
            setShowContent(false);
        }
    }, [isOpen]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return createPortal(
        <Transition
            show={isOpen}
            enter="transition-medium"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-default"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 flex justify-center bg-opacity-25 bg-blue-120 z-modal">
            <Transition
                show={showContent}
                enter="transition-medium"
                enterFrom="opacity-0 translate-y-10"
                enterTo="opacity-100 translate-y-0"
                leave="transition-default"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute inset-0 flex items-end justify-center sm:items-center">
                <div
                    ref={modalRef}
                    style={{ width: '21cm', height: '29.7cm' }}
                    className="flex flex-col w-full max-h-[90%] max-w-[90%] p-18 lg:p-[30px] bg-white rounded-t-16 sm:rounded-16 sm:mx-16">
                    <div className="overflow-x-hidden overflow-y-auto scrolling-touch">
                        <div ref={pdfRef}>{children}</div>
                    </div>

                    {/* Modal actions */}
                    <div className="flex items-center justify-between mt-32 space-x-16">
                        <div className="flex items-center space-x-16">
                            <p
                                className={cc([
                                    't-footnote text-coral-60 md:flex transition-default',
                                    {
                                        'opacity-0': !generating,
                                        'opacity-100': generating,
                                    },
                                ])}>
                                {label('MessageGeneratingPdf')}
                            </p>
                            <Spinner
                                theme="coral"
                                className={cc([
                                    'transition-default',
                                    {
                                        'opacity-0': !generating,
                                        'opacity-100': generating,
                                    },
                                ])}
                            />
                        </div>
                        <div className="flex space-x-16">
                            <Button
                                variant="tertiary"
                                theme="coral"
                                action={onCancel}>
                                {label('ButtonCancel')}
                            </Button>
                            <Button
                                theme="coral"
                                action={generatePdf}
                                disabled={generating}>
                                {label('ButtonGeneratePdf')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Transition>
            <style global jsx>{`
                html {
                    overflow-y: hidden;
                }
            `}</style>
        </Transition>,
        document.getElementById('modal')
    );
};

PdfModalComponent.propTypes = {
    isOpen: t.bool,
};

PdfModalComponent.defaultProps = {
    isOpen: false,
};

export default PdfModalComponent;
