import React, { useEffect } from 'react';

// TrackJS
import { TrackJS } from 'trackjs';

// Next
import Head from 'next/head';

// Packages and polyfills
import { motion, AnimatePresence } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';

// Style
import 'styles/foundgood.css';

// Utilities
import {
    useReportNavigationStore,
    useWizardNavigationStore,
    useInitiativeNavigationStore,
    useInitiativeDataStore,
    usePortfolioStore,
    useDashboardStore,
    useDynamicLabelsDataStore,
    useDefaultTagsStore,
} from 'utilities/store';
import { useContext, useUser, usePreserveScroll } from 'utilities/hooks';

// Components
import LayoutWrapper from 'components/_layout/layoutWrapper';
import LoginTimeout from 'components/loginTimeout';

if (!TrackJS.isInstalled()) {
    if (
        typeof window !== 'undefined' &&
        window.location.hostname !== 'localhost'
    ) {
        TrackJS.install({
            token: process.env.NEXT_PUBLIC_TRACKJS,
        });
    }
}

function MyApp({ Component, pageProps }) {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        reset: resetWizardItems,
        buildItems: buildWizardItems,
    } = useWizardNavigationStore();
    const {
        reset: resetInitiativeItems,
        buildItems: buildInitiativeItems,
    } = useInitiativeNavigationStore();
    const {
        reset: resetReportItems,
        buildItems: buildReportItems,
    } = useReportNavigationStore();
    const {
        utilities,
        reset: resetInitiativeData,
        populateInitiative,
        populateFunderTags,
    } = useInitiativeDataStore();
    const {
        getAllInitiatives: getAllInitiativesForDashboard,
    } = useDashboardStore();
    const {
        getAllInitiatives: getAllInitiativesForPortfolio,
        populatePortfolio,
        reset: resetPortfolioData,
    } = usePortfolioStore();
    const { populateTagLabels } = useDynamicLabelsDataStore();
    const { populateDefaultTags } = useDefaultTagsStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const {
        CONTEXT,
        REPORT_ID,
        INITIATIVE_ID,
        PORTFOLIO_ID,
        ACTION,
    } = useContext();
    const {
        getUserAccountType,
        getUserInitiativeTeamRole,
        getUserAccountId,
        getUserLoggedIn,
    } = useUser();
    usePreserveScroll();

    // ///////////////////
    // METHODS
    // ///////////////////

    function getLayoutKey() {
        switch (Component.layout) {
            case 'blank':
                return 0;
            case 'initiative':
            case 'default':
            case 'dashboard':
                return 1;
            case 'wizard':
            case 'report':
                return 2;
            default:
                return 0;
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Populate initiative
    useEffect(() => {
        console.log('_app.populateInitiative');
        if (getUserLoggedIn()) {
            switch (Component.layout) {
                case 'blank':
                case 'default':
                    resetInitiativeData();
                    resetPortfolioData();
                    break;
                case 'dashboard':
                    getAllInitiativesForDashboard(
                        getUserAccountType(),
                        getUserAccountId()
                    );
                    break;
                case 'portfolio':
                    getAllInitiativesForPortfolio(
                        getUserAccountType(),
                        getUserAccountId()
                    );
                    break;
                case 'portfolioCreate':
                    populatePortfolio(PORTFOLIO_ID);
                    getAllInitiativesForPortfolio(
                        getUserAccountType(),
                        getUserAccountId()
                    );
                    break;
                case 'initiative':
                    populateInitiative(INITIATIVE_ID);
                    break;
                case 'wizard':
                    populateInitiative(INITIATIVE_ID);
                    break;
                case 'report':
                    populateInitiative(INITIATIVE_ID);
                    break;
                default:
                    resetInitiativeData();
            }
        }
    }, [
        Component.layout,
        PORTFOLIO_ID,
        INITIATIVE_ID,
        REPORT_ID,
        getUserAccountType(),
        getUserAccountId(),
    ]);

    // Populate funder tags and funder labels
    useEffect(() => {
        console.log('_app.populateFunderTags');
        if (utilities.initiative.get().Id) {
            // Always get tag labels
            populateTagLabels(utilities.initiative.get());

            // Populate funder tags for wizards
            switch (Component.layout) {
                case 'wizard':
                    populateFunderTags();
                    break;
            }
        }
    }, [Component.layout, utilities.initiative.get().Id]);

    // Populate default tags
    useEffect(() => {
        console.log('_app.populateDefaultTags');

        if (getUserLoggedIn()) {
            // Populate default tags for wizards
            populateDefaultTags();
        }
    }, [Component.layout, getUserLoggedIn()]);

    // Update navigation items
    useEffect(() => {
        console.log('_app.buildNavigationItems');
        const buildArgs = {
            context: CONTEXT,
            action: ACTION,
            initiativeType:
                utilities.initiative.get().Configuration_Type__c || 'Advanced',
            reportType:
                utilities.reports.get(REPORT_ID)?.Report_Type__c ?? null,
            user: {
                userAccountType: getUserAccountType(),
                userInitiativeTeamRole: getUserInitiativeTeamRole(),
                userAccountId: getUserAccountId(),
            },
        };

        switch (Component.layout) {
            case 'blank':
            case 'default':
                resetWizardItems();
                resetInitiativeItems();
                resetReportItems();
                break;
            case 'initiative':
                resetWizardItems();
                resetReportItems();
                buildInitiativeItems(buildArgs);
                break;
            case 'wizard':
                resetInitiativeItems();
                resetReportItems();
                buildWizardItems(buildArgs);
                break;
            case 'report':
                resetWizardItems();
                resetInitiativeItems();
                buildReportItems(buildArgs);
                break;
            default:
                resetWizardItems();
                resetInitiativeItems();
                resetReportItems();
                break;
        }
    }, [
        Component.layout,
        INITIATIVE_ID,
        REPORT_ID,
        utilities.initiative.get().Configuration_Type__c,
        getUserInitiativeTeamRole(),
    ]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <Head>
                <title>Foundgood</title>
                <link rel="icon" href="/favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
            </Head>
            <AnimatePresence>
                <motion.div
                    key={getLayoutKey()}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}>
                    <LayoutWrapper
                        {...{
                            layout: Component.layout,
                            layoutSettings: Component.layoutSettings,
                            pageProps,
                        }}>
                        <ErrorBoundary
                            fallback={<p>Something went wrong.</p>}
                            onError={error => TrackJS.track(error)}>
                            <Component {...{ pageProps }} />
                        </ErrorBoundary>
                    </LayoutWrapper>
                </motion.div>
            </AnimatePresence>
            <LoginTimeout />
        </>
    );
}

export default MyApp;
