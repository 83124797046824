// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useTagging, useFormat } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import Tags from 'components/tags';

const ActivityEvaluationComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();
    const { dataSet, getValueLabel, object, label } = useLabels();
    const tagging = useTagging({
        relationKey: 'Initiative_Activity__c',
    });

    // ///////////////////
    // DATA
    // ///////////////////

    const { Activity_Start_Date__c, Activity_End_Date__c } = item;

    const country = item?.Initiative_Location__c
        ? dataSet('Countries').find(
              c => c.value === item?.Initiative_Location__c
          )?.label ?? null
        : null;

    const otherLocation = item?.Additional_Location_Information__c;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Evaluation tyåe */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Activity__c.Whos_Evaluating__c'
                    ),
                }}>
                <p>
                    {getValueLabel(
                        'Initiative_Activity__c.Whos_Evaluating__c',
                        item?.Whos_Evaluating__c
                    )}
                </p>
            </CardContentSection>

            {/* Description */}
            <CardContentSection
                {...{
                    theme,
                }}>
                <p>{item?.Things_To_Do_Description__c}</p>
            </CardContentSection>

            {/* Start date / End date  */}
            {Activity_Start_Date__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Dates_Label'
                        ),
                    }}>
                    <p>
                        {format.date(Activity_Start_Date__c)}
                        {Activity_End_Date__c
                            ? ` - ${format.date(Activity_End_Date__c)}`
                            : ''}
                    </p>
                </CardContentSection>
            )}

            {/* Location */}
            {[country, otherLocation].some(x => x) && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Initiative_Location__c'
                        ),
                    }}>
                    <p>
                        {otherLocation && otherLocation}
                        {otherLocation && country && `, `}
                        {country && country}
                    </p>
                </CardContentSection>
            )}

            {/* Tags */}
            {tagging?.getCurrentTags(item).length > 0 && (
                <CardContentSection
                    {...{
                        theme,
                        title: label('DetailsTagsActivities'),
                    }}>
                    <Tags
                        {...{
                            theme,
                            tags: tagging?.getCurrentTags(item),
                            className: 'mt-4',
                        }}
                    />
                </CardContentSection>
            )}
        </div>
    );
};

ActivityEvaluationComponent.propTypes = {
    item: t.object,
};

ActivityEvaluationComponent.defaultProps = {};

export default ActivityEvaluationComponent;
