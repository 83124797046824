// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components
import BorderBox from 'components/borderBox';

const EmptyStateComponent = ({ text }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <BorderBox
            theme="blue"
            background
            dark
            className="items-center space-y-12">
            <p className="text-blue-60">{text}</p>
        </BorderBox>
    );
};

EmptyStateComponent.propTypes = {
    text: t.string,
};

EmptyStateComponent.defaultProps = {
    text: '',
};

export default EmptyStateComponent;
