// React
import React, { useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Utilities
import { useLayoutStore } from 'utilities/store';
import { useResponsive } from 'utilities/hooks';

// Components

const DefaultLayoutComponent = ({ children, pageProps, layoutSettings }) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const {
        setMainNavigationActive,
        setMainNavigationWide,
        setSecondaryNavigationActive,
        setSecondaryNavigationAnimationOnly,
    } = useLayoutStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const bp = useResponsive();

    // ///////////////////
    // DATA
    // ///////////////////

    const smallBps = ['2xs', 'xs', 'sm', 'md'];
    const largeBps = ['lg', 'xl', '2xl', '3xl'];

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        setTimeout(() => {
            setMainNavigationWide(true);
            setSecondaryNavigationActive(false);
            setSecondaryNavigationAnimationOnly(true);
        }, 100);
    }, []);

    useEffect(() => {
        if (smallBps.includes(bp)) {
            setMainNavigationWide(false);
            setMainNavigationActive(false);
        }
        if (largeBps.includes(bp)) {
            setMainNavigationWide(true);
            setMainNavigationActive(true);
        }
    }, [bp]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="lg:ml-nav-wide mt-header pt-80 transition-default">
            <div
                className={cc([
                    'page-mx',
                    {
                        'max-w-[900px]': !layoutSettings.fullWidth,
                    },
                ])}>
                {children}
            </div>
        </div>
    );
};

DefaultLayoutComponent.propTypes = {
    pageProps: t.object,
};

DefaultLayoutComponent.defaultProps = {
    pageProps: {},
};

export default DefaultLayoutComponent;
