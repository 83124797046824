// React
import React, { useMemo } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Components

const PieChartComponent = ({ data, annotation, legend }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const colors = [
        '#507C93', // bg-teal-60
        '#545E92', // bg-blue-60
        '#995B57', // bg-coral-60
        '#977958', // bg-amber-60
        '#1C5471', // bg-teal-100
        '#223070', // bg-blue-100
        '#782C28', // bg-coral-100
        '#76502A', // bg-amber-100
        '#548DBB', // bg-teal-300
        '#4355B8', // bg-blue-300
        '#B15446', // bg-coral-300
        '#B7894D', // bg-amber-300
        '#AAC5D4',
        '#5D75F8',
        '#F8B55D',
        '#F86C5D',
        '#5DBDF8',
        '#384170',
        '#705838',
        '#703E38',
        '#385B70',
    ];

    // Get total value
    const totalValue = useMemo(
        () => data.reduce((acc, x) => acc + x.value, 0),
        [data]
    );

    // Create chart data
    const chartData = useMemo(() => {
        const baseData = data.map((x, index) => ({
            ...x,
            color: colors[index],
            value: x.value ?? 0,
            degrees: x.value ? (x.value / totalValue) * 359.9999 : 0,
        }));

        let currentDegrees = 0;
        let enrichedData = [];
        for (const section of baseData) {
            enrichedData = [...enrichedData, { ...section, currentDegrees }];
            currentDegrees = currentDegrees + section.degrees;
        }

        return enrichedData;
    }, [totalValue]);

    // Legend data
    const sortedLegendData = useMemo(
        () =>
            [...chartData].sort((a, b) =>
                legend.key(a).localeCompare(legend.key(b))
            ),
        []
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex justify-between w-full space-x-32">
            <div className="w-6/12">
                <Chart
                    {...{
                        data: chartData,
                        totalValue,
                        annotation,
                        tooltip,
                    }}
                />
            </div>
            <div className="w-6/12 pt-32">
                <div className="p-24 border-2 border-blue-20 rounded-8">
                    <div className="mb-16 t-caption-bold">{legend.title}</div>
                    <div>
                        {sortedLegendData.map((item, index) => (
                            <div
                                key={legend.key(item)}
                                className="py-4 t-footnote">
                                {legend.label(item)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

PieChartComponent.propTypes = {
    heading: t.string.isRequired,
    data: t.arrayOf(t.shape({ name: t.string, value: t.number })).isRequired,
    annotation: t.shape({
        chartTitle: t.string.isRequired,
        chartDataLabel: t.func.isRequired,
    }),
    legend: t.shape({
        title: t.string.isRequired,
        label: t.func.isRequired,
    }).isRequired,
};

PieChartComponent.defaultProps = {};

const Chart = ({ data, totalValue, annotation }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <div className="relative flex items-center justify-center">
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 100"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    {data.map((section, index) => {
                        return (
                            <CircleArc
                                key={section.value + section.color + index}
                                {...{ section }}
                            />
                        );
                    })}
                </svg>
                <div className="absolute w-full -mt-16 text-center pointer-events-none top-1/2">
                    <p className="t-caption text-blue-60">
                        {annotation?.chartTitle}
                    </p>
                    <p className="t-h5">
                        {annotation?.chartDataLabel(totalValue)}
                    </p>
                </div>
            </div>
        </>
    );
};

const CircleArc = ({ section }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    // ///////////////////
    // DATA
    // ///////////////////

    const cos = Math.cos;
    const sin = Math.sin;
    const π = Math.PI;

    // ///////////////////
    // METHODS
    // ///////////////////

    function f_matrix_times([[a, b], [c, d]], [x, y]) {
        return [a * x + b * y, c * x + d * y];
    }

    function f_rotate_matrix(x) {
        return [
            [cos(x), -sin(x)],
            [sin(x), cos(x)],
        ];
    }

    function f_vec_add([a1, a2], [b1, b2]) {
        return [a1 + b1, a2 + b2];
    }

    function degreesToRadian(degrees) {
        return (degrees * Math.PI) / 180;
    }

    function generateArc([cx, cy], [rx, ry], [t1, Δ], φ, section) {
        /* [
            returns a SVG path element that represent a ellipse.
            cx,cy → center of ellipse
            rx,ry → major minor radius
            t1 → start angle, in radian.
            Δ → angle to sweep, in radian. positive.
            φ → rotation on the whole, in radian
            URL: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
            Version 2019-06-19
        ] */

        Δ = Δ % (2 * π);
        const rotMatrix = f_rotate_matrix(φ);
        const [sX, sY] = f_vec_add(
            f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]),
            [cx, cy]
        );

        const [eX, eY] = f_vec_add(
            f_matrix_times(rotMatrix, [rx * cos(t1 + Δ), ry * sin(t1 + Δ)]),
            [cx, cy]
        );
        const fA = Δ > π ? 1 : 0;
        const fS = Δ > 0 ? 1 : 0;

        return sX && sY ? (
            <path
                stroke={section.color}
                strokeWidth={10}
                fill="transparent"
                className="stroke-[13px]"
                d={`M ${sX} ${sY} A ${[
                    rx,
                    ry,
                    (φ / (2 * π)) * 360,
                    fA,
                    fS,
                    eX,
                    eY,
                ].join(' ')}`}
            />
        ) : null;
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const cx = 50;
    const cy = 50;
    const rx = 40;
    const ry = 40;

    const startAngle = degreesToRadian(-90);
    const sweep = degreesToRadian(section.degrees);
    const rotation = degreesToRadian(section.currentDegrees);

    // ///////////////////
    // RENDER
    // ///////////////////

    return generateArc(
        [cx, cy],
        [rx, ry],
        [startAngle, sweep],
        rotation,
        section
    );
};

export default PieChartComponent;
