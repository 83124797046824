// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import ActivityTags from '../_common/activityTags';
import Pill from 'components/pill';
import CardContentSection from 'components/cardContentSection';
import CardContentSectionBox from 'components/cardContentSectionBox';

const ActivityPhysicalComponent = ({ item, theme = 'teal', utilities }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();
    const { object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Things_To_Do_Description__c,
        Activity_Start_Date__c,
        Activity_End_Date__c,
    } = item;

    // ///////////////////
    // DATA
    // ///////////////////

    const regulations = utilities.regulations.getFromActivityId(item.Id);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col my-16 space-y-12">
            {/* Description */}
            {Things_To_Do_Description__c && (
                <CardContentSection
                    {...{
                        theme,
                    }}>
                    <p>{Things_To_Do_Description__c}</p>
                </CardContentSection>
            )}

            {/* Start date / End date  */}
            {Activity_Start_Date__c && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Dates_Label'
                        ),
                    }}>
                    <p>
                        {format.date(Activity_Start_Date__c)}
                        {Activity_End_Date__c
                            ? ` - ${format.date(Activity_End_Date__c)}`
                            : ''}
                    </p>
                </CardContentSection>
            )}

            {/* Details */}
            <ActivityPhysicalDetails {...{ item, theme, regulations }} />

            {/* Tags */}
            <ActivityTags {...{ item, theme }} />
        </div>
    );
};

const ActivityPhysicalDetails = ({ item, theme, regulations = [] }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel, object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Financial_Value__c,
        CurrencyIsoCode,
        Construction_Start_Date__c,
        Construction_End_Date__c,
    } = item;
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Value */}
            <CardContentSection
                {...{
                    theme,
                    title: object.label(
                        'Initiative_Activity__c.Financial_Value__c'
                    ),
                }}>
                <p>{format.currency(Financial_Value__c, CurrencyIsoCode)}</p>
            </CardContentSection>

            {/* Regulations */}
            {regulations.length > 0 && (
                <CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Activity__c.Regulations'
                        ),
                    }}>
                    {regulations.map(x => (
                        <div
                            key={x.Id}
                            className={cc([
                                'flex justify-between p-16 mt-8 border-2 rounded-8',
                                {
                                    'border-teal-20': theme === 'teal',
                                    'border-blue-20': theme === 'blue',
                                },
                            ])}>
                            <div className="flex flex-col mt-4">
                                <span className="t-sh6">
                                    {getValueLabel(
                                        'Initiative_Activity_Regulation__c.Type__c',
                                        x?.Type__c
                                    )}
                                </span>
                                <span className="t-h5">{x?.Id__c}</span>
                            </div>
                            <div className="flex flex-col mt-4">
                                <span className="t-sh6">
                                    {object.label(
                                        'Initiative_Activity_Regulation__c.Importance__c'
                                    )}
                                </span>
                                <Pill theme={theme}>
                                    {getValueLabel(
                                        'Initiative_Activity_Regulation__c.Importance__c',
                                        x?.Importance__c
                                    )}
                                </Pill>
                            </div>
                        </div>
                    ))}
                </CardContentSection>
            )}

            {/* Start date / End date  */}
            {Construction_Start_Date__c && (
                <div className="flex !mt-24 space-x-16">
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Activity__c.Construction_Start_Date__c'
                            ),
                        }}>
                        <p>{format.date(Construction_Start_Date__c)}</p>
                    </CardContentSectionBox>
                    <CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Activity__c.Construction_End_Date__c'
                            ),
                        }}>
                        <p>
                            {Construction_End_Date__c
                                ? format.date(Construction_End_Date__c)
                                : '-'}
                        </p>
                    </CardContentSectionBox>
                </div>
            )}
        </>
    );
};

ActivityPhysicalComponent.propTypes = {
    item: t.object,
};

ActivityPhysicalComponent.defaultProps = {};

export { ActivityPhysicalDetails };
export default ActivityPhysicalComponent;
