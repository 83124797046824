// React
import React from 'react';

import Image from "next/legacy/image";

// Components
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';

const HeaderImageComponent = ({ initiative }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return initiative?.Hero_Image_URL__c ? (
        <FullWidthWrapper paddingX={false}>
            <div className="relative flex h-[400px] w-full">
                <Image
                    layout="fill"
                    objectFit="cover"
                    src={initiative?.Hero_Image_URL__c}
                />
            </div>
        </FullWidthWrapper>
    ) : null;
};

HeaderImageComponent.propTypes = {};

HeaderImageComponent.defaultProps = {};

export default HeaderImageComponent;
